#annualReport2021research-overview-container {
    position: relative;
    top: 0;
    height: fit-content;
    width: 100%;
    background-color: #C8D8DA;
    display: flex;
    flex-direction: column;
    border-radius: 0% 90vw 0% 0% / 0% 5vw 0% 0%;
}

.annualReport2021research-overview-text-container {
    text-align: justify;
    margin: -3vw 12vw 0;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021research-overview-text-container h1 {
    color: #d14e1d;
    max-width: 60vw;
    font-size: calc(20px + 2vw);
    text-align: left;
}

.annualReport2021research-overview-text-container p {
    color: #00464f;
    font-size: calc(12px + .5vw);
}

.annualReport2021research-overview-text-container a {
    color: #007a8a;
}

@media only screen and (max-width: 1150px) {

    #annualReport2021research-overview-container {
        border-radius: 0% 90vw 0% 0% / 0% 10vw 0% 0%;
    }

}

@media only screen and (max-width: 850px) {

    #annualReport2021research-overview-container {
        border-radius: 10vw 90vw 0% 0% / 5vw 15vw 0% 0%;
    }

}

@media only screen and (max-width: 375px) {

    #annualReport2021research-overview-container {
        border-radius: 25vw 90vw 0% 0% / 15vw 23vw 0% 0%;
    }

}
.educatorResources-testimonials-content-container {
    display: block;
    width: 100%;
}

#educatorResources-testimonials-Slideshow-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 50px 0;
}

.educatorResources-testimonials-Slideshow-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.educatorResources-testimonials-Slideshow-slider {
    width: 1050px;
    height: 100%;
}

.educatorResources-testimonials-Slideshow-slider ul {
    background-color: #FFFFFF;
    height: 50px;
}

.educatorResources-testimonials-Slideshow-slider ul > li > button::before {
    color: gray;
    font-size: 20px;
}

.educatorResources-testimonials-Slideshow-slider ul li.slick-active > button::before {
    color: #d14e1d;
}ƒ


.educatorResources-testimonials-Slideshow-slider > div > div > div > div > * {
    padding: 0 50px;
}

.educatorResources-testimonials-Slideshow-card {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.educatorResources-testimonials-Slideshow-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.educatorResources-testimonials-Slideshow-card-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
}

.educatorResources-testimonials-Slideshow-quoteSymbol {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00464f;
    font-size: 70px;
}

.educatorResources-testimonials-Slideshow-text {
    display: flex;
    text-align: left;
    flex-direction: column;
}

.educatorResources-testimonials-Slideshow-text > h2 {
    color: #111111;
    font-family: 'Helvetica Neue Medium',Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.educatorResources-testimonials-Slideshow-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 20px;
    line-height: 30px;
}

.educatorResources-testimonials-Slideshow-text > a {
    color: #d14e1d;
}


@media only screen and (max-width: 1000px) {
    .educatorResources-testimonials-Slideshow-slider {
        width: 100vw;
    }
}

@media only screen and (max-width: 675px) {
    .educatorResources-testimonials-Slideshow-card-content-container {
        max-width: 70vw;
    }
}

@media only screen and (max-width: 600px) {
    #educatorResources-testimonials-Slideshow-container {
        margin: 10px 0 70px 0;
    }

    .educatorResources-testimonials-Slideshow-card {
        height: auto;
    }    

    .educatorResources-testimonials-Slideshow-text > h2 {
        font-size: 17px;
    }
    
    .educatorResources-testimonials-Slideshow-text > p {
        font-size: 18px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 450px) {
    #educatorResources-testimonials-Slideshow-container {
        margin: 20px 0 70px 0;
    }

    .educatorResources-testimonials-Slideshow-card {
        height: auto;
    }    

    .educatorResources-testimonials-Slideshow-text > h2 {
        font-size: larger;
    }
    
}
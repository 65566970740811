#teamMember {
    padding: 80px 20px;
    background: #FFF;
    //border: red solid 4px;
    .teamMemberTitle {
        display: flex;
        align-items: center;
        h2 {
            margin: 0;
            @include mobile {
                font-size: 20px;
                color: $teal;
            }
            @include small-mobile {
                font-size: 20px;
                color: $teal;
                margin-right: 20px;
            }
        }
        p {
            color: $gray-text;
            padding-left: 30px;
            @include mobile {
                padding-left: 1rem;
            }
            @include small-mobile {
                padding-left: 0;
            }
        }
    }
    .text-large {
        text-align: left;
        margin: 0;
    }
    .teamMemberProfile {
        text-align: left;
    }
    .recentPublications { 
        padding: 60px 0;
        h3 {
            position: relative;
            text-align: left;
            &::after {
                background: #000;
                content: '';
                height: 1px;
                width: 70%;
                position: absolute;
                top: 20px;
                right: -1%;
                display: none;
                @include mobile {
                    top: 10px;
                    right: -30%;
                }
            }
        }
        .archiveListContent {
            h3 {
                &:after {
                    display: none;
                }
            }
        }
    }
}
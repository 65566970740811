#shortRun-webComic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.shortRun-self-comic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 989px;
  margin-top: 50px;
}
.shortRun-self-comic > img {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 989px;
}
.shortRun-title-content-container {
  position: relative;
    max-width: 1024px;
    width: 100%;
    height: 100%;
    max-height: 1280px;
    text-align: left;
    color: rgb(81, 98, 136);
    margin-top: 15px;
    line-height: 35px;
}
.shortRun-title-content-container> h4 {
  color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
}
.shortRun-title-content-container> h2 {
  font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 30px;
    letter-spacing: 2px;
}
.shortRun-title-content-container> p {
  font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.shortRun-shortRun-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 989px;
  margin-right: 27px;
  margin-top: 35px;
  margin-bottom: 50px;
}
.shortRun-content-image {
  display: flex;
  width: 100%;
  max-width: 487px;
  height: 100%;
  max-height: 420px;
  margin-right: 25px;
}
.shortRun-content-image > img {
  width: 100%;
  max-width: 487px;
  height: 100%;
  max-height: 420px;
}
.shortRun-content-text {
  display: flex;
  flex-direction: column;
    justify-content: center;
    color: #3c4142;
    width: 100%;
    max-width: 487px;
    height: 100%;
    max-height: 640px;
    text-align: left;
    margin-left: 25px;
}
.shortRun-content-text > h4 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 18px;
  letter-spacing: 1.5px;
}
.shortRun-content-text > p {
  font-size: 16px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  line-height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.shortRun-webComic-content-container-one {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}
.shortRun-webComic-content-container-one > img {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}
.shortRun-webComic-content-container-two {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}
.shortRun-webComic-content-container-two > img {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}
.shortRun-webComic-content-container-three {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}
.shortRun-webComic-content-container-three > img {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 989px;
}

@media only screen and (max-width: 1024px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 800px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 30px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 35px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: 420px;
    margin-right: 25px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 380px;
      height: 100%;
      max-height: 640px;
      text-align: left;
      margin-left: 25px;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 16px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
@media only screen and (max-width: 800px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 600px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 25px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 25px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 420px;
    margin-bottom: 20px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 640px;
      text-align: left;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 16px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
@media only screen and (max-width: 600px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 450px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 20px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 420px;
    margin-bottom: 20px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 640px;
      text-align: left;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 15px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
@media only screen and (max-width: 450px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 350px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 20px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 420px;
    margin-bottom: 20px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 350px;
      height: 100%;
      max-height: 640px;
      text-align: left;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 15px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
@media only screen and (max-width: 380px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 300px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 18px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 420px;
    margin-bottom: 20px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 640px;
      text-align: left;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 15px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
@media only screen and (max-width: 350px) {
  .shortRun-self-comic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 989px;
    margin-top: 40px;
  }
  .shortRun-self-comic > img {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-title-content-container {
    position: relative;
      max-width: 280px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      text-align: left;
      color: rgb(81, 98, 136);
      margin-top: 15px;
      line-height: 35px;
  }
  .shortRun-title-content-container> h4 {
    color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      letter-spacing: 3px;
  }
  .shortRun-title-content-container> h2 {
    font-family: 'Roboto Bold', sans-serif;
      color: #d14e1d;
      font-size: 18px;
      letter-spacing: 2px;
  }
  .shortRun-title-content-container> p {
    font-family: "Helvetica Neue Light", sans-serif;
      color: #3c4142;
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .shortRun-shortRun-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 989px;
    margin-right: 27px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .shortRun-content-image {
    display: flex;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 420px;
    margin-bottom: 20px;
  }
  .shortRun-content-image > img {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 420px;
  }
  .shortRun-content-text {
    display: flex;
    flex-direction: column;
      justify-content: center;
      color: #3c4142;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 640px;
      text-align: left;
  }
  .shortRun-content-text > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .shortRun-content-text > p {
    font-size: 15px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .shortRun-webComic-content-container-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-one > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-two > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
  .shortRun-webComic-content-container-three > img {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 989px;
  }
}
#annualReport2021Internships-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 69vw;
}

@media only screen and (max-width: 700px) {
    #annualReport2021Internships-content-container {
        top: 60vw;
    }    
  }
  

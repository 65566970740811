.areasOfResearchAccordion-section-container {
    background-color: #FFFFFF;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.areasOfResearchAccordion-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
}

.areasOfResearchAccordion-titleContent-container { 
    width: 100%; 
    max-width: 1050px;
    text-align: center; 
    border-bottom: 1px solid #111111;
    line-height: 0px; 
    margin-bottom: 50px;
  } 
  
  .areasOfResearchAccordion-titleContent-container span { 
  background:#FFFFFF; 
  padding: 0 20px 0 20px;
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 30px;
  }

@media only screen and (max-width: 1250px) {
    .areasOfResearchAccordion-section-container {
        background-color: #FFFFFF;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    
    .areasOfResearchAccordion-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1050px;
    }
}

@media only screen and (max-width: 800px) {
    .areasOfResearchAccordion-titleContent-container {  
        border-bottom: none;
    } 
}

@media only screen and (max-width: 600px) {  

    .areasOfResearchAccordion-section-container {

        margin-top: 30px;
    }
    
    .areasOfResearchAccordion-titleContent-container {  
      border-bottom: none;
      width: 100%;
    } 
      
    .areasOfResearchAccordion-titleContent-container span { 
      font-size: 25px;
      padding: 0;
    }
}

@media only screen and (max-width: 300px) {
    .areasOfResearchAccordion-titleContent-container span { 
        font-size: 18px;
      }
}
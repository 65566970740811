.equityCraftHighlightCards .equityCraftStories {
  background: #FFFFFF;
  color: #FFF;
  padding: 0;
  margin-top: 25px; 
}
  @media (min-width: 1440px) {
    .equityCraftHighlightCards .equityCraftStories {
      padding: 0; } }
  @media (max-width: 768px) {
    .equityCraftHighlightCards .equityCraftStories {
      padding-left: 32px;
      padding-right: 32px;
      max-width: 100%; } }
  .equityCraftHighlightCards .equityCraftStories h3 {
    margin-bottom: 40px; }
  .equityCraftHighlightCards .equityCraftStories .button {
    margin: 60px auto 0 auto; }

    #equity-craftStories-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
    }
  
    .equity-craftStories-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 1050px;
      height: 100%;
      max-height: auto;
      top: 0;
      left: 0;
      margin: 40px 0 40px 0;
    }

    .equity-craftStories-title { 
      width: 100%; 
      max-width: 1050px;
      text-align: center; 
      border-bottom: 2px solid #E2E3E4; 
      line-height: 0px; 
   } 
  
  .equity-craftStories-title span { 
    background:#FFFFFF; 
    padding: 0 20px 0 20px; 
    margin-top: 20px;
    color: #111111;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 25px;
  }

    .equity-craftStories-bottomLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 30px 0 30px 0;
  }

  .equity-craftStories-content-bottomLink {
    font-family: "Helvetica Neue Light";
    font-size: 18px;
    color: #CA4A1C;
    text-decoration: none;
}

.equity-craftStories-content-bottomLink:hover {
    color: #b74217;
    transition: color 0.5s;
}



    @media only screen and (max-width: 1150px) {

      .equity-craftStories-content-container {
        max-width: 800px;
      }
    
     .equity-craftStories-title { 
        max-width: 800px;
     }
    
    }
    
    
    
    @media only screen and (max-width: 900px) {
    
      .equity-craftStories-content-container {
        max-width: 700px;
      }
    
     .equity-craftStories-title { 
        max-width: 700px;
     } 
    
    }
    
    
    @media only screen and (max-width: 750px) {
      #equity-craftStories-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
      }
    
      .equity-craftStories-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1050px;
        height: 100%;
        max-height: auto;
        top: 0;
        left: 0;
        margin: 40px 0 40px 0;
      }
  
    
    .equity-craftStories-title { 
      width: 100%; 
      max-width: 75vw;
      text-align: center; 
      border-bottom: 1px solid #ffffff; 
      line-height: 0px; 
      display: flex;
      justify-content: center;
      align-items: center;
    } 
    
    .equity-craftStories-title span { 
    background:#ffffff; 
    color: #111111;
    font-family: 'Helvetica Neue Medium';
    font-size: 19px;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    }

    }

/* .newEquityNews-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #f5f1f1;
}
.newEquityNews-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0 40px 0;
  padding: 0;
}
.newEquityNews-content-container > h2 {
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 26px;
  color: #111111;
  letter-spacing: 1px;
  margin: 0;
}
.newEquityNews-cardsList {
  padding: 0;
  margin: 0;
}
.newEquityNews-cardsList li {
  display: inline-block;
  margin: 0;
  padding: 30px;
}

.newEquityNews-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 20000px;
    box-shadow: 0 8px 8px -2px rgba(0,0,0,0.2);
  }
.newEquityNews-card:hover {
  box-shadow: 0 8px 12px 8px rgba(0,0,0,0.2);
}
  .newEquityNews-card-image {
  display: flex;
  width: 300px;
  height: 200px;
  }
  .newEquityNews-card-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 300px;
    height: 210px;
    background-color: #f9f9f9;
  }
  .newEquityNews-card-content-container {
    display: flex;
    flex-direction: column;
    margin: 25px;
  }
  .newEquityNews-text {
    display: grid;
    grid-template-rows: 100px 100px;
    width: 100%;
    max-width: 300px;
    margin: 0;
    padding: 0;
  }
  .newEquityNews-textOne > h3 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    color: rgb(40, 40, 40);
    font-size: 23px;
    text-align: left;
    line-height: 27px;
    letter-spacing: 1px;
    margin: 0;
  }
  .newEquityNews-textTwo > span {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #575857;
    font-size: 20px;
    text-align: left;
    line-height: 20px;
    margin: 0;
  }
  .newEquityNews-textOne {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .newEquityNews-textTwo {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  } */
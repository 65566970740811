#philanthropyLandingPage-pillars-container {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.research-interestArea-content-container-philanthropy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  /* margin-top: 20px; */
  margin-bottom: 60px;
}
.research-interestArea-content-container-philanthropy > ul {
  list-style: none;
  padding: 0;
}
.philanthropyLandingPage-pillars-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
}
.phil-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 20000px;
}
.phil-card-image {
  display: flex;
  width: 450px;
  height: 350px;
}
.phil-card-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 450px;
    min-height: 380px;
    background-color: rgb(232, 231, 231);
}
.phil-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;

}
.phil-text > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #262626;
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.phil-card-content-container {
    display: flex;
    flex-direction: column;
    margin: 30px;
}
.philTitle-bottom-directValue {
    display: flex;
    width: 100%;
    max-width: 235px;
    height: 100%;
    max-height: 120px;
    border-bottom: 2px solid rgb(58, 58, 58);
    margin-bottom: 10px;
  }
  .philTitle-bottom-directValue > h3 {
    color:rgb(38, 38, 38);
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 23px;
    font-weight: lighter;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .philTitle-bottom-collaborative {
    display: flex;
    width: 100%;
    max-width: 153px;
    height: 100%;
    max-height: 120px;
    border-bottom: 2px solid rgb(58, 58, 58);
    margin-bottom: 10px;
  }
  .philTitle-bottom-collaborative > h3 {
    color:rgb(38, 38, 38);
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 23px;
    font-weight: lighter;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .philTitle-bottom-flexible {
    display: flex;
    width: 100%;
    max-width: 93px;
    height: 100%;
    max-height: 120px;
    border-bottom: 2px solid rgb(58, 58, 58);
    margin-bottom: 10px;
  }
  .philTitle-bottom-flexible > h3 {
    color:rgb(38, 38, 38);
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 23px;
    font-weight: lighter;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .philTitle-bottom-ethical {
    display: flex;
    width: 100%;
    max-width: 83px;
    height: 100%;
    max-height: 120px;
    border-bottom: 2px solid rgb(58, 58, 58);
    margin-bottom: 10px;
  }
  .philTitle-bottom-ethical > h3 {
    color:rgb(38, 38, 38);
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 23px;
    font-weight: lighter;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

/* UNORDERED LIST */
.interestArea-cardsList2-container-phil{
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.interestArea-cardsList2-container-phil > ul{
    padding-right: 30px;
}
#interestArea-cardsList2-phil{
    background-color: #f2f2f2;
    text-align: center;
}
#interestArea-cardsList2-phil li{
      display: inline-block;
      margin: 30px 30px;
}


@media only screen and (max-width: 600px) {
    .phil-card {
        max-width: 400px;
    }
    .phil-card-image {
      width: 400px;
      height: 311px;
    }
    .phil-card-content {
        max-width: 400px;
        height: 400px;
    }
    .phil-text {
        max-width: 400px;
    
    }
    
    /* UNORDERED LIST */
    #interestArea-cardsList2-phil li{
          margin: 10px 10px;
    }
}


@media only screen and (max-width: 500px) {
  .phil-card {
      max-width: 300px;
  }
  .phil-card-image {
    width: 300px;
    height: 233px;
  }
  .phil-card-content {
      max-width: 300px;
      height: 420px;
  }
  .phil-text {
      max-width: 300px;
  
  }
  .phil-text > p {
      font-size: 16px;
      line-height: 22px;
  }
    .philTitle-bottom-directValue > h3 {
      font-size: 21px;
      line-height: 25px;
    }
    .philTitle-bottom-collaborative > h3 {
      line-height: 25px;
    }
    .philTitle-bottom-flexible > h3 {
      line-height: 25px;
    }
    .philTitle-bottom-ethical > h3 {
      line-height: 25px;
    }
  
  /* UNORDERED LIST */
  .interestArea-cardsList2-container-phil{
      margin-right: 20px;
  }
}

@media only screen and (max-width: 370px) {
  .phil-card {
      max-width: 280px;
  }
  .phil-card-image {
    width: 280px;
    height: 218px;
  }
  .phil-card-content {
      max-width: 280px;
      height: 400px;
  }
  .phil-text {
      max-width: 280px;
  
  }
    .philTitle-bottom-directValue > h3 {
      line-height: 20px;
    }
    .philTitle-bottom-collaborative > h3 {
      line-height: 20px;
    }
    .philTitle-bottom-flexible > h3 {
      line-height: 20px;
    }
    .philTitle-bottom-ethical > h3 {
      line-height: 20px;
    }
}

@media only screen and (max-width: 350px) {
  .phil-card {
      max-width: 260px;
  }
  .phil-card-image {
    width: 260px;
    height: 202px;
  }
  .phil-card-content {
      max-width: 260px;
      height: 430px;
  }
  .phil-text {
      max-width: 260px;
  }
    .philTitle-bottom-directValue > h3 {
      margin-top: -10px;
    }
    .philTitle-bottom-collaborative > h3 {
      margin-top: -10px;
    }
    .philTitle-bottom-flexible > h3 {
      margin-top: -10px;
    }
    .philTitle-bottom-ethical > h3 {
      margin-top: -10px;
    }
  
  /* UNORDERED LIST */
  .interestArea-cardsList2-container-phil{
      margin-right: 40px;
  }
}


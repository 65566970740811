#ie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #35404e; */
    background-color: #738598;
    width: 100vw;
    max-width: 100vw;
    /* margin-top: 100px; */
  }
  .ie-everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #deecfc;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
  }
  .ie-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
    margin-bottom: 50px;
  }
  .ie-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    max-width: 640px;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    color: #eef2f5;
  }
  .ie-text-container > p {
    font-family: 'Poppins', sans-serif;
    color: #eef2f5;
    font-size: 20px;
    letter-spacing: 3px;
  }
  .ie-svg-container {
    display: flex;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 800px) {
    .ie-everyday-section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #deecfc;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      letter-spacing: 3px;
    }
    .ie-text-container > p {
      font-family: 'Poppins', sans-serif;
      color: #eef2f5;
      font-size: 16px;
      letter-spacing: 3px;
    }
    .ie-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 1280px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
      margin-bottom: 70px;
    }
  }

  @media only screen and (max-width: 450px) {
    .ie-everyday-section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #deecfc;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      letter-spacing: 3px;
    }
    .ie-text-container > p {
      font-family: 'Poppins', sans-serif;
      color:#eef2f5;
      font-size: 13px;
      letter-spacing: 3px;
    }
    .ie-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 330px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
      margin-bottom: 35px;
    }
  }

  @media only screen and (max-width: 374px) {
    .ie-everyday-section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #deecfc;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      letter-spacing: 3px;
    }
    .ie-text-container > p {
      font-family: 'Poppins', sans-serif;
      color: #eef2f5;
      font-size: 13px;
      letter-spacing: 3px;
    }
    .ie-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 330px) {
    .ie-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
      margin-bottom: 20px;
    }
  }
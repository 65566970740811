#october21Page-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #007a8a;
    position: relative;
  }
  .october21Page-description-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .october21Page-description-content-container > p,
  .october21Page-description-content-container a {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  font-size: calc(16px + 1vmin);
  color: #F9F9F9;
  font-weight: bolder;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 35px;
  }

  @media only screen and (max-width: 1200px) {
      .october21Page-description-content-container {
        max-width: 65vw;
        margin-top: 40px;
        padding-bottom: 40px;    
      }
    
      .october21Page-description-content-container > p,
      .october21Page-description-content-container a {
        font-size: calc(16px + .5vmin);
        margin-top: 0;
        margin-bottom: 0;
        line-height: 32px;
      }
      }

// Resets
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    //font-family: $primary-font;
}
.container {
    max-width: 1240px;
    margin: auto;
    box-sizing: border-box; 
}
img {
    border: 0;
    max-width: 100%;
}
#newsletter {
    display: none
}
.no-pad {
    padding: 0 30px !important;
    @include large-desktop { 
        padding: 0 !important;
    }
}

// Backward Compatibility
header, nav, main, footer, article, section, aside {
    display: block;
 }

 .contentBlocks-container {
    background: #FFF;
 }
.sunburst-page-header{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
}

.sunburst-page-hero-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    width: 100%;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+Education/Project+Highlights+Sunburst/Sunburst+Hero+Image.jpg);
    background-position: center 25%;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
}

.sunburst-page-hero-img > h1{
    font-size: calc(25px + 2em);
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.sunburst-page-header > h2{
    font-size: 25px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    padding: 15px 50px;
}

.sunburst-chart-container{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    padding-top: 15px;
}

.sunburst-chart {
    padding: 30px;
    width: 50%;
}

.sunburst-chart .highcharts-data-labels {
    text-align: center;
}

.sunburst-healthed-main-category {
    font-size: 1rem;
}

.sunburst-chart .highcharts-data-labels :nth-child(2),
.sunburst-chart .highcharts-data-labels :nth-child(3),
.sunburst-chart .highcharts-data-labels :nth-child(4){
    display: inline;
    margin-top: -20px !important;

}

.sunburst-initial > .sunburst-more-info {
    border: #d14e1d solid 5px;
    color: white;
    background-color: #d14e1d;
}

.sunburst-initial > .sunburst-more-info h3 {
    color: white;
    font-size: xx-large;
}

.sunburst-clicked > .sunburst-more-info h3 {
    font-size: x-large;
}

.sunburst-initial > .sunburst-more-info p {
    padding: 50px 0;
    font-size: x-large;
}

.sunburst-more-info {
    width: 20vw;
    height: fit-content;
    min-height: 60vh;
    border: #d14e1d solid 5px;
    color: black;
    background-color: white;
    margin-bottom: 100px;
    padding: 15px;
}

.sunburst-more-info p{
    text-align: left;
}

@media only screen and (max-width: 1750px) {
    .sunburst-healthed-main-category {
        font-size: .9rem;
    }
}

@media only screen and (max-width: 1600px) {

    .sunburst-healthed-main-category {
        font-size: .75rem;
    }

    .sunburst-chart .fa-6x{
        font-size: 3em;
    }

}

@media only screen and (max-width: 1300px) {
    .sunburst-chart-container{
        flex-direction: column-reverse;
    }
    
    .sunburst-chart {
        padding: 0px;
        height: 100%;
        width: 100%;
    }    

    .sunburst-more-info {
        width: 80vw;
        min-height: 100px;
        height: fit-content;
        margin: 30px 0 0;
    }    

    .sunburst-initial > .sunburst-more-info :empty {
        padding: 0;
    }

    .sunburst-healthed-main-category {
        display: none;
    }

    .sunburst-chart .fa-6x{
        font-size: 2em;
    }

    .sunburst-chart .highcharts-data-labels :nth-child(2),
.sunburst-chart .highcharts-data-labels :nth-child(3),
.sunburst-chart .highcharts-data-labels :nth-child(4){

    margin-top: -40px !important;

}


}

@media only screen and (max-width: 1050px) {
    .sunburst-page-header > h2{
        font-size: 20px;
    }    

    .sunburst-chart .fa-6x{
        font-size: 1em;
    }

    .sunburst-healthed-main-category {
        font-size: 11px;
    }  
}
#currentOpportunities-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #00464f;
}
.currentOpportunities-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 1024px;
  top: 0;
  left: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}
.currentOpportunities-content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 177px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 25px;
}
.currentOpportunities-content-title > h2 {
    color: #fff;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 45px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.currentOpportunities-content-container > p {
    color: #00464f;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    display: flex;
    font-size: 17px;
    line-height: 23px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}
.currentOpportunities-links-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 25px;
  background-color: #f2f2f2;
}
.currentOpportunities-links-container > p > a {
    font-size: 30px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    display: flex;
    text-decoration: none;
    color: #00464f;
    line-height: 25px;
    padding: 50px;
}
.currentOpportunities-links-container > h4 {
  font-size: 18px;
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  line-height: 25px;
}
.currentOpportunities-links-container > p > a:hover {
  color: #00464f;
  text-decoration: underline;
}

@media only screen and (max-width: 1150px) {
  .currentOpportunities-content-container {
    max-width: 800px;
  }
  .currentOpportunities-links-container > p > a:hover {
    color: #00464f;
  }
}

@media only screen and (max-width: 950px) {
  .currentOpportunities-content-container {
    max-width: 700px;
  }
}

@media only screen and (max-width: 800px) {
  .currentOpportunities-content-container {
    max-width: 550px;
  }
}

@media only screen and (max-width: 550px) {
  .currentOpportunities-content-container {
    max-width: 450px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .currentOpportunities-content-title > h2 {
      font-size: 35px;
  }
  .currentOpportunities-links-container > h4 {
    font-size: 16px;
  }
  .currentOpportunities-content-container > p {
      font-size: 16px;
  }
  .currentOpportunities-links-container > p > a {
      font-size: 20px;
      line-height: 20px;
      
  }
}

@media only screen and (max-width: 550px) {
  .currentOpportunities-content-container {
    max-width: 400px;
  }
}

@media only screen and (max-width: 400px) {
  .currentOpportunities-content-container {
    max-width: 370px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .currentOpportunities-content-title > h2 {
      font-size: 30px;
  }
  .currentOpportunities-links-container > p > a {
      font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .currentOpportunities-content-container {
    max-width: 330px;
  }
  .currentOpportunities-content-container > p {
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .currentOpportunities-content-container {
    max-width: 280px;
  }
  .currentOpportunities-links-container > p > a {
      font-size: 20px;
  }
}
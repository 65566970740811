#bigCard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .bigCard-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1280px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: -20px;
      margin-bottom: -20px;
  }

  @media only screen and (max-width: 1250px) {
    #bigCard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bigCard-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
}

@media only screen and (max-width: 1000px) {
    #bigCard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bigCard-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 600px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
}

@media only screen and (max-width: 700px) {
    #bigCard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bigCard-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 450px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
}

@media only screen and (max-width: 500px) {
    #bigCard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bigCard-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 300px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
}

@media only screen and (max-width: 350px) {
    #bigCard-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bigCard-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
}
$primary-font:'Helvetica';

h1 {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 39.06px;
    line-height: 120%;
}
h2 {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 125%;
}
h3 {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
}
h4 {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
}

//Text Colors
.textGray {
    color: $gray-text;
}
.textTeal {
    color: $teal;
}
//Text Alignment
.text-left {
    text-align: left;
}
//Custom text classes 
.text-body_bold {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
}
.text-small {
    font-style: normal;
    font-weight: 400;
    font-size: 12.08px;
    line-height: 125%;
}
.text-small_bold {
    font-size: 12.08px;
    line-height: 125%;
    font-weight: 600;
}
.text-small_strong {
    font-weight: 700;
}
.text-medium {
    font-weight: 400;
    font-size: 25px;
    line-height: 1.5em;
    @include mobile {
        font-size: 13px;
    }
}
.text-medium_bold {
    font-size: 25px;
    line-height: 1.5em;
    font-weight: 600;
    @include mobile {
        font-size: 13px;
    }
    @include mobile-full {
        font-size: 16px;
        line-height: 20px;
    }
}
.text-half-step {
    font-size: 20px;
    font-weight: 400;
    @include mobile {
        font-size: 20px;
    }
}
.text-half-step_bold  {
    font-size: 20px;
    font-weight: 600;
    @include mobile {
        font-size: 20px;
    }
}
.text-large {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 31.25px;
    line-height: 1.25em;
    @include mobile {
        font-size: 20px;
    }
}
.text-large_bold {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    line-height: 1.25em;
    @include mobile {
        font-size: 20px;
    }
}
.text-xl {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 39.06px;
    line-height: 1.2em;
}
.text-xxl {
    font-style: normal;
    font-weight: 400;
    font-size: 48.83px;
    line-height: 1em;
    @include mobile {
        font-size: 25px;
        line-height: 1.25em;
    }
}
.text-xxl_bold {
    font-style: normal;
    font-weight: 600;
    font-size: 48.83px;
    line-height: 1em;
    @include tablet {
        font-size: 31.25px;
    }
    @include mobile {
        font-size: 31.25px;
        line-height: 1.25em;
    }
    @include small-mobile {
        font-size: 31.25px;
    }
}
.text-xl_bold {
    font-size: 39px !important;
    font-weight: 600;
    @include small-mobile {
        font-size: 25px !important;
    }
}
.text-jumbo_bold {
    font-size: 61px;
    font-weight: 600;
    @include mobile {
        font-size: 39px;
    }
}
.text-xxl {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 48.83px;
    line-height: 1em;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
// Emphasized Header
.emphHeader {
    position: relative;
    span {
        position: relative;
        background-color: #FFF;
        padding-right: 40px;
        // margin-left: 20px;
        z-index: 2;
    }
    &::after {
        content: '';
        height: 1px;
        background: #000;
        position: absolute;
        left: 20%;
        top: 50%;
        width: 100%;
        z-index: 1;
        @include mobile {
            left: 78%;
        }
        @include mobile-full{
            right: 0;
            left: inherit;
        }
    }
    @include tablet {
        margin-left: 20px;
    }
    @include mobile {
        margin-left: 20px;
    }
    @include small-mobile {
        margin-left: 20px;
    }
}
p,input {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    line-height: 150%;
}

.nav-links-pillars {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
}
.nav-links-about {
    font-family: 'Helvetica', sans-serif;
}
li {
    font-family: 'Helvetica', sans-serif;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.button {
    background: $teal;
    border-radius: 4px;
    color: #FFF;
    display: inline-block;
    font-family: $primary-font;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 40px;
    text-decoration: none;
    text-transform: uppercase;
    @include mobile {
        font-size: 16px;
    }
    &.white {
        background: #FFF;
        color: $teal;
        &.text-teal {
            color: $teal;
        }
    }
    &.orange {
        background: $orange;
        color: #FFF;
    }
    .loadMore {
        border: none;
    }
}
button {
    border: none;
}

//Card Archive Categories
.cardCat {
    background: $teal;
    display: inline-block;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #FFF;
}
#twoG-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: white; */
    background-color: #738598;
    width: 100vw;
    max-width: 100vw;
  }
  .everyday-section-title-twoG {
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #bcc6c7; */
    color: #deecfc;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
    margin-top: 20px;
  }
  .twoG-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
    color: #eef2f5;
    text-align: left;
    margin-bottom: 50px;
  }
  .twoG-content-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #eef2f5;
    font-size: 50px;
    letter-spacing: 2px;
  }
  .twoG-content-container > p {
    font-size: 18px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  @media only screen and (max-width: 800px) {
    #twoG-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* background: white; */
        background-color: #738598;
        width: 100vw;
        max-width: 100vw;
      }
    .everyday-section-title-twoG {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
      }
      .twoG-content-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 550px;
          height: 100%;
          max-height: 450px;
          top: 0;
          left: 0;
          color: #eef2f5;
          text-align: left;
          margin-left: 10px;
          margin-right: 10px;
      }
      .twoG-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 40px;
        letter-spacing: 2px;
      }
      .twoG-content-container > p {
        font-size: 15px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
  
  @media only screen and (max-width: 450px) {
    #twoG-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* background: white; */
      background-color: #738598;
      width: 100vw;
      max-width: 100vw;
    }
    .everyday-section-title-twoG {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
      }
      .twoG-content-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 330px;
          height: 100%;
          max-height: 330px;
          top: 0;
          left: 0;
          color: #eef2f5;
          text-align: left;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 13px;
      }
      .twoG-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 30px;
        letter-spacing: 2px;
      }
      .twoG-content-container > p {
        font-size: 13px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
  
  @media only screen and (max-width: 374px) {
    #twoG-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* background: white; */
      background-color: #738598;
      width: 100vw;
      max-width: 100vw;
    }
      .everyday-section-title-twoG {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
      }
      .twoG-content-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 300px;
          height: 100%;
          max-height: 300px;
          top: 0;
          left: 0;
          color: #eef2f5;
          text-align: left;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 12px;
      }
      .twoG-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 25px;
        letter-spacing: 2px;
      }
      .twoG-content-container > p {
        font-size: 12px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
  
  @media only screen and (max-width: 330px) {
    #twoG-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* background: white; */
      background-color: #738598;
      width: 100vw;
      max-width: 100vw;
    }
      .twoG-content-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 280px;
          top: 0;
          left: 0;
          color: #eef2f5;
          text-align: left;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 11px;
      }
      .twoG-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 25px;
        letter-spacing: 2px;
      }
      .twoG-content-container > p {
        font-size: 11px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
#philanthropyDirectorQualifications-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
  .philanthropyDirectorQualifications-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    max-height: 30000px;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .philanthropyDirectorQualifications-list {
    background-color: white;
    text-align: center;
    margin-right: 40px;
  }
  .philanthropyDirectorQualifications-list > li {
    display: inline-block;
    margin: 10px 10px;
  }
  .philanthropyDirectorQualifications-column {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: auto;
    text-align: left;
    background-color: #00464f;
    padding-bottom: 40px;
  }
  .philanthropyDirectorQualifications-content-title {
      display: flex;
      flex-direction: column;
      width: auto;
      height: fit-content;
      text-align: left;
      margin-top: 5px;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 5px;
      border-bottom: 2px solid #e3eaea;
    }
  .philanthropyDirectorQualifications-content-title > :first-child {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 25px;
    color: #e3eaea;
    margin-bottom: 0;
  }
  .philanthropyDirectorQualifications-content-title > :nth-child(2) {
        font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
        font-size: 20px;
        color: #e3eaea;
        margin-bottom: 10px;
  }
  .philanthropyDirectorQualifications-column > ul {
    line-height: 40px;
    margin-left: 17px;
    margin-right: 50px;
  }
  .philanthropyDirectorQualifications-column > ul > li {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      font-size: 20px;
      color: #e3eaea;
  }

@media only screen and (max-width: 1000px) {
    #philanthropyDirectorQualifications-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
      }
      .philanthropyDirectorQualifications-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 30000px;
        top: 0;
        left: 0;
        margin-bottom: 20px;
      }
      .philanthropyDirectorQualifications-list {
        background-color: white;
        text-align: center;
        margin-right: 40px;
      }
      .philanthropyDirectorQualifications-list > li {
        display: inline-block;
        margin: 10px 10px;
      }
      .philanthropyDirectorQualifications-column {
        display: flex;
        flex-direction: column;
        width: 600px;
        height: auto;
        text-align: left;
        background-color: #00464f;
        padding-bottom: 40px;
      }
      .philanthropyDirectorQualifications-content-title {
          display: flex;
          width: auto;
          height: fit-content;
          text-align: left;
          margin-top: 5px;
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 5px;
          border-bottom: 2px solid #e3eaea;
        }
      .philanthropyDirectorQualifications-column > ul {
        line-height: 40px;
        margin-left: 17px;
        margin-right: 50px;
      }
      .philanthropyDirectorQualifications-column > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 20px;
          color: #e3eaea;
      }
}

@media only screen and (max-width: 700px) {
    #philanthropyDirectorQualifications-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
      }
      .philanthropyDirectorQualifications-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 30000px;
        top: 0;
        left: 0;
        margin-bottom: 20px;
      }
      .philanthropyDirectorQualifications-list {
        background-color: white;
        text-align: center;
        margin-right: 40px;
      }
      .philanthropyDirectorQualifications-list > li {
        display: inline-block;
        margin: 10px 10px;
      }
      .philanthropyDirectorQualifications-column {
        display: flex;
        flex-direction: column;
        width: 450px;
        height: auto;
        text-align: left;
        background-color: #00464f;
        padding-bottom: 40px;
      }
      .philanthropyDirectorQualifications-content-title {
          display: flex;
          width: auto;
          height: fit-content;
          text-align: left;
          margin-top: 5px;
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 5px;
          border-bottom: 2px solid #e3eaea;
        }
      .philanthropyDirectorQualifications-column > ul {
        line-height: 40px;
        margin-left: 17px;
        margin-right: 50px;
      }
      .philanthropyDirectorQualifications-column > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 20px;
          color: #e3eaea;
      }
}

@media only screen and (max-width: 500px) {
    #philanthropyDirectorQualifications-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
      }
      .philanthropyDirectorQualifications-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 30000px;
        top: 0;
        left: 0;
        margin-bottom: 50px;
      }
      .philanthropyDirectorQualifications-list {
        background-color: white;
        text-align: center;
        margin-right: 40px;
      }
      .philanthropyDirectorQualifications-list > li {
        display: inline-block;
        margin: 10px 10px;
      }
      .philanthropyDirectorQualifications-column {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: auto;
        text-align: left;
        background-color: #00464f;
        padding-bottom: 40px;
      }
      .philanthropyDirectorQualifications-content-title {
          display: flex;
          width: auto;
          height: fit-content;
          text-align: left;
          margin-top: 5px;
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 10px;
          border-bottom: 2px solid #e3eaea;
        }
      .philanthropyDirectorQualifications-content-title > :first-child {
        font-size: 20px;
      }
      .philanthropyDirectorQualifications-content-title > :nth-child(2) {
        font-size: 18px;
        }
      .philanthropyDirectorQualifications-column > ul {
        line-height: 30px;
        margin-left: 17px;
        margin-right: 50px;
      }
      .philanthropyDirectorQualifications-column > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 17px;
          color: #e3eaea;
      }
}

@media only screen and (max-width: 380px) {
    #philanthropyDirectorQualifications-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
      }
      .philanthropyDirectorQualifications-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 30000px;
        top: 0;
        left: 0;
        margin-bottom: 20px;
      }
      .philanthropyDirectorQualifications-list {
        background-color: white;
        text-align: center;
        margin-right: 40px;
      }
      .philanthropyDirectorQualifications-list > li {
        display: inline-block;
        margin: 10px 10px;
      }
      .philanthropyDirectorQualifications-column {
        display: flex;
        flex-direction: column;
        width: 310px;
        height: auto;
        text-align: left;
        background-color: #00464f;
        padding-bottom: 40px;
      }
      .philanthropyDirectorQualifications-content-title {
          display: flex;
          width: auto;
          height: fit-content;
          text-align: left;
          margin-top: 5px;
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 10px;
          border-bottom: 2px solid #e3eaea;
        }
      .philanthropyDirectorQualifications-content-title > :first-child {
        font-size: 18px;
      }
      .philanthropyDirectorQualifications-content-title > :nth-child(2) {
        font-size: 16px;
        }
      .philanthropyDirectorQualifications-column > ul {
        line-height: 30px;
        margin-left: 17px;
        margin-right: 50px;
      }
      .philanthropyDirectorQualifications-column > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 17px;
          color: #e3eaea;
      }
}

@media only screen and (max-width: 350px) {
    #philanthropyDirectorQualifications-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
      }
      .philanthropyDirectorQualifications-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 30000px;
        top: 0;
        left: 0;
        margin-bottom: 20px;
      }
      .philanthropyDirectorQualifications-list {
        background-color: white;
        text-align: center;
        margin-right: 40px;
      }
      .philanthropyDirectorQualifications-list > li {
        display: inline-block;
        margin: 10px 10px;
      }
      .philanthropyDirectorQualifications-column {
        display: flex;
        flex-direction: column;
        width: 280px;
        height: auto;
        text-align: left;
        background-color: #00464f;
        padding-bottom: 30px;
      }
      .philanthropyDirectorQualifications-content-title {
          display: flex;
          width: auto;
          height: fit-content;
          text-align: left;
          margin-top: 5px;
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 10px;
          border-bottom: 2px solid #e3eaea;
        }
      .philanthropyDirectorQualifications-content-title > :first-child {
        font-size: 16px;
      }
      .philanthropyDirectorQualifications-column > ul {
        line-height: 27px;
        margin-left: 17px;
        margin-right: 50px;
      }
      .philanthropyDirectorQualifications-column > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 16px;
          color: #e3eaea;
      }
}
#programProject-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

/* HEADER */
.programProject-header-container-hotrod {
    background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/Hot+Rod+Combustion+Header.jpg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
}
.programProject-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
}
.programProject-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px;
    }
    .programProject-header-content-container > h1 {
        font-size: 55px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .programProject-header-container-hotrod {
        background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/Hot+Rod+Combustion+Header.jpg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 70%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px;
    }
    .programProject-header-content-container > h1 {
        font-size: 45px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .programProject-header-container-hotrod {
        background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/Hot+Rod+Combustion+Header.jpg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px;
    }
    .programProject-header-content-container > h1 {
        font-size: 35px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 376px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px;
    }
    .programProject-header-content-container > h1 {
        font-size: 30px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 350px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px;
    }
    .programProject-header-content-container > h1 {
        font-size: 25px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}



/* MAIN BODY CONTENT */
#programProject-main-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
}
@media only screen and (max-width: 700px) {
    #programProject-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 70%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #programProject-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 60%;
    }
  }


/* DESCRIPTION  */
#vrLandingPage-description-container-hotrod {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .vrLandingPage-description-content-container-hotrod {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .vrLandingPage-description-content-container-hotrod > p {
    font-family: 'Helvetica Neue Light';
  font-size: 19px;
  color: rgb(62, 62, 62);
  font-weight: bolder;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 35px;
  }

  @media only screen and (max-width: 1200px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 1000px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 800px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 600px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 450px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 350px) {
    #vrLandingPage-description-container-hotrod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .vrLandingPage-description-content-container-hotrod {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .vrLandingPage-description-content-container-hotrod > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }


/* VIDEO */
#programProject-video-container-autoProjects {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}
.programProject-video-content-container-autoProjects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1250px) {
    #programProject-video-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
    }
}

@media only screen and (max-width: 1000px) {
    #programProject-video-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 20000px;
    }
}

@media only screen and (max-width: 700px) {
    #programProject-video-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 20000px;
    }
}

@media only screen and (max-width: 500px) {
    #programProject-video-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 20000px;
    }
}

@media only screen and (max-width: 350px) {
    #programProject-video-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container-autoProjects {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
    }
}

/* DOCUMENT */
#autoProject-hotrod-document-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.autoProject-hotrod-document-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 70px;
}

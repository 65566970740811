.standardSlideshow {
    background-color: #FFF;
    text-align: center;
    padding: 50px 0;
    overflow: hidden;
    a {
        color: #FFF;
    }
    h3 {
        color: #000;
    }
    .slick-slider{
        @include mobile-full{
            margin: 0 -32px;
        }
    }
    .emphHeader{
        @include mobile-full{
            margin-left: 0px;
            font-size: 31px;
            line-height: 39px;
            text-align: center;
            margin: 0 0 40px;
        }
        span{
            @include mobile-full{
                padding: 0;
            }
        }
        &:after{
            content: none;
        }
    }
    .slick-list {
        overflow: visible;
    }
    .slick-next, .slick-prev {
        z-index: 2;
        bottom: -35px;
        top:auto;
        @include mobile-full{
            bottom: 0;
            top: 25%;
            background: #fff;
            width: 44px;
            height: 44px;
            border-radius: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 3px 5px -2px #c0c0c0;
        }
    }
    .slick-prev:before {
        border-color: $teal;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        left: 0.25em;
	    transform: rotate(-135deg);
        @include mobile-full{
            border-width: 0.15em 0.15em 0 0;

        }
    }
    .slick-next:before {
        border-color: $teal;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        left: 0;
	    transform: rotate(45deg);
        @include mobile-full{
            border-width: 0.15em 0.15em 0 0;
        }
    }
    .slick-prev {
        left: 20%;
        bottom: -50px;
        @include mobile-full{
            left: 2%;
            bottom: 0px;
        }
    }
    .slick-next {
        right: 20%;
        bottom: -50px;
        @include mobile-full{
            right: 2%;
            bottom: 0px;
        }
    }
    .slide {
        background: $bg-gray;
        color: #FFF;
        margin: 0 50px;
        // height: 467px;
        overflow: hidden;
        .MuiGrid-container-1{
            height: 100%;
            .MuiGrid-item-2{
                width: 100%;
            }
        }
        .slideImage {
            width: 100%;
            img {
                width: 100%; 
                height: 100%;
                object-fit: cover; 
                object-position: 50% 50%;
                @include mobile-full{
                    min-height: 240px;
                }
            }
        }
        // Modify slide arrows
        .slick-next, .slick-prev {
            z-index: 2;
        }
        .slideContent {
            padding: 40px;
            text-align: left;
            margin: auto 0;
            @include mobile-full{
                padding: 30px;
                min-height: 260px;
            }
            li {
                color: inherit;
            }
            h1,h2,h3,h4,h5,h6 {
                color: inherit;
            }
            h3{
                margin: 0;
                padding-bottom: 20px;
                font-size: 31px;
                line-height: 39px;
                word-break: break-word;
                @include mobile-full{
                    padding-bottom: 10px;
                    font-size: 25px;
                    line-height: 31px;
                }
            }
            p, li, li span{
                font-size: 20px !important;
                line-height: 30px;
                color: #fff !important;
                margin: 0;
                @include mobile-full{
                    font-size: 14px !important;
                    line-height: 21px;
                }
                em{
                    color: #fff;
                }
            }
            ol, ul{
                padding-left: 20px;
                margin: 0;
            }
            a.button {
                margin-top: 40px;
                padding: 14px 30px;
                border-radius: 2px;
                font-size: 16px;
                line-height: 20px;
                word-break: break-word;
                @include mobile-full{
                    margin-top: 30px;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
        @include mobile-full{
            margin: 0 32px;
            overflow: inherit;
        }
    }
    .slick-dots {
        bottom: -45px;
        li  {
            width: auto;
            button {
                height: 4px;
                width: 40px;
                margin: 2px 3px;
                &::before {
                    background-color: $dark-gray;
                    width: 40px;
                    height: 4px;
                    margin: 2px 3px;
                    content: '';
                    opacity: 1;
                }
            }
        }
        li.slick-active{
            button {
                &::before {
                    background-color: #007A8A;
                }
            }
        }
    } 
    .container {
        color: #FFF;
        padding: 30px 0px 60px 0px;
        position: relative;
        overflow: hidden;
        blockquote {
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
            div {
                display: inline;
            }
        }
        @include mobile {
            padding: 40px 0;
        }
        @include large-desktop {
            margin: 40px auto;
        }
        @include mobile-full{
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0;
            overflow: inherit;
        }
        blockquote {
            text-align: left;
        }
        footer {
            text-align: left;
            display: flex;
            margin: 0;
            img {
                max-width: 65px;
                padding-right: 20px;
            }
            p {
                font-size: 12px;
                strong {
                    font-size: 16px;
                }
            }
        }
        .slideShowContainer {
            margin: 0 auto;
            max-width: 100%;
            @include mobile {
                // padding: 40px;
            }
            @include tablet {
                max-width: 95%;
                // padding: 80px;
            }
            @include small-desktop {
                // padding: 80px;
            }
            @include desktop {
                /* padding: 80px; */
            }
            @include large-desktop {
                /* padding: 80px; */
            }
        }
    }
    @include mobile-full{
        padding-top: 0;
    }
}
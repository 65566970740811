#oneC-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    /* background-color: #738598;
    width: 100vw;
   max-width: 100vw; */
  }
  
  .oneC-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
    color: #3c4142;
    text-align: left;
    margin-bottom: 50px;
  }
  .oneC-content-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 30px;
    letter-spacing: 2px;
  }
  .oneC-content-container > p {
    font-size: 18px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 800px) {
    #oneC-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 200px;
      }
      .oneC-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 670px;
        height: 100%;
        max-height: 720px;
        top: 0;
        left: 0;
        color: #3c4142;
        text-align: left;
        margin-bottom: 50px;
      }
      .oneC-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 30px;
        letter-spacing: 2px;
      }
      .oneC-content-container > p {
        font-size: 15px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 2px;
        margin-bottom: 2px;
      }
}

@media only screen and (max-width: 450px) {
    #oneC-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 150px;
      }
      .oneC-content-container {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 670px;
        height: 100%;
        max-height: 720px;
        top: 0;
        left: 0;
        color: #3c4142;
        text-align: left;
        margin-bottom: 50px;
      }
      .oneC-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 20px;
        letter-spacing: 2px;
      }
      .oneC-content-container > p {
        font-size: 13px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 2px;
        margin-bottom: 2px;
      }
}

@media only screen and (max-width: 374px) {
    #oneC-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 100px;
      }
      .oneC-content-container {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 670px;
        height: 100%;
        max-height: 720px;
        top: 0;
        left: 0;
        color: #3c4142;
        text-align: left;
        margin-bottom: 50px;
      }
      .oneC-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 20px;
        letter-spacing: 2px;
      }
      .oneC-content-container > p {
        font-size: 13px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 2px;
        margin-bottom: 2px;
      }
}

@media only screen and (max-width: 330px) {
    #oneC-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 100px;
      }
      .oneC-content-container {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 670px;
        height: 100%;
        max-height: 720px;
        top: 0;
        left: 0;
        color: #3c4142;
        text-align: left;
        margin-bottom: 50px;
      }
      .oneC-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 18px;
        letter-spacing: 2px;
      }
      .oneC-content-container > p {
        font-size: 11px;
        font-weight: 20px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 2px;
        margin-bottom: 2px;
      }
}
.emphasized {
    text-align: center;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 50px 0;
    &.green {
        .container {
            a {
                color: #FFF;
                text-decoration: underline;
            }
            background: #00464F;
            color: #FFF; 
            width: 100%;
            padding: 35px 65px;
            margin: 0;
            .text-half-step_bold {
                color: #FFF;
            }
            .imageCaption {
                color: #FFF;
            }
            h1 {
                color: #FFF;
            }
        }
    }
    &.gray {
        .container {
            background: #575857;
            color: #FFF;
            padding: 35px 65px;
            margin: 0;
            width: 100%;
            .text-half-step_bold {
                color: #FFF;
            }
            .imageCaption {
                color: #FFF;
            }
            h1 {
                color: #FFF;
            }
            a {
                color: #FFF;
                text-decoration: underline;
            }
        }
    }
    &.orange {
        .container {
            background: $orange;
            color: #FFF;
            padding: 35px 65px;
            margin: 0;
            width: 100%;
            .text-half-step_bold {
                color: #FFF;
            }
            .imageCaption {
                color: #FFF;
            }
            h1 {
                color: #FFF;
            }
            a {
                color: #FFF;
                text-decoration: underline;
            }
        }
    }
    .container {
        // padding: 40px;
        padding: 0;
        position: relative;
        // margin: 0 40px;
        background: #FFF;
        width: 100%;
        a {
            text-decoration: underline;
        }
        // &::after {
        //     content: '';
        //     height: 1px;
        //     background: #FFF;
        //     position: absolute;
        //     right: 0;
        //     bottom: -10px;
        //     width: 50%;
        // }
        @include large-desktop {
            margin: 40px auto;
            // padding: 0;
        }
        .content {
            margin: 0 auto;
            max-width: 100%;
            // padding: 0;
            @include tablet {
                max-width: 100%;
            }
            @include small-desktop {
                max-width: 100%;
            }
            @include desktop {
                max-width: 100%;
            }
            @include large-desktop {
                max-width: 100%;
                // padding: 0;
            }
        }
        .imageCaption {
            color: #FFF;
            margin: 0;
            padding: 5px 0;
        }
        .textBlock {
            text-align: left;
        }
        .text-half-step_bold {
            color: #FFF;
        }
    }
    &.fullWidth {
        .imageContainer {
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }
    &.medium {
        .imageContainer {
            max-width: 1200px;
            margin: auto;
            padding: 0 30px;
            @include large-desktop { 
                padding: 0;
            }
            img {
                width: 100%;
            }
        }
    }
}
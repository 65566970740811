.vr-stress-test-accordion-section-container {
    background-color: white;
    padding: 25px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vr-stress-test-accordion-content-container {
    display: flex;
    flex-direction: column;
}

.vr-stress-test-accordion-content-container > h2 {
    align-self: center;
    color: black;
    font-size: xx-large;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    background: linear-gradient(#ffffff 0%, #ffffff 48%,#000000 49%, #000000 50%, #000000 51%, #ffffff 52%, #ffffff 100%);
    width: 70%;
}

.vr-stress-test-accordion-content-container > h2 span {
    background: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 5;
}

@media only screen and (max-width: 600px) {  
    .vr-stress-test-accordion-content-container > h2 {
        background: none;
        border-bottom: 2px black solid;
        width: 70%;
    }    

    .vr-stress-test-accordion-content-container > h2 span {
        padding: 0;
    }    
}
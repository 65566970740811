#video-component-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.video-component-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: -20px;
    margin-bottom: -20px;
}
.video-component-cardList2 {
    display: inline-block;
    list-style-type: none;
}
.videoCard-container {
    display: flex;
    width: 1024px;
    height: 576px;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1250px) {
    #video-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .video-component-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
      .video-component-cardList2 {
          display: inline-block;
          list-style-type: none;
      }
      .videoCard-container {
          display: flex;
          width: 800px;
          height: 450px;
          margin-top: 50px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 1000px) {
    #video-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .video-component-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 600px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
      .video-component-cardList2 {
          display: inline-block;
          list-style-type: none;
      }
      .videoCard-container {
          display: flex;
          width: 600px;
          height: 337px;
          margin-top: 50px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 700px) {
    #video-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .video-component-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 450px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
      .video-component-cardList2 {
          display: inline-block;
          list-style-type: none;
      }
      .videoCard-container {
          display: flex;
          width: 450px;
          height: 253px;
          margin-top: 50px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 500px) {
    #video-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .video-component-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 300px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
      .video-component-cardList2 {
          display: inline-block;
          list-style-type: none;
      }
      .videoCard-container {
          display: flex;
          width: 300px;
          height: 169px;
          margin-top: 40px;
          margin-bottom: 40px;
      }
}

@media only screen and (max-width: 350px) {
    #video-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .video-component-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: -20px;
          margin-bottom: -20px;
      }
      .video-component-cardList2 {
          display: inline-block;
          list-style-type: none;
      }
      .videoCard-container {
          display: flex;
          width: 280px;
          height: 157px;
          margin-top: 40px;
          margin-bottom: 40px;
      }
}
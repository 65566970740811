#researcherResources-stories-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .researcherResources-stories-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 40px 0 40px 0;
  }

 .researcherResources-stories-title { 
    width: 100%; 
    max-width: 1050px;
    text-align: center; 
    border-bottom: 2px solid #E2E3E4; 
    line-height: 0px; 
 } 

.researcherResources-stories-title span { 
  background:#FFFFFF; 
  padding: 0 20px 0 20px; 
  margin-top: 20px;
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 25px;
}

.researcherResources-stories-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1050x;
    max-width: 1050px;
}

.researcherResources-stories-grid-container {
    display: grid;
    grid-template-columns: 680px repeat(1, 1fr);
    grid-column-gap: 50px;
    width: 1050px; 
    margin: 30px 0 30px 0;
}

.researcherResources-stories-columnOne {
    display: flex;
}

.researcherResources-stories-columnOne-highlightCard {
    display: grid;
    grid-template-rows: 380px 1fr;
    -moz-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
    -webkit-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
    box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
}

.researcherResources-stories-columnOne-image {
    position: relative;
}

.researcherResources-stories-columnOne-image > img {
    width: 680px;
    height: 380px;
}

.researcherResources-stories-columnOne-category-tag {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 15px;
    padding: 8px 12px 4px 12px;
    border-radius: 3px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
    float: left;
    position: absolute;
    left: -20px;
    top: 20px;
    z-index: 1000;
}

.researcherResources-stories-columnOne-info {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 10px;
    text-align: left;
    background-color: #FFFFFF;
    /* padding: 0 30px 0 0; */
    margin: 25px 35px 25px 35px;
}

.researcherResources-stories-tags {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .researcherResources-stories-hash {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .researcherResources-stories-tagCategory {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 15px;
    padding: 8px 12px 4px 12px;
    border-radius: 3px;
    /* box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5); */
    /* float: left; */
    /* position: absolute; */
    /* left: -20px;
    top: 20px;
    z-index: 1000; */
    /* display: none; */
}
  
  /* .researcherHash-stories-teal {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 14px;
    padding: 5px 12px 5px 12px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
  } */
  
  .researcherHash-stories-hash {
    color: #007a8a;
    font-family: 'Helvetica Neue Light';
    font-size: 16px;
    /* padding: 5px 12px 5px 12px; */
  }

  .researcherResources-stories-columnOne-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .researcherResources-stories-columnOne-text > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #111111;
    font-size: 28px;
    margin: 0;
  }
  
  .researcherResources-stories-columnOne-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 20px;
    line-height: 27px;
    /* margin: 0; */
  }

  .researcherResources-stories-columnOne-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .researcherResources-stories-columnOne-link > p {
      font-family: 'Helvetica Neue Light';
      color: #939598;
      font-size: 15px;
      margin: 0;
  }
  
  .researcherResources-stories-columnOne-content-link {
    font-family: "Helvetica Neue Light";
    font-size: 30px;
    color: #00464f;
    text-decoration: none;
  }
  
  .researcherResources-stories-columnOne-content-link:hover {
    color: #007a8a;
    transition: color 0.5s;
  }

.researcherResources-stories-columnTwo {
  display: flex;
}
.researcherResources-stories-columnTwo-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 30px;
}




/* COLUMN TWO TEST */
.researcherResources-stories-columnTwo-highlightCard {
    display: grid;
    /* grid-template-rows: repeat(2, 1fr); */
    grid-template-rows: 200px 1fr;
    /* grid-gap: 10px; */
    -moz-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
    -webkit-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
    box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
    height: 380px;
}

.researcherResources-stories-columnTwo-image {
    position: relative;
}

.researcherResources-stories-columnTwo-image > img {
    width: 320px;
    height: 200px;
}

.researcherResources-stories-columnTwo-category-tag {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 15px;
    padding: 8px 12px 4px 12px;
    border-radius: 3px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
    float: left;
    position: absolute;
    left: -20px;
    top: 20px;
    z-index: 1000;
}

.researcherResources-stories-columnTwo-info {
    display: grid;
    grid-template-rows: 30px 1fr 43px;
    gap: 10px;
    text-align: left;
    background-color: #FFFFFF;
    /* margin: 15px 20px 15px 20px; */
    margin: 20px;
}

.researcherResources-stories-tags {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .researcherResources-stories-hash {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  
  .researcherHash-stories-hash {
    color: #007a8a;
    font-family: 'Helvetica Neue Light';
    font-size: 16px;
  }

  .researcherResources-stories-columnTwo-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .researcherResources-stories-columnTwo-text > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #111111;
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  
  .researcherResources-stories-columnTwo-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 16px;
  }

  .researcherResources-stories-columnTwo-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .researcherResources-stories-columnTwo-link > p {
      font-family: 'Helvetica Neue Light';
      color: #939598;
      font-size: 15px;
      margin: 0;
  }
  
  .researcherResources-stories-columnTwo-content-link {
    font-family: "Helvetica Neue Light";
    font-size: 30px;
    color: #00464f;
    text-decoration: none;
  }
  
  .researcherResources-stories-columnTwo-content-link:hover {
    color: #007a8a;
    transition: color 0.5s;
  }

  .researcherResources-stories-bottomLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 30px 0;
}

.researcherResources-stories-content-bottomLink {
    font-family: "Helvetica Neue Light";
    font-size: 18px;
    color: #d14e1d;
    text-decoration: none;
}

.researcherResources-stories-content-bottomLink:hover {
    color: #b74217;
    transition: color 0.5s;
}


@media only screen and (max-width: 1150px) {

  .researcherResources-stories-content-container {
    max-width: 800px;
  }

 .researcherResources-stories-title { 
    max-width: 800px;
 } 

.researcherResources-stories-card-container {
    width: 800x;
    max-width: 800px;
}

.researcherResources-stories-grid-container {
    grid-template-columns: 450px repeat(1, 1fr);
    width: 800px; 
}

.researcherResources-stories-columnOne-highlightCard {
    grid-template-rows: 251px 1fr;
}

.researcherResources-stories-columnOne-image > img {
    width: 450px;
    height: 251px;
}

/* COLUMN TWO */

.researcherResources-stories-columnTwo-highlightCard {
  grid-template-rows: 187px 187px;
  height: 374px;
}

.researcherResources-stories-columnTwo-info {
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px;
  margin: 20px;
}

.researcherResources-stories-columnTwo-image > img {
    width: 300px;
    height: 187px;
}

}



@media only screen and (max-width: 900px) {

  .researcherResources-stories-content-container {
    max-width: 700px;
  }

 .researcherResources-stories-title { 
    max-width: 700px;
 } 

.researcherResources-stories-card-container {
    width: 700x;
    max-width: 700px;
}

.researcherResources-stories-grid-container {
    grid-template-columns: 350px repeat(1, 1fr);
    width: 700px; 
}

.researcherResources-stories-columnOne-highlightCard {
    grid-template-rows: 195px 1fr;
}

.researcherResources-stories-columnOne-image > img {
    width: 350px;
    height: 195px;
}


/* COLUMN TWO */

.researcherResources-stories-columnTwo-image > img {
    width: 300px;
    height: 187px;
}

}


@media only screen and (max-width: 750px) {
  #researcherResources-stories-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .researcherResources-stories-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 40px 0 40px 0;
  }

 /* .researcherResources-stories-title { 
    width: 100%; 
    max-width: 75vw;
    text-align: center; 
    border-bottom: 2px solid #E2E3E4; 
    line-height: 0px; 
 } 

.researcherResources-stories-title span { 
  background:#FFFFFF; 
  padding: 0 20px 0 20px; 
  margin-top: 20px;
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 25px;
} */

.researcherResources-stories-title { 
  width: 100%; 
  max-width: 75vw;
  text-align: center; 
  border-bottom: 1px solid #ffffff; 
  line-height: 0px; 
  display: flex;
  justify-content: center;
  align-items: center;
} 

.researcherResources-stories-title span { 
background:#ffffff; 
color: #111111;
font-family: 'Helvetica Neue Medium';
font-size: 19px;
white-space: nowrap;
display: inline-block;
position: absolute;
}

.researcherResources-stories-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.researcherResources-stories-grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    margin: 0;
}

.researcherResources-stories-columnOne {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}

.researcherResources-stories-columnOne-highlightCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75vw;
  margin: 10px 0 10px 0;
  padding: 30px 0 30px 0;
  -moz-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
  box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
}

.researcherResources-stories-columnOne-image {
    display: flex;
    padding-bottom: 30px;
}

.researcherResources-stories-columnOne-image > img {
    width: 320px;
    max-width: 75vw;
    height: auto;
}

.researcherResources-stories-columnOne-category-tag {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 15px;
    padding: 8px 12px 4px 12px;
    border-radius: 3px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
    float: left;
    position: absolute;
    left: -20px;
    top: 20px;
    z-index: 1000;
    display: none;
}

.researcherResources-stories-columnOne-info {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 10px;
    text-align: left;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    
}

.researcherResources-stories-tags {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .researcherResources-stories-hash {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  /* .researcherHash-stories-teal {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 14px;
    padding: 5px 12px 5px 12px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
  } */
  
  .researcherHash-stories-hash {
    color: #007a8a;
    font-family: 'Helvetica Neue Light';
    font-size: 16px;
    /* padding: 5px 12px 5px 12px; */
  }

  .researcherResources-stories-columnOne-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .researcherResources-stories-columnOne-text > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #111111;
    padding: 10px 0 0 0;
    font-size: 19px;
  }
  
  .researcherResources-stories-columnOne-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 16px;
    line-height: 23px;
  }

  .researcherResources-stories-columnOne-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .researcherResources-stories-columnOne-link > p {
      font-family: 'Helvetica Neue Light';
      color: #939598;
      font-size: 14px;
      margin: 0;
  }
  
  .researcherResources-stories-columnOne-content-link {
    font-family: "Helvetica Neue Light";
    font-size: 30px;
    color: #00464f;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .researcherResources-stories-columnOne-content-link:hover {
    color: #007a8a;
    transition: color 0.5s;
  }



/* COLUMN TWO */
.researcherResources-stories-columnTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}
.researcherResources-stories-columnTwo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}

.researcherResources-stories-columnTwo-highlightCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75vw;
  margin: 10px 0 10px 0;
  padding: 30px 0 30px 0;
  -moz-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
  box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
}

.researcherResources-stories-columnTwo-image {
    display: flex;
    padding-bottom: 30px;
}

.researcherResources-stories-columnTwo-image > img {
    width: 320px;
    max-width: 75vw;
    height: auto;
}

.researcherResources-stories-columnTwo-category-tag {
    font-family: 'Helvetica Neue Light';
    color: #ffffff;
    background-color: #007a8a;
    font-size: 15px;
    padding: 8px 12px 4px 12px;
    border-radius: 3px;
    box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5);
    float: left;
    position: absolute;
    left: -20px;
    top: 20px;
    z-index: 1000;
    display: none;
}

.researcherResources-stories-columnTwo-info {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 10px;
    text-align: left;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    
}

.researcherResources-stories-tags {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .researcherResources-stories-hash {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .researcherHash-stories-hash {
    color: #007a8a;
    font-family: 'Helvetica Neue Light';
    font-size: 16px;
  }

  .researcherResources-stories-columnTwo-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .researcherResources-stories-columnTwo-text > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #111111;
    padding: 10px 0 0 0;
    font-size: 19px;
  }
  
  .researcherResources-stories-columnTwo-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 16px;
    line-height: 23px;
  }

  .researcherResources-stories-columnTwo-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .researcherResources-stories-columnTwo-link > p {
      font-family: 'Helvetica Neue Light';
      color: #939598;
      font-size: 14px;
      margin: 0;
  }
  
  .researcherResources-stories-columnTwo-content-link {
    font-family: "Helvetica Neue Light";
    font-size: 30px;
    color: #00464f;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .researcherResources-stories-columnTwo-content-link:hover {
    color: #007a8a;
    transition: color 0.5s;
  }

}
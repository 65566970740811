#interestArea-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}
/* #interestArea-main-content-container {
  display:flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 50000px;
} */

/* HEADER */
.interestArea-header-container {
    /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
}
.interestArea-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
}
.interestArea-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
}

.interestArea-quote-text > h2 {
  color: #FFFFFF;
}

.interestArea-quote-text > h4 {
  color: #FFFFFF;
}

@media only screen and (max-width: 1000px) {
    .interestArea-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px;
    }
    .interestArea-header-content-container > h1 {
        font-size: 55px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .interestArea-header-container {
        /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 70%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .interestArea-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px;
    }
    .interestArea-header-content-container > h1 {
        font-size: 45px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .interestArea-header-container {
        /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .interestArea-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px;
    }
    .interestArea-header-content-container > h1 {
        font-size: 35px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 376px) {
    .interestArea-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px;
    }
    .interestArea-header-content-container > h1 {
        font-size: 30px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 350px) {
    .interestArea-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px;
    }
    .interestArea-header-content-container > h1 {
        font-size: 25px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}



/* MAIN BODY CONTENT */
#interestArea-main-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
}
@media only screen and (max-width: 700px) {
    #interestArea-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 70%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #interestArea-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 60%;
    }
  }

/* DESCRIPTION  */
#interestArea-description-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d14e1d;
}
.interestArea-description-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 1250px;
    top: 0;
    left: 0;
    margin-top: 40px;
    margin-bottom: 40px;
}
.interestArea-description-content-container > h3 {
  font-family: 'Helvetica Neue Light';
  color: #e3eaea;
  font-size: 40px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.interestArea-description-content-container > p {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    #interestArea-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d14e1d;
  }
  .interestArea-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 1350px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .interestArea-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interestArea-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 17px;
      text-align: left;
      line-height: 30px;
    }
  }

  @media only screen and (max-width: 700px) {
    #interestArea-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d14e1d;
  }
  .interestArea-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 1550px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .interestArea-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interestArea-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 16px;
      text-align: left;
      line-height: 30px;
    }
  }
  @media only screen and (max-width: 500px) {
    #interestArea-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d14e1d;
  }
  .interestArea-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 1550px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .interestArea-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 30px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interestArea-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 15px;
      text-align: left;
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 376px) {
    #interestArea-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d14e1d;
  }
  .interestArea-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 1550px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .interestArea-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interestArea-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 14px;
      text-align: left;
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 350px) {
    #interestArea-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d14e1d;
  }
  .interestArea-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 260px;
      height: 100%;
      max-height: 1550px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .interestArea-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interestArea-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 14px;
      text-align: left;
      line-height: 25px;
    }
  }

/* PROGRAM CARDS */
#interestArea-main-programs {
  background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.interestArea-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: left;
  width: 100%;
  max-width: 930px;
  height: 100%;
  max-height: 1280px;
  margin-top: 30px;
}
.interestArea-title-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #00464f;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.interestArea-title-container > p {
  font-family: 'Helvetica Neue Light';
  color: #00464f;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 16px;
}
#interestArea-Cards-Data2-Container{
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 50000px;
  }
  .interestArea-cardsList2-container{
    width: 100%;
    max-width: 1280px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .interestArea-cardsList2-container > ul{
    padding-right: 30px;
  }
  #interestArea-cardsList2{
    background-color: #f2f2f2;
    text-align: center;
  }
    #interestArea-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }

    @media only screen and (max-width: 1000px) {
      .interestArea-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 1280px;
        margin-top: 30px;
      }
      .interestArea-title-container > h1 {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .interestArea-title-container > p {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 700px) {
      .interestArea-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 1280px;
        margin-top: 30px;
      }
      .interestArea-title-container > h1 {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .interestArea-title-container > p {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 500px) {
      .interestArea-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 1280px;
        margin-top: 30px;
      }
      .interestArea-title-container > h1 {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .interestArea-title-container > p {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 25px;
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 376px) {
      #interestArea-Cards-Data2-Container{
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 350px;
        height: 100%;
        max-height: 20000px;
      }
      .interestArea-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 1280px;
        margin-top: 30px;
      }
      .interestArea-title-container > h1 {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .interestArea-title-container > p {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 25px;
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 350px) {
      .interestArea-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 1280px;
        margin-top: 30px;
      }
      .interestArea-title-container > h1 {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .interestArea-title-container > p {
        font-family: 'Helvetica Neue Light';
        color: #00464f;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 25px;
        font-size: 15px;
      }
    }

/* PILLARS */
#interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 930px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 930px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}

@media only screen and (max-width: 1000px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 800px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

@media only screen and (max-width: 820px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 600px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

@media only screen and (max-width: 620px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 450px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 450px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

@media only screen and (max-width: 500px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 400px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

@media only screen and (max-width: 380px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 350px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 320px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

@media only screen and (max-width: 350px) {
  #interestArea-pillars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interestArea-pillars-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 300px;
   height: 100%;
   max-height: 2400px;
   background-color: #f2f2f2;
}
.interestArea-pillars-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #00464f;
   width: 100vw;
}
.interestArea-pillars-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
}
.interestArea-pillars-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: #f2f2f2;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.interestArea-pillars-title-content-container > p {
  font-family: 'Helvetica Neue Light';
        color: #f2f2f2;
        font-weight: bolder;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 16px;
}
.pillars-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.interestArea-pillars-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00464f;
  width: 100vw;
}
}

/* IMPACT */
#interestArea-impact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.interestArea-impact-content-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 930px;
 height: 100%;
 max-height: 30000px;
 /* background-color: #f2f2f2; */
}
.interestArea-impact-title-description-container {
display: flex;
flex-direction: column;
justify-content: center;
 align-items: center;
 background-color: #f2f2f2;
 width: 100vw;
}
.interestArea-impact-title-content-container {
display: flex;
flex-direction: column;
justify-content: center;
 align-items: center;
width: 100%;
max-width: 930px;
height: 100%;
max-height: 2400px;
line-height: 20px;
}
.interestArea-impact-title-content-container > h1 {
font-family: 'Helvetica Neue Light';
color: rgb(43, 43, 43);
font-weight: bold;
font-size: 40px;
margin-bottom: 5px;
letter-spacing: 2px;
margin-top: 60px;
}
.impact-title-line {
display: flex;
width: 100px;
border-bottom: 2.5px solid rgb(67, 66, 66);
margin-top: 30px;
margin-bottom: 30px;
}
.interestArea-impact-box-container {
display: flex;
justify-content: center;
align-items: center;
background-color: #f2f2f2;
width: 100%;
max-width: 1024px;
height: 100%;
max-height: 10000px;
margin-top: -20px;
margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  #interestArea-impact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .interestArea-impact-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 930px;
   height: 100%;
   max-height: 30000px;
   /* background-color: #f2f2f2; */
  }
  .interestArea-impact-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #f2f2f2;
   width: 100vw;
  }
  .interestArea-impact-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 750px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
  }
  .interestArea-impact-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 50px;
  }
  .impact-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid rgb(67, 66, 66);
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .interestArea-impact-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 750px;
  height: 100%;
  max-height: 10000px;
  margin-top: -20px;
  margin-bottom: 50px;
  }
}

@media only screen and (max-width: 700px) {
  #interestArea-impact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .interestArea-impact-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 500px;
   height: 100%;
   max-height: 30000px;
   /* background-color: #f2f2f2; */
  }
  .interestArea-impact-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #f2f2f2;
   width: 100vw;
  }
  .interestArea-impact-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
  }
  .interestArea-impact-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 50px;
  }
  .impact-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid rgb(67, 66, 66);
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .interestArea-impact-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 10000px;
  margin-top: -20px;
  margin-bottom: 50px;
  }
}

@media only screen and (max-width: 500px) {
  #interestArea-impact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .interestArea-impact-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 350px;
   height: 100%;
   max-height: 30000px;
   /* background-color: #f2f2f2; */
  }
  .interestArea-impact-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #f2f2f2;
   width: 100vw;
  }
  .interestArea-impact-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
  }
  .interestArea-impact-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 50px;
  }
  .impact-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid rgb(67, 66, 66);
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .interestArea-impact-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 10000px;
  margin-top: -20px;
  margin-bottom: 50px;
  }
}

@media only screen and (max-width: 380px) {
  #interestArea-impact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .interestArea-impact-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 300px;
   height: 100%;
   max-height: 30000px;
   /* background-color: #f2f2f2; */
  }
  .interestArea-impact-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #f2f2f2;
   width: 100vw;
  }
  .interestArea-impact-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
  }
  .interestArea-impact-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 30px;
  }
  .impact-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid rgb(67, 66, 66);
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .interestArea-impact-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 10000px;
  margin-top: -20px;
  margin-bottom: 30px;
  }
}

@media only screen and (max-width: 350px) {
  #interestArea-impact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .interestArea-impact-content-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 280px;
   height: 100%;
   max-height: 30000px;
   /* background-color: #f2f2f2; */
  }
  .interestArea-impact-title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   background-color: #f2f2f2;
   width: 100vw;
  }
  .interestArea-impact-title-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 2400px;
  line-height: 20px;
  }
  .interestArea-impact-title-content-container > h1 {
  font-family: 'Helvetica Neue Light';
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 30px;
  }
  .impact-title-line {
  display: flex;
  width: 100px;
  border-bottom: 2.5px solid rgb(67, 66, 66);
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .interestArea-impact-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 10000px;
  margin-top: -20px;
  margin-bottom: 30px;
  }
}

/* QUOTE */
/* #careers-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.interestArea-quote-content-container {
  display: flex;
  /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
  background-position: center center;
  background-size: cover;
  height: 600px;
  width: 100%;
  position: static;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}
.interestArea-quote-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 300px;
}
.interestArea-quote-text > h2 {
  font-family: 'Helvetica Neue Light';
  font-size: 30px;
  letter-spacing: 2px;
  line-height: 40px;
}
.interestArea-quote-text > h4 {
  font-family: 'Helvetica Neue Light';
  font-size: 20px;
  letter-spacing: 2px;
  margin-top: 0px;
}
@media only screen and (max-width: 1000px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 600px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 30px;
    letter-spacing: 2px;
    line-height: 40px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 800px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 600px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 30px;
    letter-spacing: 2px;
    line-height: 40px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 650px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 600px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 40px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 500px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 450px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 30px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 376px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 400px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 25px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 350px) {
  #interestArea-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-content-container {
    display: flex;
    /* background-image: url('https://images.unsplash.com/photo-1453733190371-0a9bedd82893?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'); */
    background-position: center center;
    background-size: cover;
    height: 400px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .interestArea-quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 500px;
  }
  .interestArea-quote-text > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 25px;
  }
  .interestArea-quote-text > h4 {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}


/* RESEARCH */
#interestArea-research-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.interestArea-research-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 20000px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.list {
    background-color: #FFF;
    color: #000;
    text-align: center;
    padding: 1px;
    .container {
        position: relative;
        padding: 10px 20px;
        @include large-desktop {
            // margin: 40px auto;
        }
        @include mobile {
            padding: 40px 20px;
        }
        @include small-mobile {
            padding: 40px 20px;
        }
        .listItem {
            border-top: solid 1px #000;
            margin: 20px auto;
            max-width: 70%;
            padding: 0;
            text-align: left;
            @include tablet {
                //padding: 40px 100px;
                max-width: 80%;
            }
            @include mobile {
                padding: 0;
                max-width: 100%;
            }
            @include small-mobile {
                padding: 0;
                max-width: 100%;
            }
            img {
                width: 100%;
            }
            h4 {
                margin-bottom:0;
                @include mobile {
                    font-size: 20px;
                }
                @include small-mobile {
                    font-size: 20px;
                }
            }
            .listContent {
                text-align: left;
                margin: 10px auto 40px auto;
            }
            .button {
                margin-bottom: 40px;
                font-size: 16px;
            }
        }
        .featuredStoryContent {
            margin: 0 auto;
            max-width: 100%;
            //padding: 40px 0;
            text-align: left;
            @include tablet {
                max-width: 80%;
            }
            @include small-desktop {
                max-width: 70%;
            }
            @include desktop {
                max-width: 100%;
            }
            @include large-desktop {
                //padding: 40px 100px;
                max-width: 100%;
            }
            .storyDate {
                color: #C9C9C9;
            }
        }
    }
    img {
        display: block;
        position: relative;
        padding-bottom: 10px;
    }
}
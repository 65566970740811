#philanthropyDirectorResponsibilities-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .philanthropyDirectorResponsibilities-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 10000px;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .philanthropyDirectorResponsibilities-content-title {
    display: flex;
    width: 410px;
    height: 70px;
    text-align: left;
    border-bottom: 3px solid #2d2d2d;
  }
  .philanthropyDirectorResponsibilities-content-title > h2 {
      color: #2d2d2d;
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 50px;
      font-weight: lighter;
      line-height: 65px;
      letter-spacing: 1px;
      margin-bottom: 0px;
      margin-top: 0px;
  }
  .philanthropyDirectorResponsibilities-content-subtitle {
    display: flex;
    width: auto;
    height: 40px;
    text-align: left;
  }
  .philanthropyDirectorResponsibilities-content-subtitle > h2 {
      color: #2d2d2d;
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-weight: lighter;
      line-height: 65px;
      letter-spacing: 1px;
      margin-bottom: 0px;
      margin-top: 0px;
  }
  .philanthropyDirectorResponsibilities-content-container > ul {
    line-height: 50px;
    text-align: left;
    margin-left: -23px;
  }
  .philanthropyDirectorResponsibilities-content-container > ul > li {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      font-size: 20px;
      color: rgb(62, 62, 62);
  }

  @media only screen and (max-width: 1000px) {
    #philanthropyDirectorResponsibilities-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .philanthropyDirectorResponsibilities-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 10000px;
        top: 0;
        left: 0;
        margin-top: 30px;
        margin-bottom: 50px;
      }
      .philanthropyDirectorResponsibilities-content-title {
        display: flex;
        width: 350px;
        height: 70px;
        text-align: left;
        border-bottom: 3px solid #2d2d2d;
      }
      .philanthropyDirectorResponsibilities-content-title > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 40px;
          font-weight: lighter;
          line-height: 75px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-subtitle {
        display: flex;
        width: auto;
        height: 40px;
        text-align: left;
      }
      .philanthropyDirectorResponsibilities-content-subtitle > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: lighter;
          line-height: 65px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul {
        line-height: 40px;
        text-align: left;
        margin-left: -23px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 20px;
          color: rgb(62, 62, 62);
      }
  }

  @media only screen and (max-width: 700px) {
    #philanthropyDirectorResponsibilities-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .philanthropyDirectorResponsibilities-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 10000px;
        top: 0;
        left: 0;
        margin-top: 30px;
        margin-bottom: 50px;
      }
      .philanthropyDirectorResponsibilities-content-title {
        display: flex;
        width: 350px;
        height: 70px;
        text-align: left;
        border-bottom: 3px solid #2d2d2d;
      }
      .philanthropyDirectorResponsibilities-content-title > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 30px;
          font-weight: lighter;
          line-height: 85px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-subtitle {
        display: flex;
        width: auto;
        height: 40px;
        text-align: left;
      }
      .philanthropyDirectorResponsibilities-content-subtitle > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: lighter;
          line-height: 65px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul {
        line-height: 35px;
        text-align: left;
        margin-left: -23px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 18px;
          color: rgb(62, 62, 62);
      }
  }

  @media only screen and (max-width: 500px) {
    #philanthropyDirectorResponsibilities-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .philanthropyDirectorResponsibilities-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 10000px;
        top: 0;
        left: 0;
        margin-top: 0px;
        margin-bottom: 30px;
      }
      .philanthropyDirectorResponsibilities-content-title {
        display: flex;
        width: 280px;
        height: 70px;
        text-align: left;
        border-bottom: 2px solid #2d2d2d;
      }
      .philanthropyDirectorResponsibilities-content-title > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 25px;
          font-weight: lighter;
          line-height: 85px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-subtitle {
        display: flex;
        width: auto;
        height: 40px;
        text-align: left;
      }
      .philanthropyDirectorResponsibilities-content-subtitle > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: lighter;
          line-height: 65px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul {
        line-height: 30px;
        text-align: left;
        margin-left: -23px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 18px;
          color: rgb(62, 62, 62);
          margin-top: 5px;
          margin-bottom: 5px;
      }
  }

  @media only screen and (max-width: 380px) {
    #philanthropyDirectorResponsibilities-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .philanthropyDirectorResponsibilities-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 10000px;
        top: 0;
        left: 0;
        margin-top: 0px;
        margin-bottom: 30px;
      }
      .philanthropyDirectorResponsibilities-content-title {
        display: flex;
        width: 220px;
        height: 70px;
        text-align: left;
        border-bottom: 2px solid #2d2d2d;
      }
      .philanthropyDirectorResponsibilities-content-title > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 25px;
          font-weight: lighter;
          line-height: 85px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-subtitle {
        display: flex;
        width: auto;
        height: 40px;
        text-align: left;
      }
      .philanthropyDirectorResponsibilities-content-subtitle > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: lighter;
          line-height: 65px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul {
        line-height: 30px;
        text-align: left;
        margin-left: -23px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 18px;
          color: rgb(62, 62, 62);
          margin-top: 5px;
          margin-bottom: 5px;
      }
  }

  @media only screen and (max-width: 350px) {
    #philanthropyDirectorResponsibilities-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .philanthropyDirectorResponsibilities-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 10000px;
        top: 0;
        left: 0;
        margin-top: 0px;
        margin-bottom: 30px;
      }
      .philanthropyDirectorResponsibilities-content-title {
        display: flex;
        width: 220px;
        height: 70px;
        text-align: left;
        border-bottom: 2px solid #2d2d2d;
      }
      .philanthropyDirectorResponsibilities-content-title > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 25px;
          font-weight: lighter;
          line-height: 85px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-subtitle {
        display: flex;
        width: auto;
        height: 40px;
        text-align: left;
      }
      .philanthropyDirectorResponsibilities-content-subtitle > h2 {
          color: #2d2d2d;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: lighter;
          line-height: 65px;
          letter-spacing: 1px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul {
        line-height: 30px;
        text-align: left;
        margin-left: -23px;
      }
      .philanthropyDirectorResponsibilities-content-container > ul > li {
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 17px;
          color: rgb(62, 62, 62);
          margin-top: 5px;
          margin-bottom: 5px;
      }
  }
.linkList {
    background: #fff;
    @include mobile {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%;
    }
    @include small-mobile {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
    .container {
        padding: 60px 32px;
        background: #FFF;
        @include touch {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 100%;
        }
        @include xs-mobile {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }
        @include mobile-full{
            padding-left: 0;
            padding-right: 0;
        }
        h2 {
            margin: 0 auto 60px auto;

            @include mobile-full{
                font-size: 31px;
                line-height: 39px;
            }
        }
        .MuiGrid-grid-md-6 {
            max-width: 36%;
            @include mobile-full{
                max-width: 100%;
            }
        }
        .MuiGrid-container {
            display: flex;
            justify-content: center;
        }
        .linkListCards {
            .linkListCard {
                aspect-ratio: 1 / 1;
                background: $teal;
                color: #FFF;
                max-width: 373px;
                position: relative;
                margin: 0 auto 30px;
                @include mobile-full{
                    max-width: 310px;
                    // margin: 0 auto 30px;
                }
                @include small-mobile {
                    width: 100%;
                }
                @include xs-mobile {
                    width: 100%;
                }
                .cardInner {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    transition: transform 0.8s;
                    transform-style: preserve-3d;
                    /* Position the front and back side */
                    .cardFront, 
                    .cardBack {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        -webkit-backface-visibility: hidden; /* Safari */
                        backface-visibility: hidden;
                        @include small-desktop{
                            flex-direction: row;
                        }
                        .cardContent {
                            padding: 0 45px;
                            font-weight: 600;
                            max-height: 100%;
                            @include small-desktop{
                                padding: 0 20px;
                            }
                            @include tablet{
                                padding: 0 15px;
                            }
                            @include mobile{
                                padding: 0 15px;
                            }
                            @include small-mobile{
                                padding: 0 15px;
                            }
                            @include xs-mobile{
                                padding: 0 15px;  
                            }

                            .linklist_hovertxt {
                                display: -webkit-box;
                                -webkit-line-clamp: 6;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                font-size: 24px;
                            }

                            h3{
                                font-size: 31px;
                                line-height: 40px;
                                @include mobile-full{
                                    font-size: 25px;
                                    line-height: 31px;
                                }
                            }
                            p {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 125%;
                                text-align: center;
                            }
                        }
                        a {
                            color: #FFF;
                            display: inline-block;
                            font-size: 26px;
                            margin-top: 25px;
                            text-decoration: none;
                        }
                    }
                    .cardFront {
                        @include large-desktop{
                            justify-content: end!important;
                            align-items: center!important;
                        } 
                        @include desktop{
                            justify-content: end!important;
                            align-items: center!important;
                        }
                        @include tablet{
                            justify-content: end!important;
                            align-items: center!important;
                        }
                        @include mobile{
                            justify-content: end!important;
                            align-items: center!important;
                        }
                        @include small-mobile{
                            justify-content: end!important;
                            align-items: center!important;
                        }
                        
                    }
                    .cardBack {
                        justify-content: center;
                        background-color: $orange;
                        transform: rotateY(180deg);
                        max-height: 100%;

                    }
                }
                &:hover {
                    .cardInner {
                        transform: rotateY(180deg);
                    }
                }
            }
            &.small {
                .cardInner {
                    .cardFront { 
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            &.xs-small {
                .linkListCard {
                    max-width: 170px;
                    margin: auto;
                    &:hover {
                        .cardInner {
                            transform: rotateY(0);
                            .cardFront {
                                h3 {
                                    background-color: $orange;
                                }
                            }
                        }
                    }
                }
                .cardFront {
                    h3 {
                        background-color: $teal;
                        padding: 10px 0;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        margin-bottom: 0;
                        font-size: 16px;
                    }
                }
            }
            &.circle {
                .linkListCard {
                    border-radius: 50%;
                }
                .cardBack {
                    border-radius: 50%;
                }
            }
            &.square {
                .cardInner {
                    text-align: left;
                    .cardFront { 
                        align-items: flex-end;
                        justify-content: center;
                    }
                    .cardBack { 
                        text-align: left;
                    }
                }
                .bgImage {
                    .cardInner {
                        background: linear-gradient(rgba(#000, 0), 80%, $teal);
                    }
                }
            }
        }
        .left {
            text-align: left;
        }
        .center {
            text-align: center;
        }
    }
}
#annualReport2021Dance-overview-container {
    position: relative;
    top: 0;
    height: fit-content;
    width: 100vw;
    background-color: #C8D8DA;
    display: flex;
    flex-direction: column;
    border-radius: 0% 20% 0% 0% / 0% 30% 0% 0%;
    padding-bottom: 60px;
}

.annualReport2021Dance-overview-text-container {
    text-align: justify;
    margin: -3vw 12vw 0;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021Dance-overview-text-container h1 {
    color: #d14e1d;
    max-width: 60vw;
    font-size: calc(20px + 2vw);
    text-align: left;
}

.annualReport2021Dance-overview-text-container p {
    color: #00464f;
    font-size: calc(10px + 1vw);
}



@media only screen and (max-width: 1600px) {

    #annualReport2021Dance-overview-container {
        border-radius: 0% 90% 0% 0% / 0% 9vw 0% 0%;
    }

}

@media only screen and (max-width: 1000px) {

    #annualReport2021Dance-overview-container {
        border-radius: 10% 90% 0% 0% / 3% 13vw 0% 0%;
    }

}

@media only screen and (max-width: 800px) {

    #annualReport2021Dance-overview-container {
        border-radius: 10% 90% 0% 0% / 20% 15vw 0% 0%;
    }

}

@media only screen and (max-width: 750px) {

    #annualReport2021Dance-overview-container {
        border-radius: 10% 90% 0% 0% / 20% 16vw 0% 0%;
    }

}

@media only screen and (max-width: 700px) {

    #annualReport2021Dance-overview-container {
        border-radius: 10% 90% 0% 0% / 15% 15vw 0% 0%;
    }

}

@media only screen and (max-width: 500px) {

    #annualReport2021Dance-overview-container {
        border-radius: 0% 90% 0% 0% / 0% 12vw 0% 0%;
        top: 15px;
    }

}

@media only screen and (max-width: 375px) {

    #annualReport2021Dance-overview-container {
        border-radius: 10% 90% 0% 0% / 5% 14vw 0% 0%;
        top: 15px;
    }

}

#oneAB-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 200px;
}
.everyday-section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bcc6c7;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
}
.oneAB-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 1024px;
  top: 0;
  left: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-svg-container {
  display: flex;
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 540px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
}
.oneAB-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 1024px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 1024px;
  margin-left: 40px;
  margin-right: 10px;
  margin-top: 50px;
  line-height: 30px;
}
.oneAB-text-container-two > p {
  font-size: 18px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.oneAB-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 50px;
  letter-spacing: 2px;
}
.oneAB-text-container > p {
  font-size: 18px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}

@media only screen and (max-width: 800px) {
  #oneAB-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    letter-spacing: 3px;
  }
.oneAB-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  height: 100%;
  max-height: 1080px;
  top: 0;
  left: 0;
}
.oneAB-svg-container {
  display: flex;
  width: 100%;
  max-width: 550px;
  height: 100%;
  max-height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 550px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 550px;
  margin-left: 40px;
  margin-right: 10px;
  margin-top: 50px;
  line-height: 30px;
}
.oneAB-text-container-two > p {
  font-size: 15px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.oneAB-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 40px;
  letter-spacing: 2px;
}
.oneAB-text-container > p {
  font-size: 15px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
}

@media only screen and (max-width: 450px) {
  #oneAB-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
.oneAB-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1080px;
  top: 0;
  left: 0;
}
.oneAB-svg-container {
  display: flex;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 330px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 330px;
  margin-left: 40px;
  margin-right: 10px;
  margin-top: 30px;
  line-height: 30px;
}
.oneAB-text-container-two > p {
  font-size: 13px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.oneAB-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 30px;
  letter-spacing: 2px;
}
.oneAB-text-container > p {
  font-size: 13px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
}

@media only screen and (max-width: 330px) {
  #oneAB-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 30px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
.oneAB-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1080px;
  top: 0;
  left: 0;
}
.oneAB-svg-container {
  display: flex;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 280px;
  margin-left: 10px;
  margin-right: 10px;
}
.oneAB-text-container-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: #3c4142;
  text-align: left;
  width: 100%;
  max-width: 280px;
  margin-left: 40px;
  margin-right: 10px;
  margin-top: 30px;
  line-height: 30px;
}
.oneAB-text-container-two > p {
  font-size: 12px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.oneAB-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 30px;
  letter-spacing: 2px;
}
.oneAB-text-container > p {
  font-size: 12px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
  /* line-height: 10%; */
  margin-top: 2px;
  margin-bottom: 2px;
}
}
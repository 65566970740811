#annualReport2021-ProgramLinkslanding-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
  
  /* MAIN BODY CONTENT */
  #annualReport2021-ProgramLinkslanding-main-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    background-color: #C8D8DA;
  }
  #annualReport2021-ProgramLinkslanding-main-content-container > h1 {
    color: #00464f;
    font-family: 'Helvetica Neue Medium';
    font-size: 75px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .annualReport2021-ProgramLinks-titleLine {
    width: 90vw;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 700px) {
    #annualReport2021-ProgramLinkslanding-main-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      left: 0;
      background-color: #C8D8DA;
    }
    #annualReport2021-ProgramLinkslanding-main-content-container > h1 {
        color: #00464f;
        font-family: 'Helvetica Neue Medium';
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 0px;
      }
      .annualReport2021-ProgramLinks-titleLine {
        width: 90vw;
        border-bottom: solid 2px white;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  } 
  @media only screen and (max-width: 500px) {
    #annualReport2021-ProgramLinkslanding-main-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      left: 0;
      background-color: #C8D8DA;
    }
    #annualReport2021-ProgramLinkslanding-main-content-container > h1 {
        color: #00464f;
        font-family: 'Helvetica Neue Medium';
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 0px;
      }
      .annualReport2021-ProgramLinks-titleLine {
        width: 90vw;
        border-bottom: solid 2px white;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
  
  /* NAV LINKS */
  #annualReport2021-ProgramLinksList-main-container {
    background-color: #C8D8DA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  }
  .annualReport2021-ProgramLinksList-unorderedList {
  padding-right: 40px;
  background-color: #C8D8DA;
  
  }
  .annualReport2021-ProgramLinksList-unorderedList li {
  display: inline-block;
  margin: 10px 10px;
  }
  

.accordion-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    outline: solid #00464f 2px;
    padding:10px 0 15px;
}

.accordion-title {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 15px 0;
}

.accordion-title :nth-child(2) {
    grid-area: 1 / 2 / 3 / 3;
}

.accordion-title :last-child {
    grid-area: 3 / 3 / 4 / 4;
}

.accordion-content p,
.accordion-content li{
  text-align: left;
  padding: 0 20px;
  line-height: 2;
}

.accordion-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accordion-instructions > h2{
text-align: center;
}

.accordion-instructions-steps>ol>p{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}

.accordion-instructions-steps img {
    width: 30vw;
}
#vr-stress-test-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
  .vr-stress-test-intro-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .vr-stress-test-intro-normal {
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1024px;
      height: 100%;
  }
  .vr-stress-test-intro-normal p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: white;
    text-align: left;
    font-weight: bolder;
    font-size: 1.2em;
    line-height: 1.2em;
  }
  
  .vr-stress-test-intro-normal a {
    color: white;
  }

  @media only screen and (max-width: 1100px) {
    .vr-stress-test-intro-content-container {
      max-width: 800px;
    }
    .vr-stress-test-intro-normal {
        max-width: 800px;
    }
    .vr-stress-test-intro-normal > p {
      font-weight: lighter;
      line-height: 30px;
    }
  }
  
  @media only screen and (max-width: 850px) {
    .vr-stress-test-intro-content-container {
      max-width: 500px;
      max-height: 500px;
    }
    .vr-stress-test-intro-normal {
        max-width: 500px;
        max-height: 500px;
    }
    .vr-stress-test-intro-normal > p {
      font-size: 17px;
      line-height: 25px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .vr-stress-test-intro-content-container {
      flex-direction: column;
      max-width: 400px;
      max-height: 1000px;
    }
    .vr-stress-test-intro-normal {
        max-width: 600px;
        max-height: 1000px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .vr-stress-test-intro-normal > p {
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .vr-stress-test-intro-content-container {
      max-width: 600px;
    }
    .vr-stress-test-intro-normal {
        max-width: 280px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .vr-stress-test-intro-normal > p {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 361px) {
    .vr-stress-test-intro-normal {
        max-width: 250px;
    }
  }
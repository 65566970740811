#researcherResources-video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00464f;
  }

  .researcherResources-video-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 40px 0 40px 0;
  }

 .researcherResources-video-title { 
    width: 100%; 
    max-width: 1050px;
    text-align: center; 
    border-bottom: 1px solid #F9F9F9; 
    line-height: 0px; 
 } 

.researcherResources-video-title span { 
  background:#00464f; 
  padding: 0 20px 0 20px; 
  margin-top: 20px;
  color: #F9F9F9;
  font-family: 'Helvetica Neue Medium';
  font-size: 25px;
}

.researcherResources-videoCard-container {
    display: flex;
    width: 1050px;
    height: 590px;
    /* width: 1024px;
    height: 576px; */
    margin-top: 40px;
    margin-bottom: 40px;
}




@media only screen and (max-width: 1150px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 800px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 800px;
          height: 450px;
      }
}

@media only screen and (max-width: 900px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 700px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 700px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 700px;
          height: 394px;
      }
}

@media only screen and (max-width: 750px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 500px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 500px;
          height: 281px;
      }
}

@media only screen and (max-width: 550px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 450px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 450px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 450px;
          height: 253px;
      }
}

@media only screen and (max-width: 450px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 350px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 350px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 350px;
          height: 197px;
      }
}

@media only screen and (max-width: 380px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 330px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 330px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 330px;
          height: 185px;
      }
}

@media only screen and (max-width: 340px) {
    #researcherResources-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00464f;
      }
      .researcherResources-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin: 30px 0 30px 0;
      }
      .researcherResources-video-title {
        width: 100%; 
        max-width: 280px;
        text-align: center; 
        border-bottom: 1px solid #F9F9F9; 
        line-height: 0px; 
    }
      .researcherResources-videoCard-container {
          display: flex;
          width: 280px;
          height: 157px;
      }
}
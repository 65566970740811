#highlightOne21Page-story-container {
    background-color: #f9f9f9;
    color: #111;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2vw 5vw;
    max-height: 550px;
}

.highlightOne21Page-story-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5vw;
    max-width: 40em;
}

.highlightOne21Page-story-title {
    align-self: flex-start;
    text-align: left;
}

.highlightOne21Page-story-content {
    text-align: left;
    line-height: 2;
    font-size: 18px;
}

.highlightOne21Page-story-text-container a{
    background-color: #d14e1d;
    color: #f9f9f9;
    text-decoration: none;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    box-shadow: rgb(177 177 177) 1px 1px;
    padding: .75em;
    margin: .5em;
    border-radius: 7% / 30%;
}

.highlightOne21Page-story-text-container a:hover{
    background-color: #8E3514;
}

.highlightOne21Page-story-img-container > img {
    min-height: 320px;
    max-height: 480px;
}

@media only screen and (max-width: 1150px) {
    #highlightOne21Page-story-container {
        flex-direction: column-reverse;
        max-height: 1000px;
    }

    .highlightOne21Page-story-text-container {
        padding-bottom: 2em;
        border-bottom: #111 dotted 1px;
    }    

    .highlightOne21Page-story-title {
        align-self: center;
        text-align: center;
        padding-left: 0;
    }    
    
    .highlightOne21Page-story-content {
        font-size: 16px;
        text-align: justify;
    }
    
}

@media only screen and (max-width: 550px) {
    .highlightOne21Page-story-img-container > img {
        max-height: 320px;
    }
    
}

@media only screen and (max-width: 400px) {
    #highlightOne21Page-story-container {
        max-height: fit-content;
    }

    .highlightOne21Page-story-img-container > img {
        max-height: 300px;
    }
    
}

#philanthropyLandingPage-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
}

@media only screen and (max-width: 700px) {
    #philanthropyLandingPage-content-container {
      top: 70%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #philanthropyLandingPage-content-container {
      top: 60%;
    }
  }
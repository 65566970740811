/* em {
  color: #00464F;
  font-weight: bold;
} */

.focus {
  font-weight: bolder;
  font-size: larger;
}

#brierTerrace-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.brierTerrace-description-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 50px;
  padding-bottom: 50px;
}

.brierTerrace-description-content-container > p {
  font-family: 'Helvetica Neue Light';
font-size: 17px;
color: rgb(62, 62, 62);
font-weight: bolder;
text-align: left;
margin-top: 10px;
margin-bottom: 10px;
line-height: 30px;
/* max-height: 20em;
overflow: scroll; */
}

.brierTerrace-description-content-container > h3 {
  font-family: 'Helvetica Neue Light';
font-size: 24px;
color: rgb(62, 62, 62);
font-weight: bolder;
text-decoration: underline;
text-align: left;
margin-top: 10px;
margin-bottom: 10px;
line-height: 30px;
}

.brierTerrace-topic-container {
  color: #f2651e;
  font-size: 32px;
  font-weight: bolder;
  margin: 1em;
}

.brierTerrace-topic-content {
  color: black;
  margin: 1em;
  font-weight: bold;
  font-size: 26px;
}

@media only screen and (max-width: 1200px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 800px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}

@media only screen and (max-width: 1000px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 650px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}

@media only screen and (max-width: 800px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}

@media only screen and (max-width: 600px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}

@media only screen and (max-width: 450px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}

@media only screen and (max-width: 350px) {
  #brierTerrace-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
    }
    .brierTerrace-description-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 270px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      padding-bottom: 50px;
    }
  
    .brierTerrace-description-content-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    }
}
#annualReport2021sharing-blog-container {
    color:#00464f;
    ;
}

.annualReport2021sharing-blog-content-container{
    width: 100%;
    height: fit-content;
    text-align: justify;
}

.annualReport2021sharing-blog-banner-container{
    position: relative;
    text-align: left;
    color: #ffffff;
    height: fit-content;
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.annualReport2021sharing-blog-banner-container > img {
    position: relative;
    right: 65%;
    width: 170%;
}

.annualReport2021sharing-blog-banner-container > h2 {
    position: absolute;
    padding: 0 0 0 12vw;
    font-size: calc(12px + 2vw); 
}

.annualReport2021sharing-blog-highlight{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.annualReport2021sharing-blog-highlight > h3 {
    font-size: calc(10px + 2vw); 
    text-align: center;
    max-width: 80%;
}

.annualReport2021sharing-blog-highlight img {
    max-width: 50vw;
}

.annualReport2021sharing-blog-highlight > h4 {
    padding: 0 15vw 5vw;
    text-align: center;
}

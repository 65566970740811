#twoD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 200px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
  }
  .twoD-content-container {
    display: flex;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 1280px;
    top: 0;
    left: 0;
  }
  .twoD-svg-container {
    display: flex;
    width: 100%;
    max-width: 512px;
    height: 100%;
    max-height: 640px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .twoD-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #3c4142;
    width: 100%;
    max-width: 512px;
    height: 100%;
    max-height: 640px;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
  }
  .twoD-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 50px;
    letter-spacing: 2px;
  }
  .twoD-text-container > h4 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  .twoD-text-container > p {
    font-size: 18px;
    font-weight: 20px;
    font-family: "Helvetica Neue Light", sans-serif;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 800px) {
    #twoD-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: white;
      margin-top: 180px;
    }
    .everyday-section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      letter-spacing: 3px;
    }
    .twoD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
        margin-top: 40px;
      }
      .twoD-svg-container {
        display: flex;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 450px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .twoD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 700px;
        color: #3c4142;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
      }
      .twoD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 40px;
        letter-spacing: 2px;
      }
      .twoD-text-container > h4 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 15px;
        letter-spacing: 1.5px;
      }
      .twoD-text-container > p {
        font-size: 15px;
        font-family: "Helvetica Neue Light", sans-serif;
        line-height: 20px;
      }
}

@media only screen and (max-width: 500px) {
  #twoD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 140px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
    .twoD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
        margin-top: 30px;
      }
      .twoD-svg-container {
        display: flex;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .twoD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 700px;
        color: #3c4142;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 13px;
      }
      .twoD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 30px;
        letter-spacing: 2px;
      }
      .twoD-text-container > h4 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 13px;
        letter-spacing: 1.5px;
      }
      .twoD-text-container > p {
        font-size: 13px;
        font-family: "Helvetica Neue Light", sans-serif;
        line-height: 20px;
      }
}

@media only screen and (max-width: 374px) {
  #twoD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 120px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
    .twoD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
        margin-top: 10px;
      }
      .twoD-svg-container {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .twoD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 500px;
        color: #3c4142;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
      }
      .twoD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 25px;
        letter-spacing: 2px;
      }
      .twoD-text-container > h4 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      .twoD-text-container > p {
        font-size: 12px;
        font-family: "Helvetica Neue Light", sans-serif;
        line-height: 20px;
      }
}

@media only screen and (max-width: 330px) {
  #twoD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 100px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
    .twoD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .twoD-svg-container {
        display: flex;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .twoD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 500px;
        color: #3c4142;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 11px;
      }
      .twoD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 25px;
        letter-spacing: 2px;
      }
      .twoD-text-container > h4 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      .twoD-text-container > p {
        font-size: 12px;
        font-family: "Helvetica Neue Light", sans-serif;
        line-height: 20px;
      }
}
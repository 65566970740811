.researchTeam {
    background: $bg-color;
    color:#FFF;
    .container {
        padding: 60px 100px;
        @include mobile-full {
            padding: 40px 32px;
        }
        h3{
            margin-top: 0px;
            @include mobile-full {
                font-size: 25px;
                line-height: 31px;
                margin: 0 0 40px;
                font-weight: 600;
            } 
        }
        h2 {
            margin:0 0 30px 0;
        }
        p {
            color: #FFF;
            margin: 0;
            a {
                color: #FFF;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                } 
            }
            @include mobile {
                font-size: 20px;
            }
            @include small-mobile {
                font-size: 20px;
            }
        }
        .authorsGrid {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @include mobile {
                flex-direction: column;
            }

            @include small-mobile {
                flex-direction: column;
            }

            .authorsGrid-item {
                padding: 20px;
            }
        }

        .jss27 { margin: 0px;}
    }
}

.no-result {font-weight: bold;  font-size: 24px;}
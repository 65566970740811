#shortRun-teacherCards-Container > body {
  font-weight: 300;
  line-height: 1.5;
  color: #333;
  font-family: "Lato", sans-serif;
}
/* IMPORTANT! */
#shortRun-teacherCards-Container {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.shortRun-teacherCards-content-container{
  width: 100%;
  max-width: 1500px;
  position: relative;
  /* margin-top: 30px; */
  margin-bottom: 50px;
}
.shortRun-teacherCards-content-container > ul{
  padding-right: 30px;
}
.shortRun-teacherCards-list{
  background-color: #f2f2f2;
  text-align: center;
}
.shortRun-teacherCards-list li{
    display: inline-block;
    margin: 10px 10px;
}
.everyday-section-title-shortRun {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bcc6c7;
  background-color: #f2f2f2;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.shortRun-teacher-card{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin: 20px auto 0 auto;
border: 1px solid #cccccc;
/* border-top: 1px solid #cccccc; */
border-radius: 2px;
width: 100%;
max-width: 320px;
height: 100%;
max-height: 520px;
background: white;
font-size: 12px;
}
.shortRun-card-image {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
max-width: 320px;
height: 100%;
max-height: 320px;
}
.shortRun-card-image > img {
width: 100%;
max-width: 320px;
height: 100%;
max-height: 320px;
}
.shortRun-card-content{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 320px;
height: 100%;
max-height: 320px;
padding: 10px;
color: #3c4142;
font-family: "Helvetica Neue Light", sans-serif;
}
.shortRun-card-content > h4 {
font-family: "Helvetica Neue Light", sans-serif;
color: #3c4142;
letter-spacing: 2px;
font-size: 15px;
margin-top: 2px;
margin-bottom: 2px;
}
.shortRun-card-text-teacher {
display: flex;
width: 320px;
height: 165px;
}
.shortRun-card-text-teacher > p {
text-align: left;
margin-left: 15px;
margin-right: 15px;
font-family: "Helvetica Neue Light", sans-serif;
color: #3c4142;
font-size: 13.5px;
line-height: 20px;
}
.shortRun-social {
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}
.card-header{
padding: 10px 10px 10px 10px;
display: flex;
align-items: center;
}

.user-image{
border-radius: 50%;
width: 30px;
height: 30px;
}

.user-name{
font-weight: bold;
margin-left: 10px;
color: rgb(52, 114, 172);
}

.time{
text-align: right;
width: 100%;
color: #ccc;
}



.shortRun-likes{
font-weight: bold;
}

.hashtag{
color: #4a5279;
text-decoration: none;
margin-right: 5px;
}

hr{
border: none;
border-bottom: 1px solid #ccc;
margin-top: 20px;
margin-bottom: 0;
}

.card-actions{
padding: 10px;
display: flex;
align-items: center;
}
.action-icons{
font-size: 16px;
color: #ccc;
}
.comments-input{
margin-left: 10px;
border: none;
width: 100%;
}

@media only screen and (max-width: 376px) {
.shortRun-teacherCards-list{
  background-color: #f2f2f2;
  text-align: center;
  padding-left: 30px;
  /* margin-right: 20px; */
}
.shortRun-teacherCards-list li{
    display: inline-block;
    margin: 10px 10px;
    /* margin-right: 20px; */
}
.shortRun-card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto 0 auto;
  border: 1px solid #cccccc;
  border-radius: 2px;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 800px;
  background: white;
  font-size: 12px;
}
.shortRun-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 280px;
}
.shortRun-card-image > img {
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 280px;
}
.shortRun-card-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  height: 100%;
  max-height: 280px;
  padding: 10px;
  color: #3c4142;
  font-family: "Helvetica Neue Light", sans-serif;
}
.shortRun-card-content > h4 {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Helvetica Neue Light", sans-serif;
  color: #3c4142;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.shortRun-card-text-teacher {
  display: flex;
  width: 280px;
  height: 150px;
}
.shortRun-card-text-teacher > p {
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  font-family: "Helvetica Neue Light", sans-serif;
  color: #3c4142;
  font-size: 12px;
  line-height: 20px;
}
.shortRun-social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
}

@media only screen and (max-width: 321px) {
.shortRun-teacherCards-list{
  background-color: #f2f2f2;
  text-align: center;
  padding-left: 20px;
  /* margin-right: 20px; */
}
.shortRun-teacherCards-list li{
    display: inline-block;
    margin: 10px 10px;
    /* margin-right: 20px; */
}
.shortRun-card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto 0 auto;
  border: 1px solid #cccccc;
  border-radius: 2px;
  width: 100%;
  max-width: 260px;
  height: 100%;
  max-height: 800px;
  background: white;
  font-size: 12px;
}
.shortRun-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 260px;
  height: 100%;
  max-height: 260px;
}
.shortRun-card-image > img {
  width: 100%;
  max-width: 260px;
  height: 100%;
  max-height: 260px;
}
.shortRun-card-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 260px;
  height: 100%;
  max-height: 260px;
  padding: 10px;
  color: #3c4142;
  font-family: "Helvetica Neue Light", sans-serif;
}
.shortRun-card-content > h4 {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Helvetica Neue Light", sans-serif;
  color: #3c4142;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.shortRun-card-text-teacher {
  display: flex;
  width: 260px;
  height: 150px;
}
.shortRun-card-text-teacher > p {
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  font-family: "Helvetica Neue Light", sans-serif;
  color: #3c4142;
  font-size: 12px;
  line-height: 20px;
}
.shortRun-social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
}
.comments-input{
  margin-left: 10px;
  border: none;
  width: 100%;
}

@media only screen and (max-width: 376px) {
  .shortRun-teacherCards-list{
    background-color: #f2f2f2;
    text-align: center;
    padding-left: 30px;
    /* margin-right: 20px; */
  }
  .shortRun-teacherCards-list li{
      display: inline-block;
      margin: 10px 10px;
      /* margin-right: 20px; */
  }
  .shortRun-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px auto 0 auto;
    border: 1px solid #cccccc;
    border-radius: 2px;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 800px;
    background: white;
    font-size: 12px;
  }
  .shortRun-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
  }
  .shortRun-card-image > img {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
  }
  .shortRun-card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
    padding: 10px;
    color: #3c4142;
    font-family: "Helvetica Neue Light", sans-serif;
  }
  .shortRun-card-content > h4 {
    /* font-family: 'Poppins', sans-serif; */
    font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    letter-spacing: 2px;
    font-size: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .shortRun-card-text-teacher {
    display: flex;
    width: 280px;
    height: 150px;
  }
  .shortRun-card-text-teacher > p {
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    font-size: 12px;
    line-height: 20px;
  }
  .shortRun-social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

@media only screen and (max-width: 321px) {
  .shortRun-teacherCards-list{
    background-color: #f2f2f2;
    text-align: center;
    padding-left: 20px;
    /* margin-right: 20px; */
  }
  .shortRun-teacherCards-list li{
      display: inline-block;
      margin: 10px 10px;
      /* margin-right: 20px; */
  }
  .shortRun-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px auto 0 auto;
    border: 1px solid #cccccc;
    border-radius: 2px;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 800px;
    background: white;
    font-size: 12px;
  }
  .shortRun-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 260px;
  }
  .shortRun-card-image > img {
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 260px;
  }
  .shortRun-card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 260px;
    padding: 10px;
    color: #3c4142;
    font-family: "Helvetica Neue Light", sans-serif;
  }
  .shortRun-card-content > h4 {
    /* font-family: 'Poppins', sans-serif; */
    font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    letter-spacing: 2px;
    font-size: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .shortRun-card-text-teacher {
    display: flex;
    width: 260px;
    height: 150px;
  }
  .shortRun-card-text-teacher > p {
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    font-size: 12px;
    line-height: 20px;
  }
  .shortRun-social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
  

#research-interestArea-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}
.research-interestArea-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}
.research-interestArea-content-container > ul {
    list-style: none;
    padding: 0;
}
.research-interestArea-title-research {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1024px;
  border-bottom: 3px solid rgb(62, 62, 62);
  margin-bottom: -17px;
}
.research-interestArea-title-research > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Light';
    font-size: 35px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 2px;
}
#research-interestArea-cardList2 {
    background-color: #f2f2f2;
    text-align: center;
    display: inline-block;
    list-style-type: none;
    padding: 0;
}
.research-interestArea-link {
    text-decoration: none;
}
.research-interestArea-link > li {
    list-style-type: none;
    padding: 0;
    text-decoration: none;
}

/* LINK CARD */
.researchList-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid rgb(157, 157, 157);
}
.researchList-card-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1024px;
    /* max-width: 1024px; */
    height: 250px;
    /* max-height: 400px; */
    margin-top: 30px;
    margin-bottom: 30px;
}
.researchList-card-image-container {
    display: flex;
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;
    margin-right: 50px;
}
.researchList-card-image-container > img {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;
    border-radius: 50%;
}
.researchList-card-text-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 400px;
    margin-left: 30px;
}
.researchList-card-mainText-research {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.researchList-card-mainText-research > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
.researchList-card-mainText-research > p {
    color: rgb(84, 84, 84);
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 7px;
}
.researchList-card-bottomText {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
}
/* .researchList-card-bottomText > h3 {
    color: rgb(55, 55, 55);
    font-family: 'Helvetica Neue Medium';
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
} */
.researchList-card-bottomText > h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(55, 55, 55);
    font-family: 'Helvetica Neue Medium';
    font-size: 17px;
    font-weight: lighter;
    line-height: 20px;
    margin-right: 20px;
    
}
.researchList-card-names {
    display: flex;
    flex-direction: row;
    color: rgb(55, 55, 55);
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    
}
.researchList-card-names > h4 {
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (max-width: 1100px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 800px;
      border-bottom: 3px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 35px;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 800px;
        /* max-width: 1024px; */
        height: 300px;
        /* max-height: 400px; */
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        width: 100%;
        max-width: 220px;
        height: 100%;
        max-height: 220px;
        margin-right: 50px;
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 220px;
        height: 100%;
        max-height: 220px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 400px;
        margin-left: 30px;
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
        margin-top: 7px;
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: row;
        margin-top: -25px;
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 17px;
        font-weight: lighter;
        line-height: 20px;
        margin-right: 20px;
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 850px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 650px;
      border-bottom: 3px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 35px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 650px;
        /* max-width: 1024px; */
        height: 300px;
        /* max-height: 400px; */
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        margin-right: 20px;
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 400px;
        margin-left: 10px;
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
        margin-top: 7px;
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: row;
        margin-top: -15px;
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 20px;
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 700px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 30px;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 2000px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 230px;
        height: 100%;
        max-height: 230px;
        margin-top: 10px;
        margin-bottom: 20px;
        /* margin-right: 20px; */
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 230px;
        height: 100%;
        max-height: 230px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 2000px;
        /* margin-left: 10px; */
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
        /* margin-top: 7px; */
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 20px;
        margin-right: 10px;
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}


@media only screen and (max-width: 550px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 25px;
        font-weight: bold;
        line-height: 10px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 2000px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        margin-top: 10px;
        margin-bottom: 20px;
        /* margin-right: 20px; */
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 2000px;
        /* margin-left: 10px; */
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
        /* margin-top: 7px; */
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-top: 5px;
        /* margin-top: -25px; */
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 10px;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        /* margin-right: 10px; */
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}


@media only screen and (max-width: 430px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 340px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 340px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 20px;
        font-weight: bold;
        line-height: 10px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 340px;
        height: 100%;
        max-height: 2000px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 180px;
        height: 100%;
        max-height: 180px;
        margin-top: 10px;
        margin-bottom: 20px;
        /* margin-right: 20px; */
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 180px;
        height: 100%;
        max-height: 180px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 340px;
        height: 100%;
        max-height: 2000px;
        /* margin-left: 10px; */
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
        /* margin-top: 7px; */
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-top: 0px;
        /* margin-top: -25px; */
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 10px;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        /* margin-right: 10px; */
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}


@media only screen and (max-width: 380px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 20px;
        font-weight: bold;
        line-height: 10px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 2000px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 150px;
        height: 100%;
        max-height: 150px;
        margin-top: 5px;
        margin-bottom: 20px;
        /* margin-right: 20px; */
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 150px;
        height: 100%;
        max-height: 150px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 2000px;
        /* margin-left: 10px; */
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        /* margin-top: 7px; */
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-top: 0px;
        /* margin-top: -25px; */
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 10px;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        /* margin-right: 10px; */
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}


@media only screen and (max-width: 350px) {
    #research-interestArea-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .research-interestArea-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .research-interestArea-content-container > ul {
        list-style: none;
        padding: 0;
    }
    .research-interestArea-title-research {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-bottom: -17px;
    }
    .research-interestArea-title-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 10px;
        letter-spacing: 2px;
    }
    #research-interestArea-cardList2 {
        background-color: #f2f2f2;
        text-align: center;
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }
    .research-interestArea-link {
        text-decoration: none;
    }
    .research-interestArea-link > li {
        list-style-type: none;
        padding: 0;
        text-decoration: none;
    }
    
    /* LINK CARD */
    .researchList-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid rgb(157, 157, 157);
    }
    .researchList-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 2000px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .researchList-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 150px;
        height: 100%;
        max-height: 150px;
        margin-top: 5px;
        margin-bottom: 20px;
        /* margin-right: 20px; */
    }
    .researchList-card-image-container > img {
        width: 100%;
        max-width: 150px;
        height: 100%;
        max-height: 150px;
        border-radius: 50%;
    }
    .researchList-card-text-container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 2000px;
        /* margin-left: 10px; */
    }
    .researchList-card-mainText-research {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    .researchList-card-mainText-research > h2 {
        color: #d14e1d;
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
    .researchList-card-mainText-research > p {
        color: rgb(84, 84, 84);
        font-family: 'Helvetica Neue Light';
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        /* margin-top: 7px; */
    }
    .researchList-card-bottomText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-top: 0px;
        /* margin-top: -25px; */
    }
    .researchList-card-bottomText > h3 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }
    .researchList-card-bottomText > h4 {
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Medium';
        font-size: 16px;
        font-weight: lighter;
        line-height: 5px;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        /* margin-right: 10px; */
    }
    .researchList-card-names {
        display: flex;
        flex-direction: row;
        color: rgb(55, 55, 55);
        font-family: 'Helvetica Neue Light';
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        
    }
    .researchList-card-names > h4 {
        margin-left: 5px;
        margin-right: 5px;
    }
}
.newsMedia-header-announcement-container {
    background-color: #BE5127;
    display: flex;
    justify-content: center;
}

.newsMedia-header-announcement-container > a {
    text-decoration: none;
}

.newsMedia-header-announcement-button {
    display: flex;
    position: relative;
    bottom: 30px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 300px;
    outline: solid #fff 3px;
    border-radius: 25px;
}

.newsMedia-header-announcement-button p {
    color: #fff;
    font-size: large;
}

.newsMedia-header-announcement-button i::before {
    color: #fff;
    font-size: x-large;
}

.newsMedia-header-announcement-button:hover {
    background-color: #fff;
    p, i::before {
        color:#d14e1d;
    }
}




.researchRedesignBreadcrumb-container {
    /* position: absolute; */
    display: flex;
    width: 100%;
    left: 0;
    top: 80%;
    color: #D14E1D;
    font-family: 'Helvetica Neue Medium',Arial, Helvetica, sans-serif;
    background-color: #f2f2f2;
}

.researchRedesignBreadcrumb-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 50px;
    width: 100%;
}

.researchRedesignBreadcrumb-content-container > a > i{
    font-size: x-large;
    padding-bottom: 10px;
    color: black;
    -webkit-text-stroke: 2px #fff;
}

.researchRedesignBreadcrumb-content-container > p{
    color: black;
}


.researchRedesignBreadcrumb-content-container > p, h2{
    font-size: x-large;
}

.researchRedesignBreadcrumb-content-container > *{
    padding-right: 10px;
}

@media only screen and (max-width: 700px) {
    .researchRedesignBreadcrumb-container {
        top: 70%;
    }
}

@media only screen and (max-width: 500px) {
    .researchRedesignBreadcrumb-container {
        top: 60%;
    }

}

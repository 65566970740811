#archiveList {
    background: $bg-light;
    padding-bottom: 30px;
    .container {
        background: #FFFF;
        padding: 80px;
        @include mobile-full{
            padding: 0px;
            background: transparent;
        }
    }
}   
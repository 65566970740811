#conceptualDevelopmentLabBody-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

.conceptualDevelopmentLabBody-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;    
    top: 0;
    left: 0;
}

.conceptualDevelopmentLabBody-titleContent-container { 
  width: 100%; 
  max-width: 1050px;
  text-align: center; 
  border-bottom: 1px solid #111111;
  line-height: 0px; 
  margin-bottom: 50px;
} 

.conceptualDevelopmentLabBody-titleContent-container span { 
background:#FFFFFF; 
padding: 0 20px 0 20px;
color: #111111;
font-family: 'Helvetica Neue Medium';
font-size: 30px;
}

/* LAB DEFINITION */
.conceptualDevelopmentLabBody-definition-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D14E1D;
    width: 100%;
  }

  .conceptualDevelopmentLabBody-definition-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
    background-color: #D14E1D;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0 45px 0;
  }

  .conceptualDevelopmentLabBody-definition-bold {
    display: flex;
    width: 50%;
    margin: 0 40px 0 0;
  }

  .conceptualDevelopmentLabBody-definition-bold > h1 {
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 46px;
    color: #FFFFFF;
    letter-spacing: 1px;
  }

  .conceptualDevelopmentLabBody-definition-paragraph {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 0 0 40px;
    line-height: 25px;
  }

  .conceptualDevelopmentLabBody-definition-paragraph > h2 {
    font-family: 'Helvetica Neue Light';
    font-size: 25px;
    color: #FFFFFF;
    line-height: 35px;
    letter-spacing: 1px;
    margin: 0;
  }

  .conceptualDevelopmentLabBody-definition-paragraph > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 1px;
  }

  @media only screen and (max-width: 1200px) {  
    .conceptualDevelopmentLabBody-definition-content-container {
      flex-direction: column;
      max-width: 80vw;
    }
  
    .conceptualDevelopmentLabBody-definition-bold {
      width: 100%;
      margin: 0;
    }
  
    .conceptualDevelopmentLabBody-definition-bold > h1 {
      margin: 15px 0 15px 0;
    }
  
    .conceptualDevelopmentLabBody-definition-paragraph {
      width: 100%;
      margin: 0;
    }
  }

  @media only screen and (max-width: 800px) {

    .conceptualDevelopmentLabBody-titleContent-container {  
      border-bottom: none;
    } 

  }
  


  @media only screen and (max-width: 600px) {

    .conceptualDevelopmentLabBody-definition-content-container {
      flex-direction: column;
      max-width: 80vw;
      margin: 25px 0;
    }

    .conceptualDevelopmentLabBody-definition-bold > h1 {
      margin: 15px 0 15px 0;
      font-size: 20px;
    }

    .conceptualDevelopmentLabBody-definition-paragraph > h2 {
      font-size: 18px;
      line-height: 23px;
    }

    .conceptualDevelopmentLabBody-definition-paragraph > p {
      font-size: 18px;
      line-height: 23px;
    }

    .conceptualDevelopmentLabBody-titleContent-container {  
      border-bottom: none;
      width: 100%;
    } 
      
    .conceptualDevelopmentLabBody-titleContent-container span { 
      font-size: 25px;
      padding: 0;
    }
    
  }


/* RECENT NEWS HIGHLIGHTS */
#conceptualDevelopmentLabBody-highlights-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .conceptualDevelopmentLabBody-highlights-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 50px 0 50px 0;
  }

.conceptualDevelopmentLabBody-highlights-title { 
  margin: 0 0 30px 0;
  color: #111111;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 30px;
  letter-spacing: 1px;
}

.conceptualDevelopmentLabBody-highlightsLink {
  text-decoration: none;
}

.conceptualDevelopmentLabBody-highlightsLink:hover .conceptualDevelopmentLabBody-highlights-text > h2  {
  color: #01646F;
  transition: color 0.5s;
}

.conceptualDevelopmentLabBody-highlights-grid-container {
  display: grid;
  grid-template-columns: 300px repeat(1, 1fr);
  grid-column-gap: 130px;
  width: 100%;
  padding: 20px 0 20px 0;
}

.conceptualDevelopmentLabBody-highlights-image-container > img {
  width: 300px;
  height: 250px;
}

.conceptualDevelopmentLabBody-highlights-info-container {
  display: grid;
  grid-template-rows: 30px 1fr 30px;
  gap: 10px;
  text-align: left;
  background-color: #FFFFFF;
}

.conceptualDevelopmentLabBody-highlights-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.conceptualDevelopmentLabBody-highlights-text > h2 {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #111111;
  font-size: 25px;
  margin: 0 0 15px 0;
}

.conceptualDevelopmentLabBody-highlights-text > h2 > a {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #d14e1d;
  font-size: 25px;
  margin: 0 0 15px 0;
}

.conceptualDevelopmentLabBody-highlights-text > p {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #111111;
  font-size: 20px;
  margin: 0;
}

.conceptualDevelopmentLabBody-highlights-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.conceptualDevelopmentLabBody-highlights-link > p {
    font-family: 'Helvetica Neue Light';
    color: #969696;
    font-size: 16px;
    margin: 0;
}

.conceptualDevelopmentLabBody-highlights-content-link {
  font-family: "Helvetica Neue Light";
  font-size: 30px;
  color: #00464f;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

.conceptualDevelopmentLabBody-highlights-text > h2 >a:hover {
  color: #007a8a;
  text-decoration: underline;
}

.conceptualDevelopmentLabBody-highlights-bottomLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 30px 0;
}

.conceptualDevelopmentLabBody-highlights-content-bottomLink {
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    color: #d14e1d;
    text-decoration: none;
}

.conceptualDevelopmentLabBody-highlights-content-bottomLink:hover {
    color: #b74217;
    transition: color 0.5s;
}


@media only screen and (max-width: 1025px) {
  .conceptualDevelopmentLabBody-highlights-info-container {
    min-width: auto;
  }
}


@media only screen and (max-width: 800px) {
  .conceptualDevelopmentLabBody-highlights-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 50px 0 50px 0;
  }

  .conceptualDevelopmentLabBody-highlights-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1050x;
    max-width: 1050px;
    padding: 15px 0 15px 0;
    -moz-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    margin: 10px 0 10px 0;
  }

  .conceptualDevelopmentLabBody-highlights-title { 
    margin: 0 0 30px 0;
    color: #111111;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 23px;
    letter-spacing: 1px;
  }

.conceptualDevelopmentLabBody-highlights-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}
.conceptualDevelopmentLabBody-highlights-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75vw;
  margin: 10px 0 10px 0;
}

.conceptualDevelopmentLabBody-highlights-image-container {
  display: flex;
  padding-bottom: 30px;
}

.conceptualDevelopmentLabBody-highlights-image-container > img {
  width: 300px;
  max-width: 75vw;
  height: auto;
}

.conceptualDevelopmentLabBody-highlights-info-container {
  display: grid;
  grid-template-rows: 30px 1fr 30px;
  gap: 10px;
  text-align: left;
  background-color: #FFFFFF;
  padding: 0;
}

.conceptualDevelopmentLabBody-highlights-text > h2 {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #d14e1d;
  font-size: 19px;
}

.conceptualDevelopmentLabBody-highlights-text > p {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #111111;
  font-size: 16px;
  margin: 0;
  line-height: 23px;
}

.conceptualDevelopmentLabBody-highlights-content-link {
  font-family: "Helvetica Neue Light";
  font-size: 30px;
  color: #00464f;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.conceptualDevelopmentLabBody-highlights-bottomLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 75vw;
  margin: 30px 0 30px 0;
}

}


/* GET INVOLVED */
.conceptualDevelopmentLabBody-getInvolved-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #077A8A;
    width: 100%;
  }

  .conceptualDevelopmentLabBody-getInvolved-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: #077A8A;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0 45px 0;
  }

  .conceptualDevelopmentLabBody-getInvolved-bold {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .conceptualDevelopmentLabBody-getInvolved-bold > h2 {
    margin: 0 0 20px 0;
    color: #FFFFFF;
    font-family: 'Helvetica Neue Medium', Arial, sans-serif;
    font-size: 30px;
    letter-spacing: 1px;
  }

  .conceptualDevelopmentLabBody-getInvolved-paragraph {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 25px;
  }

  .conceptualDevelopmentLabBody-getInvolved-paragraph > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }

  .conceptualDevelopmentLabBody-getInvolved-paragraph > h3 {
    font-family: 'Helvetica Neue Medium', Arial, sans-serif;
    font-size: 21px;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }


  @media only screen and (max-width: 1200px) {
  
  .conceptualDevelopmentLabBody-getInvolved-content-container {
    max-width: 80vw;
  }

  .conceptualDevelopmentLabBody-getInvolved-bold {
    justify-content: left;
    width: 100%;
  }

  }

  @media only screen and (max-width: 600px) {

    .conceptualDevelopmentLabBody-getInvolved-bold > h2 {
      margin: 0 0 20px 0;
      font-size: 22px;
    }

    .conceptualDevelopmentLabBody-getInvolved-paragraph > h3 {
      font-size: 18px;
    }

  }


/* PUBLICATIONS */
.conceptualDevelopmentLabBody-publications-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    width: 100%;
  }

  .conceptualDevelopmentLabBody-publications-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: #FFFFFF;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0;
  }

  .conceptualDevelopmentLabBody-publications-bold {
    display: flex;
    width: 100%;
  }

  .conceptualDevelopmentLabBody-publications-bold > h2 {
    margin: 0 0 10px 0;
    color: #D14E1D;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 30px;
    letter-spacing: 1px;
  }

  .conceptualDevelopmentLabBody-publications-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    line-height: 25px;
    margin: 0;
    padding: 0;
  }

  .conceptualDevelopmentLabBody-publications-list > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #111111;
    letter-spacing: 1px;
  }

  .conceptualDevelopmentLabBody-publications-list > li {
    padding: 13px 0;
    line-height: 22px;
  }

  .conceptualDevelopmentLabBody-publications-list > li > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0;
  }

  .conceptualDevelopmentLabBody-publications-list > li > a {
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    color: #077A8A;
    letter-spacing: 1px;
    word-wrap: break-word;
    text-decoration: none;
  }

  .conceptualDevelopmentLabBody-publications-list > li > a:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 1200px) {

    .conceptualDevelopmentLabBody-publications-content-container {
      max-width: 80vw;
    }

  }

  @media only screen and (max-width: 600px) {

    .conceptualDevelopmentLabBody-publications-content-container {
      margin: 35px 0;
    }

    .conceptualDevelopmentLabBody-publications-list > li {
      padding: 15px 0;
    }

    .conceptualDevelopmentLabBody-publications-bold > h2{
      font-size: 22px;
      margin: 0;
    }

  }

  @media only screen and (max-width: 300px) {
    .conceptualDevelopmentLabBody-titleContent-container span { 
        font-size: 18px;
      }
}
.f10-banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: #077A8A;
  /* padding: 7px;   */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  z-index: 9999;
}

.f10-banner-content-container {
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    padding: 16px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* max-width: 1170px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    -moz-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.6);
    -webkit-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.6);
    box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.6);
    padding: 5px 25px 5px 25px;
    z-index: 9999;
  }
  
  .f10-banner-content-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

  }

  .f10-banner-content-text-container p {
    margin: 0;
    color: #111111;
    font-family: Helvetica Neue Light;
    font-size: 17px;
  }
  
  .f10-banner-content-container .close-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: #F9F9F9;
    font-family: Helvetica Neue Medium;
    font-size: 17px;
    font-weight: bold;
  }

  .f10-banner-link {
    /* color: #d14e1d; */
    color: #F9F9F9;
    display: flex;
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0rem;
    height: 100%;
  }
  .f10-banner-link:hover {
    color: #e1dede;
    transition: color 0.5s;
    text-decoration: underline;
  }

  #bannerAlertArrow {
    padding-bottom: 4px;
    color: #F9F9F9;
  }
  

@media only screen and (max-width: 700px) {
  .f10-banner-container {
    display: none;
  }
}
.interestArea-cardsList2-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    /* position: relative; */
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .interestArea-domain-title > h2 {
    font-family: 'Helvetica Neue Light';
    color: rgb(67, 66, 66);
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }
  .interestArea-cardsList2-container > ul{
    padding-right: 30px;
  }
  #interestArea-cardsList2{
    background-color: #f2f2f2;
    text-align: center;
  }
    #interestArea-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }

    @media only screen and (max-width: 450px) {
      .interestArea-domain-title {
        width: 100%;
        max-width: 320px;
      }
      .interestArea-cardsList2-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .interestArea-domain-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: rgb(67, 66, 66);
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
        text-align: center;
      }
      #interestArea-cardsList2 {
        margin-right: 30px;
      }
      .interestArea-cardsList2-container {
        width: 100vw;
      }
      .final-cards-container {
        width: 100%;
        max-width: 280px;
      }
    }

    @media only screen and (max-width: 380px) {
      .interestArea-domain-title {
        width: 100%;
        max-width: 280px;
      }
      .interestArea-cardsList2-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        /* position: relative; */
        margin-top: 30px;
        margin-bottom: 30px;
      }
      #interestArea-cardsList2 {
        margin-right: 30px;
      }
      .interestArea-cardsList2-container {
        width: 100vw;
      }
    }

    @media only screen and (max-width: 350px) {
      .interestArea-domain-title {
        width: 100%;
        max-width: 280px;
      }
      .interestArea-cardsList2-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        /* position: relative; */
        margin-top: 30px;
        margin-bottom: 30px;
      }
      #interestArea-cardsList2 {
        margin-left: 15px;
      }
      .interestArea-cardsList2-container {
        width: 100vw;
      }
    }
#annualReportCovid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/covidfinal.png');
    background-color: #C8D8DA;
    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportCovid-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    background-color: transparent;
}
.annualReportCovid-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportCovid-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0px;
}
.annualReportCovid-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 50px;
}
.annualReportCovid-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    max-height: auto;
    /* margin-right: 20px; */
    margin: 30px;
    margin-right: 50px;
}
.annualReportCovid-image-container > img {
    width: 30vw;
    height: auto;
}
.annualReportCovid-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    max-height: auto;
    text-align: left;
    /* margin-left: 20px; */
    margin: 30px;
    margin-left: 50px;
}
.annualReportCovid-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportCovid-description-container > h3 {
  font-family: 'Helvetica Neue Medium';
font-size: 18px;
color: #11474f;
font-weight: lighter;
margin-top: 10px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportCovid-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 20px;
    }
    .annualReportCovid-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportCovid-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportCovid-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportCovid-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportCovid-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              /* background-color: #d1e1e2; */
              background-color: transparent;
          }
          .annualReportCovid-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportCovid-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }



@media only screen and (max-width: 1650px) {
  #annualReportCovid-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/covidfinal.png');
    /* background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */


    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #C8D8DA;
}
.annualReportCovid-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    background-color: transparent;
    margin-top: 100px;
    margin-bottom: 100px;
}
.annualReportCovid-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportCovid-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 70px;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0px;
}
.annualReportCovid-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 0px;
}
.annualReportCovid-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportCovid-image-container > img {
    width: 40vw;
    height: auto;
}
.annualReportCovid-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    text-align: left;
    margin: 30px;
}
.annualReportCovid-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  /* text-align: left; */
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportCovid-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 0px;
    }


    .annualReportCovid-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportCovid-cardsList2{
        /* background-color: #d1e1e2; */
        text-align: center;
        margin-right: 10px;
      }
      #annualReportCovid-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportCovid-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportCovid-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              /* background-color: #d1e1e2; */
              background-color: transparent;
          }
          .annualReportCovid-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportCovid-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }
}


@media only screen and (max-width: 800px) {
  #annualReportCovid-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/covidfinal.png');
    /* background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #C8D8DA;
}
.annualReportCovid-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    background-color: transparent;
    margin-top: 0px;
    margin-bottom: 0px;
}
.annualReportCovid-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportCovid-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0px;
}
.annualReportCovid-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 0px;
}
.annualReportCovid-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportCovid-image-container > img {
    width: 50vw;
    height: auto;
}
.annualReportCovid-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    text-align: left;
    margin: 30px;
}
.annualReportCovid-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  /* text-align: left; */
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportCovid-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 0px;
    }


    .annualReportCovid-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportCovid-cardsList2{
        /* background-color: #d1e1e2; */
        text-align: center;
        margin-right: 10px;
      }
      #annualReportCovid-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportCovid-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportCovid-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              /* background-color: #d1e1e2; */
              background-color: transparent;
          }
          .annualReportCovid-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportCovid-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }
}



@media only screen and (max-width: 600px) {
  #annualReportCovid-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/covidfinal.png');
    /* background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #C8D8DA;
}
.annualReportCovid-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    background-color: transparent;
    margin-top: 100px;
    margin-bottom: 100px;
}
.annualReportCovid-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportCovid-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0px;
}
.annualReportCovid-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 0px;
}
.annualReportCovid-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportCovid-image-container > img {
    width: 50vw;
    height: auto;
}
.annualReportCovid-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    text-align: left;
    margin: 30px;
}
.annualReportCovid-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  /* text-align: left; */
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportCovid-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    /* margin-top: 30px; */
    margin-bottom: 150px;
    }


    .annualReportCovid-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportCovid-cardsList2{
        /* background-color: #d1e1e2; */
        text-align: center;
        margin-right: 10px;
      }
      #annualReportCovid-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportCovid-cardsList2 li{
          display: inline-block;
          margin: 0px 0px;
        }
        .annualReportCovid-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              /* background-color: #d1e1e2; */
              background-color: transparent;
          }
          .annualReportCovid-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportCovid-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }
}


@media only screen and (max-width: 370px) {
  #annualReportCovid-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/covidfinal.png');
    /* background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #C8D8DA;
}
.annualReportCovid-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    background-color: transparent;
    margin-top: 100px;
    margin-bottom: 100px;
}
.annualReportCovid-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportCovid-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0px;
}
.annualReportCovid-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 0px;
}
.annualReportCovid-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportCovid-image-container > img {
    width: 50vw;
    height: auto;
}
.annualReportCovid-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    text-align: left;
    margin: 30px;
}
.annualReportCovid-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  /* text-align: left; */
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportCovid-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    /* margin-top: 30px; */
    margin-bottom: 150px;
    }


    .annualReportCovid-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportCovid-cardsList2{
        /* background-color: #d1e1e2; */
        text-align: center;
        margin-right: 10px;
      }
      #annualReportCovid-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportCovid-cardsList2 li{
          display: inline-block;
          margin: 0px 0px;
        }
        .annualReportCovid-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 260px;
              height: auto;
              margin: 0 auto;
              /* background-color: #d1e1e2; */
              background-color: transparent;
          }
          .annualReportCovid-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportCovid-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }
}
.resourceContent {
    background: $bg-light;
    margin-top: -200px;
    padding: 95px 20px 40px 20px;
    @include mobile-full{
        padding: 200px 32px 40px;
    }
    .container >  .MuiGrid-container{
        @include touch {
            // display: flex;
            flex-direction: column;
            align-items: center;
        }  
    }
    .container >  .MuiGrid-container > .MuiGrid-item{
        @include touch {
            margin: 0;
            box-sizing: border-box;
            max-width: 100%;
        }
    }

    .resourceCitation {
        background: #FFF;
        border-radius: 4px;
        color: $teal;
        max-width: 40%;
        padding: 20px 40px;
        position: relative;
        text-align: left;
        .copyBtn {
            cursor: pointer;
        }
        @include mobile-full {
           max-width: 270px;
        }
        h4 {
            color: $gray-text;
            margin: 0 0 20px 0;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        p {
            color: $teal;
        }
        i {
            color: $teal;
            position: absolute;
            top: 15px;
            right: 20px;
        }
        a {
            text-decoration: none;
        }
    }

    .resourceCitation + .resourceBlurb {
        height: inherit;
        display: inherit;
        margin-top: 60px;
        &::before {
            top: 20px;
        }
    }

    .resourceBlurb {
        position: relative;
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 20px;

        @include tablet-full{
            padding-right: 0;
        }
        @include mobile-full {
            display: block;
            margin: 40px 0;
        }
        &::before {
            background: #000;
            content: '';
            height: 1px;
            left: -130%;
            position: absolute;
            width: 1000px;
            top: 80px;
            @include small-desktop {
                display: none;
            }
            @include tablet-full {
                display: none;
            }
        }
        p {
            font-size: 25px;
            text-align: left;
            @include mobile-full {
                font-size: 17px;
                line-height: 21px;
                margin: 0;
            }
        }
    }
    .resourceDownload {
        background: $teal;
        padding: 40px;
        @include mobile-full{
            padding: 31px 35px 25px;
        }
        img {
            display: block;
            margin-bottom: 30px;
            @include mobile-full{
                margin-bottom: 14px;
            }
        }
        a {
            color: #FFF;
            font-size: 20px;
            text-decoration: none;
            @include mobile-full{
                font-size: 16px;
            }
        }
    }
}
.storyCard {
    border-radius: 0 !important;
    height: 100%;
    text-align: left;
    .image {
        background-color: $bg-light;
        height: 240px;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        h3 {
            color: $teal;
            margin: 0;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
        }
    }
    p {
        color: #000;
    }
    .storyDate {
        color: $gray-text;
        font-size: 12px;
        font-weight: 600;
    }
}
#annualReport2021sharing-highlights-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #C8D8DA;
    font-family: 'Helvetica Neue Medium';
}

a {
    color: #007a8a;
}
#contact-header-container {
    background-image: url('https://s3.us-west-2.amazonaws.com/craft-images/Resources-and-Publications/Featured-Images/NCEE-2021-Gathering-Valuable-Student-Feedback-Hero-Image-1440-x-561.jpg');
    background-position: top center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 60%;
    width: 100%;
    left: 0;
    top: 10%;
    z-index: 1;
}

.contact-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
}
.contact-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .contact-header-content-container{
          top: 50%;
          max-width: 600px;
    }
    .contact-header-content-container > h1 {
        font-size: 55px;
    }
}

@media only screen and (max-width: 700px) {
    #contact-header-container {
        top: 0;
    }
    .contact-header-content-container{
        max-width: 450px;
    }
    .contact-header-content-container > h1 {
        font-size: 45px;
    }
}

@media only screen and (max-width: 500px) {
    #contact-header-container {
        height: 60%;
    }
    .contact-header-content-container{
        max-width: 300px;
    }
    .contact-header-content-container > h1 {
        font-size: 35px;
    }
}

@media only screen and (max-width: 376px) {
    .contact-header-content-container{
        max-width: 280px;
    }
    .contact-header-content-container > h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 350px) {
    .contact-header-content-container{
        max-width: 260px;
    }
    .contact-header-content-container > h1 {
        font-size: 25px;
    }
}
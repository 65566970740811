#annualReportTeam-container {
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/WHO+WE+ARE1.png');
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.2); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* -moz-background-size: 100% 100%;
-webkit-background-size: 100% 100%;
background-size: 100% 100%; */

background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 315vh;
    background-position: center;
    background-repeat: no-repeat;
}
.annualReportTeam-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 130vh;
    /* height: 100%; */
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 150px;
}
.annualReportTeam-whoWeAre-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    /* height: 25vh; */
    height: auto;
    max-height: 20000px;
    margin-top: 120px;
    margin-bottom: 0px;
}
.annualReportTeam-whoWeAre-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportTeam-whoWeAre-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportTeam-symbiosis-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  /* height: 50vh; */
  height: auto;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-bottom: 30px;
  }
  .annualReportTeam-symbiosis-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .annualReportTeam-symbiosis-container > h4 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .annualReportTeam-symbiosis-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }

  /* SYMBIOSIS OUR VALUES CARDS CSS */
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
    background-color: transparent;
  }
  #annualReportTeam-cardsList2{
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 300px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}


@media only screen and (max-width: 1500px) {
  #annualReportTeam-container {
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/WHO+WE+ARE1.png');
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.2); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* -moz-background-size: 100% 100%;
-webkit-background-size: 100% 100%;
background-size: 100% 100%; */

background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 300vh;
    background-position: center;
    background-repeat: no-repeat;
}
.annualReportTeam-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 130vh;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 150px;
}
.annualReportTeam-whoWeAre-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    /* height: 40vh; */
    height: auto;
    max-height: 20000px;
    margin-top: 100px;
    margin-bottom: 0px;
}
.annualReportTeam-whoWeAre-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportTeam-whoWeAre-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportTeam-symbiosis-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  /* height: 50vh; */
  height: auto;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-bottom: 30px;
  }
  .annualReportTeam-symbiosis-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .annualReportTeam-symbiosis-container > h4 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .annualReportTeam-symbiosis-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }

  /* SYMBIOSIS OUR VALUES CARDS CSS */
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
    background-color: transparent;
  }
  #annualReportTeam-cardsList2{
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 300px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}


@media only screen and (max-width: 1030px) {
  #annualReportTeam-container {
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/WHO+WE+ARE1.png');
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.2); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* -moz-background-size: 100% 100%;
-webkit-background-size: 100% 100%;
background-size: 100% 100%; */

background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 300vh;
    background-position: center;
    background-repeat: no-repeat;
}
.annualReportTeam-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 150px;
}
.annualReportTeam-whoWeAre-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;
    height: auto;
    max-height: 20000px;
    margin-top: 100px;
    margin-bottom: 0px;
}
.annualReportTeam-whoWeAre-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportTeam-whoWeAre-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportTeam-symbiosis-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: auto;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-bottom: 30px;
  }
  .annualReportTeam-symbiosis-container > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .annualReportTeam-symbiosis-container > h4 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .annualReportTeam-symbiosis-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }

  /* SYMBIOSIS OUR VALUES CARDS CSS */
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
    background-color: transparent;
  }
  #annualReportTeam-cardsList2{
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 300px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}


@media only screen and (max-width: 450px) {
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
  }
  #annualReportTeam-cardsList2{
    /* background-color: #d1e1e2; */
    background-color: #C8D8DA;
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 300px;
    height: auto;
    margin: 0 auto;
    /* background-color: #d1e1e2; */
    background-color: #C8D8DA;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}


@media only screen and (max-width: 400px) {
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
  }
  #annualReportTeam-cardsList2{
    background-color: #C8D8DA;
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 280px;
    height: auto;
    margin: 0 auto;
    background-color: #C8D8DA;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}


@media only screen and (max-width: 370px) {
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
  }
  #annualReportTeam-cardsList2{
    background-color: #C8D8DA;
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 270px;
    height: auto;
    margin: 0 auto;
    background-color: #C8D8DA;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}


@media only screen and (max-width: 350px) {
  .annualReportTeam-symbiosis-container > ul{
    padding-right: 30px;
  }
  #annualReportTeam-cardsList2{
    background-color: #C8D8DA;
    text-align: center;
    margin-right: 10px;
  }
  #annualReportTeam-cardsList2 > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    #annualReportTeam-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }
.annualReportTeam-cards-container {
  position: relative;
    margin-top: 50px;
    width: 230px;
    height: auto;
    margin: 0 auto;
    background-color: #C8D8DA;
}
.annualReportTeam-cards-container > h3 {
  color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}
.annualReportTeam-cards-container > p {
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
}
}
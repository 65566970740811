#twoA-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.twoA-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 720px;
}

/* @media only screen and (max-width: 800px) {
    #twoA-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 150px;
      }
    .twoA-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 450px;
        top: 0;
        left: 0;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
    }
    .twoA-content-container > h1 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 40px;
        letter-spacing: 2px;
      }
}

@media only screen and (max-width: 450px) {
    #twoA-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 130px;
      }
    .twoA-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        top: 0;
        left: 0;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 13px;
    }
    .twoA-content-container > h1 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 30px;
        letter-spacing: 2px;
      }
}

@media only screen and (max-width: 374px) {
    #twoA-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 100px;
      }
    .twoA-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        top: 0;
        left: 0;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
    }
    .twoA-content-container > h1 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 25px;
        letter-spacing: 2px;
      }
}

@media only screen and (max-width: 330px) {
    #twoA-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin-top: 80px;
      }
    .twoA-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        top: 0;
        left: 0;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 11px;
    }
    .twoA-content-container > h1 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 20px;
        letter-spacing: 2px;
      }
} */
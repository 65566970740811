.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    height: 130px;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 8px 4px -2px rgba(0,0,0,0.2);
  }
  
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .navbar-left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 240px;
  }
  .navbar-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 960px;
  }
  
  .navbar-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
  }
  
  .navbar-about {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    width: 100%;
    padding-right: 20px;
    padding-top: 15px;
  }
  .navbar-pillars {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    width: 100%;
    padding-right: 20px;
  }
  
  .navbar-logo {
    display: flex;
    cursor: pointer;
    font-family: Helvetica Neue Medium;
    padding: 45px 23px 0 0;
  }
  
  .navbar-logo img {
    height: auto;
    max-height: 51px;
    width: auto;
    max-width: 220px;
  }
  
  .nav-menu-about {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px 30px;
    list-style: none;
    text-align: center;
    width: 90vw;
    justify-content: end;
    align-items: center;
    /* padding: 0 0 17px 0; */
    padding: 0;
    margin: 0;
  }
  .nav-menu-pillars {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px 25px;
    list-style: none;
    text-align: center;
    width: 90vw;
    justify-content: end;
    padding: 0px;
    margin: 0;
  }
  
  .nav-item {
    height: auto;
  }
  .fa-angle-down {
    font-size: 1rem;
    margin: 5px 0 0.5rem 0.3rem;
  }
  .fa-arrow-right-long {
    font-size: 1rem;
    padding: 0 0 0 0.3rem;
  }
  
  .nav-links-about {
    color: #111111;
    display: flex;
    font-family: "Helvetica Neue Medium", sans-serif;
    font-size: 16px;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0rem;
    /* height: 100%; */
  }
  .nav-links-about:hover {
    color: #d14e1d;
    transition: color 0.5s;
  }
  .nav-links-about-highlight {
    color: #d14e1d;
    display: flex;
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0rem;
    height: 100%;
  }
  .nav-links-about-highlight:hover {
    color: #b03f15;
    transition: color 0.5s;
  }

  .nav-highlight-dropdown {
    float: left;
    overflow: hidden;
  }

  .nav-highlight-dropdown .nav-highlight-button {
    font-size: 16px;  
    border: none;
    outline: none;
    color: #d14e1d;
    background-color: inherit;
    font-family: Helvetica Neue Medium;
    margin: 0;
    padding: 0;
  }
  
  .nav-highlight-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    margin-left: -5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
  }
  
  .nav-highlight-dropdown-content a {
    float: none;
    color: #111111;
    font-size: 16px;
    padding: 14px 16px 7px 16px;
    text-decoration: none;
    font-family: Helvetica Neue Medium;
    display: block;
    text-align: left;
  }
  
  .nav-highlight-dropdown-content a:hover {
    color: #d14e1d;
    transition: color 0.5s;
  }

  .nav-highlight-dropdown:hover .nav-highlight-dropdown-content {
    display: block;
  }

  .nav-links-pillars {
    color: #111111;
    display: flex;
    font-family: Helvetica Neue Medium;
    font-size: 20px;
    font-weight: 300;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0rem;
    /* height: 100%; */
  }
  .nav-links-pillars:hover > i { transform: rotate( -180deg ); transition: transform 150ms ease } 
  
  .nav-links-pillars:hover {
    color: #d14e1d;
    transition: color 0.5s;
  }
  
  .fa-bars {
    color: #ffffff;
  }
  
/* NAVBAR TOPBAR RESOURCES DROPDOWN */
.nav-topBar-dropdown {
  float: left;
  overflow: hidden;
}

.nav-topBar-dropdown .nav-topBar-button {
  font-size: 16px;  
  border: none;
  outline: none;
  color: #111111;
  background-color: inherit;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 8px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-topBar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* margin-left: -5px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  /* padding: 8px 10px; */
  border-radius: 7px;
}

.nav-topBar-first-column {
  border-right: 1px solid rgb(228, 228, 228);
}

.nav-topBar-second-column {
  max-width: 400px;
}

.nav-topBar-dropdown-content a {
  float: none;
  color: #111111;
  font-size: 16px;
  font-weight: 300;
  padding: 14px 20px;
  text-decoration: none;
  font-family: Helvetica Neue Medium;
  display: block;
  text-align: left;
  border-radius: 7px;
}

.nav-topBar-dropdown-content a:hover {
  color: #d14e1d;
  transition: color 0.5s;
  background-color: #ebebeb;
  border-radius: 7px;
}

.nav-topBar-dropdown-content  #nav-topBar-resourcesDropdown-highlight {
  font-weight: bolder;
  font-family: Helvetica;
}

.nav-topBar-dropdown:hover > .nav-topBar-two-column {
  display: flex !important;
  flex-direction: row !important;
  padding: 10px 0;
}

.nav-topBar-dropdown:hover .nav-topBar-dropdown-content {
  display: block;
}


/* NAVBAR TOPBAR ABOUT DROPDOWN */
.nav-topBar-about-dropdown {
  float: left;
  overflow: hidden;
}

.nav-topBar-about-dropdown .nav-topBar-about-button {
  font-size: 16px;  
  border: none;
  outline: none;
  color: #111111;
  background-color: inherit;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 8px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-topBar-about-dropdown-content {
  display: none;
  position: absolute;
  top: 56px;
  /* position: relative; */
  background-color: #f9f9f9;
  /* margin-left: -5px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  /* padding: 8px 10px; */
  border-radius: 7px;
}

.nav-topBar-about-dropdown-content a {
  float: none;
  color: #111111;
  font-size: 16px;
  font-weight: 300;
  padding: 14px 20px;
  text-decoration: none;
  font-family: Helvetica Neue Medium;
  display: block;
  text-align: left;
  border-radius: 7px;
}

.nav-topBar-about-dropdown-content a:hover {
  color: #d14e1d;
  transition: color 0.5s;
  background-color: #ebebeb;
  border-radius: 7px;
}

/* .nav-topBar-about-dropdown-content > #nav-topBar-about-resourcesDropdown-highlight {
  font-weight: bolder;
  font-family: Helvetica;
} */

.nav-topBar-about-dropdown:hover .nav-topBar-about-dropdown-content {
  display: block;
}
  
  /* RESEARCH DROPDOWN */
  .navbar-researchDropdown {
    display: none;
    position: absolute;
    margin-top: 0px;
    padding: 0;
    left: 0;
    right: 0;
    -moz-box-shadow:    0px 3px 5px 5px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
  }
  .nav-item:hover .navbar-researchDropdown {display: block;}
  
  .navbar-researchDropdown[display="block"] .nav-links-pillars > i { transform: rotate( -180deg ) }
  
  .navbar-researchDropdown-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #f9f9f9;
  }
  .navbar-researchDropdown-min-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 1200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 50px 0 50px 0;
  }
  .researchDropdown-one {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 0;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .researchDropdown-one > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 24px;
    font-weight: 300;
    color: #111111;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .researchDropdown-one > p,
  .stories-latest-blog-text {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }

  .stories-latest-blog-text,
  .stories-latest-blog-title
  {
    text-align: left;
    padding: 0 10px;
  }

  .stories-latest-blog-read-now{
    font-family: Helvetica Neue Light;
    font-size: 16px;
    padding: 14px 10px !important;
  }

.stories-latest-blog {
  font-family: Helvetica Neue Medium;
  font-size: 24px;
  font-weight: 300;
}

  .stories-latest-blog-title {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
  }

.stories-latest-blog-text{
  font-family: Helvetica Neue Light;
  font-size: 16px;
}

  .researchDropdown-link {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .researchDropdown-link:hover {
    color: #d14e1d;
  }
  .researchDropdown-two {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 30px;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .researchDropdown-two > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  .researchDropdown-two > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .researchDropdown-three {
    display: grid;
    grid-template-rows: 30px 1fr;
    gap: 16px;
    text-align: left;
    height: auto;
    background-color: #f9f9f9;
    padding: 0 0 0 30px;
  }
  .researchDropdown-three > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .researchDropdown-researchLabs {
    list-style: none;
    margin: 0;
    width: 100%;
    padding: 0;
  }
  .researchDropdown-researchLabs li {
    /* display: inline-block; */
    display: flex;
    width: 160px;
    white-space: nowrap;
    padding: 0 20px 10px 0;
  }
  .researchDropdown-researchLabs > li a {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }

  #nav-dropdown-listLink-temp {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }

  .researchDropdown-researchLabs > li a:hover {
    color: #d14e1d;
    text-decoration: underline;
  }
  
  
  /* PROGRAMS DROPDOWN */
  .navbar-programsDropdown {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    -moz-box-shadow:    0px 3px 5px 5px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
  }
  .nav-item:hover .navbar-programsDropdown {display: block;}
  
  .navbar-programsDropdown[display="block"] .nav-links-pillars > i { transform: rotate( -180deg ) }
  
  .navbar-programsDropdown-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #f9f9f9;
  }
  .navbar-programsDropdown-min-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 1200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 50px 0 50px 0;
  }
  .programsDropdown-one {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 0;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .programsDropdown-one > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 24px;
    font-weight: 300;
    color: #111111;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .programsDropdown-one > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .programsDropdown-link {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .programsDropdown-link:hover {
    color: #d14e1d;
  }
  .programsDropdown-two {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 30px;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .programsDropdown-two > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  /* .programsDropdown-two > h3 {
    font-family: Helvetica Neue Medium;
    font-size: 13px;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    padding: 10px 0 0 0;
  } */
  .programsDropdown-two > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .programsDropdown-three {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    height: auto;
    background-color: #f9f9f9;
    padding: 0 0 0 30px;
  }
  .programsDropdown-three > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  
  
  /* INTEREST AREAS DROPDOWN */
  .navbar-interestareasDropdown {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    -moz-box-shadow:    0px 3px 5px 5px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
  }
  .nav-item:hover .navbar-interestareasDropdown {display: block;}
  
  .navbar-interestareasDropdown[display="block"] .nav-links-pillars > i { transform: rotate( -180deg ) }
  
  .navbar-interestareasDropdown-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #f9f9f9;
  }
  .navbar-interestareasDropdown-min-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 50px;
  }
  .interestareasDropdown-one {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 0;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .interestareasDropdown-one > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 24px;
    font-weight: 300;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  .interestareasDropdown-one > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .interestareasDropdown-link {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .interestareasDropdown-link:hover {
    color: #d14e1d;
  }
  .interestareasDropdown-two {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 30px;
    /* border-right: 1px solid rgb(228, 228, 228); */
  }
  .interestareasDropdown-two > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  /* .interestareasDropdown-two > h3 {
    font-family: Helvetica Neue Medium;
    font-size: 13px;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    padding: 10px 0 0 0;
  } */
  .interestareasDropdown-two > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .interestareasDropdown-three {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    height: auto;
    background-color: #f9f9f9;
    padding: 0 0 0 30px;
  }
  .interestareasDropdown-three > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  /* .interestareasDropdown-three > h3 {
    font-family: Helvetica Neue Medium;
    font-size: 13px;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    padding: 10px 0 0 0;
  } */
  .interestareasDropdown-three > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }

  .interestareasDropdown-programsInterestAreas {
    list-style: none;
    margin: 0;
    width: 100%;
    padding: 0;
    margin-top: -4px;
  }
  .interestareasDropdown-programsInterestAreas li {
    /* display: inline-block; */
    display: flex;
    width: 160px;
    white-space: nowrap;
    padding: 0 0px 5px 0;
  }
  .interestareasDropdown-programsInterestAreas > li a {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .interestareasDropdown-programsInterestAreas > li a:hover {
    color: #d14e1d;
    text-decoration: underline;
  }

  /* PHILANTHROPY DROPDOWN */
  .navbar-philanthropyDropdown {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    -moz-box-shadow:    0px 3px 5px 5px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
  }
  .nav-item:hover .navbar-philanthropyDropdown {display: block;}
  
  .navbar-philanthropyDropdown[display="block"] .nav-links-pillars > i { transform: rotate( -180deg ) }
  
  .navbar-philanthropyDropdown-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #f9f9f9;
  }
  .navbar-philanthropyDropdown-min-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 1200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 50px 0 50px 0;
  }
  .philanthropyDropdown-one {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 0;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .philanthropyDropdown-one > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 24px;
    font-weight: 300;
    color: #111111;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .philanthropyDropdown-one > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .philanthropyDropdown-link {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .philanthropyDropdown-link:hover {
    color: #d14e1d;
  }
  .philanthropyDropdown-two {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 30px 0 30px;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .philanthropyDropdown-two > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  /* .philanthropyDropdown-two > h3 {
    font-family: Helvetica Neue Medium;
    font-size: 13px;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    padding: 10px 0 0 0;
  } */
  .philanthropyDropdown-two > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .philanthropyDropdown-three {
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    gap: 20px;
    text-align: left;
    height: auto;
    background-color: #f9f9f9;
    padding: 0 0 0 30px;
  }
  .philanthropyDropdown-three > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  /* .philanthropyDropdown-three > h3 {
    font-family: Helvetica Neue Medium;
    font-size: 13px;
    letter-spacing: 3px;
    color: #111111;
    white-space: wrap;
    padding: 10px 0 0 0;
  } */
  .philanthropyDropdown-three > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  
  
  /* ABOUT DROPDOWN */
  .navbar-aboutDropdown {
    display: none;
    position: absolute;
    margin-top: 0px;
    padding: 0;
    left: 0;
    right: 0;
    -moz-box-shadow:    0px 3px 5px 5px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
  }
  .nav-item:hover .navbar-aboutDropdown {display: block;}
  
  .navbar-aboutDropdown[display="block"] .nav-links-pillars > i { transform: rotate( -180deg ) }
  
  .navbar-aboutDropdown-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: #f9f9f9;
  }
  .navbar-aboutDropdown-min-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 50px 0 50px 0;
  }
  .aboutDropdown-one {
    display: grid;
    grid-template-rows: 30px 1fr;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 70px 0 0;
    border-right: 1px solid rgb(228, 228, 228);
  }
  .aboutDropdown-one > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 24px;
    font-weight: 300;
    color: #111111;
    white-space: wrap;
    margin: 0;
    padding: 0;
  }
  .aboutDropdown-one > p {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
  .aboutDropdown-link {
    font-family: Helvetica Neue Light;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }
  .aboutDropdown-link:hover {
    color: #d14e1d;
  }
  .aboutDropdown-two {
    display: grid;
    grid-template-rows: 1fr;
    gap: 20px;
    text-align: left;
    background-color: #f9f9f9;
    padding: 0 0 0 70px;
  }
  .aboutDropdown-two > h2 {
    font-family: Helvetica Neue Medium;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #111111;
    white-space: nowrap;
    margin-top: 0px;
  }
  .aboutDropdown-aboutList {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .aboutDropdown-aboutList li {
    display: inline-block;
    width: 160px;
    white-space: nowrap;
    padding: 10px 40px 10px 0px;
  }
  .aboutDropdown-aboutList > li a {
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    font-weight: 300;
    color: #111111;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0rem;
  }
  .aboutDropdown-aboutList > li a:hover {
    color: #d14e1d;
    text-decoration: underline;
  }
  
  .mobileNavbar-container {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    width: 100%;
  }


  @media screen and (max-width: 1350px) {
    .navbar-right-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 840px;
    }
    .navbar-researchDropdown-min-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 1024px;
      height: auto;
      background-color: #f9f9f9;
      padding: 50px 0 50px 0;
    }
    .navbar-programsDropdown-min-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 1024px;
      height: auto;
      background-color: #f9f9f9;
      padding: 50px 0 50px 0;
    }
    .navbar-philanthropyDropdown-min-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 1024px;
      height: auto;
      background-color: #f9f9f9;
      padding: 50px 0 50px 0;
    }
    .navbar-aboutDropdown-min-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 1024px;
      height: auto;
      background-color: #f9f9f9;
      padding: 50px 0 50px 0;
    }
  }


  @media screen and (max-width: 1100px) {
    .navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      height: 128px;
      position: sticky;
      top: 0;
      z-index: 999;
      box-shadow: 0 8px 4px -2px rgba(0,0,0,0.2);
    }

    .navbar-container {
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 90px;
      width: 100%;
    }

    .mobileNavbar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      width: 100%;
      padding: 0 30px 0 30px;
    }

    .mobileNavbar-left-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobileNavbar-logo {
      display: flex;
      cursor: pointer;
      font-family: Helvetica Neue Medium;
    }

    .mobileNavbar-logo img {
      height: auto;
      max-height: 51px;
      width: auto;
      max-width: 220px;
    }

    .mobileNavbar-right-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 90px;
    }

    .mobileNavbar-navIcon {
      margin-left: 2rem;
      font-size: 2rem;
      height: 90px;
      display: flex;
      margin: 0;
      justify-content: flex-start;
      align-items: center;
    }
    .mobileNavbar-navIcon i {
      color: #111111;
      cursor: pointer;
    }
  }


  @media screen and (max-width: 1000px) {
    .navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      height: 128px;
      position: sticky;
      top: 0;
      z-index: 999;
      box-shadow: 0 8px 4px -2px rgba(0,0,0,0.2);
    }
    .mobileNavbar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 110px;
      width: 100%;
      padding: 0 50px 0 50px;
    }
  }


  @media screen and (max-width: 700px) {
    .navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      height: 70px;
      position: sticky;
      top: 0;
      z-index: 999;
      box-shadow: 0 8px 4px -2px rgba(0,0,0,0.2);
    }
    .mobileNavbar-logo img {
      height: auto;
      max-height: 39px;
      width: auto;
      max-width: 170px;
    }
    .mobileNavbar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      width: 100%;
      max-width: 400px;
      padding: 0 30px 0 30px;
    }
  }
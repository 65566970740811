#vrLearningMapBody-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
  }
  .vrLearningMapBody-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    /* max-width: auto; */
    /* height: 1000px; */
    /* max-height: 20000px; */
    /* height: 100%;
    max-height: 100%; */
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }


  @media only screen and (max-width: 500px) {
    #vrLearningMapBody-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;
    }
    .vrLearningMapBody-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 80vw;
      height: 1000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
.entryContent {
    background: $bg-light;
    padding: 0 100px 60px 100px;
    @include mobile {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%;
    }
    @include small-mobile {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
    @include mobile-full{
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
    }
    .container {
        padding: 60px 104px;
        background: #FFF;
        max-width: 994px;
        @include touch {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 100%;
        }
        @include xs-mobile {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }
        h1 {
            color: #00464F;
            margin-top: 10px;
            text-align: left;
            margin: 0 0 20px;
            @include mobile-full{
                font-size: 31px;
                line-height: 39px;
            }
        }
        .catLabel {
            color: $orange;
            text-transform: uppercase;
            text-align: left;
            margin: 0;
            font-weight: 700;
        }
        .entryBlurb {
            text-align: left;
            p {
                color: $gray-text;  
                font-weight: 600;
                font-size: 25px;
                line-height: 125%;
                color: #434242;
                @include mobile-full{
                    font-size: 20px !important;
                    line-height: 25px !important;
                    margin-top: 20px !important;
                    margin-bottom: 40px;
                }
            }
            
        }
        .featuredImage {
            margin: 60px 0 20px 0;
        }
        .featuredImageCaption {
            color: $gray-text;
            margin: 0 0 20px 0;
            @include mobile-full{
                font-size: 16px;
                line-height: 24px;
            }
        }
        .catTags {
            min-height: 45px;
            padding-bottom: 30px;
            text-align: left;
            a {
                border: $teal 2px solid;
                border-radius: 4px;
                display: inline-block;
                font-weight: 600;
                margin-right: 20px;
                padding: 10px 15px;
                text-decoration: none;
                i {
                    margin-right: 5px;
                }
            }
            .share {
                display: inline-block;
                float: right;
                a {
                    border: none;
                    font-size: 20px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    img {
                        padding: 0 0 0 20px;
                    }
                }
            }
        }
        .projectLineSection {
            border-bottom: #AEAEAE solid 1px;
            padding: 20px 0;
            // padding-top: 40px;
            margin-bottom: 20px;
            display: flex;
            text-align: left;
        }
        .authorSection {
            border-top: #AEAEAE solid 1px;
            padding: 40px 0;
            display: flex;
            text-align: left;
            .authorImage {
                width: 40%;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
            .authorContent {
                width: 55%;
                padding-left: 40px;
                h5 {
                    color: $gray-text;
                    margin: 0;
                    text-transform: uppercase;
                }
                h4 {
                    font-size: 20px;
                }
                h3 {
                    color: $teal;
                }
                .readMore {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    padding-top:30px;
                    text-decoration: none;
                    text-transform: uppercase;
                    i {
                        padding-left: 5px
                    }
                }
            }
        }
    }
}
.relatedPosts {
    background-color: $bg-light;
    padding: 60px 32px;
    @include mobile {
        padding: 0 32px 26px !important;
        background-color: #fff;;
    }
    @include small-mobile {
        padding: 0 32px 26px !important;
    }
    .container {
        padding: 0 !important;
    }
    h2 {
        text-align: left;
    }
    .button {
        margin-top: 60px;
    }
}
#annualReport2021philanthropy-partnerships-container {
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 240% 120%;
    background-position: 32% 32%;
    background-repeat: no-repeat;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021philanthropy-partnerships-intro-container {
    display: flex;
    flex-direction: row;
    margin: 10% 50px 200px 12vw;
    align-items: flex-start;
}

.annualReport2021philanthropy-partnerships-text-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    text-align: left;
    padding-top: 10%;
}

.annualReport2021philanthropy-partnerships-text-container > h1 {
    color: #d14e1d;
    font-weight: bold;
    font-size: calc(20px + 2vw);
}

.annualReport2021philanthropy-partnerships-text-container > p {
    color: #00464f;
    font-size: calc(12px + .5vw);
    width: 90%;
}

.annualReport2021philanthropy-partnerships-text-container > img {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}

.annualReport2021philanthropy-partnerships-img-container {
    width: 50%;
    height: 100%;
    padding-top: 10%;
}

.annualReport2021philanthropy-partnerships-img-container > img {
    width: 80%;
    height: 100%;
    padding: 10% 0 0 100px;
}

.annualReport2021philanthropy-partnerships-banner-container {
    position: relative;
    text-align: left;
    color: #ffffff;
    height: fit-content;
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.annualReport2021philanthropy-partnerships-banner-container > img {
    width: 100%;
}

.annualReport2021philanthropy-partnerships-banner-container > h2 {
    position: absolute;
    padding: 1.5vw 2vw 1vw;
    font-size: calc(14px + 1vw); 
}


@media only screen and (max-width: 1500px) {
    #annualReport2021philanthropy-partnerships-container {
        background-size: 120% 50vw;
        background-position: 50% 0;
    }
    
    .annualReport2021philanthropy-partnerships-intro-container {
        flex-direction:column-reverse;
        margin: 0 12vw -30vw;
        align-items: center;
    }
    
    .annualReport2021philanthropy-partnerships-text-container {
        position: relative;
        width: 80%;
        padding-top: 0;
        padding-bottom: 50px;
        top: -35vw;
    }
    
    .annualReport2021philanthropy-partnerships-text-container > h1 {
        padding: 8vw 0;
        width: 30%;
        font-size: calc(40px + 3vw);
        text-align: center;
    }    
    
    .annualReport2021philanthropy-partnerships-text-container > img {
        padding-top: 20px;
    }
    
    .annualReport2021philanthropy-partnerships-img-container {
        width: 50%;
        padding: 0 0 0 50%;
    }
        
        
}

@media only screen and (max-width: 1000px) {

    .annualReport2021philanthropy-partnerships-text-container > h1 {
        font-size: calc(30px + 2.5vw);
    }    

}

@media only screen and (max-width: 700px) {

    .annualReport2021philanthropy-partnerships-text-container > h1 {
        font-size: calc(25px + 2vw);
    }    

}



#returnToLearn-LinksContentCarousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
  }
  .returnToLearn-Links-content-container {
    position: relative;
    /* width: 100%; */
  }
  .returnToLearn-LinksContentCarousel-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    max-height: 20000px;
    margin-top: 50px;
    margin-bottom: 80px;
  }
  .returnToLearn-LinksContentCarousel-slider {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 100%;
  max-height: 20000px;
  }
  .slick-prev:before,
  .slick-next:before {
  color: rgb(40, 40, 40);
  }
  .returnToLearn-LinksContentCarousel-card-link {
  text-decoration: none;
  width: 100%;
  max-width: 300px;
  border: 2px solid #f2f2f2;
  }
  .returnToLearn-LinksContentCarousel-card-link:hover {
  text-decoration: none;
  width: 100%;
  max-width: 300px;
  border: 2px solid #00464f;
  }
  .returnToLearn-LinksContentCarousel-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 20000px;
  }
  .returnToLearn-LinksContentCarousel-card-image {
  display: flex;
  width: 300px;
  height: 200px;
  }
  .returnToLearn-LinksContentCarousel-card-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 300px;
    height: 250px;
    background-color: #f9f9f9;
    ;
  }
  .returnToLearn-LinksContentCarousel-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  .returnToLearn-LinksContentCarousel-text > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(40, 40, 40);
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .returnToLearn-LinksContentCarousel-text > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(82, 82, 82);
    font-size: 17px;
    text-align: left;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .returnToLearn-LinksContentCarousel-card-content-container {
    display: flex;
    flex-direction: column;
    margin: 30px;
  }
  
  .returnToLearn-Links-content-header {
    display: flex;
    background-color: #e0e0e0;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }
  
  .returnToLearn-Links-content-title {
    font-family: 'HelveticaNeue-CondensedBold', Arial, Helvetica, sans-serif;
    display: inline;
    line-height: 1;
  }
  
  .returnToLearn-Links-content-title > h2 {
    font-size: calc(12px + 4.5vmin);
    margin: 15px;
    padding-top: 2vw;
    color: #000;
  }  

  @media only screen and (max-width: 1500px) {  
    /* .returnToLearn-Links-content-subtitle-text {
      padding: 0 15px;
    } */
  }
  
  @media only screen and (max-width: 1208px) {
  
  /* .returnToLearn-Links-content-subtitle-text {
    padding: 0 0 0 15px;
    margin-top: 0;
  } */
  }
  
  
  @media only screen and (max-width: 950px) {
  
  /* .returnToLearn-Links-content-subtitle-text {
    padding: 0 15px;
  } */
  }
  
  @media only screen and (max-width: 730px) {
  
    .returnToLearn-LinksContentCarousel-card-content {
      height: fit-content;
    }
  }
  
  
  @media only screen and (max-width: 600px) {

    .slick-dots {
      bottom: -50px;;
      }    
  }
  
  @media only screen and (max-width: 380px) {
  
  /* .returnToLearn-Links-content-subtitle-text {
    padding: 0 10px;
  } */
  }
  
  
  @media only screen and (max-width: 360px) {
  
  /* .returnToLearn-Links-content-subtitle-text {
    padding-left: 0;
  } */
  }
  
  
  @media only screen and (max-width: 330px) {
  
  /* .returnToLearn-Links-content-subtitle-text {
    padding-left: 0;
  } */
  }
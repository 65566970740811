#programProject-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

/* HEADER */
.programProject-header-container {
    /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
}
.programProject-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
}
.programProject-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px;
    }
    .programProject-header-content-container > h1 {
        font-size: 55px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .programProject-header-container {
        /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 70%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px;
    }
    .programProject-header-content-container > h1 {
        font-size: 45px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .programProject-header-container {
        /* background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/what+we+do.jpg'); */
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px;
    }
    .programProject-header-content-container > h1 {
        font-size: 35px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 376px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px;
    }
    .programProject-header-content-container > h1 {
        font-size: 30px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}

@media only screen and (max-width: 350px) {
    .programProject-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px;
    }
    .programProject-header-content-container > h1 {
        font-size: 25px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
}



/* MAIN BODY CONTENT */
#programProject-main-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
}
@media only screen and (max-width: 700px) {
    #programProject-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 70%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #programProject-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 60%;
    }
  }


/* BRIEF & DOCUMENT */
#researchPost-briefDoc-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.researchPost-briefDoc-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* max-width: 800px; */
    height: 100%;
    max-height: 1250px;
    top: 0;
    left: 0;
}
.researchPost-brief-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 400px;
    background-color: #1a7f4f;
}
.researchPost-brief-description-container {
  display: flex;
  justify-content: center;
    align-items: center;
  text-align: center;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 1000px;
}
.researchPost-brief-description-container > p {
    font-family: 'Helvetica Neue Medium';
    color: #e3eaea;
    font-size: 20px;
    font-weight: lighter;
    text-align: center;
    line-height: 40px;
    /* margin: 30px; */
}
.researchPost-document-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 400px;
    background-color: #00464f;
}
.researchPost-document-container > p {
    font-family: 'Helvetica Neue Medium';
    color: #e3eaea;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    margin-bottom: -1px;
    margin-top: -5px;
    /* line-height: 30px; */
}

@media only screen and (max-width: 1440px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 1250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 20px;
        font-weight: lighter;
        text-align: center;
        line-height: 35px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 20px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 1200px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 1250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 18px;
        font-weight: lighter;
        text-align: center;
        line-height: 30px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 20px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 1050px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 1250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 350px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        text-align: center;
        line-height: 25px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        height: 400px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 20px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 850px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 2250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 20px;
        font-weight: lighter;
        text-align: center;
        line-height: 30px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 20px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 600px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 2250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 18px;
        font-weight: lighter;
        text-align: center;
        line-height: 25px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 450px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 2250px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        text-align: center;
        line-height: 22px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 17px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 380px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 2200px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        text-align: center;
        line-height: 20px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 17px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}

@media only screen and (max-width: 350px) {
    #researchPost-briefDoc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .researchPost-briefDoc-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        /* max-width: 800px; */
        height: 100%;
        max-height: 2200px;
        top: 0;
        left: 0;
    }
    .researchPost-brief-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #1a7f4f;
    }
    .researchPost-brief-description-container {
      display: flex;
      justify-content: center;
        align-items: center;
      text-align: center;
      width: 100%;
      max-width: 260px;
      height: 100%;
      max-height: 1000px;
    }
    .researchPost-brief-description-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-size: 16px;
        font-weight: lighter;
        text-align: center;
        line-height: 20px;
        /* margin: 30px; */
    }
    .researchPost-document-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: 350px;
        background-color: #00464f;
    }
    .researchPost-document-container > p {
        font-family: 'Helvetica Neue Medium';
        color: #e3eaea;
        font-weight: bolder;
        font-size: 17px;
        text-align: center;
        margin-bottom: -1px;
        margin-top: -5px;
        /* line-height: 30px; */
    }
}


/* DESCRIPTION  */
#programProject-description-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}
.programProject-description-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 40px;
    margin-bottom: 40px;
}
.programProject-description-content-container > h3 {
  font-family: 'Helvetica Neue Light';
  color: #e3eaea;
  font-size: 40px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.programProject-description-content-container > p {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
  }

  @media only screen and (max-width: 1440px) {
    #programProject-description-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }
    .programProject-description-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1024px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .programProject-description-content-container > h3 {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 40px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .programProject-description-content-container > p {
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 18px;
        text-align: left;
        line-height: 40px;
      }
  }

  @media only screen and (max-width: 1250px) {
    #programProject-description-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }
    .programProject-description-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 50000px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .programProject-description-content-container > h3 {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 40px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .programProject-description-content-container > p {
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 18px;
        text-align: left;
        line-height: 40px;
      }
  }

  @media only screen and (max-width: 1000px) {
    #programProject-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
  }
  .programProject-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .programProject-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .programProject-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 18px;
      text-align: left;
      line-height: 40px;
    }
  }

  @media only screen and (max-width: 700px) {
    #programProject-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
  }
  .programProject-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  .programProject-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .programProject-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 17px;
      text-align: left;
      line-height: 35px;
    }
  }
  @media only screen and (max-width: 500px) {
    #programProject-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
  }
  .programProject-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .programProject-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 30px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .programProject-description-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #e3eaea;
      font-size: 16px;
      text-align: left;
      line-height: 30px;
    }
  }
  @media only screen and (max-width: 380px) {
    #programProject-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
  }
  .programProject-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .programProject-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .programProject-description-content-container > p {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    }
  }
  @media only screen and (max-width: 350px) {
    #programProject-description-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
  }
  .programProject-description-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 260px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .programProject-description-content-container > h3 {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .programProject-description-content-container > p {
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    }
  }


/* RESEARCH */
#programProject-research-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .programProject-research-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    max-height: 20000px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


/* VIDEO */
#programProject-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}
.programProject-video-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    margin-right: 40px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1250px) {
    #programProject-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
        margin-right: 40px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    #programProject-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 20000px;
        margin-right: 40px;
        /* margin-bottom: 50px; */
    }
}

@media only screen and (max-width: 700px) {
    #programProject-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 20000px;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 500px) {
    #programProject-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 20000px;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 350px) {
    #programProject-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
    }
    .programProject-video-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        margin-right: 40px;
    }
}


/* DOCUMENT */
#programProject-document-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}
.programProject-Cards-Data2-Container{
    background-color: #f2f2f2;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 50000px;
  }
#bandLab-links-container {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bandLab-links-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    /* margin-top: 30px; */
    margin-bottom: 30px;
  }
  
  #interestArea-Cards-Data2-Container{
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 50000px;
  }
  
  
  /* VR LANDING PAGE CARDS CSS */
  .bandLab-cards-container {
  position: relative;
  margin-top: 50px;
  width: 300px;
  height: 250px;
  cursor: pointer;
  margin: 0 auto;
  }
  
  .bandLab-cards-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.5s ease;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.4);
  }
  
  .bandLab-cards-container > img {
  position: absolute;
  width: 300px;
  height: 250px;
  left: 0;
  }
  
  .bandLab-cards-title {
  position: absolute;
  width: 300px;
  left: 0;
  top: 90px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  transition: top 0.5s ease;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-title {
  top: 80px;
  }
  
  .bandLab-cards-button {
  position: absolute;
  width: 300px;
  left: 0;
  top: 145px;
  text-align: center;
  opacity: 0;
  font-size: 13px;
  transition: opacity 0.35s ease;
  }
  
  .bandLab-cards-button span {
  width: 150px;
  padding: 7px 30px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-button {
  opacity: 1;
  }
  
  @media only screen and (max-width: 374px) {
  .bandLab-cards-container {
    position: relative;
    margin-top: 50px;
    width: 240px;
    height: 200px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .bandLab-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s ease;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .bandLab-cards-container > img {
    position: absolute;
    width: 240px;
    height: 200px;
    left: 0;
  }
  
  .bandLab-cards-title {
    position: absolute;
    width: 240px;
    left: 0;
    top: 75px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top 0.5s ease;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-title {
    top: 65px;
  }
  
  .bandLab-cards-button {
    position: absolute;
    width: 240px;
    left: 0;
    top: 145px;
    text-align: center;
    opacity: 0;
    font-size: 13px;
    transition: opacity 0.35s ease;
  }
  
  .bandLab-cards-button span {
    width: 150px;
    padding: 7px 30px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .bandLab-cards-container:hover .bandLab-cards-button {
    opacity: 1;
  }
  }
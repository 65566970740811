.homepageContent {
    #hero {
        #heroContent {
            display: block;
            margin-bottom: 0 !important;
            min-height: auto !important;
            .featuredItem {
                background: $teal;
                color: #FFF;
                text-align: left;
                padding: 40px;
                .label {
                    background: #FFF;
                    color: $teal;
                    display: inline-block;
                    padding: 15px 25px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                h2 {
                    font-size: 51px !important;
                    line-height: 1.1em;
                    max-width: 80% !important;
                }
                p {
                    font-size: 1.2em;
                    max-width: 80%;
                    padding-bottom: 40px;
                }
                a {
                    color: #FFF;
                    display: block;
                    font-weight: bold;
                    text-decoration: none;
                }
            }
            .blurb {
                background: transparent !important;
                color: $orange;
                display: block;
                font-size: 1.5em;
                margin-top: 0 !important;
                left:auto !important;
                top: auto !important;
                position: relative !important;
                text-align: center;
                width: 70% !important;
                margin: 0 auto !important;
                padding: 80px 0 !important;
                p {
                    font-size: 1.5em !important;
                    margin-bottom: 40px;
                }
            }
        }
    }
}
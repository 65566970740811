.marineNav {
  background-color: var(--off-white);
  position: sticky;
  display: flex;
  top: 0px;
  width: 100%;
  /* height: 120px; */
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  background: #fafafa;
}
.marineNav .marineNav-content {
  max-width: 95vw;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.marineNav-items {
  padding-right: 40px;
}
.marineNav-items li {
  display: inline-block;
  margin: 10px 10px;
}
.marineNav-item {
  display: inline;
  margin-left: 2rem;
}
.marineNav-item:hover {
  color: black;
  cursor: pointer;
}
.marineNav-title {
  background-color: #fafafa;
  margin-top: 30px;
  margin-bottom: 0px;
}
.marineNav-title > h2 {
  font-family: 'Helvetica Neue Medium';
  color: #d14e1d;
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: 0px;
  letter-spacing: 2px;
}
.marineNav-title > p {
  font-family: 'Helvetica Neue Light';
  font-size: 17px;
  color: rgb(62, 62, 62);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
}
.marineNav-careerButton {
  width: 70px;
  height: 70px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid rgb(62, 62, 62);
}
.marineNav-careerButton:hover {
  width: 70px;
  height: 70px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #00464f;
}
.marineNav-careerButton > img {
  width: 70px;
  height: 70px;
  left: 0;
}

@media only screen and (max-width: 1600px) {
  .marineNav {
    background-color: var(--off-white);
    position: sticky;
    display: flex;
    top: 0px;
    width: 100%;
    /* height: 120px; */
    z-index: 1000;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
    background: #fafafa;
  }
  .marineNav .marineNav-content {
    max-width: 95vw;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .marineNav-items {
    padding-right: 40px;
  }
  .marineNav-items li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineNav-item {
    display: inline;
    margin-left: 2rem;
  }
  .marineNav-item:hover {
    color: black;
    cursor: pointer;
  }
  .marineNav-title {
    background-color: #fafafa;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .marineNav-title > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #d14e1d;
    font-size: 22px;
    margin-bottom: 0px;
    margin-top: 0px;
    letter-spacing: 2px;
  }
  .marineNav-title > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .marineNav-careerButton {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid rgb(62, 62, 62);
  }
  .marineNav-careerButton:hover {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #00464f;
  }
  .marineNav-careerButton > img {
    width: 60px;
    height: 60px;
    left: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .marineNav {
    background-color: var(--off-white);
    position: sticky;
    display: flex;
    top: 0px;
    width: 100%;
    /* height: 120px; */
    z-index: 1000;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
    background: #fafafa;
  }
  .marineNav .marineNav-content {
    max-width: 95vw;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .marineNav-items {
    padding-right: 40px;
  }
  .marineNav-items li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineNav-item {
    display: inline;
    margin-left: 2rem;
  }
  .marineNav-item:hover {
    color: black;
    cursor: pointer;
  }
  .marineNav-title {
    background-color: #fafafa;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .marineNav-title > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #d14e1d;
    font-size: 22px;
    margin-bottom: 0px;
    margin-top: 0px;
    letter-spacing: 2px;
  }
  .marineNav-title > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .marineNav-careerButton {
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid rgb(62, 62, 62);
  }
  .marineNav-careerButton:hover {
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #00464f;
  }
  .marineNav-careerButton > img {
    width: 50px;
    height: 50px;
    left: 0;
  }
}


@media only screen and (max-width: 750px) {
  .marineNav {
    background-color: var(--off-white);
    position: relative;
    display: flex;
    top: 0px;
    width: 100%;
    /* height: 120px; */
    z-index: 1000;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
    background: #fafafa;
  }
  .marineNav .marineNav-content {
    max-width: 95vw;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
  }
  .marineNav-items {
    padding-right: 40px;
  }
  .marineNav-items li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineNav-item {
    display: inline;
    margin-left: 2rem;
  }
  .marineNav-item:hover {
    color: black;
    cursor: pointer;
  }
  .marineNav-title {
    background-color: #fafafa;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .marineNav-title > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #d14e1d;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 0px;
    letter-spacing: 2px;
  }
  .marineNav-title > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .marineNav-careerButton {
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid rgb(62, 62, 62);
  }
  .marineNav-careerButton:hover {
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #00464f;
  }
  .marineNav-careerButton > img {
    width: 50px;
    height: 50px;
    left: 0;
  }
}
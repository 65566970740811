.benefitsAccordion-section-container {
    background-color: #00464f;
}

.benefitsAccordion-content-container {
    margin: 0 10% 50px;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}

.benefitsAccordion-item {
    background-color: #f9f9f9;
    color: #007a8a;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    border: lightgray 2px solid;

}

.benefitsAccordion-title {
    display: grid;
    grid-template-columns: 220px 1fr 50px;
}

.benefitsAccordion-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    justify-self: flex-start;
}

.benefitsAccordion-text h3{
    color: black;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.benefitsAccordion-title > i{
    display: flex;
    align-items: center;
    color: #d14e1d;
    font-size: x-large;
}

.benefitsAccordion-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: lightgray 2px solid;
}

.benefitsAccordion-image {
    padding: 10px 10px;
}

.benefitsAccordion-image > img{
    width: 150px;
    height: 150px;
}

.benefitsAccordion-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    color: black;
    height: fit-content;
    padding-top: 10px;
}

.benefitsAccordion-description > p{
    text-align: left;
    padding: 0 25px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.benefitsAccordion-description > p:empty{
    display: none;
}

@media only screen and (max-width: 1250px) {  

    .benefitsAccordion-content {
        flex-direction: column;  
        align-items: center;  
    }
    
}

@media only screen and (max-width: 768px) {  
    .benefitsAccordion-title {
        display: flex;
        flex-direction: column;
    }

    .benefitsAccordion-image > img{
        width: 100%;
        max-width: 210px;
        height: 100%;
    }    

    .benefitsAccordion-title > i{
        justify-content: center;
        font-size: xx-large;
    }    

    .benefitsAccordion-text h2,
    .benefitsAccordion-text h3{
        text-align: center;
    }
    
    
}


@media only screen and (max-width: 600px) {  

    .benefitsAccordion-title-text {
        text-align: left;
    }    

}

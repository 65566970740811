#twoE-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .twoE-content-container {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: 330px) {
    .twoE-content-container {
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }
.legacySubjectsContainer {
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.legacySubjectsHeaderText {
  padding: 50px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legacySubjectsHeaderText p {
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: xx-large;
  color: #d14e1d;
}

.legacySubjectsHeaderTextUnderline {
  width: 80vw;
  border-top: black;
  border-style: solid;
}

.legacySubjectsInterestAreas {
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 0 20%;
    flex-wrap: wrap;
}

.legacySubjectsCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  grid-template-rows: repeat(7, 1fr);
  row-gap: 15px;
  grid-auto-flow: column;
}

.legacySubjectsCardIndivid {
  display: flex;
  height: 50px;
  background-color: #f9f9f9;
  /* width: 50%; */
}

.legacySubjectsCardIndivid:hover {
  filter: brightness(0.8);
}
  
.legacySubjectsCardIndivid > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  width: 100%;
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #000;
}

.legacySubjectsCardIndivid > a > p {
  text-align: left;
}

.legacySubjectsCardIcon {
  padding-right: 20px;
}

  @media screen and (max-width: 1400px) {
  }
  
  @media screen and (max-width: 1100px) {
    
  }

  @media screen and (max-width: 980px) {
    .legacySubjectsInterestAreas {
      padding: 0 10%;
  }
  
  }

  @media screen and (max-width: 650px) {
    .legacySubjectsHeaderText p {
      font-size: x-large;
    }

    .legacySubjectsCards {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0;
      grid-template-rows: repeat(14, 1fr);
      row-gap: 14px;
      grid-auto-flow: column;
    }
    
  }

.stemDbrLabMembers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #E2E3E4;
  }
  .stemDbrLabMembers-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 0 50px 0;
    padding: 0;
  }
  .stemDbrLabMembers-content-container > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 30px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0 0 10px 0;
  }
  .stemDbrLabMembers-cardsList {
    padding: 0;
    margin: 0;
  }
  .stemDbrLabMembers-cardsList li {
    display: inline-block;
    margin: 0;
    padding: 30px 50px;
  }
  
  .stemDbrLabMembers-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      height: 100%;
    }
    .stemDbrLabMembers-card-image {
    display: flex;
    width: 230px;
    height: 300px;
    }
    .stemDbrLabMembers-card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      background-color: #077A8A;
      padding: 10px 0 10px 0;
    }

    .stemDbrLabMembers-card-content > h2 {
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 16px;
      color: #F9F9F9;
      margin: 0;
    }

    .stemDbrLabMembers-card-content > p {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      font-size: 16px;
      color: #F9F9F9;
      margin: 0;
    }

    @media only screen and (max-width: 600px) {

      .stemDbrLabMembers-content-container > h2 {
        font-size: 22px;
        margin: 0;
      }
  
      }
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 140%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 250px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-top: 100px;
  margin-bottom: 50px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  margin-left: 70px;
}
.annualReportPrograms-image-container > img {
width: 30vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 30vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  margin-right: 70px;
}
.annualReportPrograms-titleLine {
  width: 80%;
  border-bottom: solid 2px white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 75px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
/* text-align: left; */
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 100px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        margin-top: 100px;
      }
      .annualReportPrograms-cards-container {
          display: flex;
          flex-direction: column;
            margin-top: 50px;
            width: 800px;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }



@media only screen and (max-width: 1650px) {
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 230%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 300px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 100px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
}
.annualReportPrograms-image-container > img {
width: 30vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
}
.annualReportPrograms-titleLine {
  width: 50%;
  border-bottom: solid 2px white;
  margin-top: 20px;
  margin-bottom: 20px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 75px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  /* margin-top: 30px; */
  padding-top: 0px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      /* background-color: #d1e1e2; */
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        /* margin: 20px 60px; */
        margin-top: 50px;
      }
      .annualReportPrograms-cards-container {
          /* position: relative; */
          display: flex;
          flex-direction: column;
          /* align-items: center;
          justify-content: center; */
            margin-top: 50px;
            width: 800px;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }  
}



@media only screen and (max-width: 800px) {
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 270px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 30px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  /* margin-right: 70px; */
}
.annualReportPrograms-image-container > img {
width: 40vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  /* margin-left: 70px; */
}
.annualReportPrograms-titleLine {
  width: 60%;
  border-bottom: solid 2px white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
/* text-align: left; */
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 120px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      /* background-color: #d1e1e2; */
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        margin: 20px 60px;
      }
      .annualReportPrograms-cards-container {
          /* position: relative; */
          display: flex;
          flex-direction: column;
          /* align-items: center;
          justify-content: center; */
            margin-top: 50px;
            width: 60vw;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }  
}



@media only screen and (max-width: 600px) {
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 200px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 30px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  /* margin-right: 70px; */
}
.annualReportPrograms-image-container > img {
width: 50vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: auto;
  margin-bottom: 0px;
  /* margin: 30px; */
  /* margin-left: 70px; */
}
.annualReportPrograms-titleLine {
  width: 60%;
  border-bottom: solid 2px white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
/* text-align: left; */
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 30px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      /* background-color: #d1e1e2; */
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        margin: 10px 10px;
      }
      .annualReportPrograms-cards-container {
          /* position: relative; */
          display: flex;
          flex-direction: column;
          /* align-items: center;
          justify-content: center; */
            margin-top: 50px;
            width: 70vw;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }  
}


@media only screen and (max-width: 380px) {
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 100px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 30px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  /* margin-right: 70px; */
}
.annualReportPrograms-image-container > img {
width: 40vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: auto;
  margin-bottom: 0px;
  /* margin: 30px; */
  /* margin-left: 70px; */
}
.annualReportPrograms-titleLine {
  width: 60%;
  border-bottom: solid 2px white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
/* text-align: left; */
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      /* background-color: #d1e1e2; */
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        margin: 10px 10px;
      }
      .annualReportPrograms-cards-container {
          /* position: relative; */
          display: flex;
          flex-direction: column;
          /* align-items: center;
          justify-content: center; */
            margin-top: 50px;
            width: 60vw;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }  
}



@media only screen and (max-width: 340px) {
#annualReportPrograms-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d1e1e2; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
  background-position: center center;
  width: 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; */
  background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportPrograms-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  top: 0;
  left: 0;
  margin-top: 50px;
  margin-bottom: 0px;
}
.annualReportPrograms-mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60vw;
  height: 100%;
  max-height: 200px;
  margin-bottom: 50px;
}
.annualReportPrograms-mainTitle > h2 {
  color: #11474f;
  font-family: 'Helvetica Neue Medium';
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}
.annualReportPrograms-imageDescription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 90vw;
  height: 100%;
  max-height: 50000px;
  margin-bottom: 30px;
}
.annualReportPrograms-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40vw;
  height: 100%;
  max-height: auto;
  margin: 30px;
  /* margin-right: 70px; */
}
.annualReportPrograms-image-container > img {
width: 40vw;
height: auto;
}
.annualReportPrograms-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: auto;
  margin-bottom: 0px;
  /* margin: 30px; */
  /* margin-left: 70px; */
}
.annualReportPrograms-titleLine {
  width: 60%;
  border-bottom: solid 2px white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.annualReportPrograms-description-container > h2 {
  color: #d14e1d;
  font-family: 'Helvetica Neue Medium';
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0px;
}
.annualReportPrograms-description-container > p {
  font-family: 'Helvetica Neue Medium';
font-size: 16px;
color: #11474f;
font-weight: lighter;
/* text-align: left; */
margin-top: 20px;
margin-bottom: 10px;
line-height: 30px;
}
.annualReportPrograms-subpages-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  }


  .annualReportPrograms-subpages-container > ul{
      padding-right: 30px;
      background-color: transparent;
    }
    #annualReportPrograms-cardsList2{
      /* background-color: #d1e1e2; */
      text-align: center;
      margin-right: 10px;
    }
    #annualReportPrograms-cardsList2 > h2 {
      color: #11474f;
      font-family: 'Helvetica Neue Medium';
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 10px;
    }
      #annualReportPrograms-cardsList2 li{
        display: inline-block;
        margin: 0px 0px;
      }
      .annualReportPrograms-cards-container {
          /* position: relative; */
          display: flex;
          flex-direction: column;
          /* align-items: center;
          justify-content: center; */
            margin-top: 50px;
            width: 260px;
            height: auto;
            margin: 0 auto;
            background-color: transparent;
        }
        .annualReportPrograms-cards-container > h3 {
          color: #d14e1d;
            font-family: 'Helvetica Neue Medium';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .annualReportPrograms-cards-container > p {
          font-family: 'Helvetica Neue Medium';
          font-size: 16px;
          color: #11474f;
          font-weight: lighter;
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 30px;
        }  
}
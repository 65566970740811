#annualReport2021research-sharing-container {
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 150vw;
    background-position: top center;
    background-repeat: no-repeat;
    font-family: 'Helvetica Neue Medium';
}

/* Intro Section */

.annualReport2021research-sharing-intro-container {
    display: flex;
    margin: 10% 12vw 20vw;
    align-items: flex-start;
}

.annualReport2021research-sharing-text-container {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-top: 40px;
}

.annualReport2021research-sharing-text-container > h1 {
    color: #d14e1d;
    font-weight: bold;
    font-size: calc(20px + 2vw);
}

.annualReport2021research-sharing-text-container > p {
    color: #00464f;
    font-size: calc(12px + .5vw);
}

.annualReport2021research-sharing-text-container > img {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}

.annualReport2021research-sharing-img-container {
    width: 80%;
    height: 100%;
    padding-left: 20vw;
}

.annualReport2021research-sharing-img-container > img {
    width: 100%;
    height: 100%;
    /* padding-left: 100px; */
}

/* Content Section */

.annualReport2021research-sharing-content-container {
    width: 100%;
    height: fit-content;
    text-align: justify;
}

.annualReport2021research-sharing-banner-container {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vw;
}

#annualReport2021research-sharing-banner-presenting {
    background-color:#007a8a;
}

.annualReport2021research-sharing-presentation-container {
    margin-bottom: 3vw;
}

#annualReport2021research-sharing-banner-communities {
    background-color: #d14e1d;
}

.annualReport2021research-sharing-stakeholders-container {
    margin-bottom: 3vw;
}

#annualReport2021research-sharing-banner-published {
    background-color: #FF9A5E;   
}

.annualReport2021research-sharing-banner-container > img {
    height: 150%;
    padding-right: 4vw;
}

.annualReport2021research-sharing-banner-container > h2 {
    padding: 1vw 0 0 12vw;
    font-size: 30px; 
}

.annualReport2021research-sharing-content-container h3 {
    color: #d14e1d;
    text-align: left;
    font-weight: bold;
    font-size: calc(12px + 1vw);
    padding: 20px 12vw 0;
}

.annualReport2021research-sharing-content-container p {
    color: #00464f;
    padding: 0 12vw;
    font-size: calc(12px + .5vw);
}

.annualReport2021research-sharing-content-container a {
    color: #007a8a;
}

.annualReport2021research-sharing-published-papers-container {
    display: flex;
    flex-direction: column;
    padding: 0 25%;
}

.annualReport2021research-sharing-published-paper-individ {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 50px;
}

.annualReport2021research-sharing-published-paper-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 75%;
    color: #00464f;
}

.annualReport2021research-sharing-published-paper-text-container h2 {
    padding: 0 50px;
    font-size: 22px;
    letter-spacing: -1px;
    text-align: center;    
}

.annualReport2021research-sharing-published-paper-text-container p {
    padding: 0 20px;
    font-size: 16px;
    font-weight: lighter;
}

.annualReport2021research-sharing-published-paper-citation {
    font-size: 12px !important;
}

.annualReport2021research-sharing-published-paper-img-container {
    width: 30%;
}

.annualReport2021research-sharing-published-paper-img-container img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1500px) {
    .annualReport2021research-sharing-intro-container {
        flex-direction:column-reverse;
        margin: 10% 12vw -30vw;
        align-items: center;
    }
    
    .annualReport2021research-sharing-text-container {
        position: relative;
        width: 80%;
        padding-top: 0;
        padding-bottom: 50px;
        top: -30vw;
    }
    
    .annualReport2021research-sharing-text-container > h1 {
        padding-bottom: 20vw;
        width: 30%;
        font-size: calc(40px + 3vw);
    }    
    
    .annualReport2021research-sharing-text-container > img {
        padding-top: 20px;
    }
    
    .annualReport2021research-sharing-img-container {
        width: 50%;
    }
    
    .annualReport2021research-sharing-banner-container > h2 {
        padding-left: 12vw;
        font-size: calc(12px + 1vw); 
    }   

    .annualReport2021research-sharing-published-papers-container {
        padding: 0 15%;
    }
    
}

@media only screen and (max-width: 1000px) {

    .annualReport2021research-sharing-text-container > h1 {
        padding-bottom: 13vw;
    }    

    .annualReport2021research-sharing-banner-container {
        height: 7vw;
    }

    .annualReport2021research-sharing-banner-container > img {
        height: 100%;
    }    

    .annualReport2021research-sharing-banner-container > h2 {
        text-align: left;
        padding: 5px 0 5px 12vw;
        font-size: calc(10px + 1.2vw);
        max-width: 60%; 
    }   

    .annualReport2021research-sharing-published-papers-container {
        padding: 0 10%;
    }
}

@media only screen and (max-width: 800px) {

    .annualReport2021research-sharing-published-papers-container {
        padding: 0;
    }

    .annualReport2021research-sharing-published-paper-individ:nth-of-type(odd) {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .annualReport2021research-sharing-published-paper-individ:nth-of-type(even) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

    }

    .annualReport2021research-sharing-banner-container {
        height: 10vw;
    }

}

@media only screen and (max-width: 375px) {

    .annualReport2021research-sharing-banner-container {
        height: calc(20px + 15vw);
    }

    .annualReport2021research-sharing-banner-container > h2 {
        font-size: calc(10px + 1vw); 
    }   

}

.researchRedesignHighlights-container {
    display: flex;
    flex-direction: column;
    color: black;
    font-family: 'Helvetica Neue Medium',Arial, Helvetica, sans-serif;
    background-color: #f2f2f2;
}

.researchRedesignHighlights-cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 50px;
}

.researchRedesignHighlights-header-container h2{
    font-size: xx-large;
}

.researchRedesignHighlights-footer-container {
    margin-bottom: 20px;
}

.researchRedesignHighlights-footer-container a {
    color: #d14e1d;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
    font-size: large;
    text-decoration: none;
}
#preFooter {
    background: $bg-gray;
    color: #FFFFFF;
    &.principles {
        .gpGrid {
            align-content: start;
            .gpSpacer {
                border-top: solid #FFF 1px;
                margin-top: 40px;
            }
            .guidingPrinciplesBlurb {
                justify-content: center;
                text-align: left;
                @include desktop {
                    padding-left: 80px;
                }
                @include large-desktop {
                    padding-left: 80px;
                }
            }
        }       
        h3 {
            font-size: 61px;
            font-weight: 400;
            line-height: 1em;
            text-align: left;
            margin: 0;
        }
        .principlesGrid {
            padding-top: 40px;
            .principlesGridItems {
                border: none;
                background: $orange;
                min-height: 270px;
                padding: 50px 20px 20px 20px;
                position: relative;
                text-align: left;
                &::before {
                    content: '';
                    height: 1px;
                    background: $bg-gray;
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    width: 100%;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    &.cta {
        background: $teal;
        text-align: center;
        h3 {
            font-size: 48px;
            margin: 0;
            @include mobile {
                font-size: 31px;
            }
        }
        .ctaContent {
            margin: auto;
            max-width: 50%;
            @include tablet {
                max-width: 80%;
            }
            @include mobile {
                max-width: 100%;
            }
            p {
                font-size: 20px;
                line-height: 1.5em;
                @include mobile {
                    font-size: 16px;
                }
            }
        }
    }
    .container {
        padding: 80px;
        .quote {
            justify-content: start;
            h3 {
                font-size: 61px;
                margin: 0;
            }
            @include mobile {
                padding: 0;
            }
        }
        .guidingPrinciplesQuote {
            h4 {
                font-size: 31px;
                font-weight: 400;
                text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
                @include mobile {
                    font-size: 20px;
                    margin-top: 0;
                }
            }
        }
        .guidingPrinciplesAttr {
            text-align: right;
            p {
                font-size: 20px;
                font-weight: 600;
                text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
                @include mobile {
                    font-size: 14px;
                }
            }
            span {
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    height: 1px;
                    background: #FFF;
                    width: 60px;
                    left: -80px;
                    top: 10px;
                }
            }
        }
    }
}
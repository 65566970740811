.videoEmbed {
    background: #FFF;
    overflow: hidden;
    padding: 0;
    position: relative;
    @include mobile {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%;
    }
    @include mobile-full {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 30px;
        max-width: 100%;
    }
    .container {
        padding: 0;
        background: #FFF;
        @include small-desktop {
            padding: 0 20px;
            max-width: 100%;
        }
        @include touch {
           padding: 0 20px;
            max-width: 100%;
        }
        @include mobile {
            padding: 0;
            max-width: 100%;
        }

        h3 {
            text-align: left;
            margin-bottom: 20px;
            span {
                background: #FFF;
                padding-right: 40px;
                position: relative;
                z-index: 2;
            }
            &::after {
                width: 85%;
                @include handheld {
                    width: 0;   
                }
                @include mobile {
                    margin-top: 15px;
                }
                @include small-mobile {
                    margin-top: 15px;
                }
            }
        }
        p {
            margin: 0 0 30px 0;
            text-align: left;
        }
        iframe {
            @include mobile {
                height: 315px
            }
            @include small-mobile {
                height: 315px
            }
        }
    }
}
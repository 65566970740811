#october21Page-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
}

@media only screen and (max-width: 1825px) {
  #october21Page-content-container {
    top: calc(10% + 36vw);
}
}

@media only screen and (max-width: 700px) {
    #october21Page-content-container {
    top: calc(5% + 36vw);
    }
  }

#smartAbstracts-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    background-color: #e5e5e5;
}
  
.smartAbstracts-content-title {
    font-family: 'HelveticaNeue-CondensedBold', Arial, Helvetica, sans-serif;
    display: block;
    width: 100%;
    line-height: 1;
    background-color: #e0e0e0;
}

.smartAbstracts-content-title > h2{
    font-size: calc(12px + 4.5vmin);
    margin: 15px;
    padding-top: 2vw;
    color: #000;
}

/* LINK CARD */

#smartAbstracts-interestArea-container {
    position:relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    margin-bottom: 50px;
}
.smartAbstracts-interestArea-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 50000px;
}
.smartAbstracts-interestArea-content-container > ul {
    width: 100%;
    list-style: none;
    padding: 0;
}
#smartAbstracts-interestArea-cardList2 {
    background-color: #f2f2f2;
    text-align: center;
    display: inline-block;
    list-style-type: none;
    padding: 0;
}
.smartAbstracts-interestArea-link {
    text-decoration: none;
}
.smartAbstracts-interestArea-link > li {
    list-style-type: none;
    padding: 0;
    text-decoration: none;
}

.smartAbstractsList-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.smartAbstractsList-card-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1024px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.smartAbstractsList-card-content-container{
    flex-direction: row;
}

.smartAbstractsList-card-text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin-left: 30px;
}
.smartAbstractsList-card-mainText-art {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-bottom: 50px;
    border-bottom: lightgray solid 3px;
}

li:last-of-type .smartAbstractsList-card-mainText-art {
    border: none;
    padding-bottom: 0;
}

.smartAbstractsList-card-mainText-art h2 {
    color: black;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-underline-position: under;
}

.smartAbstractsList-card-mainText-art h3 {
    color: rgb(84, 84, 84);
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
    line-height: 25px;
    margin-top: 7px;
}

.smartAbstractsList-card-mainText-art p {
    color: rgb(84, 84, 84);
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 7px;
}

/* CITATION */

.smartAbstractsList-card-author-names {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:flex-start;
    flex-wrap: wrap;
}

.smartAbstractsList-card-author-names p {
    font-style: italic;
    padding: 0 1em 0 5px;
}

.smartAbstracts-button {
    background-color: #d14e1d;
    color: #f9f9f9;
    text-decoration: none;
    font-family: 'Helvetica Neue Medium',Arial,Helvetica,sans-serif;
    box-shadow: 1px 1px rgb(177 177 177);
    padding: 0.75em;
    margin: 0.5em;
    border-radius: 7%/30%;
    width: fit-content;
    align-self: center;
    
}

@media only screen and (max-width: 1100px) {

    .smartAbstracts-interestArea-content-container {
        max-width: 800px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

    
@media only screen and (max-width: 850px) {
    .smartAbstracts-interestArea-content-container {
        max-width: 650px;
    }
    
    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        width: 650px;
    }
    .smartAbstractsList-card-text-container {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 700px) {
    
    .smartAbstracts-interestArea-content-container {
        max-width: 500px;
    }
        
    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .smartAbstractsList-card-text-container {
        align-items: center;
        max-width: 500px;
        height: 100%;
    }
    .smartAbstractsList-card-mainText-art {
        text-align: center;
    }
    .smartAbstractsList-card-mainText-art > h2 {
        line-height: 28px;
    }

    .smartAbstractsList-card-abstract-text {
        padding: 0 20px;
        text-align: left;
    }

    .smartAbstractsList-card-author-names {
        align-self: center;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .smartAbstractsList-card-author-names p{
        padding: 0 10px;
    }

}


@media only screen and (max-width: 550px) {
    .smartAbstracts-interestArea-content-container {
        max-width: 400px;
    }
    

    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        max-width: 400px;
    }
    .smartAbstractsList-card-text-container {
        max-width: 400px;
    }
    .smartAbstractsList-card-mainText-art {
        align-items: center;
        justify-content: center;
    }

    .smartAbstractsList-card-mainText-art > h2 {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
    }
}


@media only screen and (max-width: 430px) {
    .smartAbstracts-interestArea-content-container {
        max-width: 340px;
    }
    
    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        max-width: 340px;
    }
    .smartAbstractsList-card-text-container {
        max-width: 340px;
    }
    .smartAbstractsList-card-mainText-art > h2 {
        line-height: 23px;
    }
}


@media only screen and (max-width: 380px) {
    .smartAbstractsList-card-content-container {
        max-width: 300px;
    }

    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        max-width: 300px;
    }
    .smartAbstractsList-card-text-container {
        max-width: 300px;
    }
    .smartAbstractsList-card-mainText-art > p {
        font-size: 16px;
    }

}


@media only screen and (max-width: 350px) {
    .smartAbstracts-interestArea-content-container {
        max-width: 270px;
    }
    
    /* LINK CARD */
    .smartAbstractsList-card-content-container {
        max-width: 270px;
    }
    .smartAbstractsList-card-text-container {
        max-width: 270px;
    }
    .smartAbstractsList-card-mainText-art > h2 {
        font-size: 17px;
        line-height: 20px;
    }
}
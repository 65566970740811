#shortRun-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#shortRun-container > html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}
.conceptualDevelopmentLabMembers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #F2F2F2;
  }
  .conceptualDevelopmentLabMembers-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 0 50px 0;
    padding: 0;
  }
  .conceptualDevelopmentLabMembers-content-container > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 30px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0 0 10px 0;
  }
  .conceptualDevelopmentLabMembers-cardsList {
    padding: 0;
    margin: 0;
  }
  .conceptualDevelopmentLabMembers-cardsList li {
    display: inline-block;
    margin: 0;
    padding: 30px 50px;
  }
  
  .conceptualDevelopmentLabMembers-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      height: 100%;
    }
    .conceptualDevelopmentLabMembers-card-image {
    display: flex;
    width: 230px;
    height: 300px;
    }
    .conceptualDevelopmentLabMembers-card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      background-color: #077A8A;
      padding: 10px 0 10px 0;
    }

    .conceptualDevelopmentLabMembers-card-content > h2 {
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 16px;
      color: #F2F2F2;
      margin: 0;
    }

    .conceptualDevelopmentLabMembers-card-content > p {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      font-size: 16px;
      color: #F2F2F2;
      margin: 0;
    }

    .conceptualDevelopmentLabMembers-titleContent-container { 
      width: 100%; 
      max-width: 1050px;
      text-align: center; 
      border-bottom: 1px solid #111111;
      line-height: 0px; 
      margin-bottom: 50px;
    } 
    
    .conceptualDevelopmentLabMembers-titleContent-container span { 
    background:#F2F2F2; 
    padding: 0 20px 0 20px;
    color: #111111;
    font-family: 'Helvetica Neue Medium';
    font-size: 30px;
    }


    @media only screen and (max-width: 800px) {
      .conceptualDevelopmentLabMembers-titleContent-container {  
          border-bottom: none;
      } 
  }

    @media only screen and (max-width: 600px) {

    .conceptualDevelopmentLabMembers-content-container > h2 {
      font-size: 22px;
      margin: 0;
    }

    .conceptualDevelopmentLabMembers-titleContent-container {  
      border-bottom: none;
      width: 100%;
    } 
      
    .conceptualDevelopmentLabMembers-titleContent-container span { 
      font-size: 25px;
      padding: 0;
    }

    }


    @media only screen and (max-width: 300px) {
      .conceptualDevelopmentLabMembers-titleContent-container span { 
          font-size: 18px;
        }
  }


/* .section {
} */

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  /* padding: 40px; */
  height: 100vh;
}
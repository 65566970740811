#educatorResources-testimonials-container{
    display: flex;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
    background-color: #FFFFFF;
  }

  .educatorResources-testimonials-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

 .educatorResources-testimonials-title { 
    width: 100%; 
    max-width: 1050px;
    text-align: center; 
    border-bottom: 2px solid #E2E2E4; 
    line-height: 0px; 
 } 

.educatorResources-testimonials-title span { 
  background:#FFFFFF; 
  padding: 0 20px 0 20px; 
  margin-top: 20px;
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 25px;
}

.educatorResources-testimonials-slideshow-container {
    display: inline block;
    height: fit-content;
}
html, body {
    height: 100%;
  }
  
  #annualReport2021EdTech-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100%;
      margin: 0px;
      padding: 0px;
      overflow-x: hidden;
      background-color: #C8D8DA;
    }
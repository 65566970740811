.rippling-iframe-container {
    background-color: #d14e1d;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rippling-iframe {
    background-color: #fff;
    max-width: 1280px;
    display: flex;
    margin: 100px 0;

}

.rippling-iframe > iframe {
    width: 80vw !important;
}

#hiringthing-jobs {
    display: flex;
    flex-direction: column;
    padding: 2em;
    border: 3px solid #00464f;
    background-color: #e3eaea;
}

#hiringthing-jobs :last-child {
    padding-bottom: 0;
}

.ht-title-link {
    font-size: 30px;
    color: #d14e1d;
    line-height: 1.2;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-decoration: none;
    font-weight: bold;
}

.ht-location {
    padding: 10px 0;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #000;
}

.ht-summary {
    padding: 10px 0;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #000;
}

.ht-apply-link {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #d14e1d;
    text-underline-position: under;
    padding-bottom: 50px;
}

.ht-no-positions{
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #000;
}

@media only screen and (max-width: 650px) {
    .ht-title-link {
        font-size: 25px;
    }
    
}
#error404Page-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80%;
    color: #000;
}

.error404Page-text-container {
    background-color: #ffffff;
    width: 100%;
    height: 200px;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error404Page-text-container p{
    padding: 0 100px;
}

.error404Page-text-container a {
    color: #007A8A;
}
#workshopsLandingPage-intro-container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d14e1d;
}
.workshopsLandingPage-intro-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 1050px;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.workshopsLandingPage-intro-bold {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 100%;
  max-height: 250px;
  margin-left: 30px;
  margin-right: 30px;
}

.workshopsLandingPage-intro-bold > h3 {
  font-family: 'Helvetica Neue Light';
  color: white;
  font-size: 40px;
  text-align: left;
}
.workshopsLandingPage-intro-normal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 614px;
    height: 100%;
    max-height: 250px;
    margin-left: 30px;
    margin-right: 30px;
}
.workshopsLandingPage-intro-normal > p {
  font-family: 'Helvetica Neue Light';
  color: white;
  text-align: left;
  font-weight: bolder;
  font-size: 20px;
  line-height: 40px;
}

@media only screen and (max-width: 1100px) {
  .workshopsLandingPage-intro-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 250px;
    top: 0;
    left: 0;
  }
  .workshopsLandingPage-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    height: 100%;
    max-height: 250px;
    margin: 25px;
  }
  
  .workshopsLandingPage-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 30px;
    font-weight: lighter;
    text-align: left;
  }
  .workshopsLandingPage-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 250px;
      margin: 25px;
  }
  .workshopsLandingPage-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: lighter;
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 850px) {
  .workshopsLandingPage-intro-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    top: 0;
    left: 0;
  }
  .workshopsLandingPage-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 250px;
    margin: 25px;
  }
  
  .workshopsLandingPage-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 25px;
    font-weight: lighter;
    text-align: left;
  }
  .workshopsLandingPage-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 350px;
      height: 100%;
      max-height: 500px;
      margin: 25px;
  }
  .workshopsLandingPage-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: lighter;
    font-size: 17px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .workshopsLandingPage-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 1000px;
    top: 0;
    left: 0;
  }
  .workshopsLandingPage-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: 1000px;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 0px;
  }
  
  .workshopsLandingPage-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 25px;
    font-weight: lighter;
    text-align: left;
  }
  .workshopsLandingPage-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 380px;
      height: 100%;
      max-height: 1000px;
      /* margin: 20px; */
      margin-top: 0px;
      margin-bottom: 30px;
  }
  .workshopsLandingPage-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: lighter;
    font-size: 17px;
    line-height: 25px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .workshopsLandingPage-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 1000px;
    top: 0;
    left: 0;
  }
  .workshopsLandingPage-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 1000px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  
  .workshopsLandingPage-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 25px;
    font-weight: lighter;
    text-align: left;
  }
  .workshopsLandingPage-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 1000px;
      margin-top: 0px;
      margin-bottom: 30px;
  }
  .workshopsLandingPage-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: lighter;
    font-size: 14px;
    line-height: 25px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 361px) {
  .workshopsLandingPage-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 1000px;
    top: 0;
    left: 0;
  }
  .workshopsLandingPage-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    height: 100%;
    max-height: 1000px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  
  .workshopsLandingPage-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 25px;
    font-weight: lighter;
    text-align: left;
  }
  .workshopsLandingPage-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 250px;
      height: 100%;
      max-height: 1000px;
      margin-top: 0px;
      margin-bottom: 30px;
  }
  .workshopsLandingPage-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: lighter;
    font-size: 14px;
    line-height: 25px;
    margin-top: 0px;
  }
}
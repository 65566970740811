#artistTeam-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.artistTeam-content-container {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: auto;
  position: relative;
  display: inline-flex;
  line-height: 0px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.artistTeam-title {
  text-align: left;
  line-height: 30px;
  margin-bottom: 60px;
}
.artistTeam-title > h4 {
  color: #bcc6c7;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
}
.artistTeam-title > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #d14e1d;
  font-size: 30px;
  letter-spacing: 2px;
}
.artisticDesignTeam-container > h4 {
  font-family: 'Poppins', sans-serif;
  color: #00464f;
  font-size: 17px;
  text-align: left;
}
.contributingArtists-container > h4 {
  font-family: 'Poppins', sans-serif;
  color: #00464f;
  font-size: 17px;
  text-align: left;
}
#artistCardsList2 {
  text-align: left;
  line-height: 0px;
}
#artistCardsList2 li {
  font-family: "Helvetica Neue Light", sans-serif;
  color:#3c4142;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
}

@media only screen and (max-width: 1280px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 650px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 30px;
    margin-bottom: 60px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 25px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 15px;
    text-align: left;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 15px;
    text-align: left;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 15px;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 800px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 650px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 20px;
    margin-bottom: 40px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 13px;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 13px;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 13px;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
    margin-bottom: 80px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 15px;
    margin-bottom: 50px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 12px;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 12px;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 12px;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 450px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 330px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
    margin-bottom: 50px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 12px;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 12px;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 12px;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 374px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
    margin-top: 10px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 12px;
    margin-bottom: 30px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 11px;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 11px;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 11px;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 330px) {
  .artistTeam-content-container {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: auto;
    position: relative;
    display: inline-flex;
    background-color: white;
    line-height: 0px;
  }
  .artistTeam-title {
    text-align: left;
    line-height: 12px;
    margin-bottom: 30px;
  }
  .artistTeam-title > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    letter-spacing: 3px;
  }
  .artistTeam-title > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .artisticDesignTeam-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 11px;
  }
  .contributingArtists-container > h4 {
    font-family: 'Poppins', sans-serif;
    color: #00464f;
    font-size: 11px;
  }
  #artistCardsList2 li {
    font-family: "Helvetica Neue Light", sans-serif;
    color:#3c4142;
    display: inline-block;
    font-size: 11px;
    margin: 10px 10px;
  }
}
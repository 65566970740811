#resourceLandingPage-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .resourceLandingPage-description-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .resourceLandingPage-description-content-container > p {
    font-family: 'Helvetica Neue Light';
  font-size: 17px;
  color: rgb(62, 62, 62);
  font-weight: bolder;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }

  @media only screen and (max-width: 1200px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 1000px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 800px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 600px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 450px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 350px) {
    #resourceLandingPage-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .resourceLandingPage-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .resourceLandingPage-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }
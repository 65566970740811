.pullQuote {
    background-color: #FFF;
    text-align: center;
    padding: 60px 0;
    .container {
        background: $teal;
        color: #FFF;
        padding: 80px 40px 40px;
        position: relative;
        &::after {
            content: '';
            height: 1px;
            background: $dark-gray;
            position: absolute;
            right: 0;
            bottom: -10px;
            width: 50%;
        }
        blockquote {
            margin: 0 0 40px 0;
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
            div {
                display: inline;
            }
        }
        @include large-desktop {
            margin: 40px auto;
        }
        blockquote {
            text-align: left;
        }
        footer {
            text-align: left;
            display: flex;
            margin: 0;
            .quoteImage {
                display: inline-block; 
                position: relative;
                overflow: hidden;
                border-radius: 50%;
                width: 65px;
                height: 65px;
                margin-top: -10px;
                margin-right: 20px;
                img {
                    
                    object-fit: cover;
                }
            }
            p {
                font-size: 12px;
                margin: 0;
                strong {
                    font-size: 16px;
                }
            }
            .quoteSocial {
                margin-left: auto;
                a {
                    display: inline-block;
                    svg {
                        height: 100%;
                        padding: 0 10px;
                        path {
                            fill: #FFF;
                            background: #fff;
                        }
                    }
                    .react-sharing-button__text {
                        display: none;
                    }
                }
            }
        }
        .content {
            margin: 0 auto;
            max-width: 100%;
            @include tablet {
                max-width: 80%;
            }
            @include small-desktop {
                max-width: 70%;
            }
            @include desktop {
                max-width: 60%;
            }
            @include large-desktop {
                max-width: 60%;
            }
        }
        .imageCaption {
            color: $gray-text;
            margin: 0;
            padding: 5px 0;
        }
        .textBlock {
            text-align: left;
        }
        .text-half-step_bold {
            color: $bg-color;
        }
    }
    &.fullWidth {
        .imageContainer {
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }
    &.medium {
        .imageContainer {
            max-width: 1200px;
            margin: auto;
            padding: 0 30px;
            @include large-desktop { 
                padding: 0;
            }
            img {
                width: 100%;
            }
        }
    }
}
#annualReport2021sharing-video-container {
    color:#00464f;
    ;
}

.annualReport2021sharing-video-content-container{
    width: 100%;
    height: fit-content;
    text-align: justify;
}

.annualReport2021sharing-video-banner-container{
    position: relative;
    text-align: left;
    color: #ffffff;
    height: fit-content;
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.annualReport2021sharing-video-banner-container > img {
    position: relative;
    right: 65%;
    width: 170%;
}

.annualReport2021sharing-video-banner-container > h2 {
    position: absolute;
    padding: 0 0 0 12vw;
    font-size: calc(12px + 2vw); 
}

.annualReport2021sharing-video-highlight{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.annualReport2021sharing-video-highlight > h3 {
    font-size: calc(10px + 2vw); 
    text-align: center;
    max-width: 80%;
}

.annualReport2021sharing-video-highlight img {
    max-width: 60vw;
}

.annualReport2021sharing-video-highlight > h4 {
    padding: 0 15vw 5vw;
    text-align: center;
}

/* Video Display */

.annualReport2021sharing-video-component-container {
    width: 60%;
    position: relative;
}

.annualReport2021sharing-video-card-container {
    padding-bottom: 56.25%;
    position: relative;
}

.annualReport2021sharing-react-player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.educatorResourcesCraftHighlightCards .educatorResourcesCraftStories {
    background: #FFFFFF;
    color: #FFF;
    padding: 0;
    margin-top: 25px; }
    @media (min-width: 1440px) {
      .educatorResourcesCraftHighlightCards .educatorResourcesCraftStories {
        padding: 0; } }
    @media (max-width: 768px) {
      .educatorResourcesCraftHighlightCards .educatorResourcesCraftStories {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%; } }
    .educatorResourcesCraftHighlightCards .educatorResourcesCraftStories h3 {
      margin-bottom: 40px; }
    .educatorResourcesCraftHighlightCards .educatorResourcesCraftStories .button {
      margin: 60px auto 0 auto; }

      #educatorResources-craftStories-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
      }
    
      .educatorResources-craftStories-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1050px;
        height: 100%;
        max-height: auto;
        top: 0;
        left: 0;
        margin: 40px 0 40px 0;
      }

      .educatorResources-craftStories-title { 
        width: 100%; 
        max-width: 1050px;
        text-align: center; 
        border-bottom: 2px solid #E2E3E4; 
        line-height: 0px; 
     } 
    
    .educatorResources-craftStories-title span { 
      background:#FFFFFF; 
      padding: 0 20px 0 20px; 
      margin-top: 20px;
      color: #111111;
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 25px;
    }

      .educatorResources-craftStories-bottomLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 30px 0 30px 0;
    }

    .educatorResources-craftStories-content-bottomLink {
      font-family: "Helvetica Neue Light";
      font-size: 18px;
      color: #CA4A1C;
      text-decoration: none;
  }
  
  .educatorResources-craftStories-content-bottomLink:hover {
      color: #b74217;
      transition: color 0.5s;
  }



      @media only screen and (max-width: 1150px) {

        .educatorResources-craftStories-content-container {
          max-width: 800px;
        }
      
       .educatorResources-craftStories-title { 
          max-width: 800px;
       }
      
      }
      
      
      
      @media only screen and (max-width: 900px) {
      
        .educatorResources-craftStories-content-container {
          max-width: 700px;
        }
      
       .educatorResources-craftStories-title { 
          max-width: 700px;
       } 
      
      }
      
      
      @media only screen and (max-width: 750px) {
        #educatorResources-craftStories-container{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
        }
      
        .educatorResources-craftStories-content-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          max-width: 1050px;
          height: 100%;
          max-height: auto;
          top: 0;
          left: 0;
          margin: 40px 0 40px 0;
        }
    
      
      .educatorResources-craftStories-title { 
        width: 100%; 
        max-width: 75vw;
        text-align: center; 
        border-bottom: 1px solid #ffffff; 
        line-height: 0px; 
        display: flex;
        justify-content: center;
        align-items: center;
      } 
      
      .educatorResources-craftStories-title span { 
      background:#ffffff; 
      color: #111111;
      font-family: 'Helvetica Neue Medium';
      font-size: 19px;
      white-space: nowrap;
      display: inline-block;
      position: absolute;
      }
  
      }
#kcpt-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}
.kcpt-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}
.kcpt-paragraph {
  display: flex;
  justify-content: center;
  align-items: normal;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 10000px;
}
.kcpt-paragraph > p {
  font-family: 'Helvetica Neue Light';
font-size: 17px;
color: rgb(62, 62, 62);
font-weight: bolder;
text-align: left;
margin-top: 10px;
margin-bottom: 10px;
line-height: 32px;
}
.kcpt-survey-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #edf0ed;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 10000px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.kcpt-survey-paragraph > p {
  font-family: 'Helvetica Neue Light';
font-size: 17px;
color: rgb(62, 62, 62);
font-weight: bolder;
text-align: left;
margin-top: 10px;
margin-bottom: 10px;
margin-left: 30px;
margin-right: 30px;
line-height: 32px;
}
.kcpt-paragraph a {
  color: #00464f;
}
.kcpt-survey-title {
  display: flex;
  width: fit-content;
  height: fit-content;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #00464F;
}
.kcpt-survey-title > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #00464F;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1200px) {
  .kcpt-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 800px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 800px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 1000px) {
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 650px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 650px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 800px) {
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 10000px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 600px) {
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 10000px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 10000px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 450px) {
  .kcpt-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 100vw;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 350px) {
  .kcpt-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 20000px;
      top: 0;
      left: 0;
    }
    .kcpt-paragraph {
      display: flex;
      justify-content: center;
      align-items: normal;
      flex-direction: column;
      width: 100%;
      max-width: 270px;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 32px;
    }
    .kcpt-survey-paragraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #edf0ed;
      width: 100%;
      max-width: 100vw;
      height: 100%;
      max-height: 10000px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .kcpt-survey-paragraph > p {
      font-family: 'Helvetica Neue Light';
    font-size: 17px;
    color: rgb(62, 62, 62);
    font-weight: bolder;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 32px;
    }
   .kcpt-survey-title {
      display: flex;
      width: fit-content;
      height: fit-content;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #00464F;
    }
    .kcpt-survey-title > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}
.featuredStory {
    background-color: $bg-gray;
    color: #FFF;
    text-align: center;
    padding: 40px 32px;
    .container {
        position: relative;
        background-color: $bg-gray;
        padding: 0;
        .featuredImage {
            @include tablet-full {
                margin: 20px 0;
            }
        }
        @include large-desktop {
            margin: 40px auto;
        }
        .emphHeader {
            margin-bottom: -45px;
            span {
                background-color: $bg-gray;
                @include xs-mobile{
                    padding-right: 20px;
                }
            }
            &::after {
                background: #FFF;
                @include tablet {
                    width: 90%;
                }
                @include mobile-full{
                    right: -32px;
                }
                @include xs-mobile{
                    content: none;
                }
            }
        }
        .featuredStoryContent {
            margin: 0 auto;
            max-width: 100%;
            //padding: 40px 0;
            text-align: left;
            .featuredStoryTitle {
                text-decoration: none;
                color: white;
            }
            .featuredStoryTitle:hover {
                text-decoration: underline;
            }
            @include small-desktop {
                max-width: 70%;
            }
            @include desktop {
                max-width: 100%;
            }
            @include large-desktop {
                //padding: 40px 100px;
                max-width: 100%;
            }
            @include mobile-full{
                padding-top: 0;
            }
            .storyDate {
                color: #8b8b8b;
                @include mobile-full{
                    font-size: 12px;
                    line-height: 19px;
                }
            }
            h2{
                @include mobile-full{
                    margin-top: 0;
                    font-size: 25px;
                    line-height: 31px;
                }
            }
            p{
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }
        h3{
            margin-top: 0;
            margin-bottom: 60px;
            @include mobile-full{
                font-size: 31px;
                line-height: 39px;
                margin-bottom: 40px;
                margin-left: 0;
            }
        }
    }
    img {
        display: block;
        position: relative;
        padding-bottom: 0px;
    }
    @include mobile-full{
        padding: 60px 32px;
    }
}
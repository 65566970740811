@import 'craft-graphql/styles/main.scss';

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url("/fonts/HelveticaNeueLTStd-Md.woff") format("woff"), url("/fonts/HelveticaNeueLTStd-Md.ttf") format("truetype")
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url("/fonts/HelveticaNeueLTStd-Lt.woff") format("woff"), url("/fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype")
}

::selection {
  background: rgb(0, 191, 243);
  color: white;
}
html {
  /* background: linear-gradient(to bottom right, rgb(10, 10, 10) 0%, rgb(5, 5, 5) 200%); */
  background: black;
  background: url('./images/home/background.gif');
  background-repeat: repeat;
  background-size: 150px;
  background-blend-mode: darken;
  /* color: white; */
  font-family: 'Helvetica', sans-serif;
  font-weight: 300;
}
.App {
  text-align: center;
  width: auto;
}
.content {
  margin-top: 80px;
}

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

 /* GENERAL FORMATTING 1*/
.pageContainer {
  overflow: hidden;
}
.researchPage {
  margin-left: 10px;
  margin-right: 10px;
}
.titleContainer {
  padding-left: 8vw;
  padding-bottom: 30px;
  text-align: left;
}
.topTitleContainerAbout {
  border-bottom-right-radius: 0px;
}
.topContentHolderNoTitleContainer {
  padding-bottom: 73px;
  text-align: left;
  margin: 0 auto;
  width: 90%;
  margin-top: -90px;
  font-size: 18px;
}
.topTitle {
  color: rgb(242, 101, 31);
  font-size: 80px;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  text-align: left;
  margin-top: 10px;
  line-height: 70px;
}
.topContent {
  margin-top: -30px;
  line-height: 20px;
}
.topContent2 { /* for a second <p>*/
  margin-top: 20px;
}
.topAboutContent {
  text-align: justify;
}

@media screen and (max-width: 959px)  {
  .topContentHolderNoTitleContainer {
    text-align: center;
  }
  .topContent {
    width: 80%;
  }
  .topAboutContent {
    margin-bottom: 50px;
    width: 100%;
  }
}
@media screen and (max-width: 700px)  {
  .titleContainer {
    border-bottom-right-radius: 0px;
    border-right: none;
  }
  .topAboutContent {
    margin-bottom: 100px;
    width: 100%;
  }
}
@media screen and (max-width: 634px)  {
  .topAboutContent {
    margin-bottom: 175px;
    width: 100%;
  }
  .broad2 {
    margin-left: 15px;
  }
}
@media screen and (max-width: 500px)  {
  .topTitle {
    text-align: center;
    font-size: 60px;
    margin-left: -45px;
    margin-top: 18px;
  }
  .topContent {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .topAboutContent {
    margin-bottom: 150px;
    width: 100%;
  }
}
.inlineBlock {
  display: inline-block;
}
.borderBottomUnderline {
  border-bottom: 1px solid white;
}
/* .button {
  font-size: 20px;
  border: 1px solid rgb(242, 101, 31);
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
  width: auto;
  background-color: transparent;
  color: inherit;
}
.button:hover {
  font-size: 20px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
  width: auto;
  background-color: rgb(242, 101, 31);
  color: inherit;
} */
.moon {
  font-family: 'Raleway', sans-serif;
}
/* GENERAL FORMATTING 1 END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* SPINNER BEGIN */

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  margin: 1px;
  margin-right: 5px;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
/* SPINNER END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* NAV BAR  start*/
.logo {
  margin-top: 5px;
  margin-left: -50px;
}
/* .internship-applications {
  margin-top: 5px;
  margin-left: -50px;
} */
/* @media screen and (max-width: 935px)  {
  .logo {
    margin-top: 115px;
    margin-left: 0px;
  }
  .internship-applications {
    margin-top: 50px;
    margin-left: -50px;
  }
} */

@media screen and (max-width: 700px)  {
  .logo {
    margin-top: 24px;
    margin-left: 0px;
  }
  /* .internship-applications {
    margin-top: 5px;
    margin-left: -50px;
  } */
}

/* NAV BAR  end*/

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* HOME TWO*/
.background {
  width: 100vw;
  height: 100vh;
}
.home-two {
  font-weight: 200;
  margin-top: -50px;
  width: 100%;
  height: 100%;
  text-align: left;
}
.panel {
  margin-left: 25px;
}
.homeSubSlides {
  margin-top: 50px;
}
.homePageImage {
  height: 94%;
  position: absolute;
  z-index: 0;
}
.homeOne {
  right: 0px;
  bottom: 0px;
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/digital-audio-left.png');
}
.homeOne:after { /* FOR FIREFOX*/
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/digital-audio-left.png');
  position: static;
  float: right;
}
.homeTwo {
  left: 0px;
  bottom: 0px;
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/digital-audio-right.png');
}
.homeTwo:after { /* FOR FIREFOX*/
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/digital-audio-right.png');
  position: static;
  float: left;
}
.homeThreeA {
  left: 200px;
  bottom: 0px;
  height: 700px;
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
}
.homeThreeA:after { /* fOR FIREFOX */
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
  position: static;
  float: left;
}
.homeThreeB {
  right: 0px;
  top: -10px;
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-painting-left.jpg');
}
.homeThreeB:after { /* fOR FIREFOX */
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-painting-left.jpg');
  position: static;
  float: right;
  top: -10px;
}
.homeFour {
  left: 0px;
  top: -10px;
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-painting-right.jpg');
}
.homeFour:after { /* fOR FIREFOX */
  content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-painting-right.jpg');
  position: static;
  float: left;
  top: -10px;
}
.homeSlideTwo {
  overflow: visible;
}
.homeSlideThree {
  overflow: visible;
}
.homeSlideFour {
  overflow: visible;
}
#students {
  width: 100vw;
  margin-top: -126px;
  padding-top: 126px;
}
#educators {
  width: 100vw;
  margin-top: -126px;
  padding-top: 126px;
}
#researchers {
  width: 100vw;
  margin-top: -126px;
  padding-top: 126px;
}
.topLeft {
  height: 50%;
  width: 35%;
  position: absolute;
}
.topRight {
  height: 50%;
  width: 49%;
  position: absolute;
  left: 36%;
}
.bottomLeft {
  height: 50%;
  width: 35%;
  position: absolute;
  top: 50%;
}
.bottomRight {
  height: 50%;
  width: 49%;
  position: absolute;
  left: 36%;
  top: 50%;
}
.headerHolderLeft {
  width: 300px;
  float: right;
  padding-top: 7vh;
}
.headerHolderLeft:hover > a div div {
  color: rgb(84, 38, 15);
}
.headerHolderRight {
  z-index: 0;
  margin-top: 20px;
  width: 200px;
}
.headerHolderRight:hover > a div div {
  color: rgb(0, 80, 102);
}
.headerTextHolderRight {
  width: 100px;
}
.homeHeader {
  font-size: 6vh;
  padding: 0px;
  height: 6vh;
  line-height: 1vh;
  font-family: sans-serif;
  font-family: 'Raleway', sans-serif;
  color: rgb(80, 80, 80);
  width: auto;
}
.leftText {
  text-align: right;
}
.rightText {
  transform: none;
  text-align: left;
}
.foundryTenLogo {
  display: inline-block;
  position: absolute;
  width: 50%;
  max-width: 430px;
  bottom: 22px;
  font-size: 10vh;
  font-family: sans-serif;
  text-decoration: underline;
}
.subHeaderOne {
  position: absolute;
  width: 100%;
  text-align: right;
}
.subHeaderText {
  font-size: 2.6vh;
  line-height: 2.2vh;
  font-weight: bold;
}
.subHeaderOneText {
  margin-top: 5px;
  color: rgb(122, 55, 22);
}
.subHeaderTwoText {
  color: rgb(0, 80, 102);
  margin-top: -15px;
}
.headerLinkHolder {
  height: auto;
  width: 100%;
  color: rgb(80, 80, 80);
  z-index: 11;
}
.rightHeaderLinkHolder {
  width: 40%;
}
.orangeText {
  color: rgb(242, 101, 31);
}
.tealText {
  color: rgb(0, 191, 243);
}
@media screen and (max-width: 1200px) {
  .homeThreeA {
    left: 15px;
    bottom: 0px;
    height: 600px;
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
  }
  .homeThreeA:after { /* fOR FIREFOX */
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
    position: static;
    float: left;
    height: 600px;
  }
}
@media screen and (max-width: 900px) {
  .foundryTenLogo {
    width: 246px;
  }
  .homeThreeA {
    bottom: 0px;
    min-height: none;
    height: 500px;
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
  }
  .homeThreeA:after { /* fOR FIREFOX */
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
    position: static;
    min-height: none;
    height: 500px;
    float: left;
  }
}
@media screen and (max-width: 700px) {
  .topLeft {
    height: 50%;
    width: 40%;
    position: absolute;
  }
  .topRight {
    height: 50%;
    width: 49%;
    position: absolute;
    left: 41%;
  }
  .bottomLeft {
    height: 50%;
    width: 40%;
    position: absolute;
    top: 50%;
  }
  .bottomRight {
    margin-top: 5px;
    left: 41%;
  }
  .headerHolderRight {
    margin-top: 30px;
  }
  .subHeaderOneText {
    margin-top: 15px;
    margin-top: 30px;
  }
  .foundryTenLogo {
    width: 220px;
    bottom: 25px;
  }
  .homeThreeA {
    left: 50px;
    bottom: 0px;
    height: 400px;
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
  }
  .homeThreeA:after { /* fOR FIREFOX */
    content: url('https://s3-us-west-2.amazonaws.com/f10-home/girl-slide-three.jpg');
    position: static;
    height: 400px;
    float: left;
  }
}
@media screen and (max-width: 600px) {
  .homeThreeB {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .foundryTenLogo {
    width: 170px;
  }
  .homeHeader {
    font-size: 5.2vh;
  }
  .headerHolderRight {
    margin-top: 35px;
  }
  .homePageImage {
    display: none;
  }
}
@media screen and (max-width: 380px) {
  .homeHeader {
    font-size: 5vh;
    height: 5.7vh;
  }
  .headerHolderLeft {
    padding-top: 11vh;
  }
  .headerHolderRight {
    margin-top: 30px;
  }
  .subHeaderOneText {
    margin-top: 30px;
  }
  .homeOneContainer {
    margin-top: -5%;
  }
  .bottomRight {
    top: 47%;
    margin-top: 8px;
  }
  .bottomLeft {
    top: 47%;
  }
}
.homeSubTitleHolder {
  margin-left: 70px;
  margin-left: 6vw;
  font-family: 'Raleway', sans-serif;
  z-index: 1;
}
.weWork {
  font-weight: 100;
  margin-bottom: -60px;
  margin-bottom: -8.5vh;
  color: rgb(145, 145, 145);
  font-size: 40px;
  font-size: 4.5vh;
  z-index: 1;
}
.homeSubSlideTitle {
  font-weight: 300;
  margin-left: 35px;
  margin-left: 2.5vw;
  font-size: 60px;
  font-size: 9vh;
  overflow-x: visible;
  margin-bottom: -1.5vh;
  color: rgb(242, 101, 31);
  z-index: 1;
}
.empty-space {
  text-align: center;
}
.homeSubContentHolder {
  text-align: left;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 14vw;
}
.homeSubContentHolderLeft {
  text-align: left;
  position: absolute;
  left: 30px;
}
.homeSubSlideContentTitle {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: 'Raleway', sans-serif;
  line-height: 5.5vh;
  line-height: 35px;
}
.homeSubSlideContentTitleOne {
  margin-bottom: -10px;
}
.homeSubSlideContent {
  color: white;
  font-size: 16px;
  font-weight: 100;
  line-height: 1.5vh;
  line-height: 8px;
}
.homeSubSlideButton {
  font-size: 20px;
  border: 1px solid rgb(242, 101, 31);
  border-radius: 4px;
  padding: 5px 10px 7px 10px;
  width: inherit;
  background-color: transparent;
  color: inherit;
  font-family: 'Raleway', sans-serif;
}
.homeSubSlideButton:hover {
  border: 1px solid rgb(0, 191, 243);
}
/* Home TWO END*/
/* HOME IN-PAGE NAVIGATOR */
.AppBar {
  z-index: 1000;
  flex-grow: 1;
  width: 100vw;
  background: black;
  height: 70px;
  background: rgb(28, 24, 16);
  flex-grow: 1;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0px;
  overflow: hidden;
}
.horizontalNav {
  position: absolute;
  top: 50%;
  cursor: col-resize;
}
.chev {
  height: 75px;
  cursor: pointer;
  transition: 1s;
  left: 0;
  z-index: 100;
}
.chevButtonHolder {
  cursor: pointer;
  padding: 5px 5px;
  margin-left: 15px;
  transition: .7s;
  z-index: 100;
}
.chevButtonHolderRight {
  position: absolute;
  right: 15px;
  margin-left: 0px;
  transition: .7s;
}
.leftBounce {
  -webkit-animation-duration: 1.75s;
  animation-duration: 1.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
  animation-name: bounce;
  -webkit-animation-name: bounce;
}
.rightBounce {
  -webkit-animation-duration: 1.75s;
  animation-duration: 1.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
  animation-name: bounceRight;
  -webkit-animation-name: bounceRight;
}
@-webkit-keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transform: translateX(0);
    box-shadow: 0px 0px 20px black;
  }
  50% {
    -webkit-transform: translateX(-8px);
    box-shadow: 0px 0px 20px black;
  }
}
@keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
}
@-webkit-keyframes bounceRight {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(8px);
  }
}
@keyframes bounceRight {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateX(8px);
  }
}
.chevButtonHolder:hover {
  margin-left: 7px;
  padding-right: 13px;
  transition: .2s;
}
.chevButtonHolderRight:hover {
  right: 7px;
  padding-left: 13px;
  padding-right: 5px;
  transition: .2s;
}
.instructions {
  position: absolute;
  top: 50%;
  right: 50px;
  margin-top: -25px;
}
.instructionsContent {
  justify-content: space-between;
  font-size: 25px;
  padding-bottom: 10px;
  font-family: 'Raleway', sans-serif;
  border-right: none;
  width: 40px;
  position: absolute;
  color: rgb(242, 101, 31);
  color: rgb(0, 191, 243);
  color: white;
  right: 12px;
  text-align: right;
  line-height: 20px;
  padding-top: 9px;
}
.instructionsSubContent {
  position: relative;
  top: 3px;
}
.bottomNav {
  text-align: center;
  width: 100%;
  position: fixed;
  cursor: pointer;
  z-Index: 1;
  background-color: transparent;
  bottom: 1vh;
}
.bottomNavItems {
  display: inline-block;
  margin: 0px 7px 10px 7px;
  color: rgb(150, 150, 150);
  font-size: 2.5vh;
  cursor: pointer;
}
.navButtonHolder {
  display: inline-block;
}
.tabContainer {
  display: none;
}
@media screen and (max-width: 900px) {
  .homeSubContentHolder {
    text-align: left;
    position: absolute;
    bottom: 160px;
    right: 14vw;
  }
  .homeSubSlideContentTitle {
    font-size: 30px;
    font-weight: 200;
    margin-bottom: 0px;
    font-family: 'Raleway', sans-serif;
    line-height: 5.5vh;
  }
  .homeSubSlideContent {
    font-size: 14px;
    font-weight: 100;
    line-height: 1.5vh;
  }
  .homeSubSlideButton {
    font-size: 16px;
    border: 1px solid rgb(242, 101, 31);
    border-radius: 4px;
    padding: 3px 10px 7px 10px;
    width: inherit;
    background-color: transparent;
    color: inherit;
    font-family: 'Raleway', sans-serif;
  }
}
@media screen and (max-width: 700px) {
   .background {
     height: 100vh;
   }
   .home-two {
     height: 100%;
   }
   .homeSubTitleHolder {
      margin-top: 25px;
      margin-left: 0vw;
   }
   .weWork {
      margin-bottom: -48px;
      font-size: 30px;
   }
   .homeSubSlideTitle {
      font-size: 50px;
      margin-bottom: -25px;
   }
   .homeSubContentHolder {
      right: 5vw;
      right: 50px;
      text-align: right;
      position: relative;
      top: -60px;
      bottom: auto;
   }
   .homeSubSlideButton {
     margin-bottom: -30px;
   }
   .chev {
      height: 45px;
   }
   .navButtonHolder {
     display: none;
   }
   .logo {
     display: inline-block;
     /* 260 X 57 */
     height: 20.25px;
     width: 104px;
     position: absolute;
     top: -20px;
     left: 10px;
   }
   .AppBar {
      height: 30px;
      max-height: 30px;
      width: 100vw;
      position: sticky;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
   }
   .bottomNav {
      margin-bottom: 40px;
      top: 40px;
   }
   .bottomNavItems {
      margin: 0px 5px 0px 5px;
      font-size: 20px;
   }
   .tabContainer {
      display: inline-block;
      width: 100%;
      position: fixed;
      cursor: pointer;
      text-align: center;
      z-Index: 1;
      bottom: 0px;
      bottom: 0vh;
      left: 0vw;
      overflow: hidden;
   }
   .bottomNavLink {
     margin: 10px 8.25vw 10px 8.25vw;
     position: relative;
     top: 20%;
   }
   .navButtons {
     text-transform: lowercase;
     color: none;
     background-color: transparent;
     border: none;
     text-align: center;
     margin: 5px;
     padding: 7px;
   }
}
@media screen and (max-width: 500px) {
  .empty-space {
    text-align: left;
  }
  .homeHeaderContainers {
    margin-top: 50px;
  }
  .homeSubContentHolder {
     right: 7vw;
     right: 18px;
     width: 90vw;
     top: -20px;
  }
  .homeSubSlideContentTitle {
     font-size: 20px;
  }
  .homeSubSlideContent {
     font-size: 12px;
  }
  .bottomNavItems {
     margin: 0px 5px 0px 5px;
     font-size: 15px;
  }
  .bottomNavLink {
    margin: 10px 5.7vw 10px 5.7vw;
  }
  .tabContainer {
     cursor: auto;
  }
  .chevButtonHolder {
    cursor: pointer;
    padding: 2px 2px;
    margin-left: 3px;
  }
  .chevButtonHolderRight {
    position: absolute;
    right: 3px;
    margin-left: 0px;
  }
  .chevButtonHolder:hover {
    margin-left: 0px;
    padding-right: 7px;
    padding-left: 0px;
  }
  .chevButtonHolderRight:hover {
    right: 0px;
    padding-left: 7px;
    padding-right: 0px;
  }
  .instructionsContent {
    justify-content: space-between;
    font-size: 4.5vw;
    line-height: 4vw;
  }
  .instructions {
    right: 15px;
    margin-top: -32.5px;
   }
}
@media screen and (max-width: 380px) {
  .leftHolder {
    margin-left: -15px;
  }
  .homeSubTitleHolder {
     margin-top: 10px;
     margin-left: 0px;
  }
  .weWork {
     margin-bottom: -33px;
     font-size: 20px;
  }
  .homeSubSlideTitle {
     font-size: 35px;
     margin-bottom: -18px;
  }
  .homeSubContentHolder {
    position: absolute;
    width: 90vw;
    top: 150px;
    right: 20px;
  }
  .homeSubSlideContentTitle {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 2px;
  }
  .homeSubSlideContent {
     font-size: 11px;
     line-height: 5px;
  }
  .homeSubSlideButton {
     font-size: 10px;
  }
  .bottomNavItems {
    margin: 0px 5px 0px 5px;
    font-size: 15px;
  }
  .bottomNavLink {
    margin: 8px 3.8vw 8px 3.8vw;
    margin-top: 20px;
  }
  .tabContainer {
     z-Index: 10010;
  }
}
/* HOME IN-PAGE NAVIGATOR END*/

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* PROGRAMS START */
.broad2 {
  font-weight: 100;
  margin-bottom: -38px;
  white-space: nowrap;
  color: rgb(100, 100, 100);
  font-size: 25px;
  text-align: left;
  margin-left: -20px;
}
.programsBroad {
  margin-bottom: -20px;
}
.programMidSubTitle2 {
  white-space: nowrap;
  text-align: left;
  font-weight: 100;
  margin-left: 155px;
  margin-top: -74px;
  margin-bottom: 70px;
  font-size: 24px;
  z-index: 0;
  color: rgb(0, 191, 243);
}
.subjectAreaList {
  margin-top: 94px;
}
.subjectAreaContainer {
  position: relative;
  top: 0px;
  background-color: grey;
  display: inline-block;
  width: 650px;
  height: 361px;
  min-width: 340px;
  min-height: 188px;
  /* margin-top: -5px; */
  margin: 10px;
  overflow: hidden;
  border: 2px solid rgb(191, 191, 191);
  border-radius: 7px;
}
.subjectAreaContainer:hover > .subjectAreaPhoto {
  opacity: 0.9;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.subjectAreaPhoto {
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  height: 100%;
  width: 100%;
}
.subjectAreaNameHolder {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 35%;
}
.subjectAreaName {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 50px;
}
.subjectAreaBriefDescriptionHolder {
  height: 80px;
  position: absolute;
  bottom: 15%;
  font-size: 20px;
  display: none;
  text-align: center;
  margin: 0 15px;
  width: 90%;
}
.subjectAreaContainer:hover > .subjectAreaBriefDescriptionHolder {
  display: block;
}
@media screen and (max-width: 1400px) {
  .subjectAreaContainer {
    width: 540px;
    height: 300px;
  }
  .subjectAreaNameHolder {
    top: 28%;
  }
  .subjectAreaName {
    line-height: 40px;
    font-size: 45px;
  }
}
@media screen and (max-width: 1200px) {
  .subjectAreaBriefDescriptionHolder {
    bottom: 10%;
    font-size: 1.5vw;
  }
}
@media screen and (max-width: 1100px) {
  .subjectAreaContainer {
    width: 480px;
    height: 266px;
    margin-top: -5px;
  }
  .subjectAreaNameHolder {
    top: 32%;
  }
  .subjectAreaName {
    font-size: 35px;
  }
  .subjectAreaBriefDescriptionHolder {
    bottom: 8%;
    font-size: 18px;
  }
}
@media screen and (max-width: 980px) {
  .subjectAreaContainer {
    width: 587px;
    height: 326px;
    margin-top: -5px;
  }
}
@media screen and (max-width: 650px) {
  .subjectAreaContainer {
    width: 360px;
    height: 200px;
    margin-top: -5px
  }
  .subjectAreaContainer:hover > .subjectAreaPhoto {
    opacity: 1;
    -webkit-filter: blur(1px);
    filter: blur(1px);
  }
  .subjectAreaPhoto {
    -webkit-filter: blur(1px);
    filter: blur(1px);
  }
  .subjectAreaNameHolder {
    top: 20%;
  }
  .subjectAreaName {
    top: -90%;
    font-size: 32px;
  }
  .subjectAreaBriefDescriptionHolder {
    bottom: -7%;
    font-size: 13px;
    display: block;
  }

}
@media screen and (max-width: 380px) {
  .broad2 {
    font-size: 25px;
  }
  .programMidSubTitle2 {
    margin-left: 155px;
    margin-top: -74px;
    margin-bottom: 70px;
    font-size: 16px;
  }
}
/* PROGRAMS END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* RESEARCH START */
.researchPage {
  text-align: center;
  width: 100vw;
}
.researchTitle {
  font-family: 'Raleway', sans-serif;
  font-size: 80px;
  font-weight: 200;
  margin-top: -10px;
  color: rgb(242, 101, 31);
}
.researchSubTitle {
  margin-left: 110px;
  margin-top: -60px;
}
.formSearchContainer {
  margin-right: 8vw;
  margin-left: 8vw;
  display: flex;
  justify-content: space-between;
}
.formSearch {
  display: inline-block;
  width: 85%;
}
.formSort {
  display: inline-block;
}
.textSearch {
  background-color: white;
}
.dateSortField {
  background-color: white;
  height: 38px;
  border-radius: 2px;
}
.allResearchContainer {
  text-align: center;
}
.allResearchTitle {
  color: rgb(242, 101, 31);
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 60px;
}
.allResearchMap {
  width: auto;
  text-align: center;
  min-height: 700px;
  margin: auto 8vw;
}
.topContentResearch { /* fix for the iceberg*/
  width: 90%;
  position: relative;
  left: 10px;
}
.researchExpansionPanel > div {
  background-color: rgba(120, 120, 120, .4);
}
.researchExpansionPanel:hover > div {
   background-color: rgba(80, 80, 80, .7);
}
.researchExpansionButton > a {
   border: 1px solid rgb(0, 191, 243);
   background-color: transparent;
}
.researchExpansionButton:hover > a {
  background-color: rgba(0, 191, 243, .1);
}
.researchExpansionTitleAcrossHolder {
  position: absolute;
  top: -10px;
  left: 10px;
}
.researchExpansionColumn {
  width: 70%;
  margin-left: 5px;
}
.researchExpansionColumnSmall {
  width: 15%;
  margin-top: 12px;
}
.researchExpansionTitle {
  color: rgb(242, 101, 31);
  padding-right: 5px;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}
.researchExpansionColumnHolders {
  display: inherit;
  margin-top: 20px;
  width: 100%;
}
.researchExpansionType {
  color: inherit;
  text-align: left;
  color: rgb(200, 200, 200);
  margin-top: 0px;
}
.researchExpansionBrief {
  color: rgb(200, 200, 200);
  text-align: left;
}
.researchRelatedConcepts {
  text-align: right;
  padding-left: 10px;
  font-size: 14px;
}
.researchOtherResources {
  text-align: left;
  font-size: 14px;
  padding-right: 10px;
}
.researchExpansionSubContent {
  font-size: 16px;
}
.reasearchTags {
  position: relative;
  right: 20px;
}
.chip {
  margin-left: 3px;
  margin-top: 5px;
}
.researchFocusConcept {
  font-size: 14px;
  margin-top: 3px;
  margin-right: 7px;
}
@media screen and (max-width: 1240px) { /* 1116px */
  .researchExpansionColumnSmall {
    width: 15%;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1000px) {
  .formSearchContainer {
    display: block
  }
  .formSearch {
    width: 100%;
  }
  .formSort {
    margin-left: 0 auto;
    margin-top: 15px;
  }
  .researchExpansionButton {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 35px;
  }
}
@media screen and (max-width: 700px) {
  .topContentResearch {
    text-align: center;
    padding-right: 10px;
  }
  .researchExpansionColumnHolders {
    margin-top: 30px;
  }
  .researchExpansionTitle {
    max-width: 95%;
    text-align: left;
  }
  .formSearch {
    margin-left: 0vw;
  }
  .formSort {
    left: 0vw;
  }
}
@media screen and (max-width: 500px) {
  .researchExpansionButton {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 50px;
  }
  .researchSubTitle {
    margin-left: 105px;
    font-size: 22px
  }
}
@media screen and (max-width: 410px)  {
  .researchSubTitle {
    margin-left: 68px;
    font-size: 20px;
    margin-top: -56px;
  }
  .researchExpansionTitleAcrossHolder {
    top: -18px;
    left: 5px;
  }
  .researchExpansionColumnHolders {
    margin-top: 30px;
  }
  .researchExpansionType {
    display: none;
  }
  .researchExpansionBrief {
    margin-left: -6px;
    position: relative;
    bottom: -35px;
    margin-bottom: 15px;
  }
  .researchExpansionButton {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 70px;
  }
  .researchRelatedConcepts {
    height: 114px;
    overflow-y: hidden;
  }
  .researchFocusConcept {
    display: block;
  }
}
/* RESEARCH END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* CONTACT */
.contactContainer {
  text-align: left;
  margin-top: 30px;
}
.contactHeaderContainer {
  margin-left: 15px;
  margin-top: -30px;
}
.contactHeaderTitle {
  font-size: 60px;
  line-height: 60px;
}
.contactMainTitle {
  font-size: 80px;
  font-family: 'Helvetica Neue Light', sans-serif;
}
.contactTitle {
  font-family: 'Helvetica Neue Light', sans-serif;
  font-size: 40px;
  padding-bottom: 10px;
  font-weight: 200;
}
.form {
  margin-top: -48px;
}
.form2 {
  margin-top: 0px;
}
.contactSubmitButtonHolder {
  margin-top: -68px;
}
.messageContainer {
  margin-left: 15px;
}
.messageSent {
  margin-left: 15px;
  color: green;
  font-family: 'Helvetica Neue Light', sans-serif;
  display: inline-block;
  position: relative;
  bottom: 13px;
}
.messageIncomplete {
  color: rgb(242, 101, 31);
}
.messageIcon {
  position: relative;
  top: 8px;
  display: inline-block;
}
.contactImage {
  width: 850px;
  margin-top: -73px;
}
.infoWindowContent {
  color: black;
}
.contactInfo {
  text-align: left;
}
.contactInfoHolders {
  margin-top: -18px;
}
.contactData {
  text-align: right;
  position: relative;
  top: -18px;
}
.infoWindowTitle {
  font-family: 'Helvetica Neue Light', sans-serif;
  color: black;
  z-index: 100000;
}
.button1 {
  display: inline;
}
.button2 {
  display: inline;
}
.map {
  margin-top: 30px;
  height: 630px;
  width: 100%;
  z-index: 0;
}
.addressContainer {
  text-align: center;
  position: relative;
  top: -604px;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  padding: 0px 10px 5px 10px;
  width: 200px;
  margin-left: 8px;
  margin-top: 8px;
}
.mapTitle {
  font-family: 'Helvetica Neue Light', sans-serif;
}
@media screen and (max-width: 1882px) {
  .contactImage {
    margin-top: -45px;
    width: 800px;
  }
}
@media screen and (max-width: 1400px) {
  .contactImage {
    width: 650px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 1170px) {
  .contactImage {
    width: 550px;
    margin-top: 60px;
  }
}
@media screen and (max-width: 959px) { /*863*/
  .contactImage {
    width: 650px;
    margin-top: -80px;
  }
}
@media screen and (max-width: 726px) { /*630*/
  .contactImage {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .contactHeaderTitle {
    font-size: 30px;
    line-height: 30px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 450px) {
  .contactSubmitButtonHolder {
    margin-top: 0px;
  }
}
@media screen and (max-width: 380px) {
  .form {
    margin-top: 0px;
  }
  .addressContainer {
    top: -590px;
    width: 90%;
    font-size: 14px;
  }
  .contactTitle {
    font-size: 20px;
    margin-bottom: -5px;
  }
  .address {
    display: inline-block;
    position: relative;
    margin-bottom: 18px;
  }
  .topAddress {
    margin-top: -30px;
  }
  .contactInfoHolders {
    margin-top: -15px;
  }
  .map {
    height: 630px;
  }
}
/* CONTACT END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* ABOUT US */
.aboutUsPageContainer {
  line-height: 20px;
}
a.anchor {
  display: block;
  position: relative;
  top: -110px;
  visibility: hidden;
}
.aboutUsHolders {
  padding-top: 100px;
}
.aboutUsNumberHolders {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  overflow: hidden;
}
.aboutUsNumber {
  font-size: 80px;
  padding-top: 15px;
  padding-bottom: 24px;
  font-weight: 400;
}
.aboutUsSubNumber {
  margin-top: -30px;
  font-size: 24px;
}
.aboutUsSubHeader {
  font-family: 'Raleway', sans-serif;
  text-align: left;
  margin-left: 30px;
  font-size: 40px;
  line-height: 30px;
  font-weight: 200;
}
.topTitleContainerAboutHolder {
  margin-left: 15px;
  overflow: hidden;
  padding-top: 10px;
  text-align: left;
  display: inline-block;
  max-width: 100vw;
  padding-left: 0px;
}
.weAre {
  padding: 2em 5em;
  font: normal 70px/90px 'Raleway', sans-serif;
  color: rgba(200, 200, 200, .8);
  display: inline;
  font-weight: 200;
}
.weAre p {
  height: 50px;
  float: left;
  margin-right: 0.3em;
  position: relative;
  top: -101px;
  max-width: 99vw;
}
.weAre b {
  font-weight: 200;
  float: left;
  overflow: hidden;
  position: relative;
  height: 100px;
  padding-top: 21px;
  margin-top: 2px;
  top: -134px;
}
.whoWeAre > span {
  position: relative;
  top: -6px;
}
.weAre .whoWeAre {
  display: inline-block;
  color: rgb(242, 101, 31);
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  height: 90px;
  text-align: left;
  -webkit-animation:move 15s;
     -moz-animation:move 15s;
      -ms-animation:move 15s;
       -o-animation:move 15s;
          animation:move 15s;
  -webkit-animation-iteration-count:infinite;
     -moz-animation-iteration-count:infinite;
      -ms-animation-iteration-count:infinite;
       -o-animation-iteration-count:infinite;
          animation-iteration-count:infinite;
  -webkit-animation-delay:3s;
     -moz-animation-delay:3s;
      -ms-animation-delay:3s;
       -o-animation-delay:3s;
          animation-delay:3s;
}
@-webkit-keyframes move {
  0%  { top: 0px; }
  10% { top: -90px; }
  20% { top: -180px; }
  30% { top: -270px; }
  40% { top: -360px; }
  50% { top: -450px; }
  60% { top: -540px; }
  70% { top: -630px; }
  80% { top: -720px; }
  90% { top: -810px; }
}
@-moz-keyframes move {
  0%  { top: 0px; }
  10% { top: -90px; }
  20% { top: -180px; }
  30% { top: -270px; }
  40% { top: -360px; }
  50% { top: -450px; }
  60% { top: -540px; }
  70% { top: -630px; }
  80% { top: -720px; }
  90% { top: -810px; }
}
@-o-keyframes move {
  0%  { top: 0px; }
  10% { top: -90px; }
  20% { top: -180px; }
  30% { top: -270px; }
  40% { top: -360px; }
  50% { top: -450px; }
  60% { top: -540px; }
  70% { top: -630px; }
  80% { top: -720px; }
  90% { top: -810px; }
}
@keyframes move {
  0%  { top: 0px; }
  10% { top: -90px; }
  20% { top: -180px; }
  30% { top: -270px; }
  40% { top: -360px; }
  50% { top: -450px; }
  60% { top: -540px; }
  70% { top: -630px; }
  80% { top: -720px; }
  90% { top: -810px; }
}
.aboutUsVideo {
  height: 25vw;
  min-width: 563px;
  width: 44vw;
  min-height: 320px;
  margin-left: -30px;
  position: relative;
  top: -30px;
  overflow: hidden;
}
.subTitleToSectionHeader2 {
  margin-top: -16px;
}
.aboutStickyHeader {
  width: 100%;
  position: sticky;
  top: 69px;
  background-color: rgba(5, 5, 5, .85);
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
  font-family: 'Raleway', sans-serif;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  z-index: 1000;
}
.boaIrbStickyHeader {
  width: auto;
}
.stickyHeaderItem {
  display: inline-block;
}
.stickyHeaderItemBoaIrb:hover {
  color: rgb(242, 101, 31);
}
.activeStyle {
  color: rgb(242, 101, 31);
}
.aboutUsPeopleContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  padding-left: 12px;
}
.aboutUsPeopleHolder {
  display: inline-block;
  position: relative;
  margin: auto;
}
/* GRID OF IMAGES BEGIN 2*/
#categories{
  overflow:hidden;
  width:75%;
  margin:0 auto;
}
.clr:after{
  content:"";
  display:block;
  clear:both;
}
#categories li{
  position:relative;
  list-style-type:none;
  float:left;
  overflow:hidden;
  visibility:hidden;
  /* vertical-align:middle; */
  /* transform: rotate(-60deg) skewY(30deg); */
}
#categories li *{
  margin:4%;
  /* margin-left: 0%; */
  /* margin-top:4%;
  margin-bottom:4%;
  margin-right:0%; */
  padding:0;
  width: 50%;
  position:absolute;
  visibility:visible;
}
#categories li > div{
  width:100%;
  height:100%;
  text-align:center;
  color:#fff;
  overflow:hidden;
  /* transform: skewY(-30deg) rotate(60deg); */
  backface-visibility:hidden;
}
/* HEX CONTENT */
#categories li img{
  left:-100%; right:-100%;
  width: auto; height:100%;
  margin:0 auto;
}
#categories div h1, #categories div p{
  width:88.7%;
  /* padding:0 5%; */
  right: 1.7%;
  /* background-color:#008080;  */
  /* background-color: rgba(0, 191, 243, 0.8);
  transition: top .2s ease-out, bottom .2s ease-out, .2s padding .2s ease-out; */
}
/* #categories li h1{
  bottom:110%;
  font-style:italic;
  font-weight:normal;
  font-size:1.5em;
  padding-top:100%;
  padding-bottom:100%;
} */
/* #categories li h1:after{
    content:'';
  display:block;
  position:absolute;
  bottom:-1px; left:45%;
  width:10%;
  text-align:center;
  z-index:1;
  border-bottom:2px solid #fff;
} */
#categories li p{
    /* padding-top:10%; */
    /* top:110%; */
  /* padding-bottom:10px; */
  /* padding-top:10px; */
  font-size:16px;
  height: 50%;
  background: rgb(204, 204, 204);
  background: rgba(128, 127, 127, 0.493);
  vertical-align: middle;
}
/* HOVER EFFECT  */
/* #categories li div:hover h1 {
  bottom:50%;
  padding-bottom:10%;
}
#categories li div:hover p{
  top:50%;
  padding-top:10%;
} */
/* 
.clrPeople li div span{
  font-size:16px;
  height: 100%;
  background: rgb(204, 204, 204);
  background: rgba(128, 127, 127, 0.493);
}

.clrInstructor li div p{
  font-size:16px;
  height: 50%;
  background: rgb(204, 204, 204);
  background: rgba(128, 127, 127, 0.493);
} */

.clrPeople li div{
  margin-top:20px;
}
.clrPeople li div p{
  top:70%;
}
.clrInstructor li div{
  padding-top:10%;
}

.clrInstructor li div p{
  top:70%;
}

#categories li div p{
  padding-top:10%;
}

#categories li div h2{
  /* top:79%; */
  left:12px;
  /* font-size: 20px; */
}

@media screen and (max-width: 1892px) {
  /* 1721  - 1548 */
  .weAre p {
    height: 50px;
    float: left;
    margin-right: 0.3em;
    position: relative;
    top: -191px; /* change to -200+ */
    max-width: 99vw;
  }
}
@media screen and (max-width: 1721px) {
  /* 1721  - 1548 */
  .weAre p {
    height: 50px;
    float: left;
    margin-right: 0.3em;
    position: relative;
    top: -191px; /* change to -200+ */
    max-width: 99vw;
  }
}
@media screen and (max-width: 1279px) {
  .topTitleContainerAbout {
    text-align: center;
  }
  .weAre p {
    margin-top: 50px;
    height: 50px;
    float: left;
    margin-right: 0.3em;
    position: relative;
    top: -50px;
  }
  .weAre b {
    float: left;
    overflow: hidden;
    position: relative;
    height: 96px;
    margin-top: -5px;
    padding-top: 10px;
    top: -87px;
  }
}
@media (min-width:1201px) {
  #categories li{
    width:17.364%; /* = (100-4.5) / 5.5 */
    padding-bottom: 20.05%; /* =  width /0.866 */
  }
  #categories li:nth-child(10n+6), #categories li:nth-child(10n+7), #categories li:nth-child(10n+8), #categories li:nth-child(10n+9), #categories li:nth-child(10n+10) {
    margin-top: auto;
    margin-bottom: auto;
    /* margin-top: -4.2%;
    margin-bottom: -4.2%; */
    /* transform: translateX(50%) rotate(-60deg) skewY(30deg); */
  }
  #categories li:nth-child(10n+6):last-child, #categories li:nth-child(10n+7):last-child, #categories li:nth-child(10n+8):last-child, #categories li:nth-child(10n+9):last-child, #categories li:nth-child(10n+10):last-child{
    margin-bottom:0;
  }
  /* #categories li:nth-child(10n+6){
    margin-left:0.5%;
  }
  #categories li:nth-child(5n+2) {
    margin-left:1%;
    margin-right:1%;
  }
  #categories li:nth-child(5n+3),#categories li:nth-child(5n+4){
    margin-right:1%;
  } */
}
@media (max-width: 1200px) and (min-width:901px) {
  #categories li{
    width:21.444%; /* = (100-3.5) / 4.5 */
    padding-bottom: 24.763%; /* =  width /0.866 */
  }
  #categories li:nth-child(8n+5), #categories li:nth-child(8n+6), #categories li:nth-child(8n+7), #categories li:nth-child(8n+8) {
    margin-top: auto;
    margin-bottom: auto;
    /* margin-top: -5.298%;
    margin-bottom: -5.298%; */
    /* transform: translateX(50%) rotate(-60deg) skewY(30deg); */
  }
  #categories li:nth-child(8n+5):last-child, #categories li:nth-child(8n+6):last-child, #categories li:nth-child(8n+7):last-child, #categories li:nth-child(8n+8):last-child{
    margin-bottom:0;
  }
  /* #categories li:nth-child(8n+5){
    margin-left:0.5%;
  }
  #categories li:nth-child(4n+2) {
    margin-left:1%;
    margin-right:1%;
  }
  #categories li:nth-child(4n+3){
    margin-right:1%;
  } */
}
@media screen and (max-width: 982px) {
  .aboutUsVideo {
    top: -80px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 967px) {
  .weAre p {
    top: -106px;
  }
  .weAre b {
    top: -51px;
  }
  .aboutUsVideo {
    top: -80px;
  }
}
@media (max-width: 900px) and (min-width:601px) {
  #categories li{
    width:27.857%; /* = (100-2.5) / 3.5 */
    padding-bottom: 32.168%; /* =  width /0.866 */
  }
  #categories li:nth-child(6n+4), #categories li:nth-child(6n+5), #categories li:nth-child(6n+6) {
    margin-top: auto;
    margin-bottom: auto;
    /* margin-top: -7.322%;
    margin-bottom: -7.322%; */
    /* transform: translateX(50%) rotate(-60deg) skewY(30deg); */
  }
  #categories li:nth-child(6n+4):last-child, #categories li:nth-child(6n+5):last-child, #categories li:nth-child(6n+6):last-child{
    margin-bottom:0;
  }
  /* #categories li:nth-child(6n+4){
    margin-left:0.5%;
  }
  #categories li:nth-child(3n+2) {
    margin-left:1%;
    margin-right:1%;
  } */
}
@media screen and (max-width: 700px) {
  .aboutStickyHeader {
    top: 30px;
  }
  .aboutUsVideo {
    height: 50vw;
    min-width: 10px;
    width: 90vw;
    min-height: 10px;
    margin-left: -30px;
    position: relative;
    top: -30px;
  }
}
@media screen and (max-width: 634px) {
  .weAre p {
    top: -150px;
  }
  .weAre b {
    top: -150px;
  }
  .aboutUsVideo {
    top: -157px;
  }
}
@media screen and (max-width: 586px) {
  .weAre b {
    top: -170px;
  }
  .weAre .whoWeAre {
    font-size: 55px;
  }
  .weAre p {
    font-size: 55px;
  }
}
@media (max-width: 600px) {
  .subTitleToSectionHeaderHolder {
    margin-top: -45px;
  }
  .subTitleToSectionHeader {
    margin-left: 30px;
  }
  .aboutStickyHeader {
    padding: 20px 40px;
  }
  .stickyHeaderItem {
    display: inline-block;
  }
  #categories li{
    width:39.4%; /* = (100-1.5) / 2.5 */
    padding-bottom: 45.496%; /* =  width /0.866 */
  }
  #categories li:nth-child(4n+3), #categories li:nth-child(4n+4){
    margin-top: auto;
    margin-bottom: auto;
    /* margin-top: -10.392%;
    margin-bottom: -10.392%; */
    /* transform: translateX(50%) rotate(-60deg) skewY(30deg); */
  }
  #categories li:nth-child(4n+3):last-child, #categories li:nth-child(4n+4):last-child{
    margin-bottom:0;
  }
  /* #categories li:nth-child(4n+3){
    margin-left:0.5%;
  }
  #categories li:nth-child(2n+2) {
    margin-left:1%;
  } */
}
@media (max-width: 430px) {
  .aboutStickyHeader {
    padding: 10px 10px;
  }
  .stickyHeaderItem {
    display: inline-block;
  }
}
@media (max-width: 380px) {
  .aboutStickyHeader {
    padding: 0px 5px;
  }
  .stickyHeaderItem {
    display: inline-block;
    font-size: 14px;
  }
}
#fork{
  position:fixed;
  bottom:0;
  right:0;
  color:#000;
  text-decoration:none;
  border:1px solid #000;
  padding:.5em .7em;
  margin:1%;
  transition: color .5s;
  overflow:hidden;
}
#fork:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 130%; height: 100%;
  background: #000;
  z-index: -1;
  transform-origin:0 0 ;
  transform:translateX(-100%) skewX(-45deg);
  transition: transform .5s;
}
/* GRID OF IMAGES END */
.aboutUsPeopleImage {
  height: 150px;
  position: relative;
  top: 0px;
  overflow: hidden;
}
.boardOfAdvisors {
  text-align: left;
}
.boaContent {
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
}
.internalReviewBoard {
  text-align: right;
}
.irb {
  margin-left: 0px;
  margin-right: 30px;
  text-align: right;
}
.irbContent {
  text-align: right;
  margin-right: 30px;
}
.irbImage {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
}
.partnersContainer {
  box-sizing: border-box;
  margin: 0;
}
.header {
  text-align: center;
  padding: 32px;
}
.careerHeader {
  margin-top: 10px;
}
.careerIntro {
  margin-top: -5px;
  text-align: left;
  margin-right: 30px;
}
.careerContainer {
  margin-top: 30px;
  text-align: left;
  margin-left: 20px;
  width: 100%;
}
.careerHolder {
  position: relative;
  top: 0px;
  margin: 18px 30px;
  padding: 15px;
  width: 215px;
  height: 200px;
  display: inline-block;
  background: linear-gradient(
    30deg,
    rgb(30, 30, 30) 0%,
    rgb(40, 40, 40) 50%,
    rgb(30, 30, 30) 100%
    );
  border-radius: 3px;
}
.careerHolder:hover {
  background: linear-gradient(
    210deg,
    rgb(40, 40, 40) 0%,
    rgb(30, 30, 30) 50%,
    rgb(40, 40, 40) 100%
    );
}
.careerHolder:hover > .corner {
  border-top: 2px solid rgb(200,200, 200);
  border-left: 2px solid rgb(200, 200, 200);
}
.corner {
  height: 12px;
  width: 12px;
  border-top: 2px solid rgb(150, 150, 150);
  border-left: 2px solid rgb(150, 150, 150);
  border-top-left-radius: 4px;
  position: absolute;
}
.cornerTopLeft {
  top: 10px;
  left: 10px;
}
.cornerTopRight {
  right: 10px;
  top: 10px;
  transform: rotateZ(90deg);
}
.cornerBottomRight {
  bottom: 10px;
  right: 10px;
  transform: rotateZ(180deg);
}
.cornerBottomLeft {
  bottom: 10px;
  left: 10px;
  transform: rotateZ(-90deg);
}
.careerTitle {
  text-align: left;
  height: 40px;
}
.careerContent {
  position: relative;
  top: 0px;
  height: 100px;
  font-size: 14px;
}
.careerLearnMore {
  position: absolute;
  right: 20px;
  bottom: 15px;
}
.noListingHolder {
  margin-top: 50px;
  text-align: left;
  margin-left: 40px;
  margin-right: 40px;
}
.noListingContent {
  line-height: 20px;
}
.equalOpp {
  font-style: italic;
  margin-top: 40px;
  font-size: 14px;
  margin-left: 30px;
  margin-right: 30px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 10px;
}
.rowBoaIrb {
  margin-bottom: -75px;
}
.column {
    -ms-flex: 16.666%;
    flex: 16.666%;
    max-width: 10%;
    padding: 0 4px;
    margin-bottom: -30px;
}
.columnName {
  position: relative;
  top: -62px;
  width: 100%;
  background-color: rgba(40, 40, 40, .7);
  padding-bottom: 13px;
  padding-top: 13px;
  text-align: center;
  opacity: 0.8;
}
.columnNamePartners {
  padding-bottom: 7px;
  top: -59px;
  visibility: hidden;
  height: 19px;
}
.partnersImage {
  width: 100%;
}
.columnPartners:hover .columnNamePartners {
  visibility: visible;
}
.partnersImage:hover .columnNamePartners {
  visibility: visible;
}
.columnNameBoaIrb {
  height: 17px;
}
.rowPartners {
  justify-content: center;
}
.columnPartners {
    -ms-flex: 16.6666%;
    flex: 16.6666%;
    max-width: 15%;
    padding: 0 0px;
    margin-bottom: -75px;
}
.columnboa {
    -ms-flex: 16.666%;
    flex: 16.666%;
    max-width: 25%;
    padding: 0 2px;
}
.column img {
    margin-top: 8px;
    vertical-align: middle;
}
@media screen and (max-width: 1659px) {
  .rowBoaIrb {
    margin-bottom: -94px;
  }
  .columnPartners {
    margin-bottom: -100px;
  }
  .columnName {
    top: -82px;
    height: 54px;
    padding-bottom: 13px;
  }
  .columnNamePartners {
    top: -84px;
    height: 51px;
  }
  .columnNameBoaIrb {
    height: 49px;
  }
}
@media screen and (max-width: 1055px) {
  .rowBoaIrb {
    justify-content: center;
  }
  .boa {
    text-align: center;
  }
  .irb {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .boaContent {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .irbContent {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 959px) {
  .boaContent {
    width: 50%;
  }
  .careerIntro {
    margin-left: 15px;
  }
}
@media screen and (max-width: 919px) {
  .rowBoaIrb {
    margin-bottom: -85px;
  }
  .columnPartners {
    margin-bottom: -90px;
  }
  .columnName {
    top: -75px;
    height: 54px;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 5px;
  }
  .columnNamePartners {
    top: -75px;
    height: 50px;
  }
  .columnNameBoaIrb {
    padding-top: 13px;
    padding-bottom: 0px;
    height: 45px;
  }
}
@media screen and (max-width: 800px) {
    .column {
        -ms-flex: 43%;
        flex: 43%;
        max-width: 43%;
    }
    .columnPartners {
        -ms-flex: 43%;
        flex: 43%;
        max-width: 43%;
    }
    .columnName {
      position: relative;
      top: -75px;
      width: 100%;
      height: 44px;
      background-color: rgba(40, 40, 40, .7);
      padding-top: 18px;
      padding-bottom: 0px;
    }
    .columnNamePartners {
      top: -75px;
      height: 40px;
    }
    .columnNameBoaIrb {
      height: 40px;
    }
}
@media screen and (max-width: 657px) {
  .row {
    justify-content: center;
  }
  .careerContainer {
    text-align: center;
    margin-left: auto;
  }
  .aboutUsSubHeader {
    text-align: center;
  }
  .boaContent {
    text-align: center;
    width: 100%;
    margin: 10px auto;
  }
  .irbContent {
    text-align: center;
    width: 100%;
    margin: 10px auto;
  }
}
@media screen and (max-width: 500px) {
  .rowBoaIrb {
    margin-bottom: -69px;
  }
  .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
  }
  .columnNameBoaIrb {
    font-size: 12px;
    top: -60px;
    height: 40px;
    padding-top: 3px;
    padding-bottom: 2px;
  }
}
/* ABOUT US END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* PERSON PAGE START */
.peoplePageContainer {
  margin-top: 40px;
  margin-bottom: 90px;
}
.imageAndBioHolder {
  text-align: left;
  overflow: hidden;
  margin-left: 30px;
}
.personImage {
  width: 250px;
  float: left;
}
.personName {
  color: white;
  left: 50px; /*0*/
  position: relative;
  min-width: 380px;
  padding-bottom: 20px;
  font-size: 90px;
}
.personTitle {
  left: 55px; /*35*/
  position: relative;
  margin-top: -70px;
  width: 250px;
  font-size: 28px;
}
.personBio {
  left: 55px; /*35*/
  position: relative;
  margin-right: 30px;
  width: 70vw;
}
@media screen and (max-width: 1224px) {
  .personName {
    left: 0px; /*0*/
  }
  .personTitle {
    left: 25px; /*35*/
    font-size: 28px;
  }
  .personBio {
    left: 25px; /*35*/
    width: 80vw;
  }
}
@media screen and (max-width: 959px) {
  .imageAndBioHolder {
    text-align: center;
  }
  .personName {
    white-space: wrap;
  }
  .personTitle {
    font-size: 24px;
  }
  .personBio {
    width: 85vw;
    margin: 10 auto;
  }
}
@media screen and (max-width: 700px) {
  .personImage {
    float: none;
    text-align: center;
  }
  .personName {
    left: 15px;
    margin: inherit auto;
    font-size: 60px;
    text-align: center;
  }
  .personTitle {
    left: 10px;
    margin-top: -65px;
    font-size: 22px;
  }
}
@media screen and (max-width: 619px) {
  .personTitle {
    left: inherit;
  }
}
@media screen and (max-width: 500px) {
  .imageAndBioHolder {
    text-align: center;
    margin-left: 0px;
  }
  .personImage {
    float: none;
  }
  .personName {
    position: relative;
    text-align: center;
    left: 0px;
    font-size: 60px;
    margin: 0 auto;
  }
  .personTitle {
    margin-top: -10px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .personBio {
    left: 0px;
    margin: 10px auto 50px auto;
    text-align: center;
  }
}
/* PERSON PAGE END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* DASHBOARD PAGE START */
.dashboard {
  margin-top: 30px;
  min-height: 2000vh;
}
.dashboardLinkHolder {
  margin-bottom: 45px;
}
.dashboardLink {
  display: inline-flex;
  width: 250px;
  justify-content: space-around;
}
/*CAREERS*/
/* .careerListContainer {

} */
.careersListHolder {
  border: 2px solid rgb(200, 200, 200);
  padding: 7px 10px 10px 10px;
  margin: 3px;
  text-align: left;
}
/* PROGRAMS */
.dashboardGet {
  min-height: 100px;
}
.noCareersContainer {
  margin: 0 auto;
}
/* DASHBOARD PAGE END */


/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* 404 PAGE BEING */
.error404pageTitleHolder {
  text-align: left;
  margin-left: 15px;
}
.image404 {
  width: 90%;
  height: 70%;
}
.theDifference {
  font-size: 50px;
}
/* 404 PAGE END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* MULTIPLE PAGES NOT NEC. GENERAL START */
/* documentViewerPage BEGING */
.documentViewerContainer {
  width: auto;
  text-align: left;
  margin-left: 50px;
  margin-top: -20px;
}
.documentViewerHolder {
  text-align: center;
  display: inline-block;
  width: 130px;
  padding: 8px;
  height: 200px;
  margin: 20px 10px 0px 0px;
  background-color: rgba(49, 49, 49, .9);
  border: 1px solid rgb(32, 32, 32);
  border-radius: 8px;
  overflow: hidden;
}
.documentViewerHolder:hover {
  background-color: rgb(213, 213, 213);
}
/* .documentViewerTitle {
  position: relative;
  margin: -34px -8px 0px -8px;
  padding: 10px 0px;
  background-color: rgba(80, 80, 80, .7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.documentViewerTitleNew{
  position: absolute;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.4);
  margin: 0px -8px 0px -130px;
  height: 200px;
  width: 130px;
}
.documentViewerTitleNew2{
  padding: 70px 0;
  visibility: visible;
}
/* .documentViewerHolder:hover > .documentViewerTitle {
  background-color: rgb(40, 40, 40);
} */
.documentViewerEmbed {
  height: 200px;
  width: 130px;
  border-radius: 8px;
}
.documentViewerEmbed > #document > html > .c3 {
  padding: 0px;
}
/* documentViewerPage End */
.individPageHeader {
  margin-left: 15px;
  text-align: center;
  min-height: 65vh;
  width: 60vw;
  margin: 0 auto;
  margin-left: 9vw;
  text-align: left;
}
.individPageTitle {
  margin-top: 15px;
  padding-top: 10px;
  margin-bottom: 50px;
  font-size: 100px;
  margin-top: 15vh;
  line-height: 100px;
  color: white;
  text-align: left;
  font-weight: 400;
}
.programData {
  padding-right: 7px;
}
.individPageDescription {
  font-size: 18px;
  line-height: 22px;
  width: 45vw;
  text-align: left;
}
.individImage {
  position: absolute;
  right: 0px;
  z-index: -1;
  top: -150px;
}
.questionsContainer {
  text-align: left;
  width: 90%;
  margin-left: 50px;
}
.QuestionContainerTitle {
  text-align: left;
  font-weight: 200;
  margin-left: 30px;
}
.questionMapHolder {
  margin-left: 50px;
}
.questionsHolder {
  margin-right: 30px;
}
.questionsTitle {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
}
.questionsContent {
  color: rgb(200, 200, 200);
  margin-left: 30px;
}
.getFromDbHolders {
  width: 99vw;
}
.getFromDbHeader {
  margin-top: 0px;
  font-size: 30px;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  margin-left: 30px;
  font-weight: 200;
}
.getFromDbContainer {
  margin-left: 50px;
  margin-top: 30px;
  margin-bottom: -15px;
}
.getFromDbToolTip {
  color: rgb(200, 200, 200);
  font-size: 16px;
  border: 1.75px solid rgb(200, 200, 200);
  border-radius: 100%;
  padding: 0px 6px;
  position: relative;
  bottom: 5px;
  left: 5px;
}
.getFromDbHeader > div > div {
  max-width: 90vw;
}
.linkGetFromDbHolder {
  width: 100%;
  text-align: left;
  overflow: hidden;
}
.linkGetFromDBP {
  padding-left: 45px;
}
.alignLeft {
  text-align: left;
}
.getProgramsFromWidth {
  width: 33%;
  display: inline-block;
}
.quarterWidth {
  width: 25%;
  display: inline-block;
}
.thirdWidth {
  width: 33%;
  display: inline-block;
}
.halfWidth {
  width: 50%;
  display: inline-block;
}
.twoThirdsWidth {
  width: 66%;
  display: inline-block;
}
.fullWidth {
  width: 100%;
  display: inline-block;
}
.flex {
  display: flex;
}
.dropDownSvg {
  fill: rgb(242, 101, 31);
}
.glowingTextOrange {
  background: radial-gradient(
    closest-corner,
    rgb(163, 53, 26) 0%,
    rgb(127, 49, 7) 10%,
    rgb(122, 52, 17) 30%,
    rgb(242, 101, 31) 80%,
    rgb(122, 52, 17) 130%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation-name: shine;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
@media screen and (min-width: 1500px) {
  .getFromDbContainer {
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: -15px;
    max-width: 1000px;
  }
}
@media screen and (max-width: 959px) {
  .getFromDbHeader {
    margin-left: 0px;
  }
  .getFromDbHeader {
    margin-top: 0px;
    font-size: 30px;
    font-family: 'Raleway', sans-serif;
    margin-left: 0px;
  }
  .getFromDbContainer {
    margin-top: 0px;
    margin-left: 15px;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .linkGetFromDbHolder {
    width: auto;
    text-align: left;
  }
  .linkGetFromDBP {
    padding-left: 45px;
  }
  .getProgramsFromWidth {
    width: 50%;
    display: inline-block;
  }
  .inlineBlockMedium {
    display: inline-block;
  }
  .quarterWidthMedium {
    width: 25%;
    display: inline-block;
  }
  .thirdWidthMedium {
    width: 33%;
    display: inline-block;
  }
  .halfWidthMedium {
    width: 50%;
    display: inline-block;
  }
  .twoThirdsWidthMedium {
    width: 66%;
    display: inline-block;
  }
  .fullWidthMedium {
    width: 100%;
    display: inline-block;
  }
}
@media screen and (max-width: 900px) {
  .individPageHeader {
    text-align: center;
    margin-left: 0px;
    width: 95%;
    margin-bottom: 10vh;
  }
}
@media screen and (max-width: 700px) {
  .individPageHeader {
    margin-left: 15px;
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
  .individPageTitle {
    font-size: 40px; /* 70px*/
    line-height: 40px;
    text-align: center;
    margin-left: 0 auto;
    margin-bottom: 0px;
    padding-bottom: 30px;
    margin-top: 20vw;
  }
  .individPageDescription {
    width: 75vw;
    text-align: center;
    margin: 0 auto;
  }
  .questionsContainer {
    text-align: left;
    margin: 0 auto;
  }
}
@media screen and (max-width: 675px) {
  .inlineBlockSmall {
    display: inline-block;
  }
  .quarterWidthSmall {
    width: 25%;
    display: inline-block;
  }
  .thirdWidthSmall {
    width: 33%;
    display: inline-block;
  }
  .halfWidthSmall {
    width: 50%;
    display: inline-block;
  }
  .twoThirdsWidthSmall {
    width: 66%;
    display: inline-block;
  }
  .fullWidthSmall {
    width: 100%;
    display: inline-block;
  }
}
@media screen and (max-width: 380px) {
  .linkGetFromDBP {
    padding-left: 0px;
  }
  .getFromDbHeader {
    margin-bottom: 0px;
  }
  .linkGetFromDb {
    font-size: 13px;
  }
}

/* MULTIPLE PAGES NOT NEC. GENERAL END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* GENERAL FORMATTING */
.foundry10 {
  font-family: 'Helvetica', sans-serif;
}
.buttonasspan {
  border: none;
  background: transparent;
}
.nonbutton {
  border: 1px solid white;
  border-radius: 4px;
  padding: 5px 5px;
}
.leftSpace {
  padding-left: 5px;
}
.link {
  color: inherit;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
.backToTop {
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
  bottom: 0px;
  float: right;
}
.orange {
  color: rgba(242, 101, 31, .7);
}
.teal {
  color: rgb(0, 191, 243);
}
.lessTeal {
  color: rgba(0, 191, 243, .7);
}
.offWhite {
  color: rgba(200, 200, 200, .7);
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
}
.noUnderline {
  text-decoration: none;
}
.noUnderline:hover {
  text-decoration: none;
}
.wordBreak {
  word-wrap: break-word;
}
.fiveLineTruncate {
  max-height: 54px;
  overflow-y: hidden;
}
.marginBottomTwenty {
  margin-bottom: 50px;
}
.preLine {
  white-space: pre-line;
}
.inheritColor {
  color: inherit
}
.goBack {
  position: relative;
  bottom: 15px;
  right: 20px;
  float: right;
}
#tooltip-left {
  width: 100px;
  margin: 0px;
}
#tooltip-bottom {
  width: 150px;
}
/* GENERATL FORMATTING 2 END*/

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* CUBE START */
.wrap {
  perspective: 20%;
  perspective-origin: 50% 50%;
}
.cube {
  margin: 0 auto;
  position: relative;
  width: 90%;
  height: 90%;
  transform-style: preserve-3d;
  transition: transform 1s;
}
.cube div {
  position: absolute;
  width: 90vw;
  height: 90vh;
  overflow: hidden;
}
.back {
  background: rgba(40,40,40,0.8);
  transform: translateZ(-350px) rotateY(180deg);
  transform: translateZ(-45vw) rotateY(180deg);
}
.right {
  background: rgba(250,0,350,0.6);
  transform: rotateY(-270deg) translateX(350px);
  transform: rotateY(-270deg) translateX(45vw);
  transform-origin: top right;
}
.leftCube {
  background: rgba(100,25,100,0.6);
  transform: rotateY(270deg) translateX(-350px);
  transform: rotateY(270deg) translateX(-45vw);
  transform-origin: left center;
}
.top {
  background: rgba(250,250,250,0.8);
  transform: rotateX(-90deg) translateY(-350px);
  transform: rotateX(-90deg) translateY(-45vh);
  transform-origin: top center;
}
.bottom {
  background: rgba(189,25,400,0.3);
  transform: rotateX(90deg) translateY(350px);
  transform: rotateX(90deg) translateY(45vw);
  transform-origin: bottom center;
}
.front {
  background: rgba(30, 30, 30 ,0.7);
  transform: translateZ(350px);
  transform: translateZ(45vw);
}
.cubeHomeTwoContainer {
  position: relative;
  right: 0vw;
}
.cubeLeftHolder {
  position: relative;
  top: 560px;
  left: 30.5vw;
}
.cubeLogoHolder {
  right: 15vw;
  width: auto;
}
/* CUBE END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* NEWSLETTER BEGIN*/
#newsletter {
  display: block;
}
#mobile_newsletter {
  display: none
}
#newsletterSlideout {
  position: fixed;
  bottom: 220px;
  right: -10px;
  width: 35px;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}
#newsletter_slideout_tab {
  margin-top:360px;
  width:60px;
  height:200px;
  position: relative;
  top: 94px;
  left: -40px;
  background-color:rgb(242, 101, 31);
  text-align: center;
  color: #fff;
  cursor:pointer;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
#newsletter_tab {
  font-size: 15px;
  color:white;
  text-align: center;
  letter-spacing:2.2px;
  height:22px;
  width:90px;
  border: 1px solid rgb(242, 101, 31);
  padding:3px 10px;
  background-color: rgba(242, 101, 31, .8);
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  writing-mode: lr-tb;
}
#newsletter_slideout_inner {
  z-index: 999;
  font-size: 13px;
  text-align:left;
  align-content: left;
  position: absolute;
  top: 10px;
  left: 26px;
  background: rgb(219, 91, 28);
  padding-top: 0;
  padding-left: 20px;
  width: 305px;
  height: 155px;
  cursor:auto;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}
#showblock:checked + #newsletterSlideout {
  right: 315px;
}
#showblock {
  display: none;
}
#newsletter_slideout_inner p {
  margin:2px;
}
#newsletter_tab:hover {
  background: rgb(242,101,31);
}
.newsletterTitle {
  margin-top: 10px;
}
#newsletter_slideout_inner .newsletterTitle {
  font-size: 15px;
}
input[type=newsletter-sub]:focus {
  border:1px solid rgb(242, 101, 31);
}
input[type=newsletter-sub] {
  margin:2px 2px;
  border: 2px solid rgba(242, 101, 31, 0.7);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  height:15px;
}
input[name='first'] {
  width:43%;
  align-content: center;
}
input[name='last'] {
  width:44%;
  align-content: center;
}
input[name='email'] {
  width:64%;
  align-content: center;
}
/* MOBILE NEWSLETTER BEGIN */
#mobile_modal {
  position:fixed;
  display:block;
  font-size: 13px;
  color:white;
  top: 7px;
  z-index: 9999;
  background-color: transparent;
  border: 1px solid rgb(242, 101, 31);
  border-radius: 4px;
  right: 10px;
  font-family:'moon';
}
#mobile_newsletter_modal {
  display:none;
  position:fixed;
  padding-top:100px;
  left:0;
  top:0;
  width:100%;
  height:100%;
  overflow:auto;
  background-color:rgb(0,0,0);
  background-color:rgba(255,255,255,0.2);
  z-index: 99;
}
#mobile_newsletter_inner {
  color:black;
  background-color:rgba(245,245,245,1);
  height:225px;
  margin:auto;
  padding:20px 7px 10px 7px;
  border:1px solid rgba(242, 101, 31, .7);
  width:75%;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;;
}
.newsletter_close {
  color:#aaaaaa;
  float:right;
  position:relative;
  top: -15px;
  font-size:28px;
  font-weight:bold;
}
.mobile_submit {
  border: 1px solid black;
  color:white;
  background-color:rgba(242, 101, 31, 0.9);
  font-family:'moon';
  font-size: 10px;
  letter-spacing: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width:26%;
  align-content: center;
}
.mobileNewsletterTitle {
  padding-top:7px;
  font-family:'moon';
  letter-spacing: 0.5px;
  font-weight: 800;
}
@media screen and (max-width: 700px) {
  #newsletter {
    display:none
  }
  #mobile_newsletter {
    display:block
  }
}
/* MOBILE NEWSLETTER END */
/* NEWSLETTER END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* STUDENT WORK BEGIN */
.sw-column-sidebar {
  padding-top:15px;
  width: 20%;
  position:relative;
  height:auto;
  padding-left:10px;
}
.sw-header {
  text-align:left;
  padding-left:11px;
}
.sw-sidebar {
  height:100vh;
}
.sw-sort {
  text-align: left;
  margin-left: 10px;
}
.sw-column-posts-left {
  position:absolute;
  margin-left:23%;
  width:32%;
  padding-bottom: 20px;
}
.sw-column-posts-right {
  position:absolute;
  left:60%;
  width:32%;
  padding-bottom: 20px;
}
.sw-column-top {
  margin-left:90%;
  position:fixed;
  width:11%;
}
.student-work-posts {
  width:96%;
  height:auto;
  align-content: center;
  padding: 5px;
  border:2px solid lightgray;
  display:block;
  opacity: 1;
  transition:0.5s ease;
  backface-visibility: hidden;
}
.sw-individ-posts {
  margin:30px 6px;
  position:relative;
}
.sw-individ-posts:hover {
  color:rgba(242, 101, 31, .7);
}
.sw-individ-posts:hover img {
  color: rgba(242, 101, 31, .7);
  opacity: 0.3;
}
.sw-individ-posts:hover .image-text-container {
  opacity:1;
}
.sw-individ-video {
  margin:30px 6px;
  height:auto;
}
.sw-individ-audio {
  margin:30px 6px;
  height:170;
  display:block;
}
.student-work-videos {
  width:96%;
  height:200px;
  align-content: center;
  padding: 5px;
  border-top: 2px solid lightgray;
  border-left: 2px solid lightgray;
  border-right: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  cursor: pointer;
}
.student-work-audio {
  width: 96%;
  align-content: center;
  padding: 5px;
  border-top: 2px solid lightgray;
  border-left: 2px solid lightgray;
  border-right: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  cursor: pointer;
}
.sw-sorting-label {
  margin-bottom:5px;
}
.sw-checkbox-list {
  display:block;
  text-align: left;
  list-style-type: none;
  position:relative;
  left:-30px;
  margin-bottom: 5px;
}
.sw-checkbox-list li {
  font-size: 14px;
  margin-bottom:5px;
}
.sw-checkbox-list label:hover {
  cursor:pointer;
  color:rgb(242, 101, 31);
}
.sw-sidebar-dropdown {
  width:76% !important;
  height:20px;
}
.sw-searchbar {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  width:75%;
  height:14px;
}
.sw-submit {
  margin-top:5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  width:77%;
  border: 1px solid rgba(242, 101, 31, 0.9);
  color:white;
  background-color:rgba(242, 101, 31, 0.9);
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  align-content: center;
  cursor:pointer;
}
.sw-expandable {
  display:none;
}
.sw-expandable-title:hover {
  cursor:pointer;
}
#sw-subject-areas {
  display:none;
}
#sw-years {
  display:none;
}
#sw-media {
  display:none;
}
#sw-subject-arrow-right {
  display:inline-block;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid white;
}
#sw-subject-arrow-down {
  display:none;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
}
#sw-year-arrow-right {
  display:inline-block;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid white;
}
#sw-year-arrow-down {
  display:none;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
}
#sw-media-arrow-right {
  display:inline-block;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid white;
}
#sw-media-arrow-down {
  display:none;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
}
#back2top {
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius:10px;
  position: fixed;
  bottom: 2%;
  right: 1%;
  background-color: #DDD;
  color: #555;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  animation-duration: 1s;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}
#back2top:hover {
  background-color: rgb(242, 101, 31);
  color: #000;
  cursor: pointer;
}
.image-text-container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width:60%;
  height:auto;
  background-color:rgba(45, 203, 231, 0.7);
  cursor:pointer;
}
.image-expand-container {
  display:none;
}
.external-icon {
  position:relative;
  color:rgb(230, 230, 230);
  background-color:rgb(230, 230, 230);
  width:10%;
  cursor:pointer;
  padding:20px;
}
.external-icon:hover {
  cursor:pointer;
}
#sw-external-icon {
  color:black;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  display:inline;
}
#sw-external-icon:hover {
  color:rgba(242, 101, 31, .8);
}
.sw-image-text {
  color: white;
  font-size: 16px;
  padding: 10px;
  margin: 5px;
  display:inline-block;
  cursor:pointer;
}
.mobile-image-description {
  display:none;
}
.video-description{
  display:flex;
  flex-direction: row;
  -ms-flex-direction: row;
  justify-content: left;
}
.video-text-container {
  background-color:rgb(230, 230, 230);
  color:rgb(230, 230, 230);
  width:90%;
  border: 2px solid rgb(230, 230, 230);
  padding:5px;
  display: flex;
  flex-direction:column;
}
.sw-video-text {
  background-color: rgb(230, 230, 230);
  display:inline;
  color:black;
  font-size:14px;
  position:relative;
  text-align:left;
  margin:0;
  left:5px;
}
.sw-audio-text {
  display:none;
}
/* STUDENT WORK MOBILE BEGIN */
#student-work-mobile {
  display: none;
}
.sw-mobile-text {
  display:none;
}
#sw-mobile-search {
  width:100%;
}
.sw-searchbar-mobile {
  width:78%;
  height:82%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  margin-right:1%;
}
.sw-search-mobile {
  color:black;
  margin-top:5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  width:16%;
  height:100%;
  text-align: center;
  background-color: white;
}
@media screen and (max-width: 700px) {
  #student-work-container {
    display:none
  }
  #student-work-mobile {
    display:inline-block;
    align-content: center;
  }
  .sw-image-text {
    background-color: rgb(230, 230, 230);
    display: inline;
    color: black;
    font-size: 14px;
    position: relative;
    text-align: left;
    margin: 0;
    left: 5px;
  }
  .student-work-posts {
    width:99%;
    padding: 10px 0px 10px 0px;
    background-color: rgb(230, 230, 230);
    border: 3px solid rgb(230, 230, 230);
    align-content: center;
  }
  .student-work-videos {
    width:99%;
    height:250px;
    padding: 10px 0px 20px 0px;
    background-color: rgb(230, 230, 230);
  }
  .student-work-audio {
    width:99%;
    height:200px;
    border: 3px solid rgb(230, 230, 230);
    padding: 10px 0px 10px 0px;
    background-color: rgb(230, 230, 230);
  }
  .sw-post-column {
    text-align: center;
    align-content: left;
    padding-bottom:20px;
    margin-bottom: 20px;
  }
  .sw-individ-posts {
    align-content: center;
  }
  .sw-column-posts {
    align-content:center;
  }
  .image-description {
    /* width:100%;
    margin-top:7px;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: left; */
    display:none;
  }
  .image-text-container {
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    position: relative;
    opacity:1;
    background-color: rgb(230, 230, 230);
    color:lightgray;
    width:90%;
    border: 2px solid rgb(230, 230, 230);
    padding:5px;
    transform: translate(0,0);
    -ms-transform: translate(0,0);
    text-align: center;
    top:0;
    left:0;
  }
  .image-expand-container {
    display:block;
    position:relative;
    color:rgb(230, 230, 230);
    background-color:rgb(230, 230, 230);
    border: 1px solid rgb(230, 230, 230);
    width:30%;
    cursor:pointer;
    padding:30px;
    padding-top:15px;
  }
  .image-expand-container:hover {
    cursor:pointer;
  }
  #image-expand {
    color:black;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    display:inline;
  }
  #image-expand:hover {
    color:rgba(242, 101, 31, .8);
  }

  .mobile-image-description{
    display:flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: left;
  }

  .mobile-image-text-container{
    background-color:rgb(230, 230, 230);
    color:rgb(230, 230, 230);
    width:90%;
    border: 2px solid rgb(230, 230, 230);
    padding:5px;
    display: flex;
    flex-direction:column;
  }
}
/* DASHBOARD STUDENT WORK */
.dashboard-student-work {
  width: 70%;
}
.studentWorkListHolder {
  border: 2px solid rgb(200, 200, 200);
  padding: 7px 10px 10px 10px;
  margin: 3px;
  text-align: left;
}
/* STUDENT WORK END */

/*////////////////////////////////////////*/
/*////////////////////////////////////////*/

/* INDIVIDUAL STUDENT WORK BEGIN */
.individ-work {
  width:50%;
  margin-top:5%;
  margin-bottom:15%;
  margin-left:3%;
}
.individ-video {
  width:50%;
  margin-top:5%;
  margin-bottom:15%;
  margin-left:3%;
}
.individ-audio {
  width:50%;
  margin-top:5%;
  margin-bottom:15%;
  margin-left:3%;
}
.individ-work-details{
  width:45%;
  text-align: left;
  margin-top: 5%;
  padding-left: 20px;
}
.individ-work-media{
  border:1px solid lightslategrey;
  width:90%;
}
/* INDIVIDUAL STUDENT WORK END */


// global styles for ShareThis
.st-btns div.st-btn {
  display: none !important;
}

.st-btns div.st-btn[data-network="facebook"],
.st-btns div.st-btn[data-network="linkedin"],
.st-btns div.st-btn[data-network="twitter"],
.st-btns div.st-btn[data-network="email"],
.st-btns div.st-btn[data-network="copy"] {
  display: inline-block !important;
}



#homeRedesign2023-Resources-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    padding-top: 50px;
  }
  .homeRedesign2023-Resources-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 30px;
  }
  .homeRedesign2023-Resource-boldText {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  }
  .homeRedesign2023-Resources-bars-desktop {
    width: 40%;
  }
  .homeRedesign2023-Resources-bars-desktop > h3 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-style: italic;
    font-size: 17px;
  }
  .homeRedesign2023-Resources-allBars {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 20px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  .homeRedesign2023-Resources-eachBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #007A8A;
    color: white;
  }
  .homeRedesign2023-Resources-eachBar :nth-child(2) {
    font-size: xx-large;
    line-height: 0;
  }
  .homeRedesign2023-Resources-text-box {
    width: 60%;
    padding-right: 50px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-align: left;
  }
  .homeRedesign2023-Resources-text-intro {
    height: 80%;
  }
  .homeRedesign2023-Resources-text-intro p {
    font-size: xx-large;
    padding-right: 30px;
  }
  .homeRedesign2023-Resources-text-explore-desktop a {
    display: flex;
    flex-direction: row;
    justify-content: start;
    height: 20%;
    align-items: center;
    color: #CA4A1C;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    text-decoration: none;
  }
  .homeRedesign2023-Resources-text-explore-desktop :nth-child(2) {
    padding-left: 5px;
    font-size: xx-large;
    line-height: 0;
  }
  .homeRedesign2023-Resources-bars-mobile {
    display: none;
  }
  .homeRedesign2023-Resources-text-explore-mobile {
    display: none;
  }
  .homeRedesign2023-Resources-eachBar-mobile {
    display: none;
  }

  @media only screen and (max-width: 1350px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 1024px;
      margin-top: 30px;
    }
  }
  
  @media only screen and (max-width: 1150px) {

    #homeRedesign2023-Resources-container{
      width: 100%;
      padding-top: 0;
      }
    .homeRedesign2023-Resources-content-container {
      max-height: 10000px;
      margin-top: 0px;
      margin-bottom: 0px;
      flex-direction: column;
    }   
    .homeRedesign2023-Resources-bars-desktop {
      display: none;
    }
    .homeRedesign2023-Resources-text-explore-desktop {
      display: none;
    }
    .homeRedesign2023-Resources-bars-mobile {
      background-color: #007A8A;
      display: flex;
      width: 100vw;
    }
    .homeRedesign2023-Resources-allBars {
      width: 100%;
      padding-bottom: 25px;
      justify-content: center;
    }
    .homeRedesign2023-Resources-allBars a {
      text-decoration: none;
    }
    .homeRedesign2023-Resources-eachBar {
      height: 100%;
      width: 100%;
      max-width: 80vw;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      background-color: white;
      color: #007A8A;
    }
    .homeRedesign2023-Resources-eachBar :nth-child(2) {
      font-size: x-large;
      line-height: 1;
  }
    .homeRedesign2023-Resources-eachBar p {
      padding: 0 20px;
      font-size: x-large;
    }
    .homeRedesign2023-Resources-eachBar-desktop {
      display: none;
    }
    .homeRedesign2023-Resources-eachBar-mobile {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .homeRedesign2023-Resource-star {
      padding-right: 10px;
    }
    .homeRedesign2023-Resources-text-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      padding: 0;
      background-color: white;
    }
    .homeRedesign2023-Resources-text-intro {
      width: 80vw;
      text-align: center;
    }
    .homeRedesign2023-Resources-text-intro p {
      padding-right: 0;
  }
    .homeRedesign2023-Resources-text-explore-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: black solid 1px;
      width: 70%;
    }
    .homeRedesign2023-Resources-text-explore-mobile-links {
      text-align: center;
      color: #BE5127;
    }
    .homeRedesign2023-Resources-text-explore-mobile-links a {
      text-decoration: none;
      color: #BE5127;
    }
}    
  
  @media only screen and (max-width: 700px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 400px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 375px;
    }
  }
  
  @media only screen and (max-width: 460px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 300px;
    }
    .homeRedesign2023-Resource-overflowText {
      display: none;
    }
  }
  
  @media only screen and (max-width: 380px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 280px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .homeRedesign2023-Resources-content-container {
      max-width: 260px;
    }
    .homeRedesign2023-Resource-star {
      display: none;
    }
  }
#annualReport2021programs-highlights-container {
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.annualReport2021programs-highlights-content-container {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 3vw 0 8vw;
    padding: 80px 0 200px;
}

.annualReport2021programs-highlights-content-container > img {
    width: 35vw;
    height: 100%;
}

.annualReport2021programs-highlights-text-container {
   padding: 15% 0;
    width: 55%; 
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021programs-highlights-text-container > h1 {
    color: #d14e1d;
    text-align: left;
    font-size: calc(20px + 2vw);
    margin-bottom: 0;
}

.annualReport2021programs-highlights-text-container > h2 {
    color: #007a8a;
    text-align: left;
    font-size: calc(18px + 2vw);
    margin-top: 0;
}

.annualReport2021programs-highlights-text-container > p {
    color: #00464f;
    text-align: justify;
    font-size: calc(10px + 1vw);
}

@media only screen and (max-width: 1000px) {
    
    .annualReport2021programs-highlights-content-container {
        flex-direction: row;
        align-items:flex-start;
        justify-content: center;
        margin: 0 3vw 0 8vw;
        padding: 100px 0 200px;
    }

    .annualReport2021programs-highlights-content-container > img {
        margin-left: 2%;
    }    
    
    .annualReport2021programs-highlights-text-container > h1 {
        text-align: center;
    }

    .annualReport2021programs-highlights-text-container > h2 {
        padding-top: 20px;
        text-align: center;
    }
    
    .annualReport2021programs-highlights-text-container > p {
        width: 160%;
        padding-top: 20px;
    }
         
}

@media only screen and (max-width: 800px) {
    .annualReport2021programs-highlights-content-container {
        flex-direction: column;
        align-items:center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .annualReport2021programs-highlights-text-container {
        padding: 7vw 0;
        width: 80%; 
     }
     
    .annualReport2021programs-highlights-text-container > h1 {
        margin-top: 40%;
    }
    
    .annualReport2021programs-highlights-text-container > p {
        width: 100%;
        padding-top: 20px;
    }
    
    .annualReport2021programs-highlights-content-container > img {
        margin-left: 0;
        padding-bottom: 5%;
    }    
}
@media only screen and (max-width: 500px) {
    #annualReport2021programs-highlights-container {
        background-size: 150vw 25%;
        background-position: top;
        background-repeat: no-repeat;
        padding-top: 10%;
    }

    .annualReport2021programs-highlights-text-container > h1 {
        margin-top: 0;
    }

    .annualReport2021programs-highlights-text-container > h2 {
        padding-bottom: 10%;
    }
    
}
.researchRedesign-content-container {
    display: block;
    width: 100%;
}

#researchRedesign-Slideshow-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.researchRedesign-Slideshow-content-container {
    display: flex;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
}

.researchRedesign-Slideshow-slider {
    width: 100vw;
    height: 100%;
}

.researchRedesign-Slideshow-slider ul {
    background-color: #00464e;
    height: 50px;
}

.researchRedesign-Slideshow-slider ul > li > button::before {
    color: white;
    font-size: 20px;
}

.researchRedesign-Slideshow-slider ul li.slick-active > button::before {
    color: #d14e1d;
}


.researchRedesign-Slideshow-slider > div > div > div > div > * {
    padding: 0 50px;
}

.researchRedesign-Slideshow-card {
    height: 450px;
    width: 100%;
    background-color: #eee9d6;
    border-radius: 30px;
    margin-bottom: 50px;
}

.researchRedesign-Slideshow-card-content-container {
    display: flex;
    flex-direction: row-reverse;
}

.researchRedesign-Slideshow-card-image {
    min-width: fit-content;
}

.researchRedesign-Slideshow-card-image > img{
    height:450px;
    width: 450px;
    border-radius: 30px;
}

.researchRedesign-Slideshow-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 30px;
}

.researchRedesign-Slideshow-text > h2 {
    color: #00464e;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
}

.researchRedesign-Slideshow-text > p {
    color: black;
    text-align: left;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
}

.researchRedesign-Slideshow-text > a {
    color: #d14e1d;
}

@media only screen and (max-width: 1500px) {
    .researchRedesign-Slideshow-card {
        height: 400px;
    }
    
    .researchRedesign-Slideshow-card-image > img{
        height:400px;
        width: 400px;
    }
}

@media only screen and (max-width: 1400px) {
    .researchRedesign-Slideshow-card-image {
        display: flex;
        align-items: center;
    }
    
    .researchRedesign-Slideshow-card-image > img{
        height:calc(25vw + 25px);
        width: calc(25vw + 25px);
    }
}

@media only screen and (max-width: 1250px) {
    .researchRedesign-Slideshow-card {
        height: 425px;
    }

}

@media only screen and (max-width: 1150px) {
    .researchRedesign-Slideshow-slider {
        width: 80vw;
    }

    .researchRedesign-Slideshow-card {
        margin-bottom: 0;
    }

    .researchRedesign-Slideshow-slider ul {
        width: 100vw;
        position: relative;
        left: -13%;
    }    

    .researchRedesign-Slideshow-slider button {
        top: 100%;
        z-index: 1;
    }    

    .researchRedesign-Slideshow-slider button::before {
        color: white
    }    

}

@media only screen and (max-width: 1000px) {
    .researchRedesign-Slideshow-slider {
        width: 100vw;
    }

    .researchRedesign-Slideshow-card {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 600px;
    }    

    .researchRedesign-Slideshow-card-content-container {
        flex-direction: column;
        align-content: center;
    }

    .researchRedesign-Slideshow-card-image {
        justify-content: center;
    }

    .researchRedesign-Slideshow-text {
        align-items: center;
        padding: 15px 50px;
    }    
    
    .researchRedesign-Slideshow-slider ul {
        left: 0;
    }    

}

@media only screen and (max-width: 800px) {
    .researchRedesign-Slideshow-card {
        height: 700px;
    }    

}

@media only screen and (max-width: 675px) {
    .researchRedesign-Slideshow-slider {
        width: 80vw;
    }

    .researchRedesign-Slideshow-slider ul {
        width: 100vw;
        position: relative;
        left: -13%;
    }    

}

@media only screen and (max-width: 600px) {
    .researchRedesign-Slideshow-card {
        height: 400px;
    }    

    .researchRedesign-Slideshow-text p {
        display: none;
    }

}

@media only screen and (max-width: 450px) {
    .researchRedesign-Slideshow-card {
        height: 350px;
    }    

    .researchRedesign-Slideshow-text > h2 {
        font-size: larger;
    }
    
}
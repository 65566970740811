.newEquityAccess-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #F9F9F9;
  }
  .newEquityAccess-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin: 40px 0 40px 0;
    padding: 0;
  }
  .newEquityAccess-content-container > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 26px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0;
  }
.newEquityAccess-info-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    margin: 10px 0 10px 0;
    padding: 0;
}
.newEquityAccess-info-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin: 50px;
    padding: 0; 
}
.newEquityAccess-info-text > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(82, 82, 82);
    font-size: 19px;
    text-align: left;
    line-height: 35px;
    margin-top: 0px;
    margin-bottom: 40px;
}
.newEquityAccess-info-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 50px;
  padding: 0;
}
.newEquityAccess-info-image > img {
  width: 100%;
}
.newEquityAccess-info-image > h3 {
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #111111;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.newEquityAccess-info-image > p {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(82, 82, 82);
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.newEquityAccess-link {
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
  }

  .newEquityAccess-link:hover {
    color: #d14e1d;
  }


  @media only screen and (max-width: 1094px) {
    .newEquityAccess-info-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1100px;
        margin: 10px 0 10px 0;
        padding: 0;
    }
    .newEquityAccess-info-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        text-align: left;
        margin: 20px 0 20px 0;
        padding: 0; 
    }
    .newEquityAccess-info-text > p {
        font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
        color: rgb(82, 82, 82);
        font-size: 19px;
        text-align: left;
        line-height: 30px;
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .newEquityAccess-info-image {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      text-align: left;
      margin: 30px 0 20px 0;
      padding: 0;
    }
    .newEquityAccess-info-image > img {
      width: 100%;
    }
    .newEquityAccess-info-image > h3 {
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 15px;
      color: #111111;
      letter-spacing: 1px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    .newEquityAccess-info-image > p {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
        color: rgb(82, 82, 82);
        font-size: 16px;
        text-align: left;
        line-height: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    
    .newEquityAccess-link {
        font-family: Helvetica Neue Medium;
        font-size: 16px;
        color: #111111;
        text-decoration: none;
      }
    
      .newEquityAccess-link:hover {
        color: #d14e1d;
      }
  }
.audienceArchive {
    .featuredOpportunity {
        background: $bg-light;
        color: #000;
        padding: 160px 32px 20px;
        @include large-desktop {
            padding: 160px 120px 80px;
        }
        @include small-mobile {
            max-width: 100%;
        }
        @include mobile-full{
            padding-top: 20px;
        }
        h2.text-xl_bold{
            @include mobile-full{
                font-size: 30px;
            }
        }
        h3 {
            margin-bottom: 40px;
        }
        .button {
            margin: 60px auto 0 auto;
        }
    }
    .stategicPriorities {
        background: #FFF;
        text-align: left;
    }
    .featuredBlog {
        background: #FFF;
        padding: 80px 120px;
        @include mobile-full{
            padding: 40px 32px;
        }
        h2.text-xl_bold{
            @include mobile-full{
                margin: 10px 0 20px;
                font-size: 30px;
            }
        }
    }
    .pressReleases {
        background: $bg-color;
        padding: 80px 120px;
        h2 {
            color: #FFF;
            margin-bottom: 40px;
        }
        .button {
            margin: 60px auto 0 auto;
        }
    }
    .newsletterNewSplit {
        background: $bg-light;
        padding: 80px 120px 150px 120px;
        .newsletterBlock,
        .newsBlock {
            div {
                height: 100%;
                padding: 20px;
                background: #FFF;
                ul {
                    list-style: none;
                    padding: 0;
                    a {
                        color: $teal;
                        padding: 10px;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                        h3 {
                            color: $teal;
                            margin: 0;
                        }
                    }
                }
                .button {
                    margin: 60px auto 0 auto;
                }
            }
        }
        .newsBlock {
            div {
                ul {
                    a {
                        position: relative;
                        &::after {
                            background: #000;
                            content: '';
                            position: absolute;
                            width: 100px;
                            height: 1px;
                            bottom: 10px;
                            left: -40px;
                        }
                    }
                }
            }
        }
    }
    #hero{
        .blurb{
            @include mobile-full{
                padding: 20px 32px;
            }
        }

    }
}

#hero #heroContent.orange .blurb{padding-left: 0; padding-right: 0;}
.newsletterSignup {
    background-color: #FFFFFF;
    text-align: center;
    padding: 60px 0;
    .container {
        background-color: #E5E4E5;
        .newsletter-title {
            margin-bottom: 30px;
            @include small-mobile {
                font-size: 25px !important;
            }
        }
        .signup-link {
            background-color: #BD5534;
            color: #FFFFFF;
            text-decoration: none;
            padding: 5px 20px;
            border-radius: 10px;
            @include small-desktop {
                font-size: 20px;
            }
            @include tablet {
                font-size: 16px;
            }
            @include small-mobile {
                font-size: 13px;
            }
            @include xs-mobile {
                font-size: 10px;
                padding: 5px 10px;
            }
        }
    }
}
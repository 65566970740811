#seattleIntro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: white;
    margin-top: 100px;
  }
  .everyday-section-title-start {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    max-width: 1024px;
    width: 100%;
    height: 100%;
    max-height: 1024px;
    flex-direction: column;
    text-align: left;
    line-height: 10px;
  }
  .everyday-section-title-start > h4 {
    color: #bcc6c7;
    /* color: #d14e1d; */
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    letter-spacing: 3px;
  }
  .seattleIntro-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
  }
  @media only screen and (max-width: 800px) {
    #seattleIntro-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      background-color: white;
      margin-top: 70px;
    }
    .everyday-section-title-start {
      display: flex;
      justify-content: center;
      align-items: center;
      /* position: relative; */
      max-width: 700px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      flex-direction: column;
      text-align: left;
    }
    .everyday-section-title-start > h4 {
      color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 21px;
      letter-spacing: 3px;
    }
    .seattleIntro-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 650px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 450px) {
    #seattleIntro-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      background-color: white;
      margin-top: 50px;
    }
    .everyday-section-title-start {
      display: flex;
      justify-content: center;
      align-items: center;
      /* position: relative; */
      max-width: 350px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      flex-direction: column;
      text-align: left;
    }
    .everyday-section-title-start > h4 {
      color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 17px;
      letter-spacing: 3px;
    }
    .seattleIntro-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 350px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 374px) {
    #seattleIntro-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      background-color: white;
      margin-top: 50px;
    }
    .everyday-section-title-start {
      display: flex;
      justify-content: center;
      align-items: center;
      /* position: relative; */
      max-width: 350px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      flex-direction: column;
      text-align: left;
    }
    .everyday-section-title-start > h4 {
      color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      letter-spacing: 3px;
    }
    .seattleIntro-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 350px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 330px) {
    .everyday-section-title-start {
      display: flex;
      justify-content: center;
      align-items: center;
      /* position: relative; */
      max-width: 330px;
      width: 100%;
      height: 100%;
      max-height: 1280px;
      flex-direction: column;
      text-align: left;
    }
    .everyday-section-title-start > h4 {
      color: #bcc6c7;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      letter-spacing: 3px;
    }
    .seattleIntro-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }
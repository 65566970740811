.marineGeologist-container {
  background: #3e4449;
  height: 100vh;
  display: flex;
}
.marineGeologist-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.marineGeologist-final-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1280px;
  height: 600px;;
  margin-top: 50px;
}
.marineGeologist-illustration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 590px;
  height: 100%;
  max-height: 600px;
  line-height: 50px;
  margin-right: 75px;
}
.marineGeologist-illustration > h1 {
  font-family: 'Helvetica Neue Medium';
  color: #ffffff;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.marineGeologist-link {
  position: relative;
  width: 500px;
  height: 500px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 50%;
}
.marineGeologist-cards-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
  border-radius: 50%;
}
.marineGeologist-link:hover .marineGeologist-cards-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.marineGeologist-link > img {
  position: absolute;
  width: 500px;
  height: 500px;
  left: 0;
}
.marineGeologist-title {
  position: absolute;
  width: 500px;
  left: 0;
  top: 35%;
  font-size: 23px;
  font-family: 'Helvetica Neue Medium';
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  opacity: 0;
  z-index: 1;
  transition: top 0.5s ease;
}
.marineGeologist-link:hover .marineGeologist-title {
  top: 40%;
  opacity: 1;
}
.marineGeologist-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  max-width: 590px;
  height: 100%;
  max-height: 600px;
  margin-left: 75px;
}
.marineGeologist-description > h2 {
  font-family: 'Helvetica Neue Medium';
  color: #2fb0d3;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.marineGeologist-description > p {
  font-family: 'Helvetica Neue Light';
  font-size: 20px;
  font-weight: bolder;
  color: #f1f3f3;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 30px;
  letter-spacing: 1.4px;
}
.marineGeologist-requirements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 590px;
  height: 100%;
}
.marineGeologist-requirements > h2 {
  font-family: 'Helvetica Neue Medium';
  color: #2fb0d3;
  font-weight: lighter;
  font-size: 18px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}
.marineGeologist-iconList {
  padding-left: 0px;
}
.marineGeologist-iconList li {
  display: inline-block;
  margin: 10px 15px;
}
.marineGeologist-icon > img {
  width: 100px;
  height: 100px;
}


@media only screen and (max-width: 1500px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1280px;
    height: 600px;;
    margin-top: 50px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 590px;
    height: 100%;
    max-height: 600px;
    line-height: 50px;
    margin-right: 30px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 450px;
    height: 450px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 450px;
    height: 450px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 450px;
    left: 0;
    top: 35%;
    font-size: 23px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 40%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    max-width: 590px;
    height: 100%;
    max-height: 600px;
    margin-left: 30px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 20px;
    font-weight: bolder;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 25px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 590px;
    height: 100%;
    max-height: 250px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 10px 10px;
  }
  .marineGeologist-icon > img {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 1300px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1024px;
    height: 600px;;
    margin-top: 50px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 487px;
    height: 100%;
    max-height: 800px;
    line-height: 50px;
    margin-right: 25px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 400px;
    height: 400px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 400px;
    left: 0;
    top: 35%;
    font-size: 23px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 40%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    max-width: 487px;
    height: 100%;
    max-height: 600px;
    margin-left: 25px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    font-weight: bolder;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 25px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 590px;
    height: 100%;
    max-height: 200px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 80px;
    height: 80px;
  }
}


@media only screen and (max-width: 1150px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1024px;
    height: 600px;;
    margin-top: 50px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 800px;
    line-height: 50px;
    margin-right: 25px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 400px;
    height: 400px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 400px;
    left: 0;
    top: 35%;
    font-size: 23px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 40%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 800px;
    margin-left: 25px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    font-weight: bolder;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 25px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 200px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 80px;
    height: 80px;
  }
}


@media only screen and (max-width: 1000px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 1000px;;
    /* margin-top: 50px; */
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
    height: 100%;
    max-height: 350px;
    line-height: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 250px;
    height: 250px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 250px;
    left: 0;
    top: 30%;
    font-size: 18px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 35%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    height: 100%;
    max-height: 400px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    /* margin-bottom: 0px; */
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
    height: 100%;
    max-height: 200px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 10px 10px;
  }
  .marineGeologist-icon > img {
    width: 70px;
    height: 70px;
  }
}


@media only screen and (max-width: 750px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 2000px;;
    margin-top: 0px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 350px;
    line-height: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 250px;
    height: 250px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 250px;
    left: 0;
    top: 30%;
    font-size: 18px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 35%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 400px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 200px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 10px 10px;
  }
  .marineGeologist-icon > img {
    width: 70px;
    height: 70px;
  }
}


@media only screen and (max-width: 650px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 2000px;;
    margin-top: 0px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 350px;
    line-height: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 250px;
    height: 250px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 250px;
    left: 0;
    top: 25%;
    font-size: 17px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 30%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 400px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 200px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 7px 7px;
  }
  .marineGeologist-icon > img {
    width: 70px;
    height: 70px;
  }
}


@media only screen and (max-width: 550px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100%;
    max-height: 6000px;
    margin-top: 0px;
    margin-bottom: 85px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 330px;
    line-height: 50px;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 200px;
    left: 0;
    top: 25%;
    font-size: 17px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 30%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 400px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 200px;
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 70px;
    height: 70px;
  }
}


@media only screen and (max-width: 450px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
    padding-bottom: 50px;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    max-height: 3000px;
    margin-top: 0px;
    margin-bottom: 85px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 370px;
    line-height: 50px;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 200px;
    left: 0;
    top: 25%;
    font-size: 17px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 30%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 450px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 200px;
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 60px;
    height: 60px;
  }
}


@media only screen and (max-width: 385px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 6000px;
    margin-top: 0px;
    margin-bottom: 85px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 370px;
    line-height: 50px;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 200px;
    left: 0;
    top: 25%;
    font-size: 17px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 30%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 450px;
    margin-left: 0px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 200px;
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 60px;
    height: 60px;
  }
}



@media only screen and (max-width: 350px) {
  .marineGeologist-container {
    background: #3e4449;
    height: 100vh;
    display: flex;
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .marineGeologist-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
  }
  .marineGeologist-final-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 100%;
    max-height: 6000px;
    margin-top: 170px;
    margin-bottom: 85px;
  }
  .marineGeologist-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 370px;
    line-height: 50px;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  .marineGeologist-illustration > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .marineGeologist-link {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 50%;
  }
  .marineGeologist-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    border-radius: 50%;
  }
  .marineGeologist-link:hover .marineGeologist-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  .marineGeologist-link > img {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
  }
  .marineGeologist-title {
    position: absolute;
    width: 200px;
    left: 0;
    top: 25%;
    font-size: 17px;
    font-family: 'Helvetica Neue Medium';
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    opacity: 0;
    z-index: 1;
    transition: top 0.5s ease;
  }
  .marineGeologist-link:hover .marineGeologist-title {
    top: 30%;
    opacity: 1;
  }
  .marineGeologist-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 500px;
    margin-left: 0px;
    margin-bottom: 70px;
  }
  .marineGeologist-description > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .marineGeologist-description > p {
    font-family: 'Helvetica Neue Light';
    font-size: 17px;
    font-weight: bolder;
    text-align: center;
    color: #f1f3f3;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 1.4px;
  }
  .marineGeologist-requirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 200px;
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .marineGeologist-requirements > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #2fb0d3;
    font-weight: lighter;
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    letter-spacing: 2px;
  }
  .marineGeologist-iconList {
    padding-left: 0px;
  }
  .marineGeologist-iconList li {
    display: inline-block;
    margin: 5px 5px;
  }
  .marineGeologist-icon > img {
    width: 60px;
    height: 60px;
  }
}
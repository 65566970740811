#stemGoBabyGo-quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
  }
  .stemGoBabyGo-quote-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 50px;
      margin-bottom: 20px;
  }
  .stemGoBabyGo-quoteParagraph-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      max-width: 1024px;
      height: auto;
      background-color: #edf0ed;
      padding: 40px;
  }
  .stemGoBabyGo-quoteParagraph-content-container > p {
    font-family: 'Helvetica Neue Light';
    color: #00464f;
    text-align: left;
    font-weight: bolder;
    font-size: 17px;
    line-height: 32px;
  }
  .stemGoBabyGo-quoteParagraph-content-container > h2 {
    font-family: 'Helvetica Neue Medium';
      color: #00464f;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
  }
  .stemGoBabyGo-quote-content-container > img {
      width: auto;
      max-width: 1024px;
      height: auto;
  }

  @media only screen and (max-width: 1200px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 40px;
          margin-bottom: 10px;
      }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 800px;
          height: auto;
      }
  }

  @media only screen and (max-width: 1000px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 650px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 35px;
          margin-bottom: 0px;
      }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 650px;
          height: auto;
      }
  }

  @media only screen and (max-width: 800px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 30px;
          margin-bottom: 0px;
      }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 500px;
          height: auto;
      }
  }

  @media only screen and (max-width: 600px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 400px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 30px;
          margin-bottom: 0px;
      }
      .stemGoBabyGo-quoteParagraph-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 400px;
        height: auto;
        background-color: #edf0ed;
        padding: 30px;
    }
    .stemGoBabyGo-quoteParagraph-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #00464f;
      text-align: left;
      font-weight: bolder;
      font-size: 17px;
      line-height: 32px;
    }
    .stemGoBabyGo-quoteParagraph-content-container > h2 {
      font-family: 'Helvetica Neue Medium';
        color: #00464f;
        font-size: 22px;
        font-weight: lighter;
        text-align: center;
    }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 400px;
          height: auto;
      }
  }

  @media only screen and (max-width: 450px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 30px;
          margin-bottom: 0px;
      }
      .stemGoBabyGo-quoteParagraph-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 400px;
        height: auto;
        background-color: #edf0ed;
        padding: 25px;
    }
    .stemGoBabyGo-quoteParagraph-content-container > p {
      font-family: 'Helvetica Neue Light';
      color: #00464f;
      text-align: left;
      font-weight: bolder;
      font-size: 17px;
      line-height: 32px;
    }
    .stemGoBabyGo-quoteParagraph-content-container > h2 {
      font-family: 'Helvetica Neue Medium';
        color: #00464f;
        font-size: 20px;
        font-weight: lighter;
        text-align: center;
    }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 280px;
          height: auto;
      }
  }

  @media only screen and (max-width: 350px) {
    #stemGoBabyGo-quote-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
      }
      .stemGoBabyGo-quote-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 270px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 30px;
          margin-bottom: 0px;
      }
      .stemGoBabyGo-quote-content-container > img {
          width: auto;
          max-width: 270px;
          height: auto;
      }
  }
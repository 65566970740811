#archiveFilters {
    background: $bg-light;
    padding: 40px 0 40px 20px;
    @include mobile-full{   
        padding: 32px 0;
    }
    .container {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        @include small-mobile {
            padding-left: 20px;
            padding-right: 20px;
        }
        @include mobile {
            padding-left: 20px;
            padding-right: 20px;
        }
        @include small-desktop {
            flex-flow: row wrap;
        }
        @include desktop {
            
        }
        @include mobile-full {
            padding-left: 32px;
            padding-right: 32px;
        }
        .styledDropdown {
            background: #FFF;
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 0 20px 20px 20px;
            outline: 1px lightgray solid;
            height: fit-content;
            padding: 0;
            min-width: 215px;
            @include mobile {
                min-width: 100%;
            }
            @include small-mobile {
                min-width: 100%;
            }
            &:first-of-type {
                margin-left: 0;
                @include mobile {
                    margin-left: 20px;
                }
                @include small-mobile {
                    margin-left: 20px;
                }
            }
            h3 {
                color: $teal;
                flex-grow: 1;
                font-weight: 400;
                font-size: 20px;
                text-align: left;
            }
            i {
                color: $teal;
            }
            ul {
                list-style-type: none;
                margin-top: 0;
                li {
                    font-size: 20px;
                    padding: 5px 0;
                    text-align: left;
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        .archiveSearch {
            display: flex;
            justify-content: space-between;
            box-shadow: none !important;
            height: 100%;
            line-height: 170%;
            @include mobile {
                display: flex;
                justify-content: space-between;
                min-width: 100%;
            }
            @include small-mobile {
                min-width: 100%;
            }
            @include mobile-full{
                margin-left: 0;
                display: flex;
            }
            input {
                background: #FFF;
                color: #007A8A;
                font-size: 20px;
                padding: 21px 15px;
                line-height: 170%;
            }
            .MuiInputBase-root {
                width: 100%;
            }
            input::placeholder {
                color: #007A8A;
                opacity: 1;
                font-size: 20px;
                font-family: Helvetica;
            }
        }
    }
}   

.resourceDropDown-category-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00464f;
    text-decoration: none;
}

.resourceDropDown-category-title i {
    padding-left: 25px;
}

.resourceDropDown-category-contents, .resourceDropDown-interest-area-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.resourceDropDown-categories li {
    line-height: 2;
    color: #d14e1d;
    list-style-type: circle;
}
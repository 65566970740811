.newsMedia {
    .featuredResearch {
        background: $bg-color;
        color: #FFF;
        padding: 40px 20px;
        @include large-desktop {
            padding: 80px 120px;
        }
        @include mobile-full {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 100%;
        }
        h3 {
            margin-bottom: 40px;
        }
        .button {
            margin: 60px auto 0 auto;
        }
    }
    .featuredBlog {
        background: #FFF;
        padding: 80px 120px;
        @include mobile {
            padding: 40px 40px;
        }
        @include mobile-full {
            padding: 40px 32px;
        }
    }
    .pressReleases {
        background: $bg-color;
        padding: 80px 120px;
        @include mobile {
            padding: 40px 40px;
        }
        @include mobile-full {
            padding: 40px 32px;
        }
        h2 {
            color: #FFF;
            margin-bottom: 40px;
        }
        .button {
            margin: 60px auto 0 auto;
        }
    }
    .newsletterNewSplit {
        background: $bg-light;
        padding: 80px 120px 150px 120px;
        @include mobile {
            padding: 80px 40px 150px 40px;
        }
        @include mobile-full{
            padding: 80px 32px;
        }
        .newsletterBlock,
        .newsBlock {
            margin-bottom: 30px;
            div {
                height: 100%;
                padding: 20px;
                background: #FFF;
                ul {
                    list-style: none;
                    padding: 0;
                    a {
                        color: $teal;
                        padding: 10px;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                        h3 {
                            color: $teal;
                            margin: 0;
                        }
                    }
                }
                .button {
                    margin: 60px auto 0 auto;
                    @include mobile {
                        margin-top: 20px;
                    }
                    @include small-mobile {
                        margin-top: 20px;
                    }
                }
            }
        }
        .newsBlock {
            div {
                ul {
                    a {
                        position: relative;
                        &::after {
                            background: #000;
                            content: '';
                            position: absolute;
                            width: 100px;
                            height: 1px;
                            bottom: 10px;
                            left: -40px;
                        }
                    }
                }
            }
        }
    }
    #hero .blurb{
        @include mobile-full{
            padding: 32px;
            margin: 0;
        }
    }
}
#marineCareers-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
  
  
  /* HEADER */
  #marineCareers-header-container {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .marineCareers-header-title-container {
      display: flex;
      flex-direction: column;
      max-width: 1024px;
      width: 100%;
      height: 100%;
      max-height: 50000px;
      text-align: center;
      color: rgb(81, 98, 136);
      margin-top: 60px;
      margin-bottom: 10px;
  }
  .marineCareers-header-title-container > h1 {
    color: #d14e1d;
      font-family: 'Helvetica Neue Medium';
      font-weight: lighter;
      font-size: 18px;
      margin-bottom: 0px;
      letter-spacing: 2px;
  }
  .marineCareers-header-title-container > h2 {
      font-family: 'Helvetica Neue Medium';
        color: rgb(62, 62, 62);
        font-weight: lighter;
        font-size: 40px;
        letter-spacing: 2px;
        margin-top: 20px;
        margin-bottom: 0px;
    }
  .marineCareers-header-title-container > p {
      font-family: 'Helvetica Neue Light';
    font-size: 19px;
    color: rgb(62, 62, 62);
    text-align: left;
    margin-bottom: -10px;
    line-height: 30px;
  }
  .marineCareers-headerImage-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .marineCareers-headerImage-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 2px solid rgb(62, 62, 62);
  }
  .marineCareers-headerImage-content-container > img {
    width: 500px;
    height: 500px;
  }
  
  
  @media only screen and (max-width: 1250px) {
      #marineCareers-header-container {
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .marineCareers-header-title-container {
          display: flex;
          flex-direction: column;
          max-width: 800px;
          width: 100%;
          height: 100%;
          max-height: 50000px;
          text-align: center;
          color: rgb(81, 98, 136);
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .marineCareers-header-title-container > h1 {
        color: #d14e1d;
          font-family: 'Helvetica Neue Medium';
          font-weight: lighter;
          font-size: 18px;
          margin-bottom: 0px;
          letter-spacing: 2px;
      }
      .marineCareers-header-title-container > h2 {
          font-family: 'Helvetica Neue Medium';
            color: rgb(62, 62, 62);
            font-weight: lighter;
            font-size: 40px;
            letter-spacing: 2px;
            margin-top: 20px;
            margin-bottom: 0px;
        }
      .marineCareers-header-title-container > p {
          font-family: 'Helvetica Neue Light';
        font-size: 19px;
        color: rgb(62, 62, 62);
        text-align: left;
        margin-bottom: -10px;
        line-height: 30px;
      }
      .marineCareers-headerImage-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .marineCareers-headerImage-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 500px;
        margin-top: 50px;
        border: 2px solid rgb(62, 62, 62);
      }
      .marineCareers-headerImage-content-container > img {
        width: 500px;
        height: 500px;
      }
  }
  
  
  @media only screen and (max-width: 1000px) {
      #marineCareers-header-container {
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .marineCareers-header-title-container {
          display: flex;
          flex-direction: column;
          max-width: 600px;
          width: 100%;
          height: 100%;
          max-height: 50000px;
          text-align: center;
          color: rgb(81, 98, 136);
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .marineCareers-header-title-container > h1 {
        color: #d14e1d;
          font-family: 'Helvetica Neue Medium';
          font-weight: lighter;
          font-size: 18px;
          margin-bottom: 0px;
          letter-spacing: 2px;
      }
      .marineCareers-header-title-container > h2 {
          font-family: 'Helvetica Neue Medium';
            color: rgb(62, 62, 62);
            font-weight: lighter;
            font-size: 35px;
            letter-spacing: 2px;
            margin-top: 20px;
            margin-bottom: 0px;
        }
      .marineCareers-header-title-container > p {
          font-family: 'Helvetica Neue Light';
        font-size: 18px;
        color: rgb(62, 62, 62);
        text-align: left;
        margin-bottom: -10px;
        line-height: 30px;
      }
      .marineCareers-headerImage-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .marineCareers-headerImage-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 500px;
        margin-top: 50px;
        border: 2px solid rgb(62, 62, 62);
      }
      .marineCareers-headerImage-content-container > img {
        width: 500px;
        height: 500px;
      }
  }
  
  @media only screen and (max-width: 700px) {
      #marineCareers-header-container {
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .marineCareers-header-title-container {
          display: flex;
          flex-direction: column;
          max-width: 450px;
          width: 100%;
          height: 100%;
          max-height: 50000px;
          text-align: center;
          color: rgb(81, 98, 136);
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .marineCareers-header-title-container > h1 {
        color: #d14e1d;
          font-family: 'Helvetica Neue Medium';
          font-weight: lighter;
          font-size: 18px;
          margin-bottom: 0px;
          letter-spacing: 2px;
      }
      .marineCareers-header-title-container > h2 {
          font-family: 'Helvetica Neue Medium';
            color: rgb(62, 62, 62);
            font-weight: lighter;
            font-size: 35px;
            letter-spacing: 2px;
            margin-top: 20px;
            margin-bottom: 0px;
        }
      .marineCareers-header-title-container > p {
          font-family: 'Helvetica Neue Light';
        font-size: 18px;
        color: rgb(62, 62, 62);
        text-align: left;
        margin-bottom: -10px;
        line-height: 30px;
      }
      .marineCareers-headerImage-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .marineCareers-headerImage-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 450px;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid rgb(62, 62, 62);
      }
      .marineCareers-headerImage-content-container > img {
        width: 450px;
        height: 450px;
      }
  }
  
  @media only screen and (max-width: 500px) {
      #marineCareers-header-container {
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .marineCareers-header-title-container {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          width: 100%;
          height: 100%;
          max-height: 50000px;
          text-align: center;
          color: rgb(81, 98, 136);
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .marineCareers-header-title-container > h1 {
        color: #d14e1d;
          font-family: 'Helvetica Neue Medium';
          font-weight: lighter;
          font-size: 17px;
          margin-bottom: 0px;
          letter-spacing: 2px;
      }
      .marineCareers-header-title-container > h2 {
          font-family: 'Helvetica Neue Medium';
            color: rgb(62, 62, 62);
            font-weight: lighter;
            font-size: 30px;
            letter-spacing: 2px;
            margin-top: 20px;
            margin-bottom: 0px;
        }
      .marineCareers-header-title-container > p {
          font-family: 'Helvetica Neue Light';
        font-size: 17px;
        color: rgb(62, 62, 62);
        text-align: left;
        margin-bottom: -10px;
        line-height: 30px;
      }
      .marineCareers-headerImage-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .marineCareers-headerImage-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid rgb(62, 62, 62);
      }
      .marineCareers-headerImage-content-container > img {
        width: 300px;
        height: 300px;
      }
  }
  
  @media only screen and (max-width: 350px) {
      #marineCareers-header-container {
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .marineCareers-header-title-container {
          display: flex;
          flex-direction: column;
          max-width: 280px;
          width: 100%;
          height: 100%;
          max-height: 50000px;
          text-align: center;
          color: rgb(81, 98, 136);
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .marineCareers-header-title-container > h1 {
        color: #d14e1d;
          font-family: 'Helvetica Neue Medium';
          font-weight: lighter;
          font-size: 17px;
          margin-bottom: 0px;
          letter-spacing: 2px;
      }
      .marineCareers-header-title-container > h2 {
          font-family: 'Helvetica Neue Medium';
            color: rgb(62, 62, 62);
            font-weight: lighter;
            font-size: 30px;
            letter-spacing: 2px;
            margin-top: 20px;
            margin-bottom: 0px;
        }
      .marineCareers-header-title-container > p {
          font-family: 'Helvetica Neue Light';
        font-size: 17px;
        color: rgb(62, 62, 62);
        text-align: left;
        margin-bottom: -10px;
        line-height: 30px;
      }
      .marineCareers-headerImage-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .marineCareers-headerImage-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid rgb(62, 62, 62);
      }
      .marineCareers-headerImage-content-container > img {
        width: 280px;
        height: 280px;
      }
  }
  
  
  /* MAIN BODY CONTENT */
  #marineCareers-main-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 10%;
  }
  
  @media only screen and (max-width: 700px) {
      #marineCareers-main-content-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        left: 0;
        top: 2%;
      }
    }
    
    @media only screen and (max-width: 500px) {
      #marineCareers-main-content-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        left: 0;
        top: 2%;
      }
    }
  
  /* MAIN REVIEW CONTENT */
  #marineCareers-review-container {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .marineCareers-review-content-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 50000px;
      background-color: #ffffff;  
      margin-top: 10px;
      margin-bottom: 50px;
  }
  .marineCareers-interview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  .marineCareers-interview > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-weight: lighter;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .marineCareers-interview > p {
    font-family: 'Helvetica Neue Light';
    font-size: 19px;
    color: rgb(62, 62, 62);
    text-align: left;
    line-height: 30px;
    margin-top: 5px;
  }
  .vrBlog-review-description-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 2000px;
    background-color: #ffffff;
  }
  .vrBlog-review-description-container > h4 {
    color: #1a7f4f;
    font-family: 'Helvetica Neue Medium';
    font-weight: lighter;
    font-size: 17px;
    letter-spacing: 1px;
    margin-right: 20px;
  }
  .vrBlog-review-blurb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 2000px;
    background-color: #ffffff;
  }
  .vrBlog-review-blurb > p {
    font-family: 'Helvetica Neue Light';
    font-size: 19px;
    color: rgb(62, 62, 62);
    text-align: left;
    line-height: 30px;
    margin-top: 5px;
  }
  
  @media only screen and (max-width: 1250px) {
    #marineCareers-review-container {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .marineCareers-review-content-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 50000px;
        background-color: #ffffff;  
        margin-top: 10px;
        margin-bottom: 50px;
    }
    .marineCareers-interview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
    .marineCareers-interview > h2 {
      color: #d14e1d;
      font-family: 'Helvetica Neue Medium';
      font-weight: lighter;
      font-size: 20px;
      letter-spacing: 1px;
    }
    .marineCareers-interview > p {
      font-family: 'Helvetica Neue Light';
      font-size: 19px;
      color: rgb(62, 62, 62);
      text-align: left;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    #marineCareers-review-container {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .marineCareers-review-content-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 50000px;
        background-color: #ffffff;  
        margin-top: 10px;
        margin-bottom: 50px;
    }
    .marineCareers-interview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
    .marineCareers-interview > h2 {
      color: #d14e1d;
      font-family: 'Helvetica Neue Medium';
      font-weight: lighter;
      font-size: 20px;
      letter-spacing: 1px;
    }
    .marineCareers-interview > p {
      font-family: 'Helvetica Neue Light';
      font-size: 19px;
      color: rgb(62, 62, 62);
      text-align: left;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #marineCareers-review-container {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .marineCareers-review-content-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 50000px;
        background-color: #ffffff;  
        margin-top: 10px;
        margin-bottom: 50px;
    }
    .marineCareers-interview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
    .marineCareers-interview > h2 {
      color: #d14e1d;
      font-family: 'Helvetica Neue Medium';
      font-weight: lighter;
      font-size: 18px;
      letter-spacing: 1px;
    }
    .marineCareers-interview > p {
      font-family: 'Helvetica Neue Light';
      font-size: 19px;
      color: rgb(62, 62, 62);
      text-align: left;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #marineCareers-review-container {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .marineCareers-review-content-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 50000px;
        background-color: #ffffff;  
        margin-top: 10px;
        margin-bottom: 50px;
    }
    .marineCareers-interview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
    .marineCareers-interview > h2 {
      color: #d14e1d;
      font-family: 'Helvetica Neue Medium';
      font-weight: lighter;
      font-size: 18px;
      letter-spacing: 1px;
    }
    .marineCareers-interview > p {
      font-family: 'Helvetica Neue Light';
      font-size: 19px;
      color: rgb(62, 62, 62);
      text-align: left;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  

/* LEARNING MORE */
#marineCareersLearning-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.marineCareersLearning-content-container {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 1280px;
height: 100%;
max-height: 100000px;
top: 0;
left: 0;
margin-top: 50px;
}
.marineCareersLearning-content-container > h2 {
font-family: 'Helvetica Neue Medium';
  color: rgb(62, 62, 62);
  font-weight: lighter;
  font-size: 25px;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.marineCareersLearning-list {
  background-color: #ffffff;
  text-align: center;
  margin-right: 60px;
  width: 100%;
  max-width: 1280px;
 }
.marineCareersLearning-list > li {
   display: inline-block;
   margin: 10px 10px;
}
.marineCareersLearning-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 350px) {
  .marineCareersLearning-list {
    background-color: #ffffff;
    text-align: center;
    margin-right: 40px;
    width: 100%;
    max-width: 1280px;
   }
  .marineCareersLearning-list > li {
     display: inline-block;
     margin: 10px 10px;
  }
}

/* GARY GREENE */
.marineCareersLearning-card-content-container-gary {
  display: flex;
  justify-content: center;
  width: 280px;
  height: 170px;
  background-color: #f2f1f0;
  padding: 10px;
}
.marineCareersLearning-card-content-container-gary > p {
  font-family: 'Helvetica Neue Light';
  font-size: 21px;
  color: rgb(62, 62, 62);
  line-height: 30px;
}
.marineCareersLearning-card-content-container-gary > p > a {
  text-decoration: none;
  color: #d14e1d;
}
.marineCareersLearning-card-content-container-gary > p > a:hover {
  text-decoration: none;
  color: #00464f;
}

@media only screen and (max-width: 350px) {
  .marineCareersLearning-card-content-container-gary {
    display: flex;
    justify-content: center;
    width: 260px;
    height: 170px;
    background-color: #f2f1f0;
    padding: 10px;
  }
  .marineCareersLearning-card-content-container-gary > p {
    font-family: 'Helvetica Neue Light';
    font-size: 21px;
    color: rgb(62, 62, 62);
    line-height: 30px;
  }
  .marineCareersLearning-card-content-container-gary > p > a {
    text-decoration: none;
    color: #d14e1d;
  }
  .marineCareersLearning-card-content-container-gary > p > a:hover {
    text-decoration: none;
    color: #00464f;
  }
}

/* BACK BUTTON */
.marineCareers-backButton {
  display: flex;
  justify-content: center;
  align-items: center;
   width: 345px;
   height: 75px;
   text-decoration: none;
   font-family: 'Helvetica Neue Medium';
   font-size: 17px;
   font-weight: lighter;
   letter-spacing: 2px;
   color: #ffffff;
   background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Button+3.jpg');
   background-position: center center;
   background-size: cover;
   border: 2px solid rgb(62, 62, 62);
   margin-top: 20px;
 }
 .marineCareers-backButton:hover {
  display: flex;
  justify-content: center;
  align-items: center;
   width: 345px;
   height: 75px;
   text-decoration: none;
   font-family: 'Helvetica Neue Medium';
   font-size: 17px;
   font-weight: lighter;
   letter-spacing: 2px;
   color: #ffffff;
   background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Button+3.jpg');
   background-position: center center;
   background-size: cover;
   border: 2px solid #d14e1d;
   margin-top: 20px;
 }
 .marineCareers-backButton > p {
   margin-top: 20px;
 }


 @media only screen and (max-width: 1000px) {
  .marineCareers-backButton {
    display: flex;
    justify-content: center;
    align-items: center;
     width: 230px;
     height: 50px;
     text-decoration: none;
     font-family: 'Helvetica Neue Medium';
     font-size: 17px;
     font-weight: lighter;
     letter-spacing: 2px;
     color: #ffffff;
     background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Button+3.jpg');
     background-position: center center;
     background-size: cover;
     border: 2px solid rgb(62, 62, 62);
     margin-top: 20px;
   }
   .marineCareers-backButton:hover {
    display: flex;
    justify-content: center;
    align-items: center;
     width: 230px;
     height: 50px;
     text-decoration: none;
     font-family: 'Helvetica Neue Medium';
     font-size: 17px;
     font-weight: lighter;
     letter-spacing: 2px;
     color: #ffffff;
     background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Button+3.jpg');
     background-position: center center;
     background-size: cover;
     border: 2px solid #d14e1d;
     margin-top: 20px;
   }
   .marineCareers-backButton > p {
     margin-top: 20px;
   }
 }
#annualReport2021landing-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 70%;
}

@media only screen and (max-width: 700px) {
    #annualReport2021landing-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #annualReport2021landing-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
    }
  }



  /* #annualReport2021landing-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 69vw;
}

@media only screen and (max-width: 700px) {
    #annualReport2021landing-content-container {
        top: 60vw;
    }    
  } */
  
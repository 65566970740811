.marineScienceCareersHeader {
  background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Header+3.jpg');
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
  text-align:center;
  padding: 65px 0;
  height: 50vh;
  width: 100%;
  left: 0;
}
.marineScienceCareersHeader-container {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  /* min-width: 50%; */
  padding: 30px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 35%;
  width: 100%;
  max-width: 600px;
}
.marineScienceCareersHeader-container > h1 {
  font-size: 65px;
  font-family: 'Helvetica Neue Light';
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .marineScienceCareersHeader {
    background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Header+3.jpg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    text-align:center;
    padding: 65px 0;
    height: 50vh;
    width: 100%;
    left: 0;
  }
  .marineScienceCareersHeader-container {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    min-width: 50%;
    padding: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 35%;
    width: 100%;
    max-width: 450px;
  }
  .marineScienceCareersHeader-container > h1 {
    font-size: 45px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .marineScienceCareersHeader {
    background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Header+3.jpg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    text-align:center;
    padding: 65px 0;
    height: 35vh;
    width: 100%;
    left: 0;
  }
  .marineScienceCareersHeader-container {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    min-width: 50%;
    padding: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 28%;
    width: 100%;
    max-width: 300px;
  }
  .marineScienceCareersHeader-container > h1 {
    font-size: 35px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }
}

@media only screen and (max-width: 376px) {
    .marineScienceCareersHeader {
      background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Header+3.jpg');
      background-position: center center;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.2);
      background-blend-mode: multiply;
      text-align:center;
      padding: 65px 0;
      height: 35vh;
      width: 100%;
      left: 0;
    }
    .marineScienceCareersHeader-container {
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 28%;
      width: 100%;
      max-width: 280px;
    }
    .marineScienceCareersHeader-container > h1 {
      font-size: 30px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
    }
}

@media only screen and (max-width: 350px) {
  .marineScienceCareersHeader {
    background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/Marine+Science+Careers+Header+3.jpg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    text-align:center;
    padding: 65px 0;
    height: 35vh;
    width: 100%;
    left: 0;
  }
  .marineScienceCareersHeader-container {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    min-width: 50%;
    padding: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 30%;
    width: 100%;
    max-width: 260px;
  }
  .marineScienceCareersHeader-container > h1 {
    font-size: 25px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }
}



/* #marineCareers-header-container {
    background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Collaboration/Marine+Science+Collaboration+Header.jpg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
  }
  .marineCareers-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
  }
  .marineCareers-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }
  
  @media only screen and (max-width: 1000px) {
    .marineCareers-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px;
    }
    .marineCareers-header-content-container > h1 {
        font-size: 55px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #marineCareers-header-container {
        background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Collaboration/Marine+Science+Collaboration+Header.jpg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 70%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .marineCareers-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px;
    }
    .marineCareers-header-content-container > h1 {
        font-size: 45px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #marineCareers-header-container {
        background-image: url('https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Collaboration/Marine+Science+Collaboration+Header.jpg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .marineCareers-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px;
    }
    .marineCareers-header-content-container > h1 {
        font-size: 35px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 376px) {
    .marineCareers-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px;
    }
    .marineCareers-header-content-container > h1 {
        font-size: 30px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .marineCareers-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px;
    }
    .marineCareers-header-content-container > h1 {
        font-size: 25px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  } */
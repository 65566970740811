#savvyLadies-header-container {
  background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/Financial+Education/savvyLadiesFinal.jpg');
    /*Photo by <a href="https://unsplash.com/@micheile?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Micheile Henderson</a> on <a href="https://unsplash.com/s/photos/money?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>*/
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
  display: flex;
  position: absolute;
  height: 70%;
  width: 100%;
  left: 0;
  top: 10%;
}
.savvyLadies-header-content-container{
  -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    min-width: 50%;
    padding: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 45%;
    width: 100%;
    max-width: 900px;
}
.savvyLadies-header-content-container > h1 {
  font-size: 65px;
  font-family: 'Helvetica Neue Light';
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .savvyLadies-header-content-container{
      -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        min-width: 50%;
        padding: 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        max-width: 600px;
  }
  .savvyLadies-header-content-container > h1 {
      font-size: 55px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
  }
}

@media only screen and (max-width: 700px) {
  #savvyLadies-header-container {
      background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/Financial+Education/savvyLadiesFinal.jpg');
        /*Photo by <a href="https://unsplash.com/@micheile?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Micheile Henderson</a> on <a href="https://unsplash.com/s/photos/money?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>*/
      background-position: center center;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.2);
      background-blend-mode: multiply;
      display: flex;
      position: absolute;
      height: 70%;
      width: 100%;
      left: 0;
      top: 0;
  }
  .savvyLadies-header-content-container{
      -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        min-width: 50%;
        padding: 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        max-width: 450px;
  }
  .savvyLadies-header-content-container > h1 {
      font-size: 45px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  #savvyLadies-header-container {
      background-image: url('https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/Financial+Education/savvyLadiesFinal.jpg');
        /*Photo by <a href="https://unsplash.com/@micheile?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Micheile Henderson</a> on <a href="https://unsplash.com/s/photos/money?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>*/
      background-position: center center;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.2);
      background-blend-mode: multiply;
      display: flex;
      position: absolute;
      height: 60%;
      width: 100%;
      left: 0;
      top: 0;
  }
  .savvyLadies-header-content-container{
      -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        min-width: 50%;
        padding: 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        max-width: 300px;
  }
  .savvyLadies-header-content-container > h1 {
      font-size: 35px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
  }
}

@media only screen and (max-width: 376px) {
  .savvyLadies-header-content-container{
      -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        min-width: 50%;
        padding: 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        max-width: 280px;
  }
  .savvyLadies-header-content-container > h1 {
      font-size: 30px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
  }
}

@media only screen and (max-width: 350px) {
  .savvyLadies-header-content-container{
      -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: white;
        min-width: 50%;
        padding: 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        max-width: 260px;
  }
  .savvyLadies-header-content-container > h1 {
      font-size: 25px;
      font-family: 'Helvetica Neue Light';
      text-align: center;
  }
}
.archiveCard {
    border-bottom: solid 1px #AEAEAE;
    display: flex;
    margin-bottom: 40px;
    padding-bottom: 40px;
    text-align: left;
    @include mobile {
        display: block;
    }
    @include small-mobile {
        display: block;
    }
    @include mobile-full{
        background: #fff;
        background: #fff;
        border: 0;
        padding-bottom: 10px;
    }
    .imageContainer {
        min-width: 30%;
        width: 30%;
        img {
            max-width: 100%;
            width: 100%;
        }  
        @include mobile {
            min-width: 100%;
            width: 100%;
        }
        @include small-mobile {
            min-width: 100%;
            width: 100%;
        } 
    }     
    .archiveListContent {
        padding-left: 40px;
        @include mobile {
            padding: 40px 20px 0 20px;
        }
        @include small-mobile {
            padding: 40px 20px 0 20px;
        } 
        @include mobile-full{
            padding: 40px 32px 0;
        }
        .cardCat {
            background: $teal;
            display: inline-block;
            margin-bottom: 20px;
            h5 {
                display: inline-block;
                font-family: $primary-font;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                margin: 0;
                padding: 5px 5px;
                text-transform: uppercase;
                color: #FFF;
            }
        }
        h3 {
            font-size: 25px; 
            margin-top: 0;
            a {
                text-decoration: none;
            }
        }
        .storyDate {
            color: $gray-text;
            margin: 0;
        }
    }
}

.resourceDropDown-container {
    background-color: #fff;
    color: #000;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    padding: 25px 0;
}

.resourceDropDown-container > h2 {
    font-size: xx-large;
    padding: 35px 15px;
}

.resourceDropDown-categories {
    display: grid;
    grid-template-columns: 15% 1fr 1fr 1fr 15%;
    column-gap: 15px;
}

.resourceDropDown-themes, .resourceDropDown-interest-areas, .resourceDropDown-published {
    display: flex;
    flex-direction: column;
    outline: 1px lightgray solid;
    border-radius: 15px;
    height: fit-content;
}

.resourceDropDown-themes{
    grid-column-start: 2;
}

.resourceDropDown-interest-areas{
    grid-column-start: 3;
}

.resourceDropDown-published {
    grid-column-start: 4;
}

.resourceDropDown-category-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00464f;
    text-decoration: none;
    padding: 0 15px;
}

.resourceDropDown-category-title i {
    padding-left: 25px;
}

.resourceDropDown-category-contents, .resourceDropDown-interest-area-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline-start: 0;
    padding: 0 25px;
    /* justify-content: left; */

}

.resourceDropDown-categories li {
    line-height: 2;
    list-style-type: none;
    display: inline-flex;
    width: 100%;
}

.resourceDropDown-categories li:hover {
    background-color: lightgray;
}

.resourceDropDown-categories li > a{
    text-decoration: none;
    color: #000;
    text-align: left;
    text-indent: -1em;
    padding-left: 1em;
}

.resourceDropDown-categories li > a:hover{
    color: #d14e1d;
    font-weight: 900;
}

@media only screen and (max-width: 1346px) {
    .resourceDropDown-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 15%;
    }
    
    .resourceDropDown-categories {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 300px;
    }
    
    .resourceDropDown-category-title {
        justify-content: space-between;
        text-align: left;
    }
    
}
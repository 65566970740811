#error404Page-header-container {
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/404/404-compass.jpeg);
    /* https://unsplash.com/photos/U1T240Fb_TY */
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
}

.error404Page-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
  }
  .error404Page-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }

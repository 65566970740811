#annualReport2021covid-needs-container {
    color:#00464f;
    ;
}

.annualReport2021covid-needs-content-container{
    width: 100%;
    height: fit-content;
    text-align: justify;
}

/* .annualReport2021covid-needs-banner-container{
    position: relative;
    text-align: left;
    color: #ffffff;
    height: fit-content;
    display: flex;
    align-items: center;
    padding-top: 30px;
} */

.annualReport2021covid-needs-banner-container{
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vw;
}

.annualReport2021covid-needs-banner-container > img {
    /* position: relative;
    right: 65%;
    width: 170%; */
    height: 150%;
    padding-right: 4vw;
}

.annualReport2021covid-needs-banner-container > h2 {
    /* position: absolute;
    padding: 3vw 0 0 12vw;
    font-size: calc(12px + 2vw);  */
    padding: 1vw 0 0 15vw;
    font-size: 30px; 
    color: #ffffff;
}
#annualReport2021covid-needs-banner-presenting {
    background-color:#007a8a;
}

.annualReport2021covid-needs-highlight{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.annualReport2021covid-needs-highlight > h2 {
    font-size: calc(10px + 2vw); 
    text-align: center;
    max-width: 80%;
}

.annualReport2021covid-needs-highlight img {
    max-width: 50vw;
}

.annualReport2021covid-needs-highlight > h3 {
    padding: 0 15vw 5vw;
    text-align: left;
    line-height: 30px;
}


#october21-header-container {
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/AR-Header-No-Text.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #9EB6B8;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
  }
  .october21-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      max-width: 65vw;
  }
  .october21-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    text-align: center;
  }
  
  @media only screen and (max-width: 1825px) {
    #october21-header-container {
      background-size: contain;
      height: 36vw;
    }
  }

  @media only screen and (max-width: 1000px) {
    .october21-header-content-container > h1 {
        font-size: 55px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #october21-header-container {
        top: 5%;
    }
    .october21-header-content-container{
          top: 50%;
    }
    .october21-header-content-container > h1 {
        font-size: 40px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #october21-header-container {
      padding-top: 50px;
    }
    .october21-header-content-container > h1 {
        font-size: 35px;
    }
  }
  
  @media only screen and (max-width: 376px) {
    .october21-header-content-container{
          top: 50%;
    }
    .october21-header-content-container > h1 {
        font-size: 30px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .october21-header-content-container > h1 {
        font-size: 25px;
    }
  }
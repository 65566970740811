.featuredPerson {
    background-color: $bg-light;
    text-align: center;
    padding: 60px 32px;
    .container {
        position: relative;
        background-color: $bg-light;
        padding: 0;
       
        @include large-desktop {
            margin: 40px auto;
        }
       
        .featuredPersonContent {
            margin: 0 auto;
            max-width: 100%;
            //padding: 40px 0;
            text-align: left;
            @include tablet {
                max-width: 80%;
            }
            @include small-desktop {
                max-width: 70%;
            }
            @include desktop {
                max-width: 100%;
            }
            @include large-desktop {
                //padding: 40px 100px;
                max-width: 100%;
            }
            .teamMemberTitle {
                display: flex;
                align-items: center;
                h2 {
                    margin: 0;
                }
                p {
                    color: $gray-text;
                    padding-left: 30px;
                }
            }
            a {
                text-decoration: none;
                i {
                    padding: 10px;
                }
            }
        }
    }
    img {
        display: block;
        position: relative;
        border-bottom: solid 1px #000;
        padding-bottom: 10px;
    }
}
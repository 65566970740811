/*---------------
  Typography
---------------*/
$primary-font:'Helvetica';

/*---------------
  Colors
---------------*/
$orange: #BE5127;
$orange-text: #CA4A1C;
$teal: #007A8A;
$dark-gray: #434242;
$gray: #E8E7E7;
$gray-text: #575857;
$bg-gray: #2C292D;
$bg-light: #F6F6F6;
$bg-color: #00464F;

/*---------------
  MEDIA QUERIES
---------------*/
$xsmobile-width: 380px;
$mobile-width: 480px;
$tablet-width: 769px;
$small-desktop-width: 1024px;
$desktop-width: 1280px;
$full-width: 1440px;

$mobile-full-width: 768px;
$tablet-full-width: 1100px;

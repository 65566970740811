#everydayUnseen-mission-container{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.everydayUnseen-mission-content-container{
    position: relative;
    max-width: 1024px;
    width: 100%;
    height: 100%;
    max-height: 1280px;
    text-align: left;
    color: rgb(81, 98, 136);
    margin-top: 15px;
    line-height: 35px;
}
.everydayUnseen-mission-content-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
}
.everydayUnseen-mission-content-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 30px;
    letter-spacing: 2px;
}
.everydayUnseen-mission-content-container > p {
    font-family: "Helvetica Neue Light", sans-serif;
    color: #3c4142;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1280px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 800px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 30px;
        line-height: 35px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 30px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 1000px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 650px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 30px;
        line-height: 35px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 25px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 800px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 650px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 30px;
        line-height: 35px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 20px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 13px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 500px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 20px;
        line-height: 30px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 20px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 12px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 450px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 330px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 10px;
        line-height: 25px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 18px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 374px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 300px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 10px;
        line-height: 20px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 15px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 11px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 330px) {
    .everydayUnseen-mission-content-container{
        position: relative;
        max-width: 280px;
        width: 100%;
        height: 100%;
        max-height: 1280px;
        text-align: left;
        color: rgb(81, 98, 136);
        margin-top: 10px;
        line-height: 20px;
    }
    .everydayUnseen-mission-content-container > h4 {
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        letter-spacing: 3px;
    }
    .everydayUnseen-mission-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #d14e1d;
        font-size: 15px;
        letter-spacing: 2px;
    }
    .everydayUnseen-mission-content-container > p {
        font-family: "Helvetica Neue Light", sans-serif;
        color: #3c4142;
        font-size: 11px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
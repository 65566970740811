#marineEndlessBlue-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d14e1d;
  }
  .marineEndlessBlue-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 250px;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .marineEndlessBlue-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 250px;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .marineEndlessBlue-intro-bold > h2 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 35px;
    text-align: center;
  }
  .marineEndlessBlue-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 614px;
      height: 100%;
      max-height: 250px;
      margin-left: 30px;
      margin-right: 30px;
  }
  .marineEndlessBlue-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: bolder;
    font-size: 18px;
    line-height: 40px;
  }
  
  @media only screen and (max-width: 1100px) {
    .marineEndlessBlue-intro-content-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 800px;
      height: 100%;
      max-height: 250px;
      top: 0;
      left: 0;
    }
    .marineEndlessBlue-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 250px;
      margin: 25px;
    }
    
    .marineEndlessBlue-intro-bold > h2 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 30px;
      font-weight: lighter;
      text-align: center;
    }
    .marineEndlessBlue-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 250px;
        margin: 25px;
    }
    .marineEndlessBlue-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
  @media only screen and (max-width: 850px) {
    .marineEndlessBlue-intro-content-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 500px;
      top: 0;
      left: 0;
    }
    .marineEndlessBlue-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 250px;
      margin: 25px;
    }
    
    .marineEndlessBlue-intro-bold > h2 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .marineEndlessBlue-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 350px;
        height: 100%;
        max-height: 500px;
        margin: 25px;
    }
    .marineEndlessBlue-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .marineEndlessBlue-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 1000px;
      top: 0;
      left: 0;
    }
    .marineEndlessBlue-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 1000px;
      /* margin: 20px; */
      margin-top: 15px;
      margin-bottom: 0px;
    }
    
    .marineEndlessBlue-intro-bold > h2 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .marineEndlessBlue-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 380px;
        height: 100%;
        max-height: 1000px;
        /* margin: 20px; */
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .marineEndlessBlue-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .marineEndlessBlue-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 1000px;
      top: 0;
      left: 0;
    }
    .marineEndlessBlue-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 1000px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    
    .marineEndlessBlue-intro-bold > h2 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .marineEndlessBlue-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 1000px;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .marineEndlessBlue-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 361px) {
    .marineEndlessBlue-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 1000px;
      top: 0;
      left: 0;
    }
    .marineEndlessBlue-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 1000px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    
    .marineEndlessBlue-intro-bold > h2 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .marineEndlessBlue-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 250px;
        height: 100%;
        max-height: 1000px;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .marineEndlessBlue-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
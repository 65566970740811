.areasOfResearchAccordion-section-container {
    background-color: #00464f;
}

.areasOfResearchAccordion-content-container {
    /* margin: 0 10% 50px; */
    margin: 0 10%;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}

.areasOfResearchAccordion-item {
    background-color: #f9f9f9;
    color: #007a8a;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    border: lightgray 2px solid;

}

.areasOfResearchAccordion-title {
    display: grid;
    grid-template-columns: 220px 1fr 50px;
}

.areasOfResearchAccordion-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    justify-self: flex-start;
}

.areasOfResearchAccordion-text h3{
    color: black;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.areasOfResearchAccordion-title > i{
    display: flex;
    align-items: center;
    color: #111111;
    font-size: x-large;
}

.areasOfResearchAccordion-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    border-top: lightgray 2px solid;
}

.areasOfResearchAccordion-image {
    padding: 10px 10px;
}

.areasOfResearchAccordion-image > img{
    width: 150px;
    height: 150px;
}

.areasOfResearchAccordion-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    color: black;
    height: fit-content;
    padding: 15px 0 15px 0;
}

.areasOfResearchAccordion-description > p{
    text-align: left;
    padding: 0 25px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.areasOfResearchAccordion-description > p:empty{
    display: none;
}

.areasOfResearchAccordion-descriptionTwo {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    color: black;
    height: fit-content;
    /* padding-top: 10px; */
    padding: 0 0 30px 0;
    /* border-top: lightgray 2px solid; */
}

.areasOfResearchAccordion-descriptionTwo-paragraph {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;
    padding: 0 25px;
    margin: 10px 0;
}

.areasOfResearchAccordion-descriptionTwo-paragraph > p {
    text-align: left;
    /* padding: 0 25px; */
    padding: 0 10px;
    margin: 0;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.areasOfResearchAccordion-descriptionTwo-paragraph > p:empty {
    display: none;
}

.areasOfResearchAccordion-descriptionTwo > h2, .areasOfResearchAccordion-descriptionThree > h2 {
    text-align: left;
    padding: 0 25px;
    color: #007a8a;
    font-size: large;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}

.areasOfResearchAccordion-descriptionThree {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    color: black;
    height: fit-content;
    padding-top: 10px;
    /* border-top: lightgray 2px solid; */
}

.fa-angles-right {
  color: #d14e1d;
}

@media only screen and (max-width: 1250px) {  

    .areasOfResearchAccordion-content {
        flex-direction: column;  
        align-items: center;  
    }
    
}

@media only screen and (max-width: 768px) {  
    .areasOfResearchAccordion-title {
        display: flex;
        flex-direction: column;
    }

    .areasOfResearchAccordion-image > img{
        width: 100%;
        max-width: 210px;
        height: 100%;
    }    

    .areasOfResearchAccordion-title > i{
        justify-content: center;
        font-size: xx-large;
    }    

    .areasOfResearchAccordion-text h2,
    .areasOfResearchAccordion-text h3{
        text-align: center;
    }
    
    
}


@media only screen and (max-width: 600px) {  

    .areasOfResearchAccordion-title-text {
        text-align: left;
    }    

}

#researchRedesign-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: left; */
    background-color: #007a8a;
  }
  .researchRedesign-intro-content-container {
    display: flex;
    flex-direction: column;;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .researchRedesign-intro-bold {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    /* max-width: 437px; */
    height: 100%;
    max-height: 250px;
    /* margin-left: 30px;
    margin-right: 30px; */
  }
  
  .researchRedesign-intro-bold > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    color: white;
    font-size: 25px;
    text-align: left;
    margin: 8px 0;
    /* margin: 0; */
  }
  .researchRedesign-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* max-width: 767px; */
      height: 100%;
      max-height: 250px;
      margin-left: 30px;
      margin-right: 30px;
  }
  .researchRedesign-intro-normal > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: white;
    text-align: left;
    font-weight: bolder;
    font-size: 17px;
    line-height: 30px;
    margin: 10px 0;
  }
  
  @media only screen and (max-width: 1350px) {
    .researchRedesign-intro-content-container {
      flex-direction: column;
      max-width: 1024px;
    }
    .researchRedesign-intro-bold {
      max-width: 1024px;
    }
    
    .researchRedesign-intro-bold > h2 {
      font-size: 27px;
      font-weight: lighter;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .researchRedesign-intro-normal {
        max-width: 1024px;
    }
    .researchRedesign-intro-normal > p {
      font-weight: lighter;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .researchRedesign-intro-content-container {
      max-width: 800px;
      max-height: 10000px;
    }
    .researchRedesign-intro-bold {
      max-width: 800px;
      max-height: 10000px;
    }
    
    .researchRedesign-intro-bold > h2 {
      font-size: 25px;
    }
    .researchRedesign-intro-normal {
        max-width: 800px;
        max-height: 10000px;
    }
  }
  
  
  @media only screen and (max-width: 900px) {
    .researchRedesign-intro-content-container {
      max-width: 650px;
    }
    .researchRedesign-intro-bold {
      max-width: 650px;
    }
    
    .researchRedesign-intro-normal {
        max-width: 650px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .researchRedesign-intro-content-container {
      max-width: 500px;
    }
    .researchRedesign-intro-bold {
      max-width: 500px;
    }
    
    .researchRedesign-intro-normal {
        max-width: 500px;
    }
    .researchRedesign-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  
  @media only screen and (max-width: 550px) {
    .researchRedesign-intro-content-container {
      max-width: 400px;
    }
    .researchRedesign-intro-bold {
      max-width: 400px;
    }
    
    .researchRedesign-intro-normal {
        max-width: 400px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .researchRedesign-intro-content-container {
      max-width: 330px;
    }
    .researchRedesign-intro-bold {
      max-width: 330px;
    }
    
    .researchRedesign-intro-bold > h2 {
      font-size: 20px;
    }
    .researchRedesign-intro-normal {
        max-width: 330px;
    }
  }
  
  
  @media only screen and (max-width: 380px) {
    .researchRedesign-intro-content-container {
      max-width: 305px;
    }
    .researchRedesign-intro-bold {
      max-width: 305px;
    }
    
    .researchRedesign-intro-normal {
        max-width: 305px;
    }
  }
  
  
  @media only screen and (max-width: 350px) {
    .researchRedesign-intro-content-container {
      max-width: 260px;
    }
    .researchRedesign-intro-bold {
      max-width: 260px;
    }
    
    .researchRedesign-intro-normal {
        max-width: 260px;
    }
  }
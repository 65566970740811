#newEquity-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #BE5127;
  }
  .newEquity-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;
    max-height: 250px;
    top: 0;
    left: 0;
  }
  .newEquity-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1170px;
      height: 100%;
      max-height: 250px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
  }
  .newEquity-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: bolder;
    font-size: 18px;
    line-height: 30px;
  }
  
  @media only screen and (max-width: 1350px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 250px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1024px;
        height: 100%;
        max-height: 250px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
  @media only screen and (max-width: 1048px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 512px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 512px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
  
  @media only screen and (max-width: 900px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 512px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 512px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  
  @media only screen and (max-width: 550px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 375px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 375px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  
  @media only screen and (max-width: 380px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
  
  
  @media only screen and (max-width: 350px) {
    .newEquity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 260px;
      height: 100%;
      max-height: 10000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .newEquity-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 10000px;
    }
    .newEquity-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 30px;
    }
  }
.globalFooter {
    background: #000;
    padding: 40px 80px 0 80px;
    @include mobile-full {
      padding: 14px 40px 0;
    }
    .container {
        padding: 40px 0;
        h3 {
            color: #FFF;
            font-size: 20px;
            font-weight: 400;
            margin: 10px 0 20px 0;
        }
        p {
            color: #FFF;
            font-size: 16px;
            margin: 10px 0;
        }
    }
    .copyright {
        padding: 20px 0;
        text-align: center;
        a {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}
  .globalFooter-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
    }
    
    .globalFooter-content-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      max-width: 1600px;
      height: 430px;
      max-height: 430px;
      margin-left: 50px;
      margin-top: 30px;
    }
    
    .footer-contact {
        text-align: left;
        svg {
            margin-bottom: 35px;
            width: 90%;
        }
        .footer-connect-links {
            text-align: left;
            line-height: 7px;
            margin-top: -6px;
            width: 100%;
            p {
                display: inline-block;
                margin: 10px 0;
                width: 43%;
                
                a {
                    display: flex;
                    font-size: 16px;
                    font-size: 600;
                    color: white;
                    text-align: left;
                    text-decoration: none;
                    width: 50%;
                    img {
                        padding-right: 10px;
                        width: auto;
                    }
                }
            }
        }
    }
   
    .footer-office {
      text-align: left;
    }
    .footer-connect {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 200px;
    }
    .footer-connect > h3 {
      text-align: left;
      color: white;
      font-size: 24px;
      margin-top: -3px;
      margin-bottom: 5px;
      letter-spacing: 2px;
    }
    .footer-landAcknowledgement {
      text-align: left;
      > p {
        font-size: 12px;
        text-align: left;
        line-height: 1.5em;
        margin-top: 5px;
      }
      a {
        color: #d14e1d;
      }
    }
   
    .globalFooter .copyright {
      padding: 20px 0;
    }
    .globalFooter .copyright a {
      color: #d14e1d;
      text-decoration: none;
      padding-right: 20px;
    }
#annualReport2021GamesLearning-spotlights-container {
    background-color: #C8D8DA;
    width: 100vw;
}

.annualReport2021GamesLearning-spotlights-content-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.annualReport2021GamesLearning-spotlight-first-title-bar {
    background-color:#007a8a;
    width: 100%;
}

.annualReport2021GamesLearning-spotlight-second-title-bar {
    background-color: #d14e1d;
    width: 100%;
}

.annualReport2021GamesLearning-spotlight-first-title-bar > p,
.annualReport2021GamesLearning-spotlight-second-title-bar > p {
    font-family: 'Helvetica Neue Medium';
    color: #f9f9f9;
    text-align: center;
    padding: 1.5vw 12vw 1vw;
    font-size: calc(16px + 1vw);
}

.annualReport2021GamesLearning-spotlights-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021GamesLearning-spotlights-text-container > h2 {
    color:#00464f;
    text-align: center;
    font-size: calc(12px + 1.5vw);
    margin: 0 15%;
}

.annualReport2021GamesLearning-spotlights-text-container > img {
    width: 50%; 
    margin: 3% 0;
    border: #d14e1d outset 3px;
    box-shadow: 5px 5px #007a8a;
 } 

.annualReport2021GamesLearning-spotlights-text-container > p {
    color: #00464f;
    text-align: center;
    font-size: calc(10px + 1vw);
    padding: 0 12%;
}

@media only screen and (max-width: 1000px) {
    
    .annualReport2021GamesLearning-spotlights-content-container {
        justify-content: center;
    }

    .annualReport2021GamesLearning-spotlights-content-container > img {
        margin-left: 2%;
    }    
    

    .annualReport2021GamesLearning-spotlights-text-container > h2 {
        padding-top: 20px;
    }
    
    .annualReport2021GamesLearning-spotlights-text-container > p {
        padding-top: 20px;
    }
         
}

@media only screen and (max-width: 800px) {
    .annualReport2021GamesLearning-spotlights-content-container {
        align-items:center;
        justify-content: center;
    }

    .annualReport2021GamesLearning-spotlights-text-container {
         width: 80%; 
     }
     
    
    .annualReport2021GamesLearning-spotlights-text-container > p {
        padding-top: 20px;
    }
    
    .annualReport2021GamesLearning-spotlights-content-container > img {
        margin-left: 0;
        padding-bottom: 5%;
    }    
}
@media only screen and (max-width: 500px) {

    .annualReport2021GamesLearning-spotlights-text-container > h1 {
        margin-top: 0;
    }
    
}
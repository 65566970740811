#work-culture-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.work-culture-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 250px;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-bottom: 0;
}
.work-culture-content-container > h2 {
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  color: black;
  font-size: 45px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.work-culture-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
  }

  @media only screen and (max-width: 1150px) {
  .work-culture-content-container {
      max-width: 800px;
      max-height: 350px;
    }
  }

  @media only screen and (max-width: 950px) {
    .work-culture-content-container {
        max-width: 700px;
      }
    }  

  @media only screen and (max-width: 800px) {
  .work-culture-content-container {
      max-width: 550px;
      max-height: 550px;
   }
  }

  @media only screen and (max-width: 650px) {
    .work-culture-content-container {
        max-width: 450px;
     }
    }  

  @media only screen and (max-width: 550px) {
  .work-culture-content-container {
      max-width: 400px;
      margin-top: 40px;
      margin-bottom: 0;
  }
  .work-culture-content-container > h2 {
    font-size: 35px;
  }
  .work-culture-content-container > p {
      font-size: 15px;
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 450px) {
    .work-culture-content-container {
        max-width: 370px;
      }
    }  
  @media only screen and (max-width: 400px) {
  .work-culture-content-container {
      max-width: 330px;
  }
  .work-culture-content-container > h2 {
    font-size: 30px;
  }
  .work-culture-content-container > p {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 350px) {
  .work-culture-content-container {
      max-width: 280px;
  }
  }
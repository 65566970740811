.researchRedesignLabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #F2F2F2;
  }
  .researchRedesignLabs-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin: 60px 0 50px 0;
    padding: 0;
  }
  .researchRedesignLabs-content-container > h1 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: xx-large;
    color: #111111;
    letter-spacing: 1px;
    margin: 0;
  }
  .researchRedesignLabs-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #111111;
    font-size: 19px;
    text-align: center;
    line-height: 35px;
  }
.researchRedesignLabs-info-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    margin: 0;
    padding: 0;
}
.researchRedesignLabs-info-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin: 50px;
    padding: 0; 
}
.researchRedesignLabs-info-text > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0;
}
.researchRedesignLabs-info-text > p {
  display: flex;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #111111;
    font-size: 19px;
    text-align: left;
    line-height: 35px;
    margin-top: 0px;
    margin-bottom: 40px;
}
.researchRedesignLabs-info-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 50px;
  padding: 0;
}
.researchRedesignLabs-info-image > img {
  width: 100%;
}

.researchRedesignLabs-link {
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    color: #d14e1d;
    text-decoration: none;
  }


  @media only screen and (max-width: 1094px) {
    .researchRedesignLabs-info-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1100px;
        margin: 0;
        padding: 0;
    }
    .researchRedesignLabs-info-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        text-align: left;
        margin: 20px 0 20px 0;
        padding: 0; 
    }
    .researchRedesignLabs-info-text > h2 {
        font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
        font-size: 20px;
        color: #111111;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    .researchRedesignLabs-info-text > p {
        font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
        color: #111111;
        font-size: 19px;
        text-align: left;
        line-height: 30px;
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .researchRedesignLabs-info-image {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      text-align: left;
      margin: 30px 0 20px 0;
      padding: 0;
    }
    .researchRedesignLabs-info-image > img {
      width: 100%;
    }
    
    .researchRedesignLabs-link {
        font-family: Helvetica Neue Medium;
        font-size: 16px;
        color: #d14e1d;;
        text-decoration: none;
      }
    
  }

@media only screen and (max-width: 600px) {
  .researchRedesignLabs-content-container {
    width: 80vw;
  }
  
  .researchRedesignLabs-info-container {
    width: 80vw;
  }
}
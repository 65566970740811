#annualReportResearch-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */


    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 160%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 400px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 50px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
    margin-right: 70px;
}
.annualReportResearch-image-container > img {
  width: 30vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
    margin-left: 70px;
}
.annualReportResearch-titleLine {
    width: 80%;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 75px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 150px;
    margin-bottom: 0px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportResearch-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }



@media only screen and (max-width: 1650px) {
  #annualReportResearch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 260%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 500px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-top: -100px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-image-container > img {
  width: 30vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-titleLine {
    width: 50%;
    border-bottom: solid 2px white;
    margin-top: 20px;
    margin-bottom: 20px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 75px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 130px;
    margin-bottom: -50px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportResearch-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }  
}



@media only screen and (max-width: 800px) {
  #annualReportResearch-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 490%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 450px;
    margin-bottom: 0px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 30px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-image-container > img {
  width: 40vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-titleLine {
    width: 60%;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 90px;
    margin-bottom: 0px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 20px 60px;
        }
        .annualReportResearch-cards-container {
            display: flex;
            flex-direction: column;
              margin-top: 50px;
              width: 300px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }  
}



@media only screen and (max-width: 600px) {
  #annualReportResearch-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-bottom: 0px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 30px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-image-container > img {
  width: 50vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    margin-bottom: 0px;
}
.annualReportResearch-titleLine {
    width: 60%;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 0px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
        .annualReportResearch-cards-container {
            /* position: relative; */
            display: flex;
            flex-direction: column;
            /* align-items: center;
            justify-content: center; */
              margin-top: 50px;
              width: 280px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }  
}


@media only screen and (max-width: 380px) {
  #annualReportResearch-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 160%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-bottom: 0px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 30px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-image-container > img {
  width: 40vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    margin-bottom: 0px;
}
.annualReportResearch-titleLine {
    width: 60%;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
        .annualReportResearch-cards-container {
            display: flex;
            flex-direction: column;
              margin-top: 50px;
              width: 280px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }  
}



@media only screen and (max-width: 340px) {
  #annualReportResearch-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d1e1e2; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Research_landing1.png');
    background-position: center center;
    width: 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    background-color: #C8D8DA;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Asset+21-8.png);
    background-size: 460%;
    background-position: top center;
    background-repeat: no-repeat;
}
.annualReportResearch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-bottom: 0px;
}
.annualReportResearch-mainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60vw;
    height: 100%;
    max-height: 200px;
    margin-bottom: 50px;
}
.annualReportResearch-mainTitle > h2 {
    color: #11474f;
    font-family: 'Helvetica Neue Medium';
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}
.annualReportResearch-imageDescription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 50000px;
    margin-bottom: 30px;
}
.annualReportResearch-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40vw;
    height: 100%;
    max-height: auto;
    margin: 30px;
}
.annualReportResearch-image-container > img {
  width: 40vw;
  height: auto;
}
.annualReportResearch-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    max-height: auto;
    margin-bottom: 0px;
}
.annualReportResearch-titleLine {
    width: 60%;
    border-bottom: solid 2px white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.annualReportResearch-description-container > h2 {
    color: #d14e1d;
    font-family: 'Helvetica Neue Medium';
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
}
.annualReportResearch-description-container > p {
    font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  color: #11474f;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
.annualReportResearch-subpages-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    padding-top: 10px;
    margin-bottom: 70px;
    }


    .annualReportResearch-subpages-container > ul{
        padding-right: 30px;
        background-color: transparent;
      }
      #annualReportResearch-cardsList2{
        text-align: center;
        margin-right: 10px;
      }
      #annualReportResearch-cardsList2 > h2 {
        color: #11474f;
        font-family: 'Helvetica Neue Medium';
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
      }
        #annualReportResearch-cardsList2 li{
          display: inline-block;
          margin: 0px 0px;
        }
        .annualReportResearch-cards-container {
            display: flex;
            flex-direction: column;
              margin-top: 50px;
              width: 260px;
              height: auto;
              margin: 0 auto;
              background-color: transparent;
          }
          .annualReportResearch-cards-container > h3 {
            color: #d14e1d;
              font-family: 'Helvetica Neue Medium';
              font-size: 25px;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 10px;
          }
          .annualReportResearch-cards-container > p {
            font-family: 'Helvetica Neue Medium';
            font-size: 16px;
            color: #11474f;
            font-weight: lighter;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 30px;
          }  
}
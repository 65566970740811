/* RESEARCH DOCUMENT VIEWER */
.research-documentViewerHolder {
    text-align: center;
    display: flex;
    width: 130px;
    padding: 8px;
    height: 200px;
    margin: 20px 10px 0px 0px;
    background-color: rgba(49, 49, 49, .9);
    border: 1px solid rgb(32, 32, 32);
    border-radius: 8px;
    overflow: hidden;
}
.research-documentViewerHolder:hover {
    background-color: rgb(213, 213, 213);
  }
.research-documentViewerEmbed {
    height: 200px;
    width: 130px;
    border-radius: 8px;
}
.research-documentViewerEmbed > #document > html > .c3 {
    padding: 0px;
  }
.research-documentViewerTitleNew {
    position: absolute;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.4);
    margin: 0px -8px 0px -130px;
    height: 200px;
    width: 130px;
}
.research-documentViewerTitleNew2 {
    padding: 70px 0;
    visibility: visible;
    font-family: 'Helvetica Neue Light';
}

.research-documentViewerContainer {
    width: auto;
  text-align: left;
  /* margin-left: 50px;
  margin-top: -20px; */
}
#conceptualDevelopmentLab-header-container {
    background-image: url('https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Conceptual_Development_Lab_Header.jpeg');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
  }
  .conceptualDevelopmentLab-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
  }
  .conceptualDevelopmentLab-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light';
    text-align: center;
  }
  
  @media only screen and (max-width: 1000px) {
    .conceptualDevelopmentLab-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px;
    }
    .conceptualDevelopmentLab-header-content-container > h1 {
        font-size: 55px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #conceptualDevelopmentLab-header-container {
        background-image: url('https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Conceptual_Development_Lab_Header.jpeg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 70%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .conceptualDevelopmentLab-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px;
    }
    .conceptualDevelopmentLab-header-content-container > h1 {
        font-size: 45px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #conceptualDevelopmentLab-header-container {
        background-image: url('https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Conceptual_Development_Lab_Header.jpeg');
        background-position: center center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        background-blend-mode: multiply;
        display: flex;
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
    }
    .conceptualDevelopmentLab-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px;
    }
    .conceptualDevelopmentLab-header-content-container > h1 {
        font-size: 35px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 376px) {
    .conceptualDevelopmentLab-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px;
    }
    .conceptualDevelopmentLab-header-content-container > h1 {
        font-size: 30px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .conceptualDevelopmentLab-header-content-container{
        -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: white;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px;
    }
    .conceptualDevelopmentLab-header-content-container > h1 {
        font-size: 25px;
        font-family: 'Helvetica Neue Light';
        text-align: center;
    }
  }
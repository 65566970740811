.contentTable {
    padding: 120px 32px;
    .content-table-pc {
        display: block;
    }
    .content-table-mobile {
        display: none;
    }
    .MuiTableHead-root {
        background-color: #007A8A;
        .MuiTableCell-head {
            color: white;
        }
    }
    .MuiTableCell-root {
        padding: 16px 32px;
    }
    .MuiTableRow-root {
        background-color: rgba(0, 0, 0, 0.04);
    }
    .mobile-col-title {
        color: #8B8B8B;
    }
    .mobile-col-val {
        font-size: 1.35rem;
    }
    @include mobile-full{
        .content-table-pc {
            display: none;
        }
        .content-table-mobile {
            display: block;
        }
    }
}
#vr-stress-test-header-container {
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/VR+Software+Stress+Test.png');
    background-position: center center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    display: flex;
    position: absolute;
    height: 70%;
    width: 100%;
    left: 0;
    top: 10%;
  }
  .vr-stress-test-header-content-container{
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px;
  }
  .vr-stress-test-header-content-container > h1 {
    font-size: 65px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    text-align: center;
  }
  
  @media only screen and (max-width: 1000px) {
    .vr-stress-test-header-content-container{
          top: 50%;
          max-width: 600px;
    }
    .vr-stress-test-header-content-container > h1 {
        font-size: 55px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #vr-stress-test-header-container {
        top: 0;
    }
    .vr-stress-test-header-content-container{
          max-width: 450px;
    }
    .vr-stress-test-header-content-container > h1 {
        font-size: 45px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #vr-stress-test-header-container {
        height: 60%;
    }
    .vr-stress-test-header-content-container{
          max-width: 300px;
    }
    .vr-stress-test-header-content-container > h1 {
        font-size: 35px;
    }
  }
  
  @media only screen and (max-width: 376px) {
    .vr-stress-test-header-content-container{
          max-width: 280px;
    }
    .vr-stress-test-header-content-container > h1 {
        font-size: 30px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .vr-stress-test-header-content-container{
          max-width: 260px;
    }
    .vr-stress-test-header-content-container > h1 {
        font-size: 25px;
    }
  }
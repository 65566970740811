#everydayUnseenStory-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
}
.everydayUnseenStory-sticky-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.october21Newsletter-link-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.october21Call-to-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #007A8A;
    padding: 0 20px;
}

.october21Call-to-action p {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: calc(12px + 1.5vmin);
    color: #FFFFFF;
}

.october21Call-to-action a {
    background-color: #f2f2f2;
    color: #007A8A;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding: 1em;
    margin: 1em;
    border-radius: 7% / 30%;
}

.october21Call-to-action a:hover {
    background-color: #ccc;

}

@media only screen and (max-width: 550px) {
    .october21Call-to-action {
        flex-direction: column;
    }
    .october21Call-to-action p {
        margin-bottom: 0;
    }
    
}
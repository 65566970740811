.craftHighlightCards .featuredResearch {
    background: #F2F2F2;
    color: #FFF;
    padding: 0; }
    @media (min-width: 1440px) {
      .craftHighlightCards .featuredResearch {
        padding: 0; } }
    @media (max-width: 768px) {
      .craftHighlightCards .featuredResearch {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%; } }
    .craftHighlightCards .featuredResearch h3 {
      margin-bottom: 40px; }
    .craftHighlightCards .featuredResearch .button {
      margin: 60px auto 0 auto; }
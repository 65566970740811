#newContact-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }

#newContact-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 60%;
    background-color: #111111;
}

/* DESCRIPTION CSS */

.newContact-description-container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: #f9f9f9;
  z-index: 1;
}
.newContact-description-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center; 
  text-align: left;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  top: 0;
  left: 0;
  margin: 50px 0;
}

.newContact-inquiries-container {
  padding-bottom: 25px;
}

.newContact-inquiries-container > h2 {
font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
color: #111111;
font-size: 30px;
font-weight: Bold;
letter-spacing: 1px;
text-align: left;
margin-bottom: 10px;
}
.newContact-inquiries-container > p {
  font-family: 'Helvetica Neue Light';
  color: #3f3f3f;
  text-align: left;
  font-weight: bolder;
  font-size: 21px;
  line-height: 30px;
}

.newContact-footer-container {
  z-index: 0;
}

@media only screen and (max-width: 1500px) {
  .newContact-description-content-container {
    max-width: 1000px;
  }
}

@media only screen and (max-width: 1100px) {
.newContact-description-content-container {
    max-width: 700px;
    margin-top: 50px;
    margin-bottom: 50px;
}
  .newContact-inquiries-container > p {
    font-family: 'Helvetica Neue Light';
    color: #3f3f3f;
    font-weight: bolder;
    font-size: 19px;
    line-height: 30px;
  }
}


@media only screen and (max-width: 750px) {
.newContact-description-content-container {
    max-width: 450px;
}
.newContact-inquiries-container > h2 {
  font-size: 25px;
  }
  .newContact-inquiries-container > h1 {
    font-size: 60px;
    }
  .newContact-inquiries-container > p {
    font-family: 'Helvetica Neue Light';
    color: #3f3f3f;
    font-weight: bolder;
    font-size: 17px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 500px) {
.newContact-description-content-container {
    max-width: 300px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.newContact-inquiries-container > h2 {
  font-size: 20px;
  }
  .newContact-inquiries-container > h1 {
    font-size: 45px;
    margin-bottom: 0px;
    }
  .newContact-inquiries-container > p {
    font-family: 'Helvetica Neue Light';
    color: #3f3f3f;
    font-weight: bolder;
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (max-width: 376px) {
.newContact-description-content-container {
    max-width: 280px;
}
  .newContact-inquiries-container > p {
    font-family: 'Helvetica Neue Light';
    color: #3f3f3f;
    font-weight: bolder;
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (max-width: 350px) {
.newContact-description-content-container {
    max-width: 260px;
}
.newContact-inquiries-container > h2 {
  font-size: 18px;
  }
  .newContact-inquiries-container > h1 {
    font-size: 40px;
    margin-top: 10px;
    }
  .newContact-inquiries-container > p {
    font-family: 'Helvetica Neue Light';
    color: #3f3f3f;
    font-weight: bolder;
    font-size: 16px;
    line-height: 27px;
  }
}

#stemDbrLabBody-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
  }

.stemDbrLabBody-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;    
    top: 0;
    left: 0;
}

/* LAB DEFINITION */
.stemDbrLabBody-definition-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D14E1D;
    width: 100%;
  }

  .stemDbrLabBody-definition-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: #D14E1D;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0 45px 0;
  }

  .stemDbrLabBody-definition-bold {
    display: flex;
    width: 100%;
  }

  .stemDbrLabBody-definition-bold > h1 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 46px;
    color: #F9F9F9;
    letter-spacing: 1px;
    margin: 20px 0 0 0;
  }

  .stemDbrLabBody-definition-paragraph {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 25px;
  }

  .stemDbrLabBody-definition-paragraph > h2 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: 25px;
    color: #F9F9F9;
    line-height: 35px;
    letter-spacing: 1px;
    margin: 0;
  }

  .stemDbrLabBody-definition-paragraph > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #F9F9F9;
    letter-spacing: 1px;
    margin: 5px 0;
  }

  .stemDbrLabBody-highlightsCard-link {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 18px 0;
    color: #077A8A;
    text-decoration: none;
  
  }

  @media only screen and (max-width: 1200px) {  
    .stemDbrLabBody-definition-content-container {
      flex-direction: column;
      max-width: 80vw;
    }
  
    .stemDbrLabBody-definition-bold {
      width: 100%;
      margin: 0;
    }
  
    .stemDbrLabBody-definition-bold > h1 {
      margin: 15px 0 15px 0;
    }
  
    .stemDbrLabBody-definition-paragraph {
      width: 100%;
      margin: 0;
    }
  }

  @media only screen and (max-width: 600px) {

    .stemDbrLabBody-definition-content-container {
      flex-direction: column;
      max-width: 80vw;
      margin: 25px 0;
    }

    .stemDbrLabBody-definition-bold > h1 {
      margin: 15px 0 15px 0;
      font-size: 20px;
    }

    .stemDbrLabBody-definition-paragraph > h2 {
      font-size: 18px;
      line-height: 23px;
    }

    .stemDbrLabBody-definition-paragraph > p {
      font-size: 18px;
      line-height: 23px;
    }
    
  }

/* DBR DEFINITION */
.stemDbrLabBody-dbrDefinition-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  width: 100%;
}

.stemDbrLabBody-dbrDefinition-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  background-color: #F9F9F9;
  width: 100%;
  max-width: 1050px;
  margin: 45px 0 45px 0;
}

.stemDbrLabBody-dbrDefinition-bold {
  display: flex;
  width: 100%;
}

.stemDbrLabBody-dbrDefinition-bold > h1 {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 30px;
  color: #111111;
  letter-spacing: 1px;
  margin: 10px 0 0 0;
}

.stemDbrLabBody-dbrDefinition-paragraph {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 25px;
}

.stemDbrLabBody-dbrDefinition-paragraph > h2 {
  font-family: 'Helvetica Neue Light';
  font-size: 25px;
  color: #111111;
  line-height: 35px;
  letter-spacing: 1px;
  margin: 0;
}

.stemDbrLabBody-dbrDefinition-paragraph > p {
  font-family: 'Helvetica Neue Light';
  font-size: 17px;
  color: #111111;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1200px) {  
  .stemDbrLabBody-dbrDefinition-content-container {
    flex-direction: column;
    max-width: 80vw;
  }

  .stemDbrLabBody-dbrDefinition-bold {
    width: 100%;
    margin: 0;
  }

  .stemDbrLabBody-dbrDefinition-bold > h1 {
    margin: 15px 0 15px 0;
  }

  .stemDbrLabBody-dbrDefinition-paragraph {
    width: 100%;
    margin: 0;
  }
}


@media only screen and (max-width: 600px) {

  .stemDbrLabBody-dbrDefinition-content-container {
    flex-direction: column;
    max-width: 80vw;
    margin: 25px 0;
  }

  .stemDbrLabBody-dbrDefinition-bold > h1 {
    margin: 15px 0 15px 0;
    font-size: 20px;
  }

  .stemDbrLabBody-dbrDefinition-paragraph > h2 {
    font-size: 18px;
    line-height: 23px;
  }

  .stemDbrLabBody-dbrDefinition-paragraph > p {
    font-size: 18px;
    line-height: 23px;
  }
  
}


/* GET INVOLVED */
.stemDbrLabBody-getInvolved-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #077A8A;
    width: 100%;
  }

  .stemDbrLabBody-getInvolved-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: #077A8A;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0 45px 0;
  }

  .stemDbrLabBody-getInvolved-bold {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .stemDbrLabBody-getInvolved-bold > h2 {
    margin: 0 0 30px 0;
    color: #F9F9F9;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 30px;
    letter-spacing: 1px;
  }

  .stemDbrLabBody-getInvolved-paragraph {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 25px;
  }

  .stemDbrLabBody-getInvolved-paragraph > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #F9F9F9;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }

  @media only screen and (max-width: 1200px) {
  
    .stemDbrLabBody-getInvolved-content-container {
      max-width: 80vw;
    }
  
    .stemDbrLabBody-getInvolved-bold {
      justify-content: left;
      width: 100%;
    }
  
    }
  
    @media only screen and (max-width: 600px) {
  
      .stemDbrLabBody-getInvolved-bold > h2 {
        margin: 0 0 20px 0;
        font-size: 22px;
      }
  
    }


  /* LAB PROJECTS */
#stemDbrLabBody-labProjects-container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
}

.stemDbrLabBody-labProjects-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1050px;
  height: 100%;
  max-height: auto;
  top: 0;
  left: 0;
  margin: 30px 0 50px 0;
}

.stemDbrLabBody-labProjects-title { 
margin: 0 0 30px 0;
color: #111111;
font-family: 'Helvetica', Arial, sans-serif;
font-size: 30px;
letter-spacing: 1px;
}

/* .stemDbrLabBody-labProjects-card-container {
display: flex;
justify-content: center;
align-items: center;
width: 1050x;
max-width: 1050px;
padding: 15px 0 15px 0;
-moz-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
-webkit-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
margin: 10px 0 10px 0;
} */

.stemDbrLabBody-labProjects-grid-container {
display: grid;
grid-template-columns: 300px repeat(1, 1fr);
grid-column-gap: 130px;
width: 100%;
padding: 20px 0 20px 0;
}

.stemDbrLabBody-labProjects-image-container > img {
width: 300px;
height: 250px;
}

.stemDbrLabBody-labProjects-info-container {
display: grid;
grid-template-rows: 30px 1fr 30px;
gap: 10px;
text-align: left;
background-color: #FFFFFF;
}

/* .stemDbrLabBody-labProjects-tags {
display: flex;
align-items: center;
flex-direction: row;
}

.stemDbrLabBody-labProjects-hash {
display: flex;
flex-direction: row;
align-items: center;
}

.youthHash-teal {
font-family: 'Helvetica Neue Light';
color: #ffffff;
background-color: #007a8a;
font-size: 14px;
padding: 5px 12px 5px 12px;
overflow: hidden;
border-radius: 5px;
}

.youthHash-hash {
color: #007a8a;
font-family: 'Helvetica Neue Light';
font-size: 16px;
padding: 5px 12px 5px 12px;
} */

.stemDbrLabBody-labProjects-text {
display: flex;
flex-direction: column;
text-align: left;
}

.stemDbrLabBody-labProjects-text > h2 {
font-family: 'Helvetica', Arial, sans-serif;
color: #111111;
font-size: 25px;
margin: 0 0 15px 0;
}

.stemDbrLabBody-labProjects-text > h2 > a {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #D14E1D;
  font-size: 25px;
  margin: 0 0 15px 0;
  }

.stemDbrLabBody-labProjects-text > p {
font-family: 'Helvetica', Arial, sans-serif;
color: #111111;
font-size: 20px;
margin: 0;
}

.stemDbrLabBody-labProjects-link {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
}

.stemDbrLabBody-labProjects-link > p {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #969696;
  font-size: 16px;
  margin: 0;
}

.stemDbrLabBody-labProjects-content-link {
  font-family: 'Helvetica', Arial, sans-serif;
font-size: 30px;
color: #00464f;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
padding-right: 50px;
}

.stemDbrLabBody-labProjects-text > h2 > a:hover {
color: #007a8a;
text-decoration: underline;
}

.stemDbrLabBody-labProjects-bottomLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0 30px 0;
}

.stemDbrLabBody-labProjects-content-bottomLink {
  font-family: "Helvetica Neue Light";
  font-size: 18px;
  color: #d14e1d;
  text-decoration: none;
}

.stemDbrLabBody-labProjects-content-bottomLink:hover {
  color: #b74217;
  transition: color 0.5s;
}

@media only screen and (max-width: 1025px) {
  .stemDbrLabBody-labProjects-info-container {
    min-width: auto;
  }
}


@media only screen and (max-width: 800px) {
  .stemDbrLabBody-labProjects-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 30px 0 50px 0;
  }

  .stemDbrLabBody-labProjects-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1050x;
    max-width: 1050px;
    padding: 15px 0 15px 0;
    -moz-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    margin: 10px 0 10px 0;
  }

  .stemDbrLabBody-labProjects-title { 
    margin: 0 0 30px 0;
    color: #111111;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 23px;
    letter-spacing: 1px;
  }

.stemDbrLabBody-labProjects-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}
.stemDbrLabBody-labProjects-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75vw;
  margin: 10px 0 10px 0;
}

.stemDbrLabBody-labProjects-image-container {
  display: flex;
  padding-bottom: 30px;
}

.stemDbrLabBody-labProjects-image-container > img {
  width: 300px;
  max-width: 75vw;
  height: auto;
}

.stemDbrLabBody-labProjects-info-container {
  display: grid;
  grid-template-rows: 30px 1fr 30px;
  gap: 10px;
  text-align: left;
  background-color: #ffffff;
  padding: 0;
}

.stemDbrLabBody-labProjects-text > h2 {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #111111;
  font-size: 25px;
}

.stemDbrLabBody-labProjects-text > p {
  font-family: 'Helvetica Neue Light';
  color: #111111;
  font-size: 16px;
  margin: 0;
  line-height: 23px;
}

.stemDbrLabBody-labProjects-content-link {
  font-family: "Helvetica Neue Light";
  font-size: 30px;
  color: #00464f;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.stemDbrLabBody-labProjects-bottomLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 75vw;
  margin: 30px 0 30px 0;
}

}


/* PUBLICATIONS */
.stemDbrLabBody-publications-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    width: 100%;
  }

  .stemDbrLabBody-publications-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: #F9F9F9;
    width: 100%;
    max-width: 1050px;
    margin: 45px 0 45px 0;
  }

  .stemDbrLabBody-publications-bold {
    display: flex;
    width: 100%;
  }

  .stemDbrLabBody-publications-bold > h2 {
    margin: 0 0 10px 0;
    color: #D14E1D;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 30px;
    letter-spacing: 1px;
  }

  .stemDbrLabBody-publications-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    line-height: 25px;
    margin: 0;
    padding: 0;
  }

  .stemDbrLabBody-publications-list > li {
    padding: 13px 0;
    line-height: 22px;
  }

  .stemDbrLabBody-publications-list > li > p {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0;
  }

  .stemDbrLabBody-publications-list > li > a {
    font-family: 'Helvetica Neue Light';
    font-size: 18px;
    color: #077A8A;
    letter-spacing: 1px;
    word-wrap: break-word;
  }

  @media only screen and (max-width: 1200px) {

    .stemDbrLabBody-publications-content-container {
      max-width: 80vw;
    }

  }

  @media only screen and (max-width: 600px) {

    .stemDbrLabBody-publications-content-container {
      margin: 35px 0;
    }

    .stemDbrLabBody-publications-list > li {
      padding: 15px 0;
    }

    .stemDbrLabBody-publications-bold > h2{
      font-size: 22px;
      margin: 0;
    }

  }
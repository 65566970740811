.programDocument-cardsList2-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .programDocument-section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .programDocument-section-title > h2 {
    font-family: 'Helvetica Neue Light';
    color: #d14e1d;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }
  .programDocument-title-border {
      width: 1024px;
      margin-top: 20px;
    border-bottom: 3px solid rgb(62, 62, 62);
  }
  .programDocument-cardsList2-container > ul{
    padding-right: 30px;
  }
  #programDocument-cardsList2{
    background-color: #f2f2f2;
    text-align: center;
  }
    #programDocument-cardsList2 li{
      display: inline-block;
      margin: 10px 10px;
    }

@media only screen and (max-width: 1100px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 800px;
          margin-top: 20px;
        border-bottom: 3px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}


@media only screen and (max-width: 850px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 650px;
          margin-top: 20px;
        border-bottom: 3px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}

@media only screen and (max-width: 700px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 500px;
          margin-top: 10px;
        border-bottom: 2px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}

@media only screen and (max-width: 550px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 400px;
          margin-top: 10px;
        border-bottom: 2px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}

@media only screen and (max-width: 430px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 340px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 340px;
          margin-top: 10px;
        border-bottom: 2px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}

@media only screen and (max-width: 380px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 300px;
          margin-top: 5px;
        border-bottom: 2px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}

@media only screen and (max-width: 350px) {
    .programDocument-cardsList2-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .programDocument-section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .programDocument-section-title > h2 {
        font-family: 'Helvetica Neue Light';
        color: #d14e1d;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      .programDocument-title-border {
          width: 270px;
          margin-top: 5px;
        border-bottom: 2px solid rgb(62, 62, 62);
      }
      .programDocument-cardsList2-container > ul{
        padding-right: 30px;
      }
      #programDocument-cardsList2{
        background-color: #f2f2f2;
        text-align: center;
      }
        #programDocument-cardsList2 li{
          display: inline-block;
          margin: 10px 10px;
        }
}




/* PROGRAM DOCUMENT VIEWER */
.programDocument-documentViewerHolder {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 130px;
    padding: 8px;
    height: 100%;
    max-height: 200px;
    margin: 20px 10px 0px 0px;
    background-color: rgba(49, 49, 49, .9);
    border: 1px solid rgb(32, 32, 32);
    border-radius: 8px;
    overflow: hidden;
}
.programDocument-documentViewerHolder:hover {
    background-color: rgb(213, 213, 213);
  }
.programDocument-documentViewerEmbed {
    height: 200px;
    width: 130px;
    border-radius: 8px;
}
.programDocument-documentViewerEmbed > #document > html > .c3 {
    padding: 0px;
  }
.programDocument-documentViewerTitleNew {
    position: absolute;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.4);
    margin: 0px -8px 0px -130px;
    height: 200px;
    width: 130px;
}
.programDocument-documentViewerTitleNew2 {
    padding: 70px 0;
    visibility: visible;
    font-family: 'Helvetica Neue Light';
}

.programDocument-documentViewerContainer {
    width: auto;
  text-align: left;
}

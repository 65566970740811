#annualReport2021EdTech-header-container {
    display: flex;
    position: relative;
    height: 70vw;
    width: 100vw;
    top: -5vw;
    overflow: hidden;
  }
  
  .annualReport2021EdTech-header-content {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #C8D8DA;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .annualReport2021EdTech-header-content img {
    width: 140%;
  }
  
  
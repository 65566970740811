#annualReport2021philanthropy-highlights-container {
    background-color: #C8D8DA;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021philanthropy-highlights-content-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 12vw;
}

.annualReport2021philanthropy-highlights-content-container > img {
    width: 28vw;
    height: 100%;
}

.annualReport2021philanthropy-highlights-text-container {
    width: 100%; 
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021philanthropy-highlights-text-container > h2 {
    color: #d14e1d;
    text-align: left;
    font-size: calc(20px + 2vw);
    margin: 50px 0 0;
}

.annualReport2021philanthropy-highlights-text-container > p {
    color: #00464f;
    font-size: calc(12px + .5vw);
    text-align: left;
}

.annualReport2021philanthropy-highlights-text-container span {
    color: #f9f9f9;
    font-size: calc(12px + .45vw);
    text-align: left;
}

.annualReport2021philanthropy-highlights-scholarships-1 > p{
    width: fit-content;
    padding: 1vw;
    background-color: #007a8a;
}

.annualReport2021philanthropy-highlights-scholarships-2 > p{
    width: fit-content;
    padding: 1vw;
    background-color:#d14e1d;
}

.annualReport2021philanthropy-highlights-scholarships-3 > p{
    width: fit-content;
    padding: 1vw;
    background-color: #e77c53;
}

.annualReport2021philanthropy-highlights-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.annualReport2021philanthropy-highlights-card-individ {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
}

.annualReport2021philanthropy-highlights-card-individ > img {
    width: 30%;
    height: 100%;
}

.annualReport2021philanthropy-highlights-card-individ-text-container {
    margin-left: 40px;
    text-align: left;
}

.annualReport2021philanthropy-highlights-card-individ-text-container > h3 {
    color: #00464f;
    font-size: calc(16px + 1vw);
}

.annualReport2021philanthropy-highlights-card-individ-text-container > p {
    color: #00464f;
    font-size: calc(12px + .5vw);
    text-align: justify;
}

.annualReport2021philanthropy-highlights-card-individ-text-container > a {
    color: #007a8a;
    font-size: medium;
}


@media only screen and (max-width: 1000px) {
  
    .annualReport2021philanthropy-highlights-content-container {
        padding-bottom: 50px;
    }
    .annualReport2021philanthropy-highlights-text-container {
        padding-bottom: 50px;
    }
    
    .annualReport2021philanthropy-highlights-text-container > h2 {
        text-align: center;
    }
    
    .annualReport2021philanthropy-highlights-card-individ-text-container {
        margin-left: 0;
    }
    
    .annualReport2021philanthropy-highlights-card-individ {
        flex-direction: column;
    }
    
    .annualReport2021philanthropy-highlights-card-individ > img {
        width: 70%;
    }    

    .annualReport2021philanthropy-highlights-card-individ-text-container > h3 {
        text-align: center;
    }
    
}


.insightsAndUpdates {
    background-color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    .container {
        position: relative;
        padding: 0;
        @include large-desktop {
            margin: 40px auto;
        }
        .archiveCard .archiveListContent h3 a {
            color: black;
        }
    }
    .emphHeader::after{
        width: 84%;
        @include mobile-full{
            display: none;
        }
    }
}
.focus {
  font-weight: bolder;
  font-size: larger;
}

p, li {
font-size: 17px;
}

#bandLab-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.bandLab-description-content-container {
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 20000px;
  top: 0;
  left: 0;
  margin-top: 50px;
  padding-bottom: 50px;
  color: rgb(62, 62, 62);
  text-align: left;
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  line-height: 2;
}

.bandLab-rubric-grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border: 2px black solid;
  margin-bottom: 20px;
}

.bandLab-rubric-grid-box {
  border: 1px black solid;
  padding: 10px;

}
    
.bandLab-accordion-container {
  display: flex;
  flex-direction: column;
  color: rgb(62, 62, 62);
  text-align: center;
}
  
  @media only screen and (max-width: 1200px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
  
  @media only screen and (max-width: 1000px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
  
  @media only screen and (max-width: 800px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
  
  @media only screen and (max-width: 600px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
  
  @media only screen and (max-width: 450px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
  
  @media only screen and (max-width: 350px) {
    #bandLab-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .bandLab-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
  }
#annualReport2021landing-header-container {
    background-position: center;
    display: flex;
    position: absolute;
    height: 60%;
    width: 100%;
    left: 0;
    top: 10%;
    background-color: #C8D8DA;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
  }
  .annualReport2021landing-header-content-container{
    /* -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #11474f;
      min-width: 50%;
      padding: 30px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 45%;
      width: 100%;
      max-width: 900px; */
      position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .annualReport2021landing-header-content-container > img {
    width: 65vw;
  }
  
  @media only screen and (max-width: 1000px) {
    .annualReport2021landing-header-content-container{
        /* -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #11474f;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 600px; */

    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
  }
  
  @media only screen and (max-width: 700px) {
    #annualReport2021landing-header-container {
        /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Header1.png'); */
        background-color: #C8D8DA;
        background-position: center center;
        background-size: cover;
        display: flex;
        position: absolute;
        height: 50%;
        width: 100%;
        left: 0;
        top: 0;
        -moz-background-size: 100% 100%;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
    }
    .annualReport2021landing-header-content-container{
        /* -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #11474f;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 450px; */
          position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
  }
  
  @media only screen and (max-width: 500px) {
    #annualReport2021landing-header-container {
        /* background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Header1.png'); */
        background-color: #C8D8DA;
        background-position: center center;
        background-size: cover;
        display: flex;
        position: absolute;
        height: 50%;
        width: 100%;
        left: 0;
        top: 0;
        -moz-background-size: 100% 100%;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
    }
    .annualReport2021landing-header-content-container{
        /* -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #11474f;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 300px; */

          position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
  }
  
  @media only screen and (max-width: 376px) {
    .annualReport2021landing-header-content-container{
        /* -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #11474f;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 280px; */
          background-color: #C8D8DA;
          position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .annualReport2021landing-header-content-container{
        /* -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #11474f;
          min-width: 50%;
          padding: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          max-width: 260px; */
          background-color: #C8D8DA;
          position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
  }




  
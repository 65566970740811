#stemGoBabyGo-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00464f;
  }
  .stemGoBabyGo-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .stemGoBabyGo-intro-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .stemGoBabyGo-intro-bold > h3 {
    font-family: 'Helvetica Neue Light';
    color: white;
    font-size: 35px;
    text-align: center;
    margin: 0px;
  }
  .stemGoBabyGo-intro-normal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: auto;
      height: 100%;
      max-height: auto;
      margin-left: 30px;
      margin-right: 30px;
  }
  .stemGoBabyGo-intro-normal > p {
    font-family: 'Helvetica Neue Light';
    color: white;
    text-align: left;
    font-weight: bolder;
    font-size: 17px;
    line-height: 32px;
  }
  
  @media only screen and (max-width: 1200px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 800px;
      height: 100%;
      max-height: auto;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: auto;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 30px;
      font-weight: lighter;
      text-align: center;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 32px;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 650px;
      height: 100%;
      max-height: auto;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: auto;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
    }
  }

  @media only screen and (max-width: 800px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: auto;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: auto;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: auto;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: auto;
      margin-bottom: 15px;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 1000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: auto;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .stemGoBabyGo-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 270px;
      height: 100%;
      max-height: 1000px;
      top: 0;
      left: 0;
    }
    .stemGoBabyGo-intro-bold {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 80vw;
      height: 100%;
      max-height: 1000px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    
    .stemGoBabyGo-intro-bold > h3 {
      font-family: 'Helvetica Neue Light';
      color: white;
      font-size: 25px;
      font-weight: lighter;
      text-align: center;
      margin-bottom: 15px;
    }
    .stemGoBabyGo-intro-normal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: auto;
        height: 100%;
        max-height: auto;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .stemGoBabyGo-intro-normal > p {
      font-family: 'Helvetica Neue Light';
      color: white;
      text-align: left;
      font-weight: lighter;
      font-size: 17px;
      line-height: 25px;
      margin-top: 0px;
    }
  }
.themesCards-container {
    display: flex;
    color: white;
    font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
    background-color: #00464e;
}

.themesCards-intro-container {
    padding: 0 25%;
}

.themesCards-intro-container > h2{
    font-size: xx-large;
}

.themesCards-intro-container > p{
    font-size: large;
    line-height: 1.5;
}

.themesCards-slideshow-container {
    display: inline block;
    height: fit-content;
}

@media only screen and (max-width: 1150px) {
    .themesCards-intro-container {
        padding: 0 15%;
    }
}
.globalBreadcrumbs {
    display: flex;
    width: 100%;
    left: 0;
    top: 80%;
    color: #D14E1D;
    background-color: $bg-light;
    padding: 50px 0;

    @include mobile {
        padding: 2.25rem 0 2rem;
        background-color: #fff;
    }

    @include small-mobile {
        padding: 2.25rem 0 2rem;
        background-color: #fff;
    }

    &.dark {
        background: $bg-gray;
        color: #FFF;

        .container .breadcrumbs-menus {
            > a > i {
                color: #FFF;
            }

            span {
                display: inline-flex;
                align-items: center;
                justify-content: left;

                p {
                    a {
                        color: #FFF;
                    }
                }
            }

            h4 {
                color: $orange;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        padding: 0px;
        max-width: 994px;

        @include tablet-full{
            padding: 0 32px 0px;
            justify-content: space-between;
        }
        
        @include mobile {
            padding: 0 30px;
            flex-direction: column;
            align-items: flex-start;

            .breadcrumbs-menus {
                margin-bottom: 26px;
                width: 100%;
            }
        }

        @include small-mobile {
            padding: 0 20px;
            flex-direction: column;
            align-items: flex-start;

            .breadcrumbs-menus {
                margin-bottom: 26px;
                width: 100%;
            }
        }

        .breadcrumbs-menus {
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        .breadcrumbs-menus > a > i {
            font-size: x-large;
            color: $dark-gray;
            -webkit-text-stroke: 2px #fff;
            
            @include mobile {
                font-size: 20px;
            }

            @include small-mobile {
                font-size: 20px;
            }
        }
        
        .breadcrumbs-menus > span {
            display: inline-flex;
            align-items: center;
            justify-content: left;

            p {
                margin: 0;

                a {
                    color: $dark-gray;
                    text-transform: capitalize;
                    text-decoration: none;
                    &:hover {
                        color: $orange;
                    }
                }
            }

            h4 {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin: 0;

                a {
                    text-transform: capitalize;
                    color: $dark-gray;
                    text-decoration: none;
                    &:hover {
                        color: $orange;
                    }
                }
            }

            .chevron {
                font-size: 25px;
                padding: 0 20px;

                @include mobile {
                    font-size: 20px;
                }

                @include small-mobile {
                    font-size: 20px;
                }
            }
        }
        
    }

    .share {
        white-space: nowrap;

        & > a {
            @include mobile {
                padding-left: 0 !important;
            }

            @include small-mobile {
                padding-left: 0 !important;
            }
        }
    }
}
.resourceContainer {
    .globalBreadcrumbs {
        .share { 
            display: none;
        }
    }
    .contentBlock.standardContent{
        @include mobile-full{
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}
.researchRedesignBreadcrumb-content-container > p{
    color: black;
}


.researchRedesignBreadcrumb-content-container > p, h2{
    font-size: x-large;
}

.researchRedesignBreadcrumb-content-container > *{
    padding-right: 10px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@media only screen and (max-width: 700px) {
    .researchRedesignBreadcrumb-container {
        top: 70%;
    }
}

@media only screen and (max-width: 500px) {
    .researchRedesignBreadcrumb-container {
        top: 60%;
    }

}    

#archiveList,
#newsletterList {
    background: $bg-light;
    text-align: left;
    padding: 50px 0;
    .newsletterSidebar {
        h3 {
            border-top: solid 1px #000;
            margin-bottom: 40px;
            padding: 10px 0 0 0;
            text-transform: uppercase;
        }
        .yearList  {
            list-style: none;
            padding: 0;
            text-align: left;
            li {
                margin-bottom: 40px;
                input.form-checkbox.checkbox{
                    border: none;
                    background: #E8E7E7;
                    margin-right: 20px;
                    width: 20px;
                    height: 20px;
                }
                a {
                    color: #000;
                    text-decoration: none;
                    &:before {
                        background: #E8E7E7;
                        border-radius: 4px;
                        content: '';
                        display: inline-block;
                        height: 20px;
                        margin-right: 20px;
                        width: 20px;
                    }
                    &:hover {
                        color: $orange;
                    }
                }
                span{
                    font-size: 31px;
                    line-height: 39px;
                    font-weight: 400;
                    color: #000;
                }
            }
        }
    }  
    .newsletterListContent {
        h4 {
            color: #00464F;
        }
    } 
    .archiveCard,
    .newsletterCard {
        padding: 0 0 40px 0;
        border-bottom: solid 1px #AEAEAE;
        h4 {
            color: #007A8A;
            margin: 32px 0 10px;
            @include mobile-full{
                font-size: 20px;
                line-height: 25px;
                margin-top: 20px;
            }
            a{
                color: #007A8A;
            }
        }
        a {
            color: #000;
            text-decoration: none;
            // line-break: anywhere;
            &:hover {
                color: $teal;
                text-decoration: underline;
            }
            @include mobile-full{
                font-size: 16px;
                line-height: 24px;
            }
        }
        p{
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            @include mobile-full{
                font-size:16px;
                line-height: 24px;
            }
        }
        @include mobile-full{
            padding-bottom: 20px;
        }
    }
    .newsletterFormBlock {
        background-color: $bg-color;
        padding: 40px;
        h4 {
            color: #FFF;
            margin: 0 0 40px 0;
        }
        .formRow {
            display: flex;
            margin-bottom: 40px;
            justify-items: center;
            align-items: end;
            .formInput {
                justify-content: center;
                flex: 1;
                &:first-of-type {
                    margin-right: 20px;
                }
                label {
                    display: block;
                    color: #fff;
                    margin-bottom: 5px;
                }
                input {
                    border: none;
                    border-radius: 4px;
                    padding: 15px 10px;
                    width: 90%;
                }
                input[type=submit] {
                    width: auto;
                }
            }
        }
    }
    h3.text-large_bold{
        color: #00464F;
        @include mobile-full{
            font-size: 25px;
            line-height: 31px;
        }
    }
    button.button.loadMore {
        margin: 45px auto 0;
        display: block;
    }
    .newsletterSidebar {
        @include mobile-full{
            display: none;
        }
    }
    .container{
        @include mobile-full{
            padding: 0 32px;
        }
    }
}

#archiveList .archiveCard.large{
    @include mobile-full{
        margin-bottom: 0;
    }
}
#annualReport2021programs-overview-container {
    position: relative;
    top: 0;
    height: fit-content;
    width: 100%;
    background-color: #C8D8DA;
    display: flex;
    flex-direction: column;
    border-radius: 0% 90vw 0% 0% / 0% 5vw 0% 0%;
    padding-bottom: 60px;
}

.annualReport2021programs-overview-text-container {
    text-align: justify;
    margin: -3vw 12vw 0;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021programs-overview-text-container h1 {
    color: #d14e1d;
    max-width: 60vw;
    font-size: calc(20px + 2vw);
    text-align: left;
}

.annualReport2021programs-overview-text-container p {
    color: #00464f;
    font-size: calc(10px + 1vw);
}

.annualReport2021programs-overview-card-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 8vw;
    margin-left: 8vw;
}

.annualReport2021programs-overview-card-container > a {
    text-decoration: none;
}

.annualReport2021programs-overview-card-individ {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36.7vw;
    min-width: 500px;
    height: 18.5vw;
    min-height: 248px;
    background-image: url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Blank+card-8.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.annualReport2021programs-overview-card-individ h1{
    display: flex;
    width: 50%;
    color: #d14e1d;
    padding: 0 10px;
}

.annualReport2021programs-overview-card-individ img{
    height: 75%;
}

.annualReport2021programs-overview-card-individ :first-child {
    margin-left: 15%;
}

.annualReport2021programs-overview-card-individ :last-child {
    margin-right: 15%;
}

/* Dance Card */
#annualReport2021programs-dance img{
    height: 88%;
    margin-left: 22%;
}

/* Digital Audio Card */
#annualReport2021programs-digitalAudio h1{
    margin-left: -190px;
}

/* Games & Learning Card */
#annualReport2021programs-gamesLearning :first-child{
    height: 50%;
    padding-top: 15%;
    padding-right: 10px;
}
#annualReport2021programs-gamesLearning :last-child{
    padding-left: 15px;
}

/* Internships Card */
#annualReport2021programs-internships :first-child{
    padding-left: 8%;
}
#annualReport2021programs-internships h1{
    margin-left: -40px;
    width: 33%;
}
#annualReport2021programs-internships :last-child{
    padding-right: 8%;
}

/* Interventions Card */
#annualReport2021programs-interventions img {
    padding:0 8% 0 5%;
}

/* STEM Card */
#annualReport2021programs-stem img{
    padding:0 8% 0 5%;
}

/* Trades Card */
#annualReport2021programs-trades :first-child{
    padding-left: 15%;
}
#annualReport2021programs-trades h1{
    width: 15%;
}
#annualReport2021programs-trades :last-child{
    padding-right: 15%;
}

/* Spotlight Arrow */
#annualReport2021programs-spotlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    width: 36.7vw;
    min-width: 500px;
}

#annualReport2021programs-spotlight h1{
    font-size: calc(20px + 1vw);
    color: #d14e1d;
}

#annualReport2021programs-spotlight img{
    width: 90%;
}

@media only screen and (max-width: 1600px) {

    #annualReport2021programs-overview-container {
        border-radius: 0% 90vw 0% 0% / 0% 8vw 0% 0%;
    }

}

@media only screen and (max-width: 1000px) {

    #annualReport2021programs-overview-container {
        border-radius: 10% 90vw 0% 0% / 3% 13vw 0% 0%;
    }

}

@media only screen and (max-width: 800px) {

    #annualReport2021programs-overview-container {
        border-radius: 10vw 90vw 0% 0% / 5vw 15vw 0% 0%;
    }

}


@media only screen and (max-width: 500px) {

    #annualReport2021programs-overview-container {
        border-radius: 10vw 90vw 0% 0% / 10vw 17vw 0% 0%;
    }

    .annualReport2021programs-overview-card-individ {
        min-width: 300px;
        min-height: 149px;
    }
    
    .annualReport2021programs-overview-card-individ h1{
        font-size: medium;
    }

    /* Digital Audio Card */
    #annualReport2021programs-digitalAudio h1{
        margin-left: -100px;
    }

    /* Internships Card */
    #annualReport2021programs-internships :first-child{
        padding-left: 10%;
    }
    #annualReport2021programs-internships h1{
        margin-left: -25px;
        width: 30%;
    }
    #annualReport2021programs-internships :last-child{
        padding-right: 10%;
    }

    #annualReport2021programs-spotlight {
        min-width: 300px;
    }    
}

@media only screen and (max-width: 375px) {

    #annualReport2021programs-overview-container {
        border-radius: 25vw 90vw 0% 0% / 15vw 21vw 0% 0%;
    }

}
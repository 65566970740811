#fourD-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #7dd5c9;
    width: 100vw;
    max-width: 100vw;
    margin-top: 100px;
  }
  .everyday-section-title-fourD {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #43413e;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
    margin-top: 30px;
  }
  .fourD-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .fourD-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1530px;
    height: 100%;
    max-height: 720px;
    top: 0;
    left: 0;
  }
  .fourD-image-one {
    display: flex;
    width: 100%;
    max-width: 510px;
    height: 100%;
    max-height: 333px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .fourD-image-one > img {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 333px;
  }
  .fourD-image-two {
    display: flex;
    width: 100%;
    max-width: 510px;
    height: 100%;
    max-height: 333px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .fourD-image-two > img {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 333px;
  }
  .fourD-image-three {
    display: flex;
    width: 100%;
    max-width: 510px;
    height: 100%;
    max-height: 333px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .fourD-image-three > img {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 333px;
  }
  
  @media only screen and (max-width: 1024px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        letter-spacing: 3px;
        margin-top: 30px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1530px;
        height: 100%;
        max-height: 720px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 510px;
        height: 100%;
        max-height: 333px;
        margin-left: 20px;
        margin-right: 10px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 510px;
        height: 100%;
        max-height: 333px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 510px;
        height: 100%;
        max-height: 333px;
        margin-left: 10px;
        margin-right: 20px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
  }

  @media only screen and (max-width: 800px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
        margin-top: 30px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 1280px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 333px;
      }
  }
  
  @media only screen and (max-width: 600px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
        margin-top: 30px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 480px;
        height: 100%;
        max-height: 1280px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 296px;
      }
  }

  @media only screen and (max-width: 450px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
        margin-top: 20px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 1280px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 222px;
      }
  }
  
  @media only screen and (max-width: 374px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
        margin-top: 15px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 1280px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
  }
  
  @media only screen and (max-width: 330px) {
    #fourD-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7dd5c9;
        width: 100vw;
        max-width: 100vw;
        margin-top: 100px;
      }
      .everyday-section-title-fourD {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43413e;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
        margin-top: 15px;
      }
      .fourD-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
      .fourD-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 1280px;
        top: 0;
        left: 0;
      }
      .fourD-image-one {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-one > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-two {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-two > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-three {
        display: flex;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
      .fourD-image-three > img {
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 192px;
      }
  }
.fwi {
    background: #FFFFFF;
    padding: 20px 0;
    &.fullWidth {
        .imageContainer {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
    &.medium {
        .imageContainer {
            max-width: 1200px;
            margin: auto;
            padding: 0 30px;
            @include large-desktop { 
                padding: 0;
            }
            img {
                width: 100%;
            }
        }
    }
    &.small {
        .container {
            max-width: 786px;
            margin: auto;
        }
        .imageContainer {
            max-width: 786px;
            margin: auto;
            padding: 0 30px;
            @include large-desktop { 
                padding: 0;
            }
            img {
                @include mobile { 
                    max-width: 60%;
                }
                @include mobile { 
                    max-width: 70%;
                }
            }
        }
    }
    .container {
        @include small-desktop { 
            padding: 30px 0;
        }
         @include tablet { 
            padding: 30px 0;
        }
        @include mobile { 
            padding: 0;
        }
        @include small-mobile { 
            padding: 0;
        }
        h2 {
            text-align: left;
        }
        .imageCaption {
            padding: 10px 0;
            text-align: right;
            span {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background: #000;
                    width: 80px;
                    left: -100px; 
                    top: 12px;
                    @include mobile {
                        left: -60px; 
                        width: 40px;
                        top: 7px;
                    }
                }
            }
        }
    }
}
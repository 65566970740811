#niiModo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.niiModo-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 1024px;
  top: 0;
  left: 0;
  margin-top: 50px;
}
.niiModo-image-container {
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 100%;
  max-height: 512px;
  margin-left: 30px;
  margin-right: 30px;
}
.niiModo-image-container > img {
  width: 100%;
  max-width: 512px;
  height: 100%;
  max-height: 512px;
}
.niiModo-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 512px;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
  line-height: 35px;
}
.niiModo-text-container > h4 {
  color: #bcc6c7;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 3px;
}
.niiModo-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #d14e1d;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.niiModo-text-container > p {
  font-size: 17px;
  font-family: "Helvetica Neue Light", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3c4142;
}

@media only screen and (max-width: 1280px) {
  .niiModo-content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    max-height: 1000px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .niiModo-image-container > img {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    text-align: left;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 35px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 30px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 17px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 1000px) {
  .niiModo-content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 850px;
    height: 100%;
    max-height: 850px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    width: 100%;
    max-width: 425px;
    height: 100%;
    max-height: 425px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    width: 100%;
    max-width: 425px;
    height: 100%;
    max-height: 425px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 425px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 30px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 25px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 15px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 800px) {
  .niiModo-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    height: 100%;
    max-height: 1000px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 650px;
    height: 100%;
    max-height: 500px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 35px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 13px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 600px) {
  .niiModo-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 700px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 350px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 350px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 350px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 30px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 12px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 450px) {
  .niiModo-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 330px;
    height: 100%;
    max-height: 560px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 330px;
    height: 100%;
    max-height: 280px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 25px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 12px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 374px) {
  .niiModo-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 460px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 230px;
    height: 100%;
    max-height: 230px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 230px;
    height: 100%;
    max-height: 230px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 230px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 20px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 15px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 11px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}

@media only screen and (max-width: 330px) {
  .niiModo-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 400px;
    top: 0;
    left: 0;
  }
  .niiModo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .niiModo-image-container > img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;
  }
  .niiModo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 200px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 20px;
  }
  .niiModo-text-container > h4 {
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 3px;
  }
  .niiModo-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #d14e1d;
    font-size: 15px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .niiModo-text-container > p {
    font-size: 11px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c4142;
  }
}
#ib-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
  /* margin-top: 100px; */
  /* background-color: blue;
  width: 100vw;
  max-width: 100vw; */
}
.everyday-section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bcc6c7;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
}
.ib-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 1024px;
  top: 0;
  left: 0;
  /* margin-top: 20px; */
  /* font-family: "HelveticaNeue-Medium", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
}
.ib-text-container > h2 {
  font-family: 'Roboto Bold', sans-serif;
  color: #323738;
  font-size: 50px;
  letter-spacing: 2px;
}
.ib-text-container > p {
  font-size: 18px;
  font-family: "Helvetica Neue Light", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ib-svg-container {
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 100%;
  max-height: 512px;
  margin-left: 10px;
  margin-right: 10px;
}
.ib-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 512px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  color: #3c4142;
}

@media only screen and (max-width: 800px) {
    .ib-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .everyday-section-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
      }
      .ib-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 40px;
        letter-spacing: 2px;
      }
      .ib-text-container > p {
        font-size: 15px;
        font-weight: 15px;
        font-family: "Helvetica Neue Light", sans-serif;
      }
      .ib-svg-container {
        display: flex;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 450px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .ib-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 450px;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
      }
}

@media only screen and (max-width: 450px) {
    .ib-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .everyday-section-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bcc6c7;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
      }
      .ib-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 30px;
        letter-spacing: 2px;
      }
      .ib-text-container > p {
        font-size: 13px;
        font-weight: 15px;
        font-family: "Helvetica Neue Light", sans-serif;
      }
      .ib-svg-container {
        display: flex;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .ib-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 13px;
      }
}

@media only screen and (max-width: 374px) {
    .ib-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .ib-svg-container {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .ib-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
      }
}

@media only screen and (max-width: 330px) {
    .ib-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .ib-svg-container {
        display: flex;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .ib-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        color: gray;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 11px;
      }
}
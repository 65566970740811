#annualReport2021covid-support-container {
    color:#00464f;
    ;
}

.annualReport2021covid-support-content-container{
    width: 100%;
    height: fit-content;
    text-align: justify;
}

.annualReport2021covid-support-banner-container{
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vw;
}

.annualReport2021covid-support-banner-container > img {
    height: 150%;
    padding-right: 4vw;
}

.annualReport2021covid-support-banner-container > h2 {
    padding: 1vw 0 0 15vw;
    font-size: 30px; 
    color: #ffffff;
}

#annualReport2021covid-support-banner-presenting {
    background-color: #d14e1d;
}

.annualReport2021covid-support-highlight{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.annualReport2021covid-support-highlight > h2 {
    font-size: calc(10px + 2vw); 
    text-align: center;
    max-width: 80%;
}

.annualReport2021covid-support-highlight img {
    max-width: 50vw;
}

.annualReport2021covid-support-highlight > h3 {
    padding: 0 15vw 5vw;
    text-align: left;
    line-height: 30px;
}


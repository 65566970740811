#id-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4fb;
    /* margin-top: 100px; */
    width: 100vw;
    max-width: 100vw;
  }
  .id-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 80vw;
    height: 70vh;
    max-height: 720px;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: 1280px) {
    .id-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      max-width: 100vw;
      height: 65vh;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    .id-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      max-width: 100vw;
      height: 60vh;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }
@media only screen and (max-width: 800px) {
  .id-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    height: 45vh;
    max-height: 720px;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .id-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    height: 35vh;
    max-height: 720px;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 450px) {
  .id-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    height: 33vh;
    max-height: 720px;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 374px) {
  .id-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    height: 33vh;
    max-height: 720px;
    top: 0;
    left: 0;
  }
}

  @media only screen and (max-width: 330px) {
    .id-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      max-width: 100vw;
      height: 33vh;
      max-height: 720px;
      top: 0;
      left: 0;
    }
  }
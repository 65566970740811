#homeRedesign2023-header-container {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 500px;
    width: 100vw;
    left: 0;
    top: 0;
}
.homeRedesign2023-header-image-container{
    display: flex;
    background-image: url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/New-Homepage_Hero-Image.jpg');
    background-position: top center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 62vw;
}
.homeRedesign2023-header-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 38vw;
    background-color: #d14e1d;
    padding: 50px;
}
.homeRedesign2023-header-text-container > h1 {
    font-size: 35px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: white;
    text-align: left;    
}
.homeRedesign2023-header-emphasized-text {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.homeRedesign2023-header-text-container > a {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: x-large;
    color: white;
    text-align: left;
    text-decoration: none;
}

@media only screen and (max-width: 1150px) {
    #homeRedesign2023-header-container {
        flex-direction: column;
        height: 750px;
        align-items: center;
    }
    .homeRedesign2023-header-image-container{
        height: 60%;
        max-width: 100vw;
    }
    .homeRedesign2023-header-text-container {
        width: 100%;
        max-width: 100vw;
        height: 40%;
        box-sizing: border-box;
    }
    .homeRedesign2023-header-text-container > h1 {
        font-size: 30px;
    }
    .homeRedesign2023-header-text-container > a {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 700px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 500px) {
    #homeRedesign2023-header-container {
        height: 500px;
    }
    .homeRedesign2023-header-image-container{
        height: 50%;
    }
    .homeRedesign2023-header-text-container {
        height: 50%;
        padding: 50px;
        background-color: #BE5127;
    }

    .homeRedesign2023-header-text-container > h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 376px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 350px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 16px;
    }
}
.quoteSlideshow {
    background-color: #FFF;
    text-align: center;
    padding: 20px 0;
    h3 {
        color: #000;
        padding-bottom: 40px;
    }
    .slick-list {
        overflow: visible;
    }
    // Modify slide arrows
    .slick-next, .slick-prev {
        z-index: 2;
    }
    .slick-prev:before {
        border-color: #FFF;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        left: 0.25em;
	    transform: rotate(-135deg);
    }
    .slick-next:before {
        border-color: #FFF;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        left: 0;
	    transform: rotate(45deg);
    }
    .slick-dots {
        li {
            &.slick-active {
                button {
                    background: #fff;
                    &:before {
                        display: none;
                    }
                }
            }
            button {
                border-radius: 50%;
                border: solid 1px #FFF;
                width: 15px;
                height: 15px;
                &:before {
                    display: none;
                }
            }
        }
    }
    .quoteSlide {
        color: #FFF;
        overflow: visible;
        blockquote {
            margin: 0;
            font-size: 25px;
            font-weight: 400;
            height: 100%;
            line-height: 1.25em ;
            padding: 40px;
            position: relative;
            text-align: left;
            &::before {
            content: open-quote;
            }
            &::after {
                content: close-quote;
            }
            @include large-desktop {
                padding: 40px 80px;
            }
            @include desktop {
                padding: 40px 80px;
            }
            @include small-desktop {
                padding: 40px 80px;
            }
            @include tablet {
                padding: 40px 80px;
            }
            @include mobile {
                padding: 40px 40px;
            }
            @include small-mobile {
                padding: 40px 40px;
            }
            @include xs-mobile {
                padding: 20px 40px;

            }
            .quoteAvitar {
                position: absolute;
                width: 120px;
                height: 120px;
                top: -140px;
                right: 39%;
                @include tablet {
                    top: -105px;
                    right: 39%;
                }
                @include mobile {
                    top: -100px;
                    right: 35%;
                }
                @include small-mobile {
                    top: -100px;
                    right: 30%;
                }
            }
        }
        footer {
            text-align: left;
            display: flex;
            margin: 0;
            @include large-desktop {
                padding: 0 120px;
            }
            @include desktop {
                padding: 0 120px;
            }
            @include small-desktop {
                padding: 0 120px;
            }
            @include tablet {
                padding: 0 80px 40px 80px;
            }
            @include mobile {
                padding: 40px 40px;
            }
            @include small-mobile {
                padding: 40px 40px 0;
            }
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                @include large-desktop {
                    font-size: 20px;
                }
                strong {
                    font-size: 14px;
                    @include large-desktop {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .container {
        color: #FFF;
        padding: 0;
        position: relative;
        overflow: hidden;
        blockquote {
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
            div {
                display: inline;
            }
        }
        @include large-desktop {
            margin: 40px auto;
        }
        @include mobile {
            padding: 40px 0;
        }
        @include small-mobile {
            padding: 40px 0;
        }
        blockquote {
            text-align: left;
        }
        footer {
            text-align: left;
            display: flex;
            margin: 0;
            img {
                max-width: 65px;
                padding-right: 20px;
            }
            p {
                font-size: 12px;
                strong {
                    font-size: 16px;
                }
            }
        }
        .slideShowContainer {
            background: $teal;
            margin: 0 auto;
            max-width: 100%;
            padding: 0;
            @include large-desktop {
                padding: 80px;
            }
            @include desktop {
                padding: 80px;
            }
            @include small-desktop {
                padding: 80px;
            }
            @include tablet {
                max-width: 95%;
                padding: 40px;
            }
            @include mobile {
                padding: 40px;
            }
            @include small-mobile {
                padding: 40px;
            }

        }
    }
}
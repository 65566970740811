#ic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: white; */
  background-color: #738598;
  width: 100vw;
  max-width: 100vw;
  /* margin-top: 100px; */
  /* background-color: #35404e;
  width: 100vw;
  max-width: 100vw; */
}
.everyday-section-title-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #deecfc;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
  }
.ic-content-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    /* color: #323738; */
    color: #eef2f5;
    font-size: 40px;
    letter-spacing: 2px;
}
.ic-content-container > p {
  font-size: 18px;
  font-weight: 20px;
  font-family: "Helvetica Neue Light", sans-serif;
}
.ic-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 720px;
  top: 0;
  left: 0;
  /* color: #3c4142; */
  color: #eef2f5;
  text-align: left;
  /* margin-top: 250px; */
  margin-bottom: 50px;
}

@media only screen and (max-width: 800px) {
    .ic-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 550px;
        height: 100%;
        max-height: 450px;
        top: 0;
        left: 0;
        color: #eef2f5;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        /* margin-top: 200px; */
    }
    .everyday-section-title-ic {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #deecfc;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 3px;
      }
    .ic-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 35px;
        letter-spacing: 2px;
    }
    .ic-content-container > p {
      font-size: 15px;
      font-weight: 20px;
      font-family: "Helvetica Neue Light", sans-serif;
    }
}

@media only screen and (max-width: 450px) {
    .ic-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        top: 0;
        left: 0;
        color: #eef2f5;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        /* margin-top: 200px; */
        /* font-size: 13px; */
    }
    .everyday-section-title-ic {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #deecfc;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 3px;
      }
    .ic-content-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #eef2f5;
        font-size: 30px;
        letter-spacing: 2px;
    }
    .ic-content-container > p {
      font-size: 13px;
      font-weight: 20px;
      font-family: "Helvetica Neue Light", sans-serif;
    }
}

@media only screen and (max-width: 374px) {
    .ic-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        top: 0;
        left: 0;
        color: #eef2f5;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        /* margin-top: 50px; */
        font-size: 12px;
    }
}

@media only screen and (max-width: 330px) {
    .ic-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        top: 0;
        left: 0;
        color: #eef2f5;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        /* margin-top: 120px; */
        font-size: 11px;
    }
}
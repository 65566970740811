#educatorResources-publications-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .educatorResources-publications-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 40px 0 40px 0;
  }

 .educatorResources-publications-title { 
    width: 100%; 
    max-width: 1050px;
    text-align: center; 
    border-bottom: 2px solid #E2E2E4;
    line-height: 0px; 
 } 

.educatorResources-publications-title span { 
  background:#FFFFFF; 
  padding: 0 20px 0 20px; 
  margin-top: 20px;
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 25px;
}

.educatorResources-publications-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1050x;
  max-width: 1050px;
  padding: 15px 0 15px 0;
  -moz-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
  -webkit-box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
  box-shadow: -2px 8px 12px -8px rgba(0,0,0,0.2);
  margin: 10px 0 10px 0;
}
.educatorResources-publications-grid-container {
  display: grid;
  grid-template-columns: 300px repeat(1, 1fr);
  grid-column-gap: 30px;
  width: 100%;
  padding: 20px 0 20px 0;
}

.educatorResources-publications-image-container > img {
  width: 300px;
  height: 250px;
}

.educatorResources-publications-info-container {
  display: grid;
  grid-template-rows: 30px 1fr 30px;
  gap: 10px;
  text-align: left;
  background-color: #FFFFFF;
  padding: 0 30px 0 0;
  min-width: 720px;
}

.educatorResources-publications-tags {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.educatorResources-publications-hash {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.educatorHash-teal {
  font-family: 'Helvetica Neue Light';
  color: #ffffff;
  background-color: #007a8a;
  font-size: 14px;
  padding: 5px 12px 5px 12px;
  overflow: hidden;
  border-radius: 5px;
  /* box-shadow: 2px 4px 8px -2px rgba(0,0,0,0.5); */
}

.educatorHash-hash {
  color: #007a8a;
  font-family: 'Helvetica Neue Light';
  font-size: 16px;
  padding: 5px 12px 5px 12px;
}

.educatorResources-publications-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.educatorResources-publications-text > h2 {
  font-family: 'Helvetica Neue Medium';
  color: #111111;
  font-size: 19px;
}

.educatorResources-publications-text > p {
  font-family: 'Helvetica Neue Light';
  color: #111111;
  font-size: 16px;
  margin: 0;
}

.educatorResources-publications-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.educatorResources-publications-link > p {
    font-family: 'Helvetica Neue Light';
    color: #969696;
    font-size: 16px;
    margin: 0;
}

.educatorResources-publications-content-link {
  font-family: "Helvetica Neue Light";
  font-size: 30px;
  color: #00464f;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

.educatorResources-publications-content-link:hover {
  color: #007a8a;
  transition: color 0.5s;
}

.educatorResources-publications-bottomLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 30px 0;
}

.educatorResources-publications-content-bottomLink {
    font-family: "Helvetica Neue Light";
    font-size: 18px;
    color: #d14e1d;
    text-decoration: none;
}

.educatorResources-publications-content-bottomLink:hover {
    color: #b74217;
    transition: color 0.5s;
}

.educatorResources-publications-footer-container {
  margin: 50px 0 10px 0;
}

.educatorResources-publications-footer-container a {
  color: #CA4A1C;
  font-family: 'Helvetica Neue Regular',Arial, Helvetica, sans-serif;
  font-size: large;
  text-decoration: none;
}


@media only screen and (max-width: 1025px) {
  .educatorResources-publications-info-container {
    min-width: auto;
  }
}


@media only screen and (max-width: 800px) {
  .educatorResources-publications-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    max-height: auto;
    top: 0;
    left: 0;
    margin: 30px 0 20px 0;
  }

  .educatorResources-publications-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1050x;
    max-width: 1050px;
    padding: 15px 0 15px 0;
    -moz-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    box-shadow: 0 8px 8px -8px rgba(0,0,0,0.2);
    margin: 10px 0 10px 0;
  }

 .educatorResources-publications-title { 
    width: 100%; 
    max-width: 75vw;
    text-align: center; 
    border-bottom: 1px solid #ffffff; 
    line-height: 0px; 
    display: flex;
    justify-content: center;
    align-items: center;
 } 

.educatorResources-publications-title span { 
  background:#ffffff; 
  color: #111111;
  font-family: 'Helvetica Neue Medium';
  font-size: 19px;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
}

.educatorResources-publications-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1050x;
  max-width: 1050px;
}
.educatorResources-publications-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75vw;
  margin: 10px 0 10px 0;
}

.educatorResources-publications-image-container {
  display: flex;
  padding-bottom: 30px;
}

.educatorResources-publications-image-container > img {
  width: 300px;
  max-width: 75vw;
  height: auto;
}

.educatorResources-publications-info-container {
  display: grid;
  grid-template-rows: 30px 1fr 30px;
  gap: 10px;
  text-align: left;
  background-color: #ffffff;
  padding: 0;
}

.educatorResources-publications-text > h2 {
  font-family: 'Helvetica Neue Medium';
  color: #111111;
  font-size: 19px;
}

.educatorResources-publications-text > p {
  font-family: 'Helvetica Neue Light';
  color: #111111;
  font-size: 16px;
  margin: 0;
  line-height: 23px;
}

.educatorResources-publications-content-link {
  font-family: "Helvetica Neue Light";
  font-size: 30px;
  color: #00464f;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.educatorResources-publications-bottomLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 75vw;
  margin: 30px 0 30px 0;
}

}
.resourceDropDown-interest-area-contents > li:hover {
  font-weight: bolder;
  text-decoration: underline;
  color: #007A8A;
}

.resourceDropDown-category-contents > li:hover {
  font-weight: bolder;
  text-decoration: underline;
  color: #007A8A;
  }
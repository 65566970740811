#researchPost-team-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d14e1d;
}
.researchPost-team-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 1550px;
    top: 0;
    left: 0;
    margin-top: 40px;
    margin-bottom: 40px;
}
.researchPost-team-content-container > h3{
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.researchPost-names-container {
    display: flex;
    flex-direction: row;
}
.researchPost-names {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue Light';
    color: #e3eaea;
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: 1px;
}
.researchPost-names > h4 {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #e3eaea;
    padding-bottom: 3px;
}

@media only screen and (max-width: 1050px) {
    #researchPost-team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d14e1d;
    }
    .researchPost-team-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 700px;
        height: 100%;
        max-height: 1550px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .researchPost-team-content-container > h3{
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
    .researchPost-names-container {
        display: flex;
        flex-direction: row;
    }
    .researchPost-names {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        letter-spacing: 1px;
    }
    .researchPost-names > h4 {
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid #e3eaea;
        padding-bottom: 3px;
    }
}

@media only screen and (max-width: 800px) {
    #researchPost-team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d14e1d;
    }
    .researchPost-team-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 1550px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .researchPost-team-content-container > h3{
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
    .researchPost-names-container {
        display: flex;
        flex-direction: row;
    }
    .researchPost-names {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        letter-spacing: 1px;
    }
    .researchPost-names > h4 {
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid #e3eaea;
        padding-bottom: 3px;
    }
}

@media only screen and (max-width: 600px) {
    #researchPost-team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d14e1d;
    }
    .researchPost-team-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 1550px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .researchPost-team-content-container > h3{
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }
    .researchPost-names-container {
        display: flex;
        flex-direction: row;
    }
    .researchPost-names {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 17px;
        font-weight: lighter;
        letter-spacing: 1px;
    }
    .researchPost-names > h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e3eaea;
        padding-bottom: 2px;
    }
}

@media only screen and (max-width: 450px) {
    #researchPost-team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d14e1d;
    }
    .researchPost-team-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 1550px;
        top: 0;
        left: 0;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .researchPost-team-content-container > h3{
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }
    .researchPost-names-container {
        display: flex;
        flex-direction: row;
    }
    .researchPost-names {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica Neue Light';
        color: #e3eaea;
        font-size: 16px;
        font-weight: lighter;
        letter-spacing: 1px;
    }
    .researchPost-names > h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e3eaea;
        padding-bottom: 2px;
    }
}

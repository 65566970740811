.featuredResource{
    background-color: #FFFFFF;
    text-align: center;
    padding: 60px 0;
    .container {
        position: relative;
        padding: 0;
        @include large-desktop {
            margin: 40px auto;
        }
        .content {
            background: #FFFFFF;
        }
        blockquote {
            text-align: left;
        }
        footer {
            text-align: left;
            display: flex;
            margin: 0;
            img {
                max-width: 65px;
                padding-right: 20px;
            }
            p {
                font-size: 12px;
                margin: 0;
                strong {
                    font-size: 16px;
                }
            }
        }
        .content {
            margin: 0 auto;
            max-width: 100%;
            padding: 40px;
            text-align: left;
            @include tablet {
                max-width: 100%;
            }
            @include small-desktop {
                max-width: 100%;
            }
            @include desktop {
                max-width: 100%;
            }
            @include large-desktop {
                padding: 0;
                max-width: 100%;
            }
            h4 {
                color: $teal;
                margin-top: 0;
            }
            h4 > a {
                text-decoration: none;
            }
            h4 > a:hover {
                text-decoration: underline;
            }

            img {
                width: 100%;
            }
        }
        .imageCaption {
            color: $gray-text;
            margin: 0;
            padding: 5px 0;
        }
        .textBlock {
            text-align: left;
        }
        .text-half-step_bold {
            color: $bg-color;
        }
        .resourceCitation {
            background: #FFF;
            border-radius: 4px;
            color: $teal;
            margin-top: 20px;
            padding: 15px 20px;
            position: relative;
            text-align: left;
            .copyBtn {
                cursor: pointer;
            }
            @include large-desktop {
               
            }
            @include tablet{
               
            }
            @include small-mobile{
                max-width: 80%;
            }
            h4 {
                color: $gray-text;
                margin: 0 0 20px 0;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            p {
                color: $teal;
            }
            i {
                color: $teal;
                position: absolute;
                top: 15px;
                right: 20px;
            }
            a {
                text-decoration: none;
            }
        }
        h3{
            @include mobile-full{
                font-size: 25px;
                line-height: 31px;
                padding: 0px 0 20px 32px;
            }
        }
    }
    &.fullWidth {
        .imageContainer {
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }
    &.medium {
        .imageContainer {
            max-width: 1200px;
            margin: auto;
            padding: 0 30px;
            @include large-desktop { 
                padding: 0;
            }
            img {
                width: 100%;
            }
        }
    }
    .emphHeader::after{
        width: 84%;
        @include mobile-full{
            display: none;
        }
    }
}
#annualReport2021research-highlights-container {
    background-color: #C8D8DA;
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021research-highlights-content-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 14vw;
}

.annualReport2021research-highlights-content-container > img {
    width: 28vw;
    height: 100%;
}

.annualReport2021research-highlights-text-container {
    width: 65%; 
    font-family: 'Helvetica Neue Medium';
}

.annualReport2021research-highlights-text-container > h2 {
    color: #d14e1d;
    text-align: right;
    font-size: calc(20px + 2vw);
    margin: 150px 0 0;
}

.annualReport2021research-highlights-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.annualReport2021research-highlights-card-individ {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
}

.annualReport2021research-highlights-card-individ > img {
    width: 30%;
    height: 100%;
}

.annualReport2021research-highlights-card-individ-text-container {
    margin-left: 40px;
    text-align: left;
}

.annualReport2021research-highlights-card-individ-text-container > h3 {
    color: #00464f;
    font-size: calc(16px + 1vw);
}

.annualReport2021research-highlights-card-individ-text-container > p {
    color: #00464f;
    font-size: calc(12px + .5vw);
    text-align: justify;
}

.annualReport2021research-highlights-card-individ-text-container > a {
    color: #007a8a;
    font-size: medium;
}


@media only screen and (max-width: 1000px) {
  
    .annualReport2021research-highlights-text-container > h2 {
        text-align: center;
    }
    
    .annualReport2021research-highlights-card-individ {
        flex-direction: column;
    }
    
    .annualReport2021research-highlights-card-individ > img {
        width: 70%;
    }    

    .annualReport2021research-highlights-card-individ-text-container > h3 {
        text-align: center;
    }
    
}


#marineBycatch22Video-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .marineBycatch22Video-video-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1024px;
      height: 100%;
      max-height: 50000px;
      top: 0;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
  }
  .marineBycatch22Video-videoCard-container {
    display: flex;
    width: 1024px;
    height: 576px;
    margin-top: 30px;
    margin-bottom: 70px;
}
.marineBycatch22Video-video-title {
    width: 1024px;
    border-bottom: 2px solid rgb(65, 65, 65);
}

@media only screen and (max-width: 1150px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 800px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 800px;
          height: 450px;
          margin-top: 30px;
          margin-bottom: 70px;
      }
}

@media only screen and (max-width: 900px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 700px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 700px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 700px;
          height: 394px;
          margin-top: 30px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 700px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 500px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 500px;
          height: 281px;
          margin-top: 30px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 550px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 450px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 450px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 450px;
          height: 253px;
          margin-top: 30px;
          margin-bottom: 50px;
      }
}

@media only screen and (max-width: 450px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 350px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 350px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 350px;
          height: 197px;
          margin-top: 30px;
          margin-bottom: 40px;
      }
}

@media only screen and (max-width: 380px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 330px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 330px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 330px;
          height: 185px;
          margin-top: 40px;
          margin-bottom: 40px;
      }
}

@media only screen and (max-width: 340px) {
    #marineBycatch22Video-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .marineBycatch22Video-video-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 280px;
          height: 100%;
          max-height: 50000px;
          top: 0;
          left: 0;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      .marineBycatch22Video-video-title {
        width: 280px;
        border-bottom: 2px solid rgb(65, 65, 65);
    }
      .marineBycatch22Video-videoCard-container {
          display: flex;
          width: 280px;
          height: 157px;
          margin-top: 40px;
          margin-bottom: 40px;
      }
}
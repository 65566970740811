#teamArchive {
    padding: 0 0 80px 0;
    background: $bg-light;
    //border: red solid 4px;
    .teamGrid {
        padding: 60px 20px;
        .text-xxl {
            margin-bottom: 20px;
        }
        .teamCatContent {
            max-width: 70%;
            margin: 0 auto 60px auto;
            text-align: center;
            @include mobile {
                max-width: 80%;
            }
            @include small-mobile {
                max-width: 95%;
            }
        }
        .teamCard {
            background: #FFF;
            border-radius: 0;
            box-shadow: none;
            position: relative;
            text-align: left;
            overflow: visible;
            margin: 10px;
            height: calc(100% - 20px);
            &:after {
                content: '';
                position: absolute;
                height: 1px;
                background: #000;
                width: 100%;
                bottom: -10px;
            }
            .teamImage {
                background-position: top;
                min-height: 236px;
                @include tablet {
                    background-position: top center;
                    min-height: 436px;
                }
                 @include mobile {
                    background-position: top center;
                    min-height: 436px;
                }
                @include small-mobile {
                    background-position: top center;
                    min-height: 436px;
                }
            }
            h3 {
                margin: 0;
                @include mobile {
                    font-size: 25px;
                }
                @include small-mobile {
                    font-size: 25px;
                }
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .teamMemberPronouns {
                margin: 2px 0;
            }
            .teamMemberPosition {
                color: $gray-text;
                margin: 0;
            }
        }
    }
}
.f10-footer-container {
  height: 800px;
  font-family: 'Helvetica Neue Bold', Arial, Helvetica, sans-serif;
  color: white;
}

.f10-footer-content-container {
  position: relative;
  height: 100%;
  padding-left: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3fr 2fr;
}

.f10-footer-content-container a {
  color: white;
  text-decoration: none;
}

/* Orange Circle Content */

.f10-footer-newsletter-signup {
  position: relative;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  overflow: hidden;
}

.f10-footer-newsletter-text {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  line-height: 1.5;
}

.f10-footer-newsletter-text :first-child{
  font-size: large;
}

.f10-footer-newsletter-text :nth-child(2){
  font-size: xx-large;
  text-align: left;
  padding: 0 45px 0 0;
}

.f10-footer-newsletter-text :nth-child(3){
  font-size: large;
  padding: 0 100px 40px 50px;
  text-align: left;
}

.f10-footer-newsletter-subscribe-button {
  width: fit-content;
  background-color: black;
  border: 2px solid white;
  padding: 15px 30px !important;
  align-self: center;
}

.f10-footer-newsletter-subscribe-button:hover {
  background-color: #575857;
}

.f10-footer-newsletter-text > p {
  background-color: #BE5127;
  padding: 0;
}


.f10-footer-circle {
  position: relative;
  border-radius: 50%;
  background-color: #BE5127;
  width: 70vw;
  height: 70vw;
  left: 0;
  top: -33%;
  z-index: -1;
}

/* Logo / Header Links / Contact Info / Social Media / Legal */

.f10-footer-about-us {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
  padding: 15px 0;
}

.f10-footer-logo {
  margin-bottom: -20px;
}

.f10-footer-category-links{
  display: flex;
}

.f10-footer-category-links a {
  font-size: large;
}

.f10-footer-category-links-a{
    padding-right: 50px;
}


.f10-footer-office-info {
  display: flex;
  padding-right: 10px;
  justify-content: left;
  align-items: baseline;
  }

.f10-footer-office-info-a *,
.f10-footer-office-info-b *,
.f10-footer-office-info-c * {
  font-size: medium;
  padding-right: 40px;
}

.f10-footer-office-info-c > p {
  margin-right: 0;
  padding-right: 0;
}

.nowrap {
  text-wrap: nowrap;
}

.f10-footer-social-legal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
}

.f10-footer-social-media a{
  padding: 0 5px;
}

.f10-footer-social-media :first-child{
  padding-left: 0;
}

.f10-footer-legal-links {
  display: flex;
  justify-content: space-evenly;
  padding: 30px 0 0;
}

.f10-footer-legal-links a{
  font-size: small;
}

.f10-footer-category-links-a,
.f10-footer-category-links-b,
.f10-footer-office-info-a,
.f10-footer-office-info-b,
.f10-footer-office-info-c p,
.f10-footer-legal-links a {
  background-color: black;
}

.altPosition {
  display: none;
}

/* Divider Line */

.f10-footer-divider {
  width: 97%;
  height: 5px;
  background-color: white;
  display: flex;
  justify-self: flex-start;
}

/* Land Acknowledgement */

.f10-footer-land-acknowledgement {
  position: absolute;
  text-align: left;
  grid-column: 1/2;
  grid-row: 2;
}

.f10-footer-land-acknowledgement :first-child {
  padding: 30px 0 10px;
  font-size: x-large;
  font-weight: 300;
}

.f10-footer-land-acknowledgement :not(:first-child) {
  font-size: medium;
  line-height: 1.5;
}

.f10-footer-land-acknowledgement a {
  text-decoration: underline;
  font-size: medium !important;
}

.f10-footer-land-acknowledgement > h2,
.f10-footer-land-acknowledgement p {
  background-color: black;
}


@media only screen and (max-width: 1680px) {
  .f10-footer-newsletter-text :nth-child(3) {
    margin-left: 20px;
  }
  
}

@media only screen and (max-width: 1600px) {

    .f10-footer-content-container {
      grid-template-rows: 1.5fr 1fr;
    }  

  /* Orange Circle Content */

    .f10-footer-newsletter-signup {
      grid-row: 1 / 2;
      height: 90%;
    }

    .f10-footer-newsletter-text {
      justify-content: flex-start;
      padding: 50px;
      line-height: 1.5;
    }
    
    .f10-footer-newsletter-text :nth-child(2) {
      margin-left: 60px;
      margin-right: 10px;
    }

    .f10-footer-newsletter-text-bonus {
      display: none;
    }

    .f10-footer-circle {
      position: absolute;
      width: 60vw;
      height: 60vw;
      top: unset;
      bottom: 0;
    }
    
  /* Logo / Header Links / Contact Info / Social Media / Legal */

    .f10-footer-about-us {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      justify-content: space-between;
    }

    .f10-footer-logo {
      grid-column: 1;
    }

    .f10-footer-office-info {
      padding-right: 45vw;
    }
    
    .f10-footer-social-legal {
      z-index: 99;
    }
    
    .f10-footer-legal-links {
      padding: 30px 0 0 120px;
    }  

  /* Divider Line */

  .f10-footer-divider {
    grid-column: 1/3;
    grid-row: 2;
    width: calc(100% - 50px);
  }

    /* Land Acknowledgement */

  .f10-footer-land-acknowledgement {
    grid-column: 1/3;
    padding-right: 50px;

  }  
}

@media only screen and (max-width: 1300px) {
  .f10-footer-office-info-c {
    display: none;
  }
  
  .altPosition {
    display: flex;
  }
  }  

@media only screen and (max-width: 1150px) {

  .f10-footer-content-container {
    padding: 0 50px;
    grid-template-columns: 1fr;
    grid-template-rows: 200px 2.5fr .1fr 1fr;
  }  

    /* Orange Circle Content */

    .f10-footer-newsletter-signup {
      grid-column: 1;
      grid-row: 1;
      overflow: visible;
    }

    .f10-footer-newsletter-text {
      padding: 0;
    }
    
    .f10-footer-newsletter-text * {
      padding: 0;
    }

    .f10-footer-newsletter-text :nth-child(2){
      text-align: center;
      padding: 0;
      line-height: 1.2;
      margin: 20px 0;
      width: 65%;

    }  

    .f10-footer-circle {
      width: 100%;
      height: 100vw;
      bottom: -120px;
    }

  
    /* Logo / Header Links / Contact Info / Social Media / Legal */

    .f10-footer-about-us {
      grid-row: 2 / 3;
      display: grid;
      grid-template-rows: 55px 1fr 4fr 1fr;
    }

    .f10-footer-logo {
      grid-column: 1;
      grid-row: 2;
      display: flex;
      height: 100%;
      align-self: flex-end;
    }

    .f10-footer-category-links{
      grid-row: 3;
      grid-column: 1;
      flex-direction: column ;
      justify-content: center;
      align-items: flex-start;
      padding-top: 15px;
    }

    .f10-footer-category-links-a,
    .f10-footer-category-links-b {
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  
    .f10-footer-office-info {
      grid-row: 3;
      grid-column: 2;
      flex-direction: column;
      padding-right: 0;
      justify-content: center;
      align-items: end;
    }    

    .f10-footer-office-info-a,
    .f10-footer-office-info-b,
    .f10-footer-office-info-c {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .f10-footer-office-info-a *,
    .f10-footer-office-info-a a,
    .f10-footer-office-info-b *,
    .f10-footer-office-info-b a,
    .f10-footer-office-info-c * {
      padding-right: 0;
      text-align: right;
      justify-content: space-evenly;
    }

    .altPosition {
      display: none;
    }  

    .f10-footer-social-legal {
      grid-row: 4;
      grid-column: 1 / 3;
      display: grid;
      grid-template-columns: 3fr 1fr;
    }

    .f10-footer-social-media a{
      padding: 0 10px;
    }    

    .f10-footer-legal-links {
      flex-direction: column;
      text-align: right;
      height: 100%;
      padding: 0;
    }  
    
    /* Divider Line */

    .f10-footer-divider {
      grid-column: 1;
      grid-row: 3;
      width: 100%;
    }  

    /* Land Acknowledgement */

    .f10-footer-land-acknowledgement {
      grid-column: 1;
      grid-row: 4;
    }  

}

@media only screen and (max-width: 775px) {

  /* Orange Circle Content */

  .f10-footer-newsletter-text {
    display: grid;
    height: 150%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .f10-footer-newsletter-text :first-child{
    margin: auto;
  }  

  .f10-footer-newsletter-text :nth-child(2) {
    grid-row: 2;
    margin: auto;
  }

  .f10-footer-newsletter-subscribe-button {
    grid-row: 3;
    justify-self: center;
  }

  /* Logo / Header Links / Contact Info / Social Media / Legal */

  .f10-footer-social-legal {
    display: flex;
    flex-direction: column;
  }

  .f10-footer-legal-links {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items:flex-end;
  }  

  /* Divider Line */

  /* Land Acknowledgement */

}

@media only screen and (max-width: 600px) {

  .f10-footer-content-container {
    padding: 0 15px;
    grid-template-rows: 100px 500px 700px 5px 1fr;
  }  

  /* Orange Circle Content */

  .f10-footer-newsletter-text :first-child{
    margin: 18px 0;
  }  

  .f10-footer-newsletter-text :nth-child(2) {
    margin: 0 0 20px;
  }


  .f10-footer-newsletter-signup {
    grid-row: 2;
  }

  .f10-footer-circle {
    width: 100%;
    height: 90vw;
    top: 0;
    bottom: unset;
  }

  .f10-footer-newsletter-text {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 8vw 0 0 0;
  }

  .f10-footer-newsletter-text :first-child{
    padding: 0 60px;
  }  

  .f10-footer-newsletter-text :nth-child(2){
    padding: 0 10px;
  }    

  /* Logo / Header Links / Contact Info / Social Media / Legal */

  .f10-footer-about-us {
    grid-row: 3 / 4;
    grid-template-rows: 70px 200px 300px 150px;
  }

  .f10-footer-logo {
    position: relative;
    top: 20px;
    grid-column: 1/3;
    justify-content: center;
    grid-row: 1;
    width: 100%;
  }

  .f10-footer-logo img {
    position: relative;
    bottom: 30px;
  }

  .f10-footer-category-links{
    grid-row: 2;
    grid-column: 1/3;
    flex-direction: row ;
    justify-content: space-between;
    align-items: center;
  }

  .f10-footer-category-links-b {
    text-align: right;
  }

  .f10-footer-office-info {
    grid-column: 1/3;
    align-items: center;
  }    

  .f10-footer-office-info-a *,
  .f10-footer-office-info-a a,
  .f10-footer-office-info-b *,
  .f10-footer-office-info-b a,
  .f10-footer-office-info-c * {
    text-align: center;
  }

  .f10-footer-social-legal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 150px;
  }

  .f10-footer-legal-links {
    height: fit-content;
    padding-bottom: 20px;
    margin-top: 30px;
  }  

  /* Divider Line */

  .f10-footer-divider {
    grid-row: 4;
    margin-top: 100px;
  }  


  /* Land Acknowledgement */

  .f10-footer-land-acknowledgement {
    padding-right: 0;
    grid-row: 5;
    margin-top: 100px;
  }

  .f10-footer-land-acknowledgement :not(:first-child) {
    line-height: 2;
  }  
  
}

@media only screen and (max-width: 500px) {

  .f10-footer-content-container {
    grid-template-rows: 50px 100vw 750px 5px 1fr;
  }  

  /* Orange Circle Content */

  .f10-footer-newsletter-signup {
    height: 100%;
  }

  .f10-footer-newsletter-text {
    padding: 0;
  }

  .f10-footer-newsletter-text :first-child{
    font-size: medium;
    padding: 0;
  }  

  .f10-footer-newsletter-text :nth-child(2){
    font-size: x-large;
  }    

  .f10-footer-newsletter-subscribe-button {
    padding: 15px !important;
  }

    /* Logo / Header Links / Contact Info / Social Media / Legal */

    .f10-footer-social-media{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 25px 0;
    }    

    .f10-footer-legal-links {
      padding-bottom: 0;
    }    

}

@media only screen and (max-width: 350px) {

  /* Orange Circle Content */

  .f10-footer-newsletter-text :first-child{
    font-size: small;
    padding: 2em 0 0 0;
    margin: 0;
  }  

  .f10-footer-newsletter-text :nth-child(2){
    font-size: large;
    padding: 1em 0;
    margin: 0;
  }    

  .f10-footer-newsletter-subscribe-button {
    padding: 10px !important;
  }
  
  /* Logo / Header Links / Contact Info / Social Media / Legal */

  .f10-footer-legal-links :first-child{
    text-align: left;
  }    

}

/* .f10-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.f10-footer-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1600px;
  height: 430px;
  max-height: 430px;
  margin-left: 50px;
  margin-top: 30px;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  height: 100%;
  max-height: 200px;
  margin-top: -12px;
}
.footer-contact > a {
  margin-left: 3px;
  margin-top: 9px;
  font-size: 18px;
  font-family: "Helvetica Neue Light";
  text-decoration: underline;
  color: #d14e1d;
  text-align: left;
  word-spacing: 2px;
  letter-spacing: 0.5px;
}
.footer-contact > img {
  margin-left: 0;
}
.footer-privacy {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  height: 100%;
  max-height: 200px;
}
.footer-privacy > a {
  margin-left: 3px;
  margin-top: 9px;
  font-size: 13px;
  font-family: "Helvetica Neue Light";
  text-decoration: none;
  color: #d14e1d;
  text-align: left;
}
.footer-connect {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
}
.footer-connect > h3 {
  text-align: left;
  font-family: "Helvetica Neue Light";
  color: white;
  font-size: 24px;
  margin-top: -3px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}
.footer-connect-links > p > a {
  margin-top: 7px;
  font-size: 14px;
  font-family: "Helvetica Neue Light";
  text-decoration: underline;
  color: white;
  text-align: left;
  letter-spacing: 1px;
}
.footer-connect-links {
  text-align: left;
  line-height: 7px;
  margin-top: -6px;
}
.footer-office {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
}
.footer-office > h3 {
  text-align: left;
  font-family: "Helvetica Neue Light";
  color: white;
  font-size: 24px;
  margin-top: -3px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}
.footer-office > p {
  font-size: 14px;
  font-family: "Helvetica Neue Light";
  color: white;
  text-align: left;
  line-height: 3px;
}

.footer-office-contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
}
.footer-office-contact > h3 {
  text-align: left;
  font-family: "Helvetica Neue Light";
  color: white;
  font-size: 24px;
  margin-top: -3px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}
.footer-office-contact > p {
  font-size: 14px;
  font-family: "Helvetica Neue Light";
  color: white;
  text-align: left;
  line-height: 3px;
}
.footer-landAcknowledgement {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 200px;
}
.footer-landAcknowledgement > h3 {
  text-align: left;
  font-family: "Helvetica Neue Light";
  color: white;
  font-size: 24px;
  margin-top: -3px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}
.footer-landAcknowledgement > p {
  font-size: 14px;
  font-family: "Helvetica Neue Light";
  color: white;
  text-align: left;
  line-height: 18px;
  margin-top: 5px;
}
.footer-landAcknowledgement a {
  color: #d14e1d;
}

@media only screen and (max-width: 1450px) {
  .f10-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
  
  .f10-footer-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 20000px;
    margin-right: 50px;
    margin-top: 60px;
    margin-bottom: 200px;
  }
  
  .footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 1500px;
    margin-bottom: 20px;
  }

  .footer-contact > a {
    margin-top: 5px;
    font-size: 18px;
    font-family: "Helvetica Neue Light";
    text-decoration: underline;
    color: #d14e1d;
    text-align: left;
    word-spacing: 2px;
    letter-spacing: 0.5px;
  }

  .footer-contact > img {
    margin-left: 0;
  }

  .footer-privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 1500px;
  }
  
  .footer-privacy > a {
    margin-left: 3px;
    margin-top: 9px;
    font-size: 13px;
    font-family: "Helvetica Neue Light";
    text-decoration: none;
    color: #d14e1d;
    text-align: left;
  }
  .footer-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .footer-connect > h3 {
    text-align: left;
    font-family: "Helvetica Neue Light";
    color: white;
    font-size: 24px;
    margin-top: -3px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .footer-connect-links > p > a {
    margin-top: 7px;
    font-size: 14px;
    font-family: "Helvetica Neue Light";
    text-decoration: underline;
    color: white;
    text-align: left;
    letter-spacing: 1px;
  }
  .footer-connect-links {
    text-align: left;
    line-height: 7px;
    margin-top: -10px;
  }
  .footer-office {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .footer-office > h3 {
    text-align: left;
    font-family: "Helvetica Neue Light";
    color: white;
    font-size: 24px;
    margin-top: -3px;
    margin-bottom: 2px;
    letter-spacing: 2px;
  }
  .footer-office > p {
    font-size: 14px;
    font-family: "Helvetica Neue Light";
    color: white;
    text-align: left;
    line-height: 3px;
  }
  
  .footer-office-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer-office-contact > h3 {
    text-align: left;
    font-family: "Helvetica Neue Light";
    color: white;
    font-size: 24px;
    margin-top: -3px;
    margin-bottom: 2px;
    letter-spacing: 2px;
  }
  .footer-office-contact > p {
    font-size: 14px;
    font-family: "Helvetica Neue Light";
    color: white;
    text-align: left;
    line-height: 3px;
  }
  .footer-landAcknowledgement {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .footer-landAcknowledgement > h3 {
    text-align: center;
    font-family: "Helvetica Neue Light";
    color: white;
    font-size: 24px;
    margin-top: -3px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .footer-landAcknowledgement > p {
    font-size: 14px;
    font-family: "Helvetica Neue Light";
    color: white;
    text-align: center;
    line-height: 18px;
    margin-top: 5px;
  }
} */
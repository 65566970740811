.thirdPartyCode {
    // background-color: #FFF;
    // color: #FFF;
    text-align: center;
    padding: 20px 0;
    .container {
        position: relative;
        padding: 0 20px;
        @include large-desktop {
            margin: 40px auto;
            padding: 0;
        }
        @include small-desktop {
            padding: 0;
        }
        h3 {
            color: #000;
        }
        iframe{
            @include mobile-full{
                width: 100%;
            }
        }
    }

}
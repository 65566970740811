#philanthropyDirectorApply-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #d14e1d;
  }
  .philanthropyDirectorApply-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 30000px;
    top: 0;
    left: 0;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .philanthropyDirectorApply-content-container > h2 {
      color: #e3eaea;
      font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
      font-size: 50px;
      font-weight: lighter;
      line-height: 55px;
      letter-spacing: 2px;
      margin-bottom: 0px;
      margin-top: 0px;
  }
  .philanthropyDirectorApply-content-container > p {
      color: #e3eaea;
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      font-size: 20px;
      line-height: 35px;
      text-align: left;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .philanthropyDirectorApply-content-container > p > a {
      color: #e3eaea;
  }
@media only screen and (max-width: 1000px) {
    #philanthropyDirectorApply-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #d14e1d;
      }
      .philanthropyDirectorApply-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 60px;
        margin-bottom: 60px;
      }
      .philanthropyDirectorApply-content-container > h2 {
          color: #e3eaea;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 50px;
          font-weight: lighter;
          line-height: 55px;
          letter-spacing: 2px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorApply-content-container > p {
          color: #e3eaea;
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 20px;
          line-height: 35px;
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
      }
}

@media only screen and (max-width: 700px) {
    #philanthropyDirectorApply-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #d14e1d;
      }
      .philanthropyDirectorApply-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 450px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .philanthropyDirectorApply-content-container > h2 {
          color: #e3eaea;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 40px;
          font-weight: lighter;
          line-height: 55px;
          letter-spacing: 2px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorApply-content-container > p {
          color: #e3eaea;
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 20px;
          line-height: 35px;
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
      }
}

@media only screen and (max-width: 500px) {
    #philanthropyDirectorApply-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #d14e1d;
      }
      .philanthropyDirectorApply-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
      }
      .philanthropyDirectorApply-content-container > h2 {
          color: #e3eaea;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 30px;
          font-weight: lighter;
          line-height: 35px;
          letter-spacing: 2px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorApply-content-container > p {
          color: #e3eaea;
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 18px;
          line-height: 30px;
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
      }
}

@media only screen and (max-width: 380px) {
    #philanthropyDirectorApply-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #d14e1d;
      }
      .philanthropyDirectorApply-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
      }
      .philanthropyDirectorApply-content-container > h2 {
          color: #e3eaea;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 30px;
          font-weight: lighter;
          line-height: 35px;
          letter-spacing: 2px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorApply-content-container > p {
          color: #e3eaea;
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 18px;
          line-height: 30px;
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
      }
}

@media only screen and (max-width: 350px) {
    #philanthropyDirectorApply-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #d14e1d;
      }
      .philanthropyDirectorApply-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 260px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
      }
      .philanthropyDirectorApply-content-container > h2 {
          color: #e3eaea;
          font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
          font-size: 25px;
          font-weight: lighter;
          line-height: 35px;
          letter-spacing: 2px;
          margin-bottom: 0px;
          margin-top: 0px;
      }
      .philanthropyDirectorApply-content-container > p {
          color: #e3eaea;
          font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
          font-size: 17px;
          line-height: 30px;
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
      }
}
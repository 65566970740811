.researchCollaborate-container {
    color: black;
    font-family: 'Helvetica Neue Light',Arial, Helvetica, sans-serif;
    background-color: #f2f2f2;
    padding: 50px 0;
}

.researchCollaborate-content-container {
    padding: 0 100px;
    display: flex;
}

.researchCollaborate-img-container {
    padding: 0 25px 50px;
    min-width: fit-content;
}

.researchCollaborate-text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 25px;
}

.researchCollaborate-text-container > h2 {
    font-family: 'Helvetica Neue Medium',Arial, Helvetica, sans-serif;
    font-size: xx-large;
}

.researchCollaborate-button-container {
    padding: 45px 0;
}

.researchCollaborate-button-container > a {
    background-color: #d14e1d;
    color: #f9f9f9;
    text-decoration: none;
    font-size: x-large;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    box-shadow: rgb(177 177 177) 1px 1px;
    padding: 1em;
    margin: .5em;
    border-radius: 12px;
}

@media only screen and (max-width: 1200px) {
    .researchCollaborate-content-container {
        flex-direction: column;
    }
    
    .researchCollaborate-text-container {
        text-align: center;
        padding-left: 0;
    }
    
    .researchCollaborate-text-container p{
        text-align: left;
    }

    .researchCollaborate-img-container {
        padding: 0 0 20px 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }    

    .researchCollaborate-button-container {
        display: flex;
        align-content: center;
        justify-content: center;
    }    
}

@media only screen and (max-width: 700px) {
    .researchCollaborate-content-container {
        padding: 0 60px;
    }
    
    .researchCollaborate-img-container img{
        max-width: 70vw;
    }
}

@media only screen and (max-width: 500px) {
    .researchCollaborate-content-container {
        padding: 0 40px;
    }

    .researchCollaborate-button-container > a {
        font-size: large;
        white-space: nowrap;
    }
}
.newEquityConsiderations-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #F9F9F9;
  }
  .newEquityConsiderations-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 65vw;
    height: 100%;
    max-height: 20000px;
    /* margin: 0 0 60px 0; */
    margin: 50px 0 60px 0;
    padding-bottom: 0;
  }
  .newEquityConsiderations-content-container > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 26px;
    color: #111111;
    letter-spacing: 1px;
    margin: 0 0 40px 0;
  }

  .newEquityConsiderations-carousel-slider {
    display: flex;
    flex-direction: column;
    width: 65vw;
    height: 100%;
    max-height: 20000px;
    }
    .slick-prev:before,
    .slick-next:before {
    color: rgb(40, 40, 40);
    }

    .newEquityConsiderations-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 20000px;
      background-color: #00464f;
      border-radius: 20px;
    }
    .newEquityConsiderations-card-image {
    display: flex;
    margin: 0 20px 20px 20px;
    }
    .newEquityConsiderations-card-image > img {
      border-radius: 20px;
    }
    .newEquityConsiderations-card-content {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      max-width: 300px;
      height: 235px;
    }
    .newEquityConsiderations-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
    }
    .newEquityConsiderations-text > h3 {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      color: #f1f1f1;
      font-size: 20px;
      text-align: left;
      line-height: 30px;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    .newEquityConsiderations-text > p {
      font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
      color: #f1f1f1;
      font-size: 17px;
      text-align: left;
      line-height: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .newEquityConsiderations-card-content-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      margin: 23px;
    }

    
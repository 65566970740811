#threeCD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 150px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
  }
  .threeCD-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 1024px;
    top: 0;
    left: 0;
  }
  .threeCD-svg-container {
    display: flex;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 1024px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 100px;
  }
  .threeCD-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #3c4142;
    text-align: left;
    width: 100%;
    max-width: 1024px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .threeCD-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 50px;
    letter-spacing: 2px;
  }
  .threeCD-text-container > p {
    font-size: 18px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
  }

@media only screen and (max-width: 800px) {
  #threeCD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 130px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    letter-spacing: 3px;
  }
  .threeCD-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    max-height: 1080px;
    top: 0;
    left: 0;
  }
  .threeCD-svg-container {
    display: flex;
    width: 100%;
    max-width: 550px;
    height: 100%;
    max-height: 450px;
    margin-left: 10px;
    margin-right: 10px;
  }
.threeCD-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #3c4142;
    text-align: left;
    width: 100%;
    max-width: 550px;
    height: 100%;
    max-height: 500px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .threeCD-text-container > h2 {
    font-family: 'Roboto Bold', sans-serif;
    color: #323738;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .threeCD-text-container > p {
    font-size: 15px;
    font-family: "Helvetica Neue Light", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 450px) {
  #threeCD-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin-top: 100px;
  }
  .everyday-section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bcc6c7;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;
  }
    .threeCD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .threeCD-svg-container {
        display: flex;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        margin-left: 10px;
        margin-right: 10px;
      }
    .threeCD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #3c4142;
        text-align: left;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 330px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 13px;
      }
      .threeCD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 30px;
        letter-spacing: 2px;
      }
      .threeCD-text-container > p {
        font-size: 13px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
}

@media only screen and (max-width: 374px) {
    .threeCD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .threeCD-svg-container {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        margin-left: 10px;
        margin-right: 10px;
      }
    .threeCD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #3c4142;
        text-align: left;
        width: 100%;
        max-width: 300px;
        height: 100%;
        max-height: 300px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
      }
      .threeCD-text-container > h2 {
        font-family: 'Roboto Bold', sans-serif;
        color: #323738;
        font-size: 25px;
        letter-spacing: 2px;
      }
      .threeCD-text-container > p {
        font-size: 12px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
}

@media only screen and (max-width: 330px) {
    .threeCD-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        max-height: 1080px;
        top: 0;
        left: 0;
      }
      .threeCD-svg-container {
        display: flex;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        margin-left: 10px;
        margin-right: 10px;
      }
    .threeCD-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #3c4142;
        text-align: left;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 280px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 11px;
      }
      .threeCD-text-container > p {
        font-size: 11px;
        font-family: "Helvetica Neue Light", sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
}
.icp {
    background: #FFF;
    padding: 20px 0;
    &.gray {
        h1,h2,h3,h4,h5,h6 {
            color: inherit;
        }
        background: $bg-light;
        a {
            color: inherit;
        }
        em {
            color: inherit;
            font-weight: inherit;
        }
        .container {
            background: $bg-light;
        }
    }
    &.green {
        background: $teal;
        color: #FFF;
        h1,h2,h3,h4,h5,h6 {
            color: inherit;
        }
        a {
            color: #FFF;
        }
        em {
            color: inherit;
            font-weight: inherit;
        }
        .container {
            background: $teal;
        }
    }
    &.orange {
        background: $orange;
        color: #FFF;
        h1,h2,h3,h4,h5,h6 {
            color: inherit;
        }
        a {
            color: #FFF;
        }
        em {
            color: inherit;
            font-weight: inherit;
        }
        .container {
            background: $orange;
        }
    }
    @include small-desktop { 
        padding: 30px 0;
    }
     @include tablet { 
        padding: 30px 0;
    }
    @include mobile { 
        padding: 0;
    }
    @include small-mobile { 
        padding: 0;
    }
    .left {
        flex-direction: row-reverse;
    }
    .container {
        padding: 0;
        @include small-desktop { 
            padding: 30px 0;
        }
         @include tablet { 
            padding: 30px 0;
        }
        @include mobile { 
            padding: 20px;
        }
        @include small-mobile { 
            padding: 20px;
        }
        @include mobile-full{
            padding-left: 0;
            padding-right: 0;
        }
        .left {
            flex-direction: row-reverse;
        }
        .icpContent {
            padding: 0 20px;
            margin: auto;
            text-align: left;
            @include tablet {
                padding: 0 40px;
            }
            @include mobile { 
                padding: 0 40px;
            }
            @include small-mobile { 
                padding: 0 20px;
            }
            em {
                color: inherit;
                font-weight: inherit;
            }
        }
    .icpHeading {
        font-size: x-large
        }
    }
}
#autoResearch-womenInAuto-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  .autoResearch-womenInAuto-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 20000px;
    top: 0;
    left: 0;
    margin-bottom: 100px;
  }

  .autoResearch-description-content-container > p {
    font-family: 'Helvetica Neue Light';
  font-size: 17px;
  color: rgb(62, 62, 62);
  font-weight: bolder;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }
  .autoResearch-womenInAuto-paragraph {
    display: flex;
    justify-content: center;
    align-items: normal;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 10000px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .autoResearch-womenInAuto-paragraph > p {
    font-family: 'Helvetica Neue Light';
  font-size: 17px;
  color: rgb(62, 62, 62);
  font-weight: bolder;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  }

  @media only screen and (max-width: 1200px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        color: rgb(62, 62, 62);
        font-weight: bolder;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 30px;
      }
  }

  @media only screen and (max-width: 1000px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 800px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        color: rgb(62, 62, 62);
        font-weight: bolder;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 30px;
      }
  }

  @media only screen and (max-width: 600px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
        font-size: 17px;
        color: rgb(62, 62, 62);
        font-weight: bolder;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 30px;
      }
  }

  @media only screen and (max-width: 450px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 280px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }

  @media only screen and (max-width: 350px) {
    #autoResearch-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
      }
      .autoResearch-description-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 20000px;
        top: 0;
        left: 0;
        margin-top: 50px;
        padding-bottom: 50px;
      }
    
      .autoResearch-description-content-container > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
      .autoResearch-womenInAuto-paragraph {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 10000px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .autoResearch-womenInAuto-paragraph > p {
        font-family: 'Helvetica Neue Light';
      font-size: 17px;
      color: rgb(62, 62, 62);
      font-weight: bolder;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 30px;
      }
  }


/* CARD LINKS CSS */
.autoResearch-cards-container {
  position: relative;
  margin-top: 50px;
  width: 500px;
  height: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.autoResearch-cards-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.5s ease;
}

.autoResearch-cards-container:hover .autoResearch-cards-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.4);
}

.autoResearch-cards-container > img {
  position: absolute;
  width: 500px;
  height: 300px;
  left: 0;
}

.autoResearch-cards-title {
  position: absolute;
  width: 500px;
  left: 0;
  top: 120px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  transition: top 0.5s ease;
}

.autoResearch-cards-container:hover .autoResearch-cards-title {
  top: 110px;
}

.autoResearch-cards-button {
  position: absolute;
  width: 500px;
  left: 0;
  top: 145px;
  text-align: center;
  opacity: 0;
  font-size: 13px;
  transition: opacity 0.35s ease;
}

.autoResearch-cards-button span {
  width: 150px;
  padding: 7px 30px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.autoResearch-cards-container:hover .autoResearch-cards-button {
  opacity: 1;
}

@media only screen and (max-width: 800px) {
  .autoResearch-cards-container {
    position: relative;
    margin-top: 50px;
    width: 500px;
    height: 300px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .autoResearch-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .autoResearch-cards-container > img {
    position: absolute;
    width: 500px;
    height: 300px;
    left: 0;
  }
  
  .autoResearch-cards-title {
    position: absolute;
    width: 500px;
    left: 0;
    top: 120px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-title {
    top: 110px;
  }
  
  .autoResearch-cards-button {
    position: absolute;
    width: 500px;
    left: 0;
    top: 145px;
    text-align: center;
    opacity: 0;
    font-size: 13px;
    transition: opacity 0.35s ease;
  }
  
  .autoResearch-cards-button span {
    width: 150px;
    padding: 7px 30px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-button {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .autoResearch-cards-container {
    position: relative;
    margin-top: 50px;
    width: 400px;
    height: 240px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .autoResearch-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .autoResearch-cards-container > img {
    position: absolute;
    width: 400px;
    height: 240px;
    left: 0;
  }
  
  .autoResearch-cards-title {
    position: absolute;
    width: 400px;
    left: 0;
    top: 90px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-title {
    top: 80px;
  }
  
  .autoResearch-cards-button {
    position: absolute;
    width: 400px;
    left: 0;
    top: 145px;
    text-align: center;
    opacity: 0;
    font-size: 13px;
    transition: opacity 0.35s ease;
  }
  
  .autoResearch-cards-button span {
    width: 150px;
    padding: 7px 30px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-button {
    opacity: 1;
  }
}

@media only screen and (max-width: 450px) {
  .autoResearch-cards-container {
    position: relative;
    margin-top: 50px;
    width: 280px;
    height: 168px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .autoResearch-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .autoResearch-cards-container > img {
    position: absolute;
    width: 280px;
    height: 168px;
    left: 0;
  }
  
  .autoResearch-cards-title {
    position: absolute;
    width: 280px;
    left: 0;
    top: 80px;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-title {
    top: 70px;
  }
  
  .autoResearch-cards-button {
    position: absolute;
    width: 280px;
    left: 0;
    top: 145px;
    text-align: center;
    opacity: 0;
    font-size: 13px;
    transition: opacity 0.35s ease;
  }
  
  .autoResearch-cards-button span {
    width: 150px;
    padding: 7px 30px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-button {
    opacity: 1;
  }
}

@media only screen and (max-width: 350px) {
  .autoResearch-cards-container {
    position: relative;
    margin-top: 50px;
    width: 270px;
    height: 162px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .autoResearch-cards-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .autoResearch-cards-container > img {
    position: absolute;
    width: 270px;
    height: 162px;
    left: 0;
  }
  
  .autoResearch-cards-title {
    position: absolute;
    width: 270px;
    left: 0;
    top: 65px;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top 0.5s ease;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-title {
    top: 55px;
  }
  
  .autoResearch-cards-button {
    position: absolute;
    width: 270px;
    left: 0;
    top: 145px;
    text-align: center;
    opacity: 0;
    font-size: 13px;
    transition: opacity 0.35s ease;
  }
  
  .autoResearch-cards-button span {
    width: 150px;
    padding: 7px 30px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .autoResearch-cards-container:hover .autoResearch-cards-button {
    opacity: 1;
  }
}
.classAccordion-section-container {
    background-color: #00464f;
}

.classAccordion-content-container {
    margin: 0 10% 50px;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}

.classAccordion-item {
    background-color: #E0ECED;
    color: #007A8A;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    border: #AEC4C6 2px solid;

}

.classAccordion-title {
    display: grid;
    grid-template-columns: 220px 1fr 50px;
}

.classAccordion-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    justify-self: flex-start;
}

.classAccordion-text h3{
    color: #000;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.classAccordion-title > i{
    display: flex;
    align-items: center;
    color: #007A8A;
    font-size: x-large;
}

.classAccordion-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: #AEC4C6 2px solid;
}

.classAccordion-image {
    padding: 10px 10px;
}

.classAccordion-image > img{
    width: 150px;
    height: 150px;
}

.classAccordion-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Helvetica Neue Regular', Arial, Helvetica, sans-serif;
    color: #000;
    height: fit-content;
    padding-top: 10px;
}

.classAccordion-description > p{
    text-align: left;
    width: 85%;
    padding: 0 25px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.classAccordion-description > p:empty{
    display: none;
}

@media only screen and (max-width: 1250px) {  

    .classAccordion-content {
        flex-direction: column;  
        align-items: center;  
    }
    
}

@media only screen and (max-width: 768px) {  
    .classAccordion-title {
        display: flex;
        flex-direction: column;
    }

    .classAccordion-image > img{
        width: 100%;
        max-width: 210px;
        height: 100%;
    }    

    .classAccordion-title > i{
        justify-content: center;
        font-size: xx-large;
    }    

    .classAccordion-text h2,
    .classAccordion-text h3{
        text-align: center;
    }
    
    
}


@media only screen and (max-width: 600px) {  

    .classAccordion-title-text {
        text-align: left;
    }    

}

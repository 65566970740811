#researcherResources-emphasizedContent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
}

.researcherResources-emphasizedContent-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    background-color: #F2F2F2;
    margin-top: 30px;
    margin-bottom: 80px;
  }

.researcherResources-emphasizedContent-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 680px;
    height: 100%;
    background-color: #F2F2F2;
    margin-top: 50px;
    margin-bottom: 50px;
}

.researcherResources-emphasizedContent-titles {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 680px;
}

.researcherResources-emphasizedContent-titles > h2 {
    font-family: 'Helvetica Neue Medium';
    color: #00464F;
    font-size: 20px;
    margin-bottom: 15px;
}

.researcherResources-emphasizedContent-titles > h1 {
    font-family: 'Helvetica Neue Medium';
    color: #111111;
    font-size: 28px;
    margin: 0;
}

.researcherResources-emphasizedContent-titles > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 19px;
    line-height: 27px;
}

.researcherResources-emphasizedConten-image {
    display: flex;
}

.researcherResources-emphasizedConten-image > img {
    width: 680px;
    height: 380px;
}

.researcherResources-emphasizedContent-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    max-width: 680px;
    height: 100%;
    margin: 20px 0;
}

.researcherResources-emphasizedContent-text > p {
    font-family: 'Helvetica Neue Light';
    color: #111111;
    font-size: 18px;
    line-height: 27px;
    margin: 15px 0 0 0;
}


@media only screen and (max-width: 800px) {
    
    .researcherResources-emphasizedContent-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 85vw;
        height: 100%;
        background-color: #F2F2F2;
        margin-top: 30px;
        margin-bottom: 80px;
      }
    
    .researcherResources-emphasizedContent-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 75vw;
        height: 100%;
        background-color: #F2F2F2;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    .researcherResources-emphasizedContent-titles {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 100%;
       max-width: 75vw;
    }
    
    .researcherResources-emphasizedContent-titles > h2 {
        font-family: 'Helvetica Neue Medium';
        color: #00464F;
        font-size: 20px;
        margin-bottom: 15px;
    }
    
    .researcherResources-emphasizedContent-titles > h1 {
        font-family: 'Helvetica Neue Medium';
        color: #111111;
        font-size: 28px;
        margin: 0;
    }
    
    .researcherResources-emphasizedContent-titles > p {
        font-family: 'Helvetica Neue Light';
        color: #111111;
        font-size: 18px;
        line-height: 27px;
    }
    
    .researcherResources-emphasizedConten-image > img {
        width: 75vw;
        height: auto;
    }
    
    .researcherResources-emphasizedContent-text {
        max-width: 75vw;
    }
    
    .researcherResources-emphasizedContent-text > p {
        font-size: 17px;
    }
}


@media only screen and (max-width: 500px) {

    .researcherResources-emphasizedContent-content-container {
        margin-bottom: 60px;
      }
    
    .researcherResources-emphasizedContent-info-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .researcherResources-emphasizedContent-titles > h2 {
        font-size: 18px;
    }
    
    .researcherResources-emphasizedContent-titles > h1 {
        font-size: 22px;
    }
    
    .researcherResources-emphasizedContent-titles > p {
        font-size: 17px;
    }
}
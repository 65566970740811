#philanthropyDirectorDescription-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.philanthropyDirectorDescription-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 3000px;
    top: 0;
    left: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.philanthropyDirectorDescription-intro-content-container > h3 {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  color: #e3eaea;
  font-size: 40px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 1000px) {
    #philanthropyDirectorDescription-intro-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
  }
  .philanthropyDirectorDescription-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 600px;
      height: 100%;
      max-height: 3000px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 20px;
  }
  .philanthropyDirectorDescription-intro-content-container > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 700px) {
    #philanthropyDirectorDescription-intro-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
  }
  .philanthropyDirectorDescription-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 3000px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 20px;
  }
  .philanthropyDirectorDescription-intro-content-container > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #e3eaea;
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    #philanthropyDirectorDescription-intro-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
  }
  .philanthropyDirectorDescription-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 300px;
      height: 100%;
      max-height: 3000px;
      top: 0;
      left: 0;
      margin-top: 40px;
      margin-bottom: 20px;
  }
  .philanthropyDirectorDescription-intro-content-container > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #e3eaea;
    font-size: 30px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 380px) {
    #philanthropyDirectorDescription-intro-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
  }
  .philanthropyDirectorDescription-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 280px;
      height: 100%;
      max-height: 5000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 20px;
  }
  .philanthropyDirectorDescription-intro-content-container > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 350px) {
    #philanthropyDirectorDescription-intro-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
  }
  .philanthropyDirectorDescription-intro-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 260px;
      height: 100%;
      max-height: 5000px;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-bottom: 20px;
  }
  .philanthropyDirectorDescription-intro-content-container > h3 {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #e3eaea;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorDescription-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    }
  }
.standardContent {
    //background: $bg-light;
    // padding: 0 20px;
    @include mobile {
        //padding-left: 32px;
        //padding-right: 32px;
        max-width: 100%;
    }
    @include small-mobile {
        padding-left: 5px;
        padding-right: 5px;
        max-width: 100%;
    }
    @include large-desktop {
        // padding-left: 0;
        // padding-right: 0;
    }
    @include mobile-full{
        // padding-left: 0;
        // padding-right: 0;
    }
    .container {
        padding: 10px;
        background: #FFF;
        @include touch {
            //padding-left: 32px;
            //padding-right: 32px;
            max-width: 100%;
        }
        @include xs-mobile {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }
        @include mobile-full{
            padding-left: 0;
            padding-right: 0;
            padding-top: 0px;
        }
        .left {
            text-align: left;
        }
        .center {
            text-align: center;
        }
        /* Redactor Styles */
        h1 {
            padding: 60px 0 10px 0;
            @include small-mobile {
                font-size: 25px;
            }
        }
        em {
            font-weight: inherit;
            color: inherit;
        }
        blockquote {
            font-weight: 400;
            font-size: 25px;
            line-height: 150%;
            margin: 20px 0 40px 0;
            padding-left: 80px;
            color: #00464F;
            position: relative;
            &:before {
                position: absolute;
                content: '“';
                color: $orange;
                font-size: 4em;
                top: 30px;
                left: 0;
            }
        }
        h2{
            color: #00464F;
            font-size: 39px;
            // line-height: 31px;
            @include small-mobile{
                font-size: 25px;
                // line-height: 25px;
            }
        }
        h3{
            color: #00464F;
            font-size: 25px;
            @include small-mobile{
                font-size: 20px;
            }
        }
        h4{
            // color: #00464F;
            font-size: 20px;
            @include small-mobile{
                font-size: 18px;
            }
        }
    }
}

section.contentBlock.standardContent{
    .container{
        p{
            // em{
            //     color: #00464F;
            //     font-weight: bold;
            // }
        }
    }
}
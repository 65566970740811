#philanthropyDirectorExtra-intro-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.philanthropyDirectorExtra-intro-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 30000px;
    top: 0;
    left: 0;
    margin-top: -30px;
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: rgb(62, 62, 62) double 3px;
}
.philanthropyDirectorExtra-intro-content-container > h2 {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  color: rgb(62, 62, 62);
  font-size: 25px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.philanthropyDirectorExtra-intro-content-container > h3 {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  color: #e3eaea;
  font-size: 40px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.philanthropyDirectorExtra-intro-content-container > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62);
    font-size: 17px;
    text-align: left;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .philanthropyDirectorExtra-intro-content-container > ul > li {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: rgb(62, 62, 62); 
    line-height: 2;
  }
  @media only screen and (max-width: 1000px) {
  .philanthropyDirectorExtra-intro-content-container {
      align-items: center;
      max-width: 600px;
      max-height: 3000px;
  }
  }

  @media only screen and (max-width: 700px) {
  .philanthropyDirectorExtra-intro-content-container {
      max-width: 450px;
  }
  }
  @media only screen and (max-width: 500px) {
  .philanthropyDirectorExtra-intro-content-container {
      max-width: 300px;
  }
  .philanthropyDirectorExtra-intro-content-container > h2 {
    font-size: 20px;
  }
  .philanthropyDirectorExtra-intro-content-container > h3 {
    font-size: 30px;
  }
  }
  @media only screen and (max-width: 380px) {
  .philanthropyDirectorExtra-intro-content-container {
      max-width: 280px;
      max-height: 5000px;
  }
  .philanthropyDirectorExtra-intro-content-container > h2 {
    font-size: 16px;
  }
  .philanthropyDirectorExtra-intro-content-container > h3 {
    font-size: 25px;
  }
  .philanthropyDirectorExtra-intro-content-container > p {
    font-size: 16px;
    }
  }
  @media only screen and (max-width: 350px) {
  .philanthropyDirectorExtra-intro-content-container {
      max-width: 260px;
  }
  }